import React, {useState} from 'react';
import {useDidUpdate} from '@julaya/common/hooks';
import {Drawer} from '@julaya/common/ui/molecules';
import {SearchForm} from '@julaya/common/ui/atoms';
import {Typography} from '@julaya/common/system';
const SvgFailIllustration = require('@julaya/common/assets/images/app/no-data.png').default;

import {Text} from '@julaya/common/ui/atoms';

import ReceiverItem from './ReceiverItem';

import './BeneficiaryDrawer.scss';

const BeneficiaryDrawer = ({latestReceivers, services, onSelect, query, setQuery, loading, drawerOpen, setDrawerOpen}) => {
  const [localOptions, setLocalOptions] = useState([]);

  const options = query === '' ? latestReceivers : services;

  let content = null;

  useDidUpdate(() => {
    setLocalOptions(options);
  }, [options]);

  if (loading) {
    content = (
      <>
        <div className="beneficiaries-select-drawer__section-title">{query}</div>
        <div className="beneficiaries-select-drawer__loader">
          <div className="beneficiaries-select-drawer__dot-flashing"></div>
          <div className="beneficiaries-select__separator"></div>
          <Text className="--center">
            Renseignez un nom, un numéro de téléphone, un IBAN, un numéro de carte, un tracking number ou une référence de wallet
          </Text>
        </div>
      </>
    );
  } else if (Array.isArray(localOptions) && localOptions.length) {
    if (query) {
      content = (
        <>
          <div className="beneficiaries-select-drawer__section-title">{query}</div>
          <div className="beneficiaries-select-drawer__services">
            {localOptions?.map((item, i) => {
              return (
                <ReceiverItem
                  key={`b-${i}`}
                  onClick={serviceImage => {
                    onSelect(item, serviceImage);
                  }}
                  receiver={item}
                />
              );
            })}
          </div>
        </>
      );
    } else {
      const sections = {
        history: 'Mes transactions récentes',
        wallet: 'Mes comptes',
        beneficiary: 'Mes bénéficiaires',
        'prepaid-card': 'Mes cartes bancaires',
        company: 'Entreprises partenaires'
      };
      const data = localOptions.reduce(
        (acc, item) => {
          if (item.type && acc[item.type]) {
            acc[item.type].push(item);
          }
          return acc;
        },
        {history: [], beneficiary: [], wallet: [], company: [], 'prepaid-card': []}
      );
      content = Object.keys(data).map((section, sId) => {
        if (Array.isArray(data[section]) && data[section].length) {
          return (
            <React.Fragment key={`s-${sId}`}>
              <div className="beneficiaries-select-drawer__section-title">{sections[section]}</div>
              <div className="beneficiaries-select-drawer__services">
                {data[section].map((item, i) => {
                  return (
                    <ReceiverItem
                      key={`b-${i}`}
                      onClick={serviceImage => {
                        onSelect(item, serviceImage);
                      }}
                      receiver={item}
                    />
                  );
                })}
              </div>
            </React.Fragment>
          );
        } else {
          return null;
        }
      });
    }
  } else if (query && options.length === 0) {
    content = (
      <div className="beneficiaries-select-drawer__content-empty">
        <img src={SvgFailIllustration} alt="Aucun résultat trouvé" width={200} height={150} />
        <Typography className="mt-2" tag="h6" variant="h6" lead={true}>
          Aucun résultat trouvé.
        </Typography>
        <Typography tag="p" color="neutral">
          Nous n’avons pas trouvé ce que vous recherchez, veuillez réessayer autrement.{' '}
        </Typography>
      </div>
    );
  } else {
    content = (
      <div className="beneficiaries-select-drawer__no-data">
        <img src={require('@julaya/common/assets/images/transfer-rapid-illustrations/transfert.png').default} alt={'Service'} width="30%" />

        <Text big bold>
          Qui est le destinataire de votre transfert ?
        </Text>
        <div>Choisissez parmi votre liste de destinataires ou entrez un nouveau numéro de téléphone.</div>
      </div>
    );
  }

  return (
    <Drawer setOpen={setDrawerOpen} open={drawerOpen} title="Choix du destinataire" icon="jla-icon-wallet" color="primary">
      <Drawer.Content className="beneficiaries-select-drawer">
        <div className="beneficiaries-select-drawer__search-form">
          <SearchForm placeholder={'Rechercher...'} value={query} onChange={setQuery} name="select-search-beneficiary" variant="grey" />
        </div>

        <div className="beneficiaries-select-drawer__content">{content}</div>
      </Drawer.Content>
    </Drawer>
  );
};

export default BeneficiaryDrawer;
