// ---  POSSIBLE ACLS --- //
// 'RESTRICTIONS_WALLET',
// 'RESTRICTIONS_USER',
// 'RESTRICTIONS_EXPENSE_POLICY',
// 'RESTRICTIONS_BENEFICIARY',
// 'RESTRICTIONS_SMS_TRANSFER_BULK',
// 'RESTRICTIONS_SMS_TRANSFER_RAPID',
// 'ACCESS_ONLY_1_WALLET',
// 'LIMIT_EXPORT_PERSONALIZED_30_DAYS',
// 'LIMIT_EXPORT_REGULAR_1_YEAR',
// 'FEATURE_EXPORT_TEMPLATE',
// 'LIMIT_15_TRANSACTION',
// 'FEATURE_LOAN',
// 'FEATURE_ACTIVITY_LOG'

const useSubscriptionRestrictions = (company, restriction) => {
  if (!company || !company.plan || !Array.isArray(company.plan.acls) || company.plan.acls.length === 0) {
    return false;
  }

  return company.plan.acls.includes(restriction);
};

export default useSubscriptionRestrictions;
