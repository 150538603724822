/* eslint-disable react-hooks/exhaustive-deps */
import { useDebounce } from '@julaya/common/hooks';
import { Text } from '@julaya/common/ui/atoms';
import { useEffect, useState } from 'react';
import DrawerSelector from './DrawerSelector';
import ReceiverInfoDisplay from './ReceiverInfoDisplay';

import './BeneficiariesSelect.scss';

const BeneficiariesSelect = props => {
  const {
    services,
    latestReceivers,
    servicesTags,
    handleChange,
    errors,
    setErrors,
    values,
    getValidationState,
    getServicesLoading,
    getServicesByQuery,
    getServices,
    setNextButtonDisabled,
    onSubmit,
    canSeeOnlyOne
  } = props;

  const [query, setQuery] = useState('');
  const [receiver, setReceiver] = useState(null);
  const [loading, setLoading] = useState(null);

  const [filteredReceivers, setFilteredReceivers] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);

  const debouncedSearchTerm = useDebounce(query, 900);

  useEffect(() => {
    if (receiver && values.externalFullname) {
      setNextButtonDisabled(false);
    } else {
      setNextButtonDisabled(true);
    }
  }, [receiver]);

  useEffect(() => {
    if (query.length > 3 && values.walletId) {
      getServicesByQuery(values.walletId, {query, way: 'out'}, [['beneficiary']]);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (query.length > 0) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [query]);

  useEffect(() => {
    if (typeof getServicesLoading == 'boolean') {
      setLoading(getServicesLoading);
    }
  }, [getServicesLoading]);

  useEffect(() => {
    if (receiver) {
      if ((receiver.externalFullname || receiver.serviceSelected.externalFullname) && receiver.serviceSelected.isExternalFullnameVerified) {
        onSubmit();
      }
    }
  }, [receiver]);

  useEffect(() => {
    if (canSeeOnlyOne) {
      const filtered = latestReceivers.filter(receiver => {
        return !['transfer'].includes(receiver.typeSlug) || receiver.serviceSelected.isDefaultWallet;
      });
      const filteredServices = services.filter(service => { return !['transfer'].includes(service.typeSlug) || service.isDefaultWallet; });

      setFilteredReceivers(filtered);
      setFilteredServices(filteredServices)
    } else {
      setFilteredReceivers(latestReceivers);
      setFilteredServices(services);
    }
  }, [canSeeOnlyOne, latestReceivers, services]);

  return (
    <div className="beneficiaries-select">
      <DrawerSelector
        query={query}
        setQuery={setQuery}
        latestReceivers={filteredReceivers}
        services={filteredServices}
        onSelect={data => {
          handleChange({
            target: {
              name: 'externalReference',
              value: data.serviceSelected.externalReference
            }
          });

          handleChange({
            target: {
              name: 'externalFullname',
              value: data.serviceSelected.externalFullname ?? ''
            }
          });
          handleChange({
            target: {
              name: 'typeSlug',
              value: data.typeSlug
            }
          });
          handleChange({
            target: {
              name: 'serviceSlug',
              value: data.serviceSelected.serviceSlug
            }
          });
          setReceiver(data);
          setErrors({});
        }}
        loading={loading}
        setLoading={setLoading}
        servicesTags={servicesTags}
        currentStep={props.currentStep}
      />
      {errors.externalReference && <Text color="error">{getValidationState(values.externalReference, 'externalReference')}</Text>}

      <div className="beneficiaries-select__separator"></div>

      {receiver ? (
        <ReceiverInfoDisplay receiver={receiver} {...props} />
      ) : (
        <>
          <div className="beneficiaries-select__no-data">
            <img src={require('@julaya/common/assets/images/transfer-rapid-illustrations/transfert.png').default} alt={'Service'} width="30%" />

            <Text big bold>
              Qui est le destinataire de votre transfert ?
            </Text>
            <div>Choisissez parmi votre liste de destinataires ou entrez un nouveau numéro de téléphone.</div>
          </div>
        </>
      )}
    </div>
  );
};

export default BeneficiariesSelect;
