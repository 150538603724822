import React from "react";
import moment from "moment";

import { functions } from "@julaya/common/utils";

const DetailBody = ({ batchDetails }) => {
  const { metadata, status } = batchDetails;
  const sum = batchDetails ? functions.getBatchSum(batchDetails, true) : "-";
  const fees = batchDetails ? functions.getBatchFees(batchDetails, true) : "-";
  const total = batchDetails ? functions.getBatchTotal(batchDetails) : "-";

  const sentAt =
    Array.isArray(batchDetails?.TaskSignatures) &&
    batchDetails.TaskSignatures.length > 0
      ? batchDetails.TaskSignatures[batchDetails.TaskSignatures.length - 1]
          .createdAt
      : null;

  return (
    <div className="task-detail__body">
      {/* Montant total */}
      <div className="mb-3">
        <div className="font-weight-bold text-muted small mb-1">
          Montant total
        </div>
        <div className="font-weight-bold">{sum}</div>
      </div>
      {/* Frais total */}
      <div className="mb-3">
        <div className="font-weight-bold text-muted small mb-1">
          Frais total
        </div>
        <div className="font-weight-bold">{fees}</div>
      </div>
      {/* Date d'envoi */}
      <div className="mb-3">
        <div className="font-weight-bold small mb-1">Date d'envoi</div>
        <div className="font-weight-bold">
          {sentAt ? moment(sentAt).format("DD/MM/YYYY - HH:mm") : "-"}
        </div>
      </div>
      {/* Nombre de destinataires */}
      <div className="mb-3">
        <div className="font-weight-bold text-muted small mb-1">
          Nombre de destinataires
        </div>
        <div className="font-weight-bold">{total}</div>
      </div>
      {/* Statut des opérations */}

      {!["draft", "to-validate"].includes(status) && (
        <div>
          <div className="font-weight-bold text-muted small mb-1">
            Statut des opérations
          </div>
          <div className="font-weight-bold">
            {metadata.nbSuccess} sur{" "}
            {metadata.nbPending + metadata.nbFailed + metadata.nbSuccess}
          </div>
        </div>
      )}
    </div>
  );
};

export default DetailBody;
