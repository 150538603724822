import { Col, Container, Row } from "reactstrap";

const DepositReceipt = ({ item, type = "bank-receipt" }) => {
  const getFileName = (image) => {
    const parts = image.split("/");
    return parts[parts.length - 1];
  };

  return item.attachment !== null ? (
    <div className="py-3">
      <div className="mb-2 d-flex align-items-center">
        <div>
          Preuve de dépôt {type === "bank-receipt" ? "bancaire" : "Moov"}
        </div>
      </div>
      <Container className="px-0">
        <Row className="no-gutters">
          <Col xs={18} className="overflow-hidden">{getFileName(item.attachment)}</Col>
          <Col xs={6} className="d-flex justify-content-end">
            <a
              className="btn btn-primary btn-sm"
              href={item.attachment}
              rel="noopener noreferrer"
              target="_blank"
            >
              Consulter
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  ) : null;
};

export default DepositReceipt;
