/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect} from 'react';
import {constants} from '../utils';
import useDidUpdate from './useDidUpdate';

const useHistoryFilter = (getHistory, walletType, walletId, batchId = undefined, downloadHistory = null) => {
  const [minDate, setMinDateFunc] = useState('');
  const [maxDate, setMaxDateFunc] = useState('');
  const [status, setStatus] = useState([]);
  const [transactionType, setTransactionType] = useState([]);
  const [initiator, setInitiator] = useState([]);
  const [validator, setValidator] = useState([]);
  const [query, setQuery] = useState('');
  const [reset, setReset] = useState(0);

  const [page, setPage] = useState(1);
  const [filtered, setFiltered] = useState(false);

  const setMinDate = (date, reload = false) => {
    setMinDateFunc(date);
    if (reload) setReset(reset + 1);
  };

  const setMaxDate = (date, reload = false) => {
    setMaxDateFunc(date);
    if (reload) setReset(reset + 1);
  };

  const resetFilters = () => {
    setMinDate('');
    setMaxDate('');
    setStatus([]);
    setTransactionType([]);
    setInitiator([]);
    setValidator([]);
    setQuery('');
    setPage(1);
    setFiltered(false);
    setReset(reset + 1);
  };

  useEffect(() => {
    callGetHistory();
  }, [reset]);

  useEffect(() => {
    setPage(1);
  }, [minDate, maxDate, status, transactionType, query]);

  useDidUpdate(() => {
    if (constants.platform === 'app' || page > 0) {
      callGetHistory();
    }
  }, [page, filtered]);

  const callGetHistory = () => {
    let formattedMinDate = null;
    let formattedMaxDate = null;

    if (minDate !== '' && minDate !== null) {
      formattedMinDate = minDate.split('/').reverse().join('-');
    }
    if (maxDate !== '' && maxDate !== null) {
      formattedMaxDate = maxDate.split('/').reverse().join('-');
    }

    const myData = {
      minDate: formattedMinDate,
      maxDate: formattedMaxDate,
      status,
      query,
      transactionType,
      // initiator,
      // validator,
      page,
      // walletType: walletType?.toUpperCase(),
      walletId: parseInt(walletId)
    };

    if (batchId) {
      myData.taskIds = [batchId];
    }

    setFiltered(
      !!(
        (myData.minDate || myData.maxDate || query !== '' || myData.status.length || myData.transactionType.length)
        // myData.initiator.length ||
        // myData.validator.length
      )
    );
    getHistory(walletId, myData);
  };

  const callDownloadHistory = (extraParam = null) => {
    if (downloadHistory) {
      const myData = {
        status,
        query,
        transactionType,
        initiator,
        validator
      };

      for (const [key, value] of Object.entries(extraParam)) {
        myData[key] = value;
      }

      downloadHistory({...myData});
    }
  };

  return {
    callGetHistory,
    callDownloadHistory,
    minDate,
    setMinDate,
    maxDate,
    setMaxDate,
    status,
    setStatus,
    transactionType,
    setTransactionType,
    query,
    setQuery,
    initiator,
    setInitiator,
    validator,
    setValidator,
    page,
    setPage,
    filtered,
    resetFilters
  };
};

export default useHistoryFilter;
