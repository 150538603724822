import React from 'react';
import {Link} from 'react-router-dom';
import {functions} from '@julaya/common/utils';
import {Button} from '@julaya/common/ui/atoms';
import {Icon} from '@julaya/common/system';

const TaskCard = props => {
  const {batch} = props;

  return (
    <Link to={`/tasks/details/${batch.id}`} className="tasks-card">
      <div className="tasks-card__content">
        <div className="d-flex flex-column w-100">
          {/* Header */}
          <div className="d-flex align-items-center justify-content-between">
            <div className="tasks-amount mb-0 h4 mt-2 font-weight-bold">
              <div className="h6 text-muted">{batch.Wallet.label}</div>
              {functions.formatMoney(batch.metadata ? batch.metadata.sum : 0)}
            </div>

            <Button variant="badge" icon="jla-icon-task" rounded />
          </div>

          {/* Body */}
          <div className="tasks-card__body" title={batch.title}>
            {batch.title}
          </div>

          {/* Footer */}
          <div className="text-muted tasks-card__footer">
            {['waiting', 'to-validate', 'draft'].includes(batch.status) ? (
              <div className="tasks-card__transactions-count">
                <Icon name="bulk" size="h5" />
                <div className="ml-2 font-weight-bold">{batch.metadata?.total || batch.sTotal || 0} transaction(s)</div>
              </div>
            ) : batch.status === 'pending' ? (
              <div className="tasks-card__transactions-checks">
                <div className="tasks-card__transactions-checks__pending">
                  <Icon name="clock" size="h5" />
                  <div className="checks-content">{batch.metadata.nbPending}</div>
                </div>
                <div className="tasks-card__transactions-checks__failed ml-3">
                  <Icon name="x-circle" size="h5" />
                  <div className="checks-content">{batch.metadata.nbFailed}</div>
                </div>
                <div className="tasks-card__transactions-checks__success ml-3">
                  <Icon name="bulk" size="h5" />
                  <div className="checks-content">{batch.metadata.nbSuccess}</div>
                </div>
              </div>
            ) : batch.status === 'retry' ? (
              <div className="tasks-card__transactions-retry">
                <i className="icon-retry"></i>
                <div className="ml-2">{batch.metadata.nbFailed} transaction(s) rejouable(s)</div>
              </div>
            ) : (
              <div></div>
            )}
            <div className="d-flex align-items-center">
              <span className="small mr-1">{batch.TaskActivities.length}</span>
              <Icon name="chat-bubble" size="h5" />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default TaskCard;
