import React from 'react';
import cx from 'classnames';
import './AdvanceCollapse.scss';

const AdvanceCollapse = ({children, className = '', isOpen = false}) => {
  return (
    <div
      className={cx('jla-adv-collapse', className, {
        '--open': isOpen
      })}
    >
      {children}
    </div>
  );
};

AdvanceCollapse.Header = ({children, className = ''}) => {
  return <div className={cx('jla-adv-collapse__header', className)}>{children}</div>;
};

AdvanceCollapse.Content = ({children, className = ''}) => {
  return (
    <div className={cx('jla-adv-collapse__content', className)}>
      <div className="jla-adv-collapse__content-inner">{children}</div>
    </div>
  );
};

export default AdvanceCollapse;
