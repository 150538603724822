/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState } from 'react';

import cx from 'classnames';
import { Redirect, useHistory } from 'react-router-dom';

import { useDidUpdate, useForm } from '@julaya/common/hooks';

import { JSpinner } from 'components/UI';
import { v4 as uuidv4 } from 'uuid';

const getValidationState = (str, name) => {
  if (name === 'password') {
    if (str === '') {
      return 'Champ obligatoire';
    } else if (str.length < 4) {
      return 'Vous devez indiquer 4 caractères';
    }
  } else if (name === 'otp') {
    if (str === '') {
      return 'Champs obligatoire';
    }

    if (str.length !== 6) {
      return 'Le code doit comporter 6 caractères';
    }
  }
  return false;
};

const isSignature = (signatures, user, wallet) => {
  if (user.isSuperSignature) {
    return false;
  }
  const count = Array.isArray(signatures) ? signatures.length + 1 : 1;
  if (wallet.nbSignatures === count) {
    return false;
  }
  return true;
};

const PasswordModal = ({showModal, user, signTask, taskId, signTaskLoading, signTaskError, signatures, wallet}) => {
  const [values, handleChange, errors, setErrors] = useForm({
    password: '',
    otp: ''
  });

  const [activeIndex, setActiveIndex] = useState(0);
  const [redirect, setRedirect] = useState(false);

  const history = useHistory();

  const uniqueId = useMemo(() => uuidv4(), []);

  const onSubmit = e => {
    e.preventDefault();

    let mErrors = {};
    if (getValidationState(values.password)) {
      mErrors.password = true;
    }
    if (user?.tfaActive && getValidationState(values.otp)) {
      mErrors.otp = true;
    }

    if (Object.keys(mErrors).length !== 0) {
      setErrors(mErrors);
    } else {
      signTask(taskId, values.password, values.otp, uniqueId);
    }
  };

  useDidUpdate(() => {
    if (signTaskLoading === false && signTaskError === '') {
      setActiveIndex(1);
    }
  }, [signTaskLoading, signTaskError]);

  if (redirect) {
    return <Redirect to={`/tasks`} />;
  }

  return (
    <div className={`modal-associates-types-container ${showModal ? 'open' : ''}`}>
      <div
        className="modal-associates-types"
        style={{
          height: '40%',
          borderRadius: '15px 15px 0px 0px'
        }}>
        {/* Modal Header */}
        <div className="modal-associates-head">
          <div
            className="d-flex flex-row align-items-center justify-content-between"
            style={{
              width: '100%'
            }}>
            <div className="d-flex align-items-center">
              <div className="h5 mb-0" style={{fontSize: '1.2rem'}}>
                Finalisation du transfert
              </div>
            </div>
          </div>
        </div>
        {/* Modal Body */}
        <div className="modal-associates-body" style={{padding: '0'}}>
          {/* Modal Body : Show password & OTP inputs */}
          {activeIndex === 0 && (
            <form className="needs-validation d-flex flex-column flex-grow-1" onSubmit={onSubmit}>
              <div className="container-fluid p-4 flex-grow-1">
                <div className="row">
                  <div className="col-24 form-group">
                    <label htmlFor="password">Mot de passe</label>
                    <input
                      id="password"
                      className={cx('form-control bg-white', {
                        'is-invalid': errors.password
                      })}
                      type="password"
                      name="password"
                      placeholder="Entrez votre mot de passe"
                      value={values.password}
                      onChange={handleChange}
                      autoComplete="off"
                      required
                      style={{
                        height: '3rem',
                        borderRadius: '10px',
                        border: '1px solid #979797'
                      }}
                    />
                    <div className="invalid-feedback">{getValidationState(values.password, 'password')}</div>
                  </div>
                </div>

                {user.tfaActive && (
                  <div className="row mt-2">
                    <div className="col-24 form-group">
                      <label className="text-muted" htmlFor="otp">
                        Code d’authentification deux facteurs
                      </label>
                      <input
                        id="otp"
                        className={cx('form-control', {
                          'is-invalid': errors.otp
                        })}
                        type="otp"
                        name="otp"
                        placeholder="Entrez votre code"
                        value={values.otp}
                        onChange={handleChange}
                        autoComplete="off"
                        required
                        style={{
                          height: '3rem',
                          borderRadius: '10px',
                          border: '1px solid #979797'
                        }}
                      />

                      <div className="invalid-feedback">{getValidationState(values.otp, 'otp')}</div>
                    </div>
                  </div>
                )}
                {signTaskError && (
                  <div className="row mt-2">
                    <div className="col-24 text-danger">{signTaskError}</div>
                  </div>
                )}
              </div>
              <div className="d-flex p-4 w-100 position-absolute" style={{bottom: '0'}}>
                <button
                  type="button"
                  className="btn w-100 btn-light mr-2"
                  onClick={() => {
                    history.goBack();
                  }}>
                  {' '}
                  Annuler
                </button>
                <button type="submit" className="btn w-100 btn-primary" disabled={signTaskLoading}>
                  {signTaskLoading && (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{' '}
                    </>
                  )}
                  Signer
                </button>
              </div>
            </form>
          )}
          {/* Modal Body : Show success message */}
          {activeIndex === 1 &&
            (signTaskLoading ? (
              <div className="position-absolute" style={{top: ' 50%', right: '50%'}}>
                <JSpinner />
              </div>
            ) : (
              <>
                {isSignature(signatures, user, wallet) ? (
                  <>
                    <div className="p-4 ">
                      <img
                        src={require('@julaya/common/assets/images/rapid-transfer/rapid-transfer-success.png').default}
                        alt="Success Icon"
                        height="140px"
                        className={'w-100 mb-2'}
                      />
                      <div className="text-muted mb-3">
                        Bravo, le transfert groupé a été pris en compte, vous pouvez dès maintenant demander sa validation au près de votre
                        administrateur.
                      </div>

                      <div className="d-flex text-success mb-3">
                        <div className="mr-2 bg-success bullet-icon">
                          <i className="icon-check text-white"></i>
                        </div>
                        <span>Opérations enregistrées avec succès</span>
                      </div>
                    </div>
                    <div className="p-4 w-100 position-absolute" style={{bottom: '0'}}>
                      <button type="button" className="btn btn-primary w-100" onClick={() => setRedirect(true)}>
                        Consulter l'historique
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="p-4 ">
                      <img
                        src={require('@julaya/common/assets/images/rapid-transfer/rapid-transfer-success.png').default}
                        alt="Success Icon"
                        height="120px"
                        className={'w-100 mb-2'}
                      />
                      <div className="text-muted mb-3">Retrouvez dès maintenant vos transactions dans votre historique de paiements.</div>

                      <div className="d-flex text-success mb-3">
                        <div className="mr-2 bg-success bullet-icon">
                          <i className="icon-check text-white"></i>
                        </div>
                        <span>Opérations enregistrées avec succès</span>
                      </div>
                    </div>
                    <div className="p-4 w-100 position-absolute" style={{bottom: '0'}}>
                      <button type="button" className="btn btn-primary w-100" onClick={() => setRedirect(true)}>
                        Consulter l'historique
                      </button>
                    </div>
                  </>
                )}
              </>
            ))}
        </div>
      </div>
    </div>
  );
};

export default PasswordModal;
