import React from 'react';
import {Button} from '../../atoms';

const StepHeader = ({title, step, currentStep, setCurrentStep, completedStep}) => {
  return (
    <div className="multiStepForm__step__header">
      <div className="title">
        {step < currentStep ? (
          <Button variant="badge" className="icon" icon="icon-check-circle-outline" status="success" size="medium" />
        ) : step > currentStep ? (
          <Button variant="badge" className="icon" text={step + 1} size="medium" />
        ) : (
          <Button variant="badge" className="icon" icon="icon-arrow-circle-right" size="medium" />
        )}
        {title}
      </div>

      {step < currentStep ? (
        <Button
          icon="icon-chevron-down"
          action={() => {
            setCurrentStep(step);
          }}
          status="neutral"
        />
      ) : step > currentStep ? (
        <Button icon="icon-chevron-down" />
      ) : (
        <Button
          icon="icon-chevron-up"
          action={() => {
            if (step < completedStep) {
              setCurrentStep(completedStep);
            }
          }}
        />
      )}
    </div>
  );
};

export default StepHeader;
