/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo} from 'react';
import {functions} from '@julaya/common/utils';

const Pagination = ({count, length, loading, page, setPage}) => {
  const {maxNbPage, disabledLeft, disabledRight} = useMemo(() => functions.getPagination(page, count, length, loading), [count, length, loading]);
  if (loading) {
    return (
      <div className="d-flex align-items-center">
        <div className="mr-2 placeholder-line" style={{width: 50}}></div>
        <div className="placeholder-square"></div>
      </div>
    );
  }
  return (
    <div className="d-flex align-items-center">
      <div className="pagination-footer rounded bg-body" role="group" aria-label="Pagination">
        <button type="button" className="btn btn-grey" disabled={disabledLeft} onClick={() => setPage(page - 1 || 1)}>
          <i className="jla-icon-chevron-left header-icon"></i>
        </button>

        <span className="mr-2">
          {page} sur {maxNbPage}
        </span>

        <button type="button" disabled={disabledRight} className="btn btn-grey" onClick={() => setPage(page < maxNbPage ? page + 1 : maxNbPage)}>
          <i className="jla-icon-chevron-right header-icon"></i>
        </button>
      </div>
    </div>
  );
};

export default Pagination;
