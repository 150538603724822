import { network, functions, constants } from '@julaya/common/utils';
import {
	GET_USER_LIST_REQUEST,
	GET_USER_LIST_SUCCESS,
	GET_USER_LIST_FAILURE,
	CREATE_USER_REQUEST,
	CREATE_USER_SUCCESS,
	CREATE_USER_FAILURE,
	GET_USER_REQUEST,
	GET_USER_SUCCESS,
	GET_USER_FAILURE,
	GET_IS_VALID_PHONE_REQUEST,
	GET_IS_VALID_PHONE_SUCCESS,
	GET_IS_VALID_PHONE_FAILURE
} from '../actionTypes';

export const getUserList =
	(params = { query: '', page: 1, pageSize: 20, archived: false, role: constants.ROLES }) =>
	async (dispatch) => {
		dispatch({ type: GET_USER_LIST_REQUEST });
		try {
			const response = await network.request(`/admin/users/list`, 'POST', params);
			const users = response.data;

			dispatch({
				type: GET_USER_LIST_SUCCESS,
				payload: {
					users
				}
			});
		} catch (error) {
			dispatch({ type: GET_USER_LIST_FAILURE, error: error.message });
		}
	};

export const createUser = (params) => {
	return async (dispatch, getState) => {
		dispatch({
			type: CREATE_USER_REQUEST
		});

		try {
			const {
				auth: { tfaActive }
			} = getState();

			const args = {
				...params,
				password: await functions.encryptCode(password)
			};

			if (tfaActive) {
				args.otp = otp;
			}
			await network.request(`/admin/users`, 'POST', args);

			dispatch({
				type: CREATE_USER_SUCCESS
			});
		} catch (error) {
			dispatch({ type: CREATE_USER_FAILURE, error: error.message });
		}
	};
};


export const getUser = (args) => async (dispatch) => {
	dispatch({
		type: GET_USER_REQUEST
	});
	try {
		const userDetails = await network.request(`/admin/users/find`, 'POST', args);
		dispatch({
			type: GET_USER_SUCCESS,
			payload: {
				userDetails
			}
		});
	} catch (error) {
		dispatch({ type: GET_USER_FAILURE, error: error.message });
	}
}

export const clearUserDetail = () => async (dispatch) => {
	dispatch({
		type: GET_USER_SUCCESS,
		payload: { userDetails: null },
	});
}

export const validatePhone = (args) => async (dispatch) => {
	dispatch({
		type: GET_IS_VALID_PHONE_REQUEST
	});
	try {
		await network.request(`/admin/users/find`, 'POST', args);
		dispatch({
			type: GET_IS_VALID_PHONE_SUCCESS,
			payload: {
				isValidPhone: true
			}
		});
	} catch (error) {
		dispatch({ type: GET_IS_VALID_PHONE_FAILURE, error: error.message, isValidPhone: false });
	}
}

export const clearValidatePhoneState = () => async (dispatch) => {
	dispatch({
		type: GET_IS_VALID_PHONE_SUCCESS,
		payload: { isValidPhone: false },
	});
}
