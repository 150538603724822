/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {matchPath, useLocation, useHistory} from 'react-router-dom';

import routing from '../../../routing';
import {Icon} from '@julaya/common/system';

const Header = ({appNavigation}) => {
  const navigationData = routing.getNavigation(routing.appRoutes);

  const {pathname} = useLocation();
  const history = useHistory();
  const [data] = useState(navigationData);
  const [icon, setIcon] = useState(null);
  const [action, setAction] = useState(null);
  const [title, setTitle] = useState(null);

  useEffect(() => {
    let match = null;
    const paths = Object.keys(data);
    for (let index = 0; index < paths.length; index++) {
      const path = paths[index];

      const m = matchPath(pathname, {
        path,
        isExact: true
      });
      if (m?.isExact) {
        match = m;
      }
    }

    if (match) {
      let path = paths.find(route => match.path === route);

      if (data[path]) {
        setTitle(data[path]?.title || null);
        const aaa = history.goBack;
        setAction(data[path]?.goBack ? () => aaa : null);
        setIcon(data[path]?.goBack ? 'chevron-left' : null);
      }
    }
  }, [pathname, data]);

  useEffect(() => {
    if (appNavigation) {
      setTitle(appNavigation?.title || null);
      setAction(typeof appNavigation?.action === 'function' ? () => appNavigation.action : null);
      setIcon(appNavigation?.icon ? appNavigation?.icon : null);
    }
  }, [appNavigation]);

  return (
    <div className="header-bar">
      <div className="header-left">
        {
          typeof action === 'function' && icon !== 'reload' ? (
            <a
              href="#julaya"
              onClick={e => {
                e.preventDefault();
                action();
              }}>
              <Icon color={'neutral'} name={'chevron-left'} size="h5" />
            </a>
          ) : null
          // icon && <i className={`icon-${icon} h3 m-0 text-white`}></i>
        }

        {title && <div className="title">{title}</div>}
      </div>
      <div className="header-right">
        {/* <a
          href='#julaya'
          onClick={e => {
            e.preventDefault();
            // setNotificationsOpened("notifications");
          }}
        >
          <i className='icon-notification h3 m-0 text-white'></i>
        </a> */}
        {icon === 'reload' && (
          <a
            href="#julaya"
            onClick={e => {
              e.preventDefault();
              action();
            }}>
            <i className={`jla-icon-refresh h3 m-0 text-white`}></i>
          </a>
        )}
      </div>
    </div>
  );
};

export default Header;
