import {network} from '@julaya/common/utils';

import {
  GET_BENEFICIARIES_REQUEST,
  GET_BENEFICIARIES_SUCCESS,
  GET_BENEFICIARIES_FAILURE,
  CREATE_BENEFICIARY_REQUEST,
  CREATE_BENEFICIARY_SUCCESS,
  CREATE_BENEFICIARY_FAILURE,
  UPDATE_BENEFICIARY_REQUEST,
  UPDATE_BENEFICIARY_SUCCESS,
  UPDATE_BENEFICIARY_FAILURE,
  DELETE_BENEFICIARY_REQUEST,
  DELETE_BENEFICIARY_SUCCESS,
  DELETE_BENEFICIARY_FAILURE
} from '../actionTypes';

export const getBeneficiaries = data => async dispatch => {
  dispatch({
    type: GET_BENEFICIARIES_REQUEST,
    payload: {
      beneficiaries: [],
      beneficiariesCount: 0
    }
  });

  const {page = 1, pageSize = 20, query = ''} = data;

  try {
    const queryParameters = network.encodeQueryParameters({page, pageSize, query});

    const response = await network.request(`/admin/beneficiaries/list?${queryParameters}`, 'GET');

    if (!Array.isArray(response.data)) {
      throw new Error('Des erreurs sont survenues, veuillez rééssayer');
    }
    const beneficiariesCount = typeof response.count !== 'undefined' ? response.count : 0;
    dispatch({
      type: GET_BENEFICIARIES_SUCCESS,
      payload: {
        beneficiariesPage: page,
        beneficiaries: response.data,
        beneficiariesCount
      }
    });
  } catch (error) {
    dispatch({type: GET_BENEFICIARIES_FAILURE, error: error.message});
  }
};

export const createBeneficiary = params => async dispatch => {
  dispatch({
    type: CREATE_BENEFICIARY_REQUEST
  });

  try {
    await network.request(`/admin/beneficiaries`, 'POST', params);

    dispatch({
      type: CREATE_BENEFICIARY_SUCCESS
    });
  } catch (error) {
    dispatch({type: CREATE_BENEFICIARY_FAILURE, error: error.message});
  }
};

export const updateBeneficiary = (params, beneficiaryId) => async dispatch => {
  dispatch({
    type: UPDATE_BENEFICIARY_REQUEST
  });

  try {
    await network.request(`/admin/beneficiaries/${beneficiaryId}`, 'PUT', params);

    dispatch({
      type: UPDATE_BENEFICIARY_SUCCESS
    });
  } catch (error) {
    dispatch({type: UPDATE_BENEFICIARY_FAILURE, error: error.message});
  }
};

export const deleteBeneficiary = (params, beneficiaryId) => async dispatch => {
  dispatch({
    type: DELETE_BENEFICIARY_REQUEST
  });

  try {
    await network.request(`/admin/beneficiaries/${beneficiaryId}`, 'DELETE');

    dispatch({
      type: DELETE_BENEFICIARY_SUCCESS
    });
  } catch (error) {
    dispatch({type: DELETE_BENEFICIARY_FAILURE, error: error.message});
  }
};
