import React from 'react';
import cx from 'classnames';

import './Typography.scss';

const Typography = props => {
  const {className, tag: Tag = 'div', children, variant = 'body', color, lead, underline, strike, ...attrs} = props;

  return (
    <Tag
      className={cx('jla-text', className, {
        [`jla-text--${variant}`]: variant,
        [`jla-text--${color}`]: color,
        [`jla-text--lead`]: lead,
        [`jla-text--underline`]: underline,
        [`jla-text--strike`]: strike,
        [`jla-text--typo-clickable`]: attrs.onClick
      })}
      {...attrs}
    >
      {children}
    </Tag>
  );
};

export default Typography;
