import {combineReducers} from 'redux';

import baseReducer from './base';

import loading from './loading';
import error from './error';

import auth from './auth';
import history from './history';
import transaction from './transaction';
import taskManager from './taskManager';
import documents from './documents';
import beneficiaries from './beneficiaries';
import prepaidCards from './prepaidCards';
import notifications from './notifications';
import wallets from './wallets';
import users from './users';
import ops from './ops';
import admin from './admin';

export default combineReducers({
  auth: baseReducer(...auth, false),
  history: baseReducer(...history),
  transaction: baseReducer(...transaction),
  taskManager: baseReducer(...taskManager),
  documents: baseReducer(...documents),
  beneficiaries: baseReducer(...beneficiaries),
  prepaidCards: baseReducer(...prepaidCards),
  notifications: baseReducer(...notifications),
  wallets: baseReducer(...wallets),
  users: baseReducer(...users),
  admin: baseReducer(...admin),
  ops: baseReducer(...ops),
  loading,
  error
});
