/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect} from 'react';

const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

const useEventTimout = (cond, fn, timeOutVal) => {
  const [condition, setCondition] = useState(cond);

  let timeOut;

  useEffect(() => {
    if (condition) {
      destroyAll();
    } else {
      for (var i in events) {
        window.addEventListener(events[i], resetTimeout);
      }
      createTimeout();
    }
    return destroyAll;
  }, [condition]);

  const removeTimeout = () => {
    if (timeOut) clearTimeout(timeOut);
  };

  const createTimeout = () => {
    timeOut = setTimeout(fn, timeOutVal);
  };

  const resetTimeout = () => {
    removeTimeout();
    createTimeout();
  };

  const destroyAll = () => {
    removeTimeout();

    for (var i in events) {
      window.removeEventListener(events[i], resetTimeout);
    }
  };

  return setCondition;
};

export default useEventTimout;
