import {sha256} from 'js-sha256';
import store from '@julaya/common/store';
import {storage, constants} from '@julaya/common/utils';
import {logout} from '@julaya/common/store/actions';

const formatErrors = e => {
  let message = 'Des erreurs sont survenues, veuillez rééssayer';
  const logoutMessages = ['UNAUTHORIZED', "L'utilisateur est déconnecté", 'Veuillez vous identifier pour continuer', 'Signature invalide'];
  if (e) {
    message = e.message;
  }

  if (logoutMessages.includes(message)) {
    store.dispatch(logout(true));
  }

  switch (message) {
    case 'Failed to fetch':
      message =
        "Une erreur s'est produite, nous sommes désolés de la gêne occasionnée. Il se peut que votre connexion internet soit instable. Si vous pensez que votre connexion internet n'est pas en cause, veuillez contacter le support client de Julaya pour obtenir une assistance.";
      break;
    case 'Authorization Required':
      message = "Vous n'êtes pas autorisé à accéder à cette ressource";
      break;
    default:
      break;
  }
  message = message.replace(/.*Error:/g, '');
  message = message.replace(/.*error:/g, '');

  if (errList[message]) {
    message = errList[message];
  }

  return message;
};

const request = async (endpoint, method = 'GET', params = null, isLogin = false, returnType = 'json') => {
  let options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'App-Version': constants.appVersion,
      'x-secret-token': constants.secretToken
    }
  };

  if (constants.platform === 'pro') {
    options.headers['x-julaya-origin'] = constants.julayaOriginPro;
  } else if (constants.platform === 'app') {
    options.headers['x-julaya-origin'] = constants.julayaOriginApp;
  }

  try {
    const accessToken = await storage.get('accessToken');
    const currentCompany = await storage.get('currentCompany');

    if (accessToken === null && !isLogin) {
      throw new Error('Veuillez vous identifier pour continuer');
    }
    if (accessToken !== null && accessToken.userId && !isLogin) {
      options.headers.Authorization = accessToken.id;

      const stringBody = params !== null ? JSON.stringify(params) : '{}';
      options.headers['x-signature'] = sha256.hmac(accessToken.id, stringBody + accessToken.userId + endpoint);
    }

    if (params != null) options.body = JSON.stringify(params);

    if (currentCompany !== null) {
      options.headers['x-current-company'] = currentCompany;
    }

    let res = await fetch(constants.apiUrl + endpoint, options);
    let data;

    if (returnType === 'json') {
      try {
        data = await res.json();
      } catch (error) {}
    } else if (returnType === 'text') {
      data = await res.text();
    } else {
      data = res;
    }

    if (!res.ok) {
      let message = 'Des erreurs sont survenues, veuillez rééssayer';

      if (returnType === 'raw') {
        data = await data.json();
      }

      if (data && data.error) {
        message = data.error.message ? data.error.message : data.error;
      } else if (data && data.message) {
        message = data.message;
      }

      if (htmlStatusMessages.includes(message)) {
        message = 'Des erreurs sont survenues, veuillez rééssayer';
      }

      if (message === 'INVALID_APP_VERSION') {
        message = "Une nouvelle version de l'application est disponible, Veuillez rafraîchir votre page pour mettre à jour";
        store.dispatch({
          type: 'INVALID_APP_VERSION',
          payload: {
            invalidAppVersion: true
          }
        });
      }

      if (message) throw new Error(message);
    } else if (data && data.error) {
      throw new Error(data.error.message);
    } else {
      return data;
    }
  } catch (e) {
    throw new Error(formatErrors(e));
  }
};

const fileUploader = async (endpoint, data) => {
  const formData = new FormData();
  const headers = {
    'x-secret-token': constants.secretToken,
    'App-Version': constants.appVersion
  };

  if (constants.platform === 'pro') {
    headers['x-julaya-origin'] = constants.julayaOriginPro;
  } else if (constants.platform === 'app') {
    headers['x-julaya-origin'] = constants.julayaOriginApp;
  }

  Object.keys(data).forEach(key => formData.append(key, data[key]));

  const accessToken = await storage.get('accessToken');
  const currentCompany = await storage.get('currentCompany');
  if (accessToken !== null && accessToken.userId && currentCompany) {
    headers.Authorization = accessToken.id;

    headers['x-current-company'] = currentCompany;

    const stringBody = '{}';
    headers['x-signature'] = sha256.hmac(accessToken.id, stringBody + accessToken.userId + endpoint);

    try {
      let res = await fetch(constants.apiUrl + endpoint, {
        method: 'POST',
        body: formData,
        headers
      });

      let data = await res.json();

      if (!res.ok) {
        let message = 'Des erreurs sont survenues, veuillez rééssayer';
        if (data && data.error) {
          message = data.error.message ? data.error.message : data.error;
        }
        throw new Error(message);
      } else if (data && data.error) {
        throw new Error(data.error.message || data.error);
      } else {
        return data;
      }
    } catch (e) {
      throw new Error(formatErrors(e));
    }
  } else {
    throw new Error('Veuillez vous identifier pour continuer');
  }
};

function encodeQueryParameters(data) {
  const ret = [];
  for (let d in data) ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
}

const htmlStatusMessages = [
  'Reason Phrase',
  'Continue',
  'Switching Protocols',
  'Processing',
  'OK',
  'Created',
  'Accepted',
  'Non Authoritative Information',
  'No Content',
  'Reset Content',
  'Partial Content',
  'Multi-Status',
  'Multiple Choices',
  'Moved Permanently',
  'Moved Temporarily',
  'See Other',
  'Not Modified',
  'Use Proxy',
  'Temporary Redirect',
  'Permanent Redirect',
  'Bad Request',
  'Unauthorized',
  'Payment Required',
  'Forbidden',
  'Not Found',
  'Method Not Allowed',
  'Not Acceptable',
  'Proxy Authentication Required',
  'Request Timeout',
  'Conflict',
  'Gone',
  'Length Required',
  'Precondition Failed',
  'Request Entity Too Large',
  'Request-URI Too Long',
  'Unsupported Media Type',
  'Requested Range Not Satisfiable',
  'Expectation Failed',
  "I'm a teapot",
  'Insufficient Space on Resource',
  'Method Failure',
  'Unprocessable Entity',
  'Locked',
  'Failed Dependency',
  'Precondition Required',
  'Too Many Requests',
  'Request Header Fields Too Large',
  'Unavailable For Legal Reasons',
  'Internal Server Error',
  'Not Implemented',
  'Bad Gateway',
  'Service Unavailable',
  'Gateway Timeout',
  'HTTP Version Not Supported',
  'Insufficient Storage',
  'Network Authentication Required',
  'Unknown Error'
];

const errList = {
  TASK_UUID_ALREADY_EXISTS: 'Des erreurs sont survenues, vérfiez votre historique de transactions avant de réessayer',
  SequelizeUniqueConstraintError: 'La ressource existe déjà, veuillez vérifier vos données avant de continuer', // TODO: wording
  ERROR_LOGIN_USER_PWD_INCORRECT: 'Mot de passe invalide',
  ERROR_LOGIN_USER_NOT_FOUND: 'Aucun utilisateur trouvé pour ce numéro de téléphone',
  USER_2FA_CODE_INVALID: 'Code temporaire invalide',
  ERROR_LOGIN_USER_PWD_TOO_MANY_ATTEMPTS:
    'Votre compte est bloqué car vous avez fait trop des tentatives de mot de passe. Veuillez appeler le +225 22 01 86 16 (pour un compte Ivorien), le +221 778 016 000 (pour un compte Sénégalais) ou le +229 63 20 22 22 (pour un compte Béninois) pour assistance.',
  ERROR_LOGIN_USER_NOT_ACTIVE: 'Votre compte a été désactivé, veuillez contacter le support technique',
  ERROR_FORGOT_PWD_USER_NOT_FOUND: 'Ce numéro ne correspond à aucun compte Julaya',
  ERROR_FORGOT_PWD_USER_PWD_TOO_MANY_ATTEMPTS:
    'Votre compte est bloqué car vous avez fait trop des tentatives de mot de passe. Veuillez appeler le +225 22 01 86 16 (pour un compte Ivorien), le +221 778 016 000 (pour un compte Sénégalais) ou le +229 63 20 22 22 (pour un compte Béninois) pour assistance.',
  ERROR_FORGOT_PWD_USER_NOT_ACTIVE: 'Votre compte a été désactivé, veuillez contacter votre administrateur',
  ERROR_SIGN_NO_ITEMS: 'Vous ne pouvez pas signer une tâche sans transaction(s).',
  ERROR_SIGN_ITEMS_WITH_ERROR: 'Vous ne pouvez pas signer une tâche car certaines transactions comportent des erreurs',
  USER_EMAIL_ALREADY_EXISTS: 'Cette adresse email est déjà utilisée',
  USER_PHONE_ALREADY_EXISTS: 'Ce numéro de téléphone est déjà utilisé',
  USER_IS_COLLECTOR_ONLY: "Votre rôle de collecteur ne vous permet pas d'accéder à notre plateforme.",
  COLLECTOR_PHONE_ALREADY_EXIST: 'Le numéro est déjà rattaché à un autre utilisateur collecteur.',
  USER_ALREADY_EXISTS: 'Le numéro est déjà rattaché à un autre utilisateur',
  WAVE_USER_NOT_FOUND: "Nous ne pouvons pas créer le collecteur car celui n'a pas de compte Wave",
  USER_PHONE_INVALID: 'Le numéro de téléphone est incorrect',
  INVALID_PHONE_NUMBER: 'Le numéro de téléphone saisi est incorrect. Veuillez le corriger et essayer à nouveau.',
  WALLET_HAS_TRANSACTIONS_PENDING: 'Des opérations sont en cours sur ce compte',
  AN_ACTIVE_PHYSICAL_CARD_ALREADY_EXISTS: 'Une carte physique existe déjà pour cet utilisateur.',
  INVALID_SECRET_CODE: 'Mot de passe invalide'
};

export default {
  request,
  fileUploader,
  encodeQueryParameters
};
