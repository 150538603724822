export const methodsList = [
  {
    slug: 'cash',
    icon: 'jla-icon-bills',
    title: 'Dépôt cash',
    description: (
      <span>
        Généralement en moins de <b>1 jour ouvré.</b>
      </span>
    ),
    details: (
      <div className="pb-3">
        <span>Un justificatif sera nécessaire pour valider votre rechargement</span>
        <div className="mb-1 font-weight-bold">Le bordereau de versement d'espèces déchargé par la banque</div>
      </div>
    ),
    isFaster: true
  },
  {
    slug: 'bank',
    icon: 'jla-icon-bank',
    title: 'Virement bancaire',
    description: (
      <span>
        Généralement en moins de <b>1 jour ouvré.</b>
      </span>
    ),
    details: (
      <div>
        <span>Un justificatif sera nécessaire pour valider votre rechargement :</span>
        <div className="mb-1 font-weight-bold">
          <ul>
            <li>Virement physique : ordre de virement signé et déchargé par la banque.</li>
            <li>Virement électronique : ordre de virement signé/transmis.</li>
          </ul>
        </div>
      </div>
    ),
    isFaster: false
  },
  {
    slug: 'check',
    icon: 'jla-icon-pencil-square',
    title: 'Dépôt chèque',
    description: (
      <span>
        Généralement en moins de <b>3 jours ouvrés.</b>
      </span>
    ),
    details: (
      <div>
        <span>Un justificatif sera nécessaire pour valider votre rechargement :</span>
        <div className="mb-3 font-weight-bold">Une photo de la décharge du chèque par Julaya.</div>
        <span>Attention ! Pour que votre banque accepte votre chèque, inscrivez l’ordre comme suit : “Julaya Côte d’Ivoire” ou “Julaya Sénégal”</span>
      </div>
    ),
    isFaster: false
  }
];
