import cx from 'classnames';

import './Radio.scss';

const Radio = ({id, className, status = 'transparent', selected, onChange, children}) => {
  return (
    <div
      id={id}
      className={cx(`radio --${status} ${className ? className : ''}`, {
        '--selected': selected
      })}
      onClick={onChange}
    >
      {children}
    </div>
  );
};

export default Radio;
