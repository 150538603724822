/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";

import * as actions from "@julaya/common/store/actions";
import { Provider, connect } from "react-redux";
import { bindActionCreators } from "redux";

import store from "@julaya/common/store";
import { constants, functions } from "@julaya/common/utils";


import {Spinner} from 'reactstrap';

import AppLayout from 'components/Layouts/App';
import AuthLayout from 'components/Layouts/Auth';

import '@julaya/common/scss/fonts.scss';
import 'scss/index.scss';


window.beamer_config = {
  display: null,
  alert: true,
  selector: '#sidebar-logo',
  product_id: constants.beamerID,
  language: 'FR'
};

window.hsConversationsSettings = {
  loadImmediately: false
};

const AppComponent = state => {
  const {isConnected, ownPassword, hydrateState, user} = state;

  useEffect(() => {
    isConnected === undefined && hydrateState();
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (!functions.isMobile(userAgent)) {
      window.location.replace(constants.julayaProUrl);
    }
  }, []);

//   const [loadHubSpot] = useScript(
//     "//js.hs-scripts.com/6087706.js",
//     {
//       type: "text/javascript",
//       id: "hs-script-loader",
//       async: true,
//       defer: true,
//     },
//     true
//   );
  // const [loadBeamer] = useScript(
  // 	'//app.getbeamer.com/js/beamer-embed.js',
  // 	{
  // 		type: 'text/javascript',
  // 		id: 'bm-script-loader',
  // 		async: true,
  // 		defer: true
  // 	},
  // 	true
  // )

  if (['live'].includes(process.env.REACT_APP_ENV)) {
    // loadBeamer()
    // loadHubSpot();
    if (window.HubSpotConversations) {
      if (isConnected && user && user.email && user.hsToken) {
        window.hsConversationsSettings = {
          identificationEmail: user.email,
          identificationToken: user.hsToken
        };
        window.HubSpotConversations.widget.load();
      } else {
        window.HubSpotConversations.widget.load();
      }
    } else {
      window.hsConversationsSettings = {
        loadImmediately: true
      };
    }
  }

  if (isConnected === undefined) {
    return (
      <div className="py-5 py-lg-5 pt-5" style={{justifyContent: 'center', display: 'flex'}}>
        <Spinner type="grow" color="info" style={{width: '5rem', height: '5rem'}} />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <Route
        path="/"
        render={props => (isConnected === true && ownPassword === true ? <AppLayout {...props} {...state} /> : <AuthLayout {...props} />)}
      />
    </BrowserRouter>
  );
};

const mapStateToProps = ({auth, transaction}) => {
  return {
    isConnected: auth.isConnected,
    ownPassword: auth.ownPassword,
    user: auth.user,
    appNavigation: transaction.appNavigation
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(actions, dispatch);
};

const App = connect(mapStateToProps, mapDispatchToProps)(AppComponent);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('app')
);
