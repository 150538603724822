import React, {useState, useRef, useEffect, useMemo} from 'react';
import Icon from '../../DataDisplay/Icon/Icon';
import TextHelper from '../../DataDisplay/TextHelper/TextHelper';
import Radio from '../Radio/Radio';
import Checkbox from '../Checkbox/Checkbox';
import cx from 'classnames';

import './Select.scss';

const Select = props => {
  const {
    name,
    values = {}, // { value|keys: "label" }
    value, // 'selectedValue' || [keys]
    disabledItems, //  [keys]
    onChange: _onChange,
    className,
    label,
    hintText,
    icon,
    feedback,
    warning,
    error,
    success,
    placement = 'bottom',
    placeholder = '',
    disabled = false,
    open: _open = false,
    white = false,
    multiple = false,
    ...attrs
  } = props;

  const [open, setOpen] = useState(_open);

  const menu = useRef();

  useEffect(() => {
    const listener = event => {
      if (!open || !menu.current || menu.current.contains(event.target)) {
        return;
      }

      let isToogler = false;

      let path = event.path || event.composedPath();

      path.forEach(node => {
        if (node.classList && node.classList.contains('select__toogler')) {
          isToogler = true;
        }
      });

      if (isToogler) {
        return;
      }

      const parent = document.querySelector('.jla-select__menu');

      let close = true;
      path.forEach(node => {
        if (node == parent) {
          close = false;
        }
      });

      if (close) {
        onToggle();
      }
    };

    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('click', listener);
    };
  }, [menu, open]);

  let prefix = null;
  if (icon) {
    if (typeof icon === 'string') {
      prefix = <Icon name={icon} className="jla-select__prefix" />;
    } else {
      prefix = icon;
    }
  }

  // prepare feedback
  let feedbackType = '';
  let feedbackText = null;
  if (feedback) {
    feedbackText = feedback;
  }

  /* feedback icons */
  if (error) {
    feedbackText = typeof error === 'string' ? error.trim() : '';
    feedbackType = 'error';
  } else if (warning) {
    feedbackText = typeof warning === 'string' ? warning.trim() : '';
    feedbackType = 'warning';
  } else if (success) {
    feedbackText = typeof success === 'string' ? success.trim() : '';
    feedbackType = 'success';
  }

  // prepare text classes
  const classList = cx('jla-select', `jla-select--${placement}`, className, {
    [`jla-select--warning`]: !!warning,
    [`jla-select--error`]: !!error,
    [`jla-select--success`]: !!success,
    [`jla-select--bg-white`]: !!white,
    [`jla-select--disabled`]: !!attrs.disabled,
    [`jla-select--open`]: open
  });

  const onChange = val => {
    let newValue;
    let isSelect = true;
    if (multiple) {
      newValue = [...value];
      isSelect = !value.includes(val);
      if (value.includes(val)) {
        newValue = newValue.filter(v => v !== val);
      } else {
        newValue.push(val);
      }
    } else {
      isSelect = value !== val;
      if (value === val) {
        newValue = '';
      } else {
        newValue = val;
      }
    }

    _onChange({
      target: {
        name,
        value: newValue
      },
      selected: {
        name,
        isSelect,
        value: val
      }
    });
  };

  const onToggle = () => {
    if (!disabled) {
      setOpen(!open);
    }
  };

  const renderValue = () => {
    if (multiple) {
      if (value?.length) {
        return value.map(v => values[v]).join(', ');
      } else {
        return <span className={cx('jla-select__placeholder', {'jla-select__placeholder--hidden': !placeholder})}>{placeholder || `0`}</span>;
      }
    } else {
      if (value) {
        return values[value];
      } else {
        return <span className={cx('jla-select__placeholder', {'jla-select__placeholder--hidden': !placeholder})}>{placeholder || `0`}</span>;
      }
    }
  };

  return (
    <>
      {label && (
        <div
          className={cx('jla-select__label', {
            'jla-select__label--disabled': !!disabled
          })}>
          <label>{label}</label>
          {hintText && <div className="jla-select__label-hint">{hintText}</div>}
        </div>
      )}
      <div className={classList} {...attrs}>
        <button
          className={cx('jla-select__input select__toogler', {
            'jla-select__input--prefix': !!prefix
          })}
          type="button"
          onClick={onToggle}>
          {prefix}
          {renderValue()}
          <Icon name="chevron-down" className="jla-select__suffix" />
        </button>

        <div className="jla-select__body" ref={menu}>
          {Object.keys(values).map((v, i) => (
            <a
              href="#julaya"
              onClick={e => {
                e.preventDefault();
                if (!disabledItems?.includes(v)) {
                  onChange(v);
                  if (!multiple) {
                    onToggle();
                  }
                }
              }}
              key={i}
              className={cx('jla-select__item select__toogler', {
                'jla-select__item--selected': false,
                'jla-select__item--disabled': disabledItems?.includes(v)
              })}>
              <div className="jla-select__item-radio">
                {multiple ? <Checkbox checked={value.includes(v)} onChange={() => {}} /> : <Radio checked={value == v} onChange={() => {}} />}
                <span className="jla-select__item-value">{values[v]}</span>
              </div>
            </a>
          ))}
        </div>
      </div>

      <TextHelper text={feedbackText} type={feedbackType} className="jla-select__feedback" />
    </>
  );
};

export default Select;
