/* eslint-disable react-hooks/exhaustive-deps */
/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
import React, {useState, useEffect} from 'react';
import PasswordResetPhone from './PasswordResetPhone';
import PasswordResetTemp from './PasswordResetTemp';
import PasswordResetNew from './PasswordResetNew';
import {useForm} from '@julaya/common/hooks';

const UpdatePassword = props => {
  const {isConnected, ownPassword, passwordUpdateLoading} = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [identifier, setIdentifier] = useState('');
  const [values, handleChange, setErrors] = useForm({
    password: ''
  });

  useEffect(() => {
    if ((isConnected === true && ownPassword === false) || passwordUpdateLoading) {
      setActiveIndex(2);
    }
  }, [isConnected, ownPassword]);

  return (
    <div className="reset">
      {activeIndex === 0 ? (
        <PasswordResetPhone {...props} setIdentifier={setIdentifier} goToIndex={setActiveIndex} />
      ) : activeIndex === 1 ? (
        <PasswordResetTemp {...props} values={values} handleChange={handleChange} setErrors={setErrors} goToIndex={setActiveIndex} />
      ) : (
        <PasswordResetNew connectionInfo={values} identifier={identifier} {...props} goToIndex={setActiveIndex} />
      )}
    </div>
  );
};

export default UpdatePassword;
