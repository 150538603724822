import React from 'react';
import cx from 'classnames';

import { Typography } from "../../index";
import './Bullet.scss';

const Bullet = props => {
  const {children, type, bullet: _bullet, className, ...attrs} = props;
  let bullet = _bullet;

  if (type === 'icon' && bullet) {
    bullet = <div className="jla-bullet__icon">{bullet}</div>;
  }

  if (type === 'dot') {
    bullet = <Typography color="primary" variant="h4">·</Typography>;
  }

  return (
    <div className={`jla-bullet ${className}`}>
      {bullet}
      <div className={cx(`jla-bullet__content${bullet ? '' : '-m0'}`)} {...attrs}>
        {children}
      </div>
    </div>
  );
};

export default Bullet;
