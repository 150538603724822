import React from 'react';
import moment from 'moment';
import cx from 'classnames';
import {Icon} from '@julaya/common/system';

const DetailSignature = ({approversDropdown, toggleApproversDropdown, batchDetails}) => {
  const {status, nbSignatures, TaskSignatures = []} = batchDetails;
  return (
    <div
      className={cx('d-block font-weight-bold dropable ', {
        disabled: status === 'draft',
        open: approversDropdown
      })}>
      <button
        className="dropdown-sign"
        onClick={e => {
          e.preventDefault();

          if (status !== 'draft') {
            toggleApproversDropdown();
          }
        }}>
        <div className="d-flex align-items-center text-muted">
          <Icon className="mr-3" name="user-check" size="h5" />
          Signataire(s)
        </div>
        <div className="d-flex align-items-center">
          {nbSignatures && (
            <div className="mr-2 text-muted">
              {status === 'pending' || status === 'retry'
                ? `(${TaskSignatures.length}/${TaskSignatures.length})`
                : `(${TaskSignatures.length}/${nbSignatures})`}
            </div>
          )}
          <i className={`${status === 'draft' ? 'jla-icon-lock' : 'jla-icon-chevron-right'} arrow p-2 text-black rounded align-self-center`}></i>
        </div>
      </button>

      <div className="content">
        {TaskSignatures.length ? (
          TaskSignatures.map((signature, i) => {
            return (
              <div key={i} className={'droppable-item'}>
                <div className="number">#{i + 1}</div>
                <div>
                  <div>{signature.User.firstname + ' ' + signature.User.lastname}</div>
                  <div className="font-weight-bold small mt-1 text-muted text-right">{moment(signature.createdAt).format('DD/MM/YYYY - HH:mm')}</div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="text-center p-2 text-muted">Aucun signataire</div>
        )}
      </div>
    </div>
  );
};
export default DetailSignature;
