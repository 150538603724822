/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {constants} from '@julaya/common/utils';
import {DateRangepicker, DropdownSelect, Searchbar} from 'components/UI';

const Filter = props => {
  const {filter, walletType, onClose} = props;

  const {minDate, setMinDate, maxDate, setMaxDate, status, setStatus, transactionType, setTransactionType, filtered, resetFilters, setQuery, query} =
    filter;

  return (
    <div className="pt-4">
      <Searchbar className="mb-3" value={query} onChange={setQuery} name="history-overlay-search-query" bg="grey" />

      {walletType === 'wallet' && (
        <div className="mb-3">
          <DropdownSelect value={status} label={'Par statut'} values={constants.transactionStatuses} onChange={setStatus} />
        </div>
      )}

      <div className="mb-3">
        <DropdownSelect
          value={transactionType}
          label={'Par type de transaction'}
          values={walletType === 'wallet' ? constants.cardTransactionTypes : constants.transactionTypes}
          onChange={setTransactionType}
        />
      </div>

      <div className="mb-3">
        <DateRangepicker
          title="Par date"
          key={`datepicker-from`}
          defaultMinDate={minDate}
          onChangeMinDate={setMinDate}
          defaultMaxDate={maxDate}
          onChangeMaxDate={setMaxDate}
        />
      </div>
      <div className="my-7"></div>

      {filtered && (
        <div className="text-center mb-2">
          <a
            href="#julaya"
            className="text-secondary font-weight-bold"
            onClick={e => {
              e.preventDefault();
              resetFilters();
              onClose();
            }}>
            Réinitialiser les filtres
          </a>
        </div>
      )}
    </div>
  );
};

export default Filter;
