import TaskListKanban from "components/UI/Tasks/TaskListKanban";
import PageWrapper from "components/UI/PageWrapper";

const TaskList = (props) => {
  return (
    <PageWrapper className="tasks">
      <TaskListKanban {...props} />
    </PageWrapper>
  );
};

export default TaskList;
