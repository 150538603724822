/* eslint-disable react-hooks/exhaustive-deps */

// TODO:
import { useEffect } from "react";

import { useDialog } from "@julaya/common/hooks";

import { PageWrapper } from "components/UI";
import TransactionCard from "components/UI/Tasks/TransactionCard";
import PasswordModal from "./PasswordModal";
import RecapModal from "./RecapModal";

const TaskValidate = (props) => {
  const {
    match: {
      params: { taskId },
    },
    initTaskManager,
    resetTaskManager,
    taskItems,
    clearErrors,
  } = props;

  useEffect(() => {
    if (taskId) {
      initTaskManager(taskId);
    }
    return () => {
      resetTaskManager();
      clearErrors();
    };
  }, []);

  const [recapModal, toggleRecapModal] = useDialog(true);
  const [passwordModal, togglePasswordModal] = useDialog();
  const [transfersList, toggleTransfersList] = useDialog();

  return (
    <div className="task-validate">
      {recapModal && (
        <RecapModal
          showModal={recapModal}
          toggleShowModal={toggleRecapModal}
          toggleTransfersList={toggleTransfersList}
          togglePassword={togglePasswordModal}
          {...props}
        />
      )}
      {passwordModal && <PasswordModal showModal={passwordModal} {...props} />}
      {transfersList && (
        <PageWrapper>
          {taskItems.map((item, index) => {
            return <TransactionCard item={item} key={index} />;
          })}
          <button
            className="btn w-75 btn-primary recap-button"
            onClick={() => {
              toggleTransfersList();
              toggleRecapModal();
            }}
          >
            Retour au récapitulatif
          </button>
        </PageWrapper>
      )}
    </div>
  );
};

export default TaskValidate;
