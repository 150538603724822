/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {details} from './extra';

const DetailRow = props => {
  const {detailKey, item, wallet, user} = props;
  const detail = details[detailKey];
  item.wallet = wallet;
  item.user = user;

  if (!detail) {
    return null;
  }

  const value = detail.getValue(props);

  if (!value) {
    return null;
  }

  return (
    <>
      <div className="mb-2">
        <span className="text-muted small font-weight-bold">{detail.title}</span>
      </div>
      <div className="mb-3">{value}</div>
    </>
  );
};

export default DetailRow;
