import cx from 'classnames';
import './Text.scss';

// colors: primary success error pending grey white text
const Text = ({className = '', big, bold, children, color = 'text'}) => {
  return (
    <div
      className={cx(`text ${className} --${color}`, {
        '--big': big,
        '--bold': bold
      })}
    >
      {children}
    </div>
  );
};

export default Text;
