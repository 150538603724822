import React, { useRef } from "react";

const Footer = ({
  item,
  attachedDepositReceipt,
  attachDepositReceiptLoading,
  attachDepositReceiptError,
  onFileLoaded,
}) => {
  const inputRef = useRef(null);
  let content = null;

  if (item.needAttachFile === true) {
    content = (
      <div className="py-3">
        {attachDepositReceiptError && (
          <div className={`text-danger text-center py-1`}>
            Des erreurs sont survenues lors du chargement de votre document
          </div>
        )}

        <button
          type="button"
          className="btn btn-block btn-primary"
          onClick={() => inputRef.current.click()}
          disabled={attachDepositReceiptLoading}
        >
          {attachDepositReceiptLoading && (
            <>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>{" "}
            </>
          )}
          Ajouter votre justificatif
        </button>

        <input
          ref={inputRef}
          type="file"
          className="hidden-file-input"
          onChange={onFileLoaded}
        />
      </div>
    );
  }

  if (
    attachedDepositReceipt &&
    item &&
    attachedDepositReceipt.uniqId === item.uniqId
  ) {
    content = (
      <div className="bg-white px-4 py-3 border-top">
        <div className={`text-success text-center py-1`}>
          Document ajouté avec succès
        </div>
      </div>
    );
  }

  return content;
};

export default Footer;
