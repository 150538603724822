/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import cx from 'classnames';

import TableColumn from './table/TableColumn';
import TasksCard from './table/TasksCard';
import {constants, functions} from '@julaya/common/utils';
import {ErrorDisplay, JSpinner} from 'components/UI';

const TasksKanban = props => {
  const {
    batchesTasks,
    batchesTasksLoading,
    batchesTasksError,

    selectedFilter,
    debouncedSearchTerm,
    callGetBatchesTasks
  } = props;

  // Retrieve the tasks
  useEffect(() => {
    callGetBatchesTasks();
  }, []);

  // Construct our data table
  const Table = ({selectedFilter}) => {
    const filtreStatus = selectedFilter.map(item => {
      return constants.statusType[item];
    });

    const colsToShow = Array.isArray(selectedFilter) && selectedFilter.length > 0 ? filtreStatus : constants.statusType;
    let columns = [];

    columns.push(
      Object.values(colsToShow).map(statusColumn => (
        <TableColumn status={statusColumn} key={statusColumn.slug}>
          {batchesTasks.map(item => {
            // Boolean to decide if the task belong to the status that we want to show
            let isReturn = false;
            // Change status for "Retryable" transactions
            functions.isRetry(item);
            // Check the task status with our boolean
            if (statusColumn.slug === 'pending' ? ['sending', 'pending'].includes(item.status) : item.status === statusColumn.slug) {
              isReturn = true;
            }
            // if it's true we show that task at the table column otherwise it will be Null
            if (isReturn) {
              return <TasksCard batch={item} key={item.id} />;
            } else {
              return null;
            }
          })}
        </TableColumn>
      ))
    );

    return columns;
  };

  return (
    <div
      className={cx('tasks-container', {
        'success-search-query': !batchesTasksLoading && debouncedSearchTerm && batchesTasks.length > 0
      })}>
      <div className="d-flex no-gutters flex-column">
        {/* Check if the searched elements or the whole tasks list isn't empty */}
        {debouncedSearchTerm && batchesTasks.length === 0 ? (
          <div className="mt-4 w-100 d-flex align-items-center justify-content-center">
            <div>Il n'y a pas de résultats pour cette recherche</div>
          </div>
        ) : // In case of tasks are still loading we show the spinner
        batchesTasksLoading ? (
          <div className="mt-4 w-100 d-flex align-items-center justify-content-center">
            <JSpinner />
          </div>
        ) : // In case of an Error , the error lsg will be shown to the user with retry CTA
        batchesTasksError ? (
          <ErrorDisplay errorText={batchesTasksError} onClick={callGetBatchesTasks} />
        ) : (
          // Otherwise our table is ready
          <Table selectedFilter={selectedFilter} />
        )}
      </div>
    </div>
  );
};

export default TasksKanban;
