/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import cx from 'classnames';
import {useHistory, Link} from 'react-router-dom';

import {Button} from '@julaya/common/ui/atoms';
import {PageWrapper, IconContainer, CardInfo} from 'components/UI';
import {functions} from '@julaya/common/utils';

import History from './History';

const Wallet = props => {
  const {
    historyLoading,
    historyError,
    getWalletDetails,
    walletDetailsLoading,
    wallet,
    setSelectedItem,
    match: {
      params: {walletType, walletId}
    }
  } = props;

  const history = useHistory();

  useEffect(() => {
    getWalletDetails(walletId, true);
  }, []);

  return (
    <div className="position-relative d-flex flex-column flex-grow-1">
      {walletDetailsLoading ? (
        <div className="d-flex align-items-center bg-white py-3 px-4 ">
          <div className="placeholder-square rounded" style={{width: '2.9rem', height: '2.9rem'}}></div>
          <div className="d-flex flex-column flex-grow-1 font-weight-bold pl-3">
            <div className="placeholder-line mb-2" style={{width: '5rem', height: '1rem'}}></div>

            <div className="placeholder-line mt-1" style={{width: '6rem'}}></div>
          </div>
          <div className="placeholder-square rounded" style={{width: '2.9rem', height: '2.9rem'}}></div>
        </div>
      ) : (
        wallet && (
          <>
            <div className="d-flex align-items-center bg-white py-3 px-4 ">
              <Button variant="badge" icon={walletType === 'card' ? 'jla-icon-credit-card' : 'jla-icon-wallet'} rounded />
              <div className="d-flex flex-column flex-grow-1 font-weight-bold pl-3">
                <div className="title text-black ">{functions.formatMoney(wallet.balance)}</div>
                <div className="text-muted">{wallet.label}</div>
              </div>
              <a
                href="#julaya"
                onClick={e => {
                  e.preventDefault();
                  history.goBack();
                }}>
                <IconContainer icon="jla-icon-x-mark" color="muted" bg="grey" />
              </a>
            </div>
            {walletType === 'card' && (
              <div className="px-4">
                <CardInfo
                  selectedCard={{
                    ...wallet
                  }}
                />

                <div className="pt-3">
                  <Link to={`/accounts/card/${walletId}/gestion`} className="btn btn-white d-flex align-items-center justify-content-center">
                    <i className="jla-icon-gear h4 m-0 mr-2"></i>Gérer ma carte
                  </Link>
                </div>
              </div>
            )}

            {walletType === 'wallet' && (
              <div className="px-4">
                <div className="pt-3 d-flex align-items-center justify-content-center">
                  {wallet.canTopUp && <Button link="/bank-disposal" icon="jla-icon-wallet" status="white" text="Recharger" full />}
                  {wallet.canSend && (
                    <Button className="ml-2" link="/transfer/rapid-transfer" icon="jla-icon-arrows-right-left" text="Envoyer" full />
                  )}
                </div>
              </div>
            )}
          </>
        )
      )}

      <PageWrapper
        className={cx({
          'd-flex flex-grow-1 mt-2': historyLoading || historyError
        })}>
        <History {...props} setSelectedItem={setSelectedItem} />
      </PageWrapper>
    </div>
  );
};

export default Wallet;
