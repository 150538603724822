import React, {useMemo} from 'react';
import cx from 'classnames';

import './Toggle.scss';

const Toggle = props => {
  const {id, className, heading, description, label, hintText, ...attrs} = props;

  const componentId = useMemo(() => {
    return id || `jla-toggle-${(Math.random() + 1).toString(36).substring(7)}`;
  }, []);

  const classList = cx('jla-toggle', className);

  return (
    <>
      {label && (
        <div
          className={cx('jla-toggle__label', {
            'jla-toggle__label--disabled': !!attrs.disabled
          })}>
          <label htmlFor={componentId}>{label}</label>
          {hintText && <div className="jla-toggle__label-hint">{hintText}</div>}
        </div>
      )}
      <div className={classList}>
        <input type="checkbox" className="jla-toggle__input" id={componentId} {...attrs} />
        <label className="jla-toggle__ui" htmlFor={componentId}>
          <span></span>
        </label>
        {heading && (
          <div className="jla-toggle__heading-wrap">
            <label className="jla-toggle__heading" htmlFor={componentId}>
              {heading}
            </label>
            {description && <div className="jla-toggle__heading-descr">{description}</div>}
          </div>
        )}
      </div>
    </>
  );
};

export default Toggle;
