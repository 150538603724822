import React from 'react';

import cx from 'classnames';
import {colors} from '@julaya/common/utils/ui';

import './Spinner.scss';
import Typography from "../Typography/Typography";

const Spinner = props => {
  let {color, size, className, tag: Tag = 'div', label, subTitle, direction, ...attrs} = props;

  return (
    <div className={`jla-spinner-wrapper jla-spinner-wrapper--${direction}`}>
      <Tag
        {...attrs}
        className={cx(`jla-spinner`, className, {
          [`jla-spinner--${size}`]: size,
          [`jla-spinner--${color}`]: color && colors.includes(color),
            [`jla-spinner--${direction}`]: label,
        })}
      />
      <div>
        <Typography lead>{label}</Typography>
        <Typography color="neutral">{subTitle}</Typography>
      </div>
    </div>
  );
};

export default Spinner;
