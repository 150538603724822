import { Tag } from '@julaya/common/system';
import cx from 'classnames';
import './BankSelectItem.scss';

const BankSelectItem = ({
  image,
  tag,
  onClick,
  selected = false,
  label = '',
  subtitle = '',
  className = '',
  averageTiming,
  disabled,
  icon = 'jla-icon-chevron-right'
}) => {
  return (
    <a
      href="#julaya"
      className={cx(`bank-select-item ${className}`, {
        '--selected': selected
      , '--disabled': disabled})}
      onClick={disabled ? undefined: e => {
        e.preventDefault();
        onClick()
      }}>
      <div className="bank-select-item__content">
        {image && (
          <div
            className="logo"
            style={{
              backgroundImage: `url(${image})`
            }}></div>
        )}

        {tag && (
          <div className="tag">
            <i className={`${tag}`} />
          </div>
        )}

        <div>
          <div className="label">{label}</div>
          <div className="subtitle">{subtitle}</div>
        </div>
      </div>

      {averageTiming && (
        <Tag tagType="outline" prefixIcon="clock" color={averageTiming.color} style={{gap: '8px'}}>
          {averageTiming.text}
        </Tag>
      )}
      {icon && <i className={`bank-select-item__icon ${icon}`} />}
    </a>
  );
};

export default BankSelectItem;

// USAGE EXAMPLE
{
  /* <BankSelectItem onClick={() => setSelectedBank(bank)} image={logo} label={name} /> */
}
