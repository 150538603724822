import React, {useMemo} from 'react';
import cx from 'classnames';

import './Radio.scss';

const Radio = props => {
  const {id, className, checked, heading, description, label, hintText, multilined, disabled, onChange = () => {}, ...attrs} = props;

  if (disabled) {
    attrs.disabled = true;
    attrs.defaultChecked = checked;
  } else {
    attrs.checked = checked;
  }

  const componentId = useMemo(() => {
    return id || `jla-radio-${(Math.random() + 1).toString(36).substring(7)}`;
  }, []);

  const classList = cx('jla-radio', className);

  return (
    <>
      {label && (
        <div
          className={cx('jla-radio__label', {
            'jla-radio__label--disabled': !!disabled
          })}>
          <label htmlFor={componentId}>{label}</label>
          {hintText && <div className="jla-radio__label-hint">{hintText}</div>}
        </div>
      )}

      <div className={classList}>
        <input type="radio" className="jla-radio__input" id={componentId} onChange={onChange} {...attrs} />
        <label className="jla-radio__ui" htmlFor={componentId}></label>
        {heading && (
          <div className="jla-radio__heading-wrap">
            <label className="jla-radio__heading" htmlFor={componentId}>
              {heading}
            </label>
            {description && (
              <div
                className={cx('jla-radio__heading-descr', {
                  'jla-radio__heading-descr-long': multilined
                })}>
                {description}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Radio;
