import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '@julaya/common/store/actions';
import selector from '@julaya/common/store/selectors';

import Component from '@julaya/common/ui/features/EntrepriseSwitch';

const mapStateToProps = ({auth, loading, error, transaction, wallets}) => {
  return {
    ...selector(
      {
        userCompanies: 'GET_USER_COMPANIES',
        userInfo: 'USER_INFO',
        landingWallets: 'GET_LANDING_WALLETS'
      },
      {loading, error}
    ),

    user: auth.user,
    userCompanies: auth.companiesList,
    preferredCompany: auth.preferredCompany,
    landingWallets: wallets.landingWallets?.wallets || [],

    isMenuOpen: transaction.isMenuOpen
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
