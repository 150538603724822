import React from 'react';
import {Link, matchPath} from 'react-router-dom';
import cx from 'classnames';

const TableColumn = ({children, status, fullList}) => {
  // Check the pathname of the page => used with status : success
  const match = matchPath(window.location.pathname, {
    path: '/tasks/success'
  });
  //  Declare the card variable : contain all valid children
  let card;
  // Retrieve all valid children
  const elements = React.Children.toArray(children).filter(child => React.isValidElement(child));
  // Count Children
  const count = elements.length;

  // check if the status is success => display 'Voir les tâches terminées' card
  if (status.slug === 'success') {
    match !== null && match.isExact
      ? (card = <div className="font-weight-bold py-5 text-center">Aucune tâche terminées</div>)
      : (card = (
          <Link to="/tasks/success" className=" d-flex align-items-center justify-content-center py-5 operation-tag disposal">
            <div className="font-weight-bold text-center text-secondary mr-4">Voir les tâches terminées</div>{' '}
            <div className="position-relative">
              <i className="icon-external-link h4 text-primary mb-0 position-absolute" style={{top: '-14px', left: '-10px'}} />
            </div>
          </Link>
        ));
  }
  // check nbr of children = 0 => display 'Aucune tâche en cours' card
  else if (count === 0) {
    card = <div className="font-weight-bold py-5 text-center">Aucune tâche en cours</div>;
  }
  // Otherwise we check if we want to show the full list of elements
  // if it's full list we show all of them
  else if (fullList) {
    card = (
      <>
        {elements.map(elt => {
          return elt;
        })}
      </>
    );
  }
  // if it's not we show just the first 3 elements
  else {
    card = (
      <>
        {elements.slice(0, 3).map(elt => {
          return elt;
        })}
      </>
    );
  }

  return (
    <div
      className={cx(`status-column status-${status.slug}`, {
        waiting: status.slug === 'draft' || status.slug === 'to-validate',
        isEmpty: count === 0 && status.slug !== 'complete'
      })}>
      <div className="status-columns-header">
        <div className="pill-status d-flex justify-content-between w-100 px-4 py-3">
          <div>{status.title}</div>
          <div>{status.slug !== 'success' && <span className="ml-2">{count}</span>}</div>
        </div>
      </div>
      <div className="status-columns-body">
        {/* Display the card */}
        {card}
        {/* We check if the count > 3 & fullList in order to show the button or Not */}
        {count > 3 && !fullList && (
          <Link to={`/tasks/${status.slug}`} className="btn btn-block btn-primary mt-2">
            Afficher toutes les tâches
          </Link>
        )}
      </div>
    </div>
  );
};

export default TableColumn;
