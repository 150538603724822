import {Icon} from '@julaya/common/system';
import React from 'react';
import {Link} from 'react-router-dom';

const icon = {
  draft: {icon: 'pencil', color: 'secondary'},
  'to-validate': {icon: 'pencil-square', color: 'secondary'},
  pending: {icon: 'clock', color: 'warning'},
  'to-retry': {icon: 'x-circle', color: 'danger'},
  complete: {icon: 'check-circle', color: 'success'}
};

const wording = {
  draft: 'Brouillon',
  'to-validate': 'À signer',
  pending: 'En cours de traitement',
  'to-retry': 'Echec',
  complete: 'Terminée'
};

const getCTALabel = (selectedBatch, user) => {
  const count = Array.isArray(selectedBatch.TaskSignatures) ? selectedBatch.TaskSignatures.length : 1;
  if (user.isSuperSignature) {
    return `Voir, signer et envoyer`;
  }
  return `Voir et signer (${count}/${selectedBatch.Wallet.nbSignatures})`;
};

const Wording = ({item}) => {
  if (item) {
    if (item.status === 'draft') {
      return <div className="text-muted mb-2">Un brouillon n'est visible que par vous, il peut être modifié à volonté avant d'être signé !</div>;
    }

    if (item.status === 'pending') {
      return (
        <div className="text-muted">
          Le transfert groupé est en cours de traitement par Julaya, vous serez notifié à l'envoi de la dernière transaction.
        </div>
      );
    }

    if (item.status === 'to-retry') {
      return (
        <div className="text-muted">
          Certaines transactions de cette tâche ont échoué à cause d'un problème chez l'opérateur et n'ont pas été débitées. Vous pouvez rejouer
          toutes les transactions en toute sécurité en cliquant sur "Rejouer".
        </div>
      );
    }

    if (item.status === 'to-validate') {
      return (
        <div className="text-muted">
          Le transfert groupé est en attente de signatures. Dès que le nombre de signatures sera suffisant, le transfert sera envoyé.
        </div>
      );
    }
  }

  return null;
};

const Actions = ({item, user}) => {
  if (item) {
    if (item.status === 'to-validate') {
      return (
        <OperationStatusLink link={`/tasks/${item.walletId}/${item.id}/validate`} icon={'jla-icon-pencil-square'} text={getCTALabel(item, user)} />
      );
    }
  }

  return null;
};

const OperationStatusLink = ({link, icon, text}) => {
  return (
    <Link to={link} className="mt-3 p-2 text-white bg-primary d-flex align-items-center justify-content-between font-weight-bold rounded">
      <div className="d-flex align-items-center">
        <i className={`${icon} h4 mb-0 ml-2 mr-3`}></i>
        {text}
      </div>
    </Link>
  );
};

const OperationStatus = ({status, selectedBatch, user}) => {
  return (
    <div className={`rounded p-3 bg-body ${status}`}>
      <div className={`d-flex align-items-center ${status !== 'complete' && 'mb-3'}`}>
        <Icon name={icon[status]?.icon ?? ''} className="mr-3" color={icon[status]?.color} size="h5" />
        <div className="font-weight-bold">{wording[status]}</div>
      </div>

      <Wording item={selectedBatch} />
      <Actions item={selectedBatch} user={user} />
    </div>
  );
};

export default OperationStatus;
