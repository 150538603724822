/* eslint-disable react-hooks/exhaustive-deps */
import {useState} from 'react';

const useDialog = (state = false) => {
  const [modal, setModal] = useState(state);
  const toggle = val => {
    setModal(typeof val === 'boolean' ? val : !modal);
  };

  return [modal, toggle];
};

export default useDialog;
