/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {matchPath, useLocation, Link} from 'react-router-dom';
import cx from 'classnames';

import routing from '../../../routing';

const Menu = ({menu, setMenu}) => {
  const navigationData = routing.getNavigation(routing.appRoutes);

  const {pathname} = useLocation();
  const [data] = useState(navigationData);

  useEffect(() => {
    let match = null;
    const paths = Object.keys(data);
    for (let index = 0; index < paths.length; index++) {
      const path = paths[index];

      const m = matchPath(pathname, {
        path,
        isExact: true
      });
      if (m?.isExact) {
        match = m;
      }
    }

    if (match) {
      let path = paths.find(route => match.path === route);

      if (data[path]) {
        setMenu(data[path]?.menu || null);
      }
    }
  }, [pathname, data]);

  return menu ? (
    <div className="main-menu">
      <Link
        to="/dashboard"
        className={cx(`menu-item`, {
          active: menu === 'wallets'
        })}>
        <i className="jla-icon-wallet " />
        <div className="label">Mes comptes</div>
      </Link>
      <Link
        to="/tasks"
        className={cx(`menu-item`, {
          active: menu === 'tasks'
        })}>
        <i className="jla-icon-task" />
        <div className="label">Mes tâches</div>
      </Link>
      <Link
        to="/profile"
        className={cx(`menu-item`, {
          active: menu === 'profile'
        })}>
        <i className="jla-icon-user-circle" />
        <div className="label">Mon profil</div>
      </Link>
    </div>
  ) : null;
};

export default Menu;
