import React from 'react';
import './CustomSelect.scss';
import {Icon} from '../../../system';

const CustomSelect = ({onClick, selectedItem, placeholder, icon}) => {
  return (
    <a
      href="#julaya"
      className="jla-custom-select"
      onClick={e => {
        e.preventDefault();
        onClick();
      }}>
      <div className="jla-custom-select__content">
        {selectedItem ? (
          <>
            {selectedItem.image && (
              <div
                className="image"
                style={{
                  backgroundImage: `url(${selectedItem.image})`
                }}></div>
            )}

            {selectedItem.tag && (
              <div className="tag">
                <i className={`${selectedItem.tag}`} />
              </div>
            )}

            <div className="label text-black">{selectedItem.label}</div>
          </>
        ) : (
          <>
            {icon ? <Icon className="text-secondary mr-2" name={icon} size="h5" /> : null}
            <div className="text-primary">{placeholder}</div>
          </>
        )}
      </div>

      <i className={`jla-custom-select__icon text-black jla-icon-chevron-up-down`} />
    </a>
  );
};

export default CustomSelect;

// USAGE AS BUTTON/TRIGGER ONLY
{
  /* <CustomSelect
  onClick={() => setBankSelectDrawer(true)}
  selectedItem={null}
  placeholder='Sélectionnez une banque'
/> */
}

// TODO: USAGE AS SELECT
