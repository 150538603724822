import {} from '@julaya/common/store/actionTypes';

const actions = [];

const initialState = {
  user: {},
  company: {},
  wallets: {count: 0, selected: {}, data: []},
  history: {walletId: null, taskId: [], count: null, selected: {}, data: []},
  notifications: {count: null, selected: {}, data: []},
  cards: {count: null, selected: {}, data: []},
  tasks: {count: null, selected: {}, data: []}
};

export default [initialState, actions];
