import {GET_BENEFICIARIES_SUCCESS} from '../actionTypes';

const actions = [GET_BENEFICIARIES_SUCCESS];

const initialState = {
  beneficiaries: [],
  beneficiariesCount: 0,
  beneficiariesPage: 1
};

export default [initialState, actions];
