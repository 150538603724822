import React from 'react';
import Surface from '../Surface/Surface';
import Typography from '../../DataDisplay/Typography/Typography';
import Button from '../../Inputs/Button/Button';
import cx from 'classnames';
import {Link} from 'react-router-dom';

import './MarketingCards.scss';

const imgs = {
  wallet: require('../../../assets/images/marketing-card-images/wallet.webp').default,
  creditcard: require('@julaya/common/assets/images/marketing-card-images/credit-card.webp').default,
  user: require('@julaya/common/assets/images/marketing-card-images/user.webp').default,
  beneficiary: require('@julaya/common/assets/images/marketing-card-images/beneficiary.webp').default
};

const MarketingCards = props => {
  const {cards, onClickBtnCard, className = ''} = props;

  return (
    <Surface items="stretch" wrap="nowrap" className={cx('jla-marketing-cards', className)} transparent direction="row" gap="md">
      {cards.map((item, i) => {
        return <Item {...item} onClickBtnCard={onClickBtnCard} key={i} />;
      })}
    </Surface>
  );
};

const Item = props => {
  const {title, description, variant, action, onClickBtnCard} = props;

  return (
    <div className={`jla-marketing-card jla-marketing-card--${variant}`}>
      <div className="jla-marketing-card__img" style={{backgroundImage: `url(${imgs[variant]})`}}></div>
      <div className="jla-marketing-card__content">
        <div>
          <Typography lead className="jla-marketing-card__title">
            {title}
          </Typography>
          <Typography>{description}</Typography>
        </div>
        <ItemAction data={action} onClickBtnCard={onClickBtnCard} />
      </div>
    </div>
  );
};

const ItemAction = ({data, onClickBtnCard}) => {
  const params = {};

  if (data.type === 'link') {
    params.tag = Link;
    params.to = data.to;
  } else {
    params.tag = 'button';
    params.onClick = () => {
      onClickBtnCard(data.slug);
    };
  }

  return (
    <div>
      <Button btnType="outline" color="primary" size="md" nowrap block {...params}>
        {data.title}
      </Button>
    </div>
  );
};

export default MarketingCards;
