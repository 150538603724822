/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
// import { CustomSelect } from "@julaya/common/ui/molecules";
import { AmountInput, Input, Text } from '@julaya/common/ui/atoms';
import { constants, functions } from '@julaya/common/utils';

import { RapidTransferDetailsAlert } from './Alert';
import './TransferDetail.scss';

const TransferDetail = props => {
  const {
    values,
    handleChange,
    errors,
    getValidationState,
    selectedWallet,
    summaryError,
    isMobile,
    setNextButtonDisabled
  } = props;

  useEffect(() => {
    if (values.amount) {
      setNextButtonDisabled(false);
    } else {
      setNextButtonDisabled(true);
    }
  }, [values.amount]);

  const getServiceTitle = () => {
    if (values.serviceSlug && constants.servicesMap[values.serviceSlug.toLowerCase()]) {
      return constants.servicesMap[values.serviceSlug.toLowerCase()].title;
    }
    if (values.serviceSlug === "bill-payment-default-civ") {
        return 'Paiement de facture';
    }
    return '-';
  };

  const getServiceIcon = () => {
    if (values.serviceSlug && constants.serviceSlugIcons[values.serviceSlug.toLowerCase()]) {
      return (
        <img src={constants.serviceSlugIcons[values.serviceSlug.toLowerCase()]} alt="service icon" />
      );
    }
    return null;
  };

  return (
    <div className="transfer-detail-info">
      {/* <CustomSelect onClick={() => {}} selectedItem={selectedWallet} placeholder={""} icon='icon-lock' /> */}

      {/* NEW */}
      <div className="transfer-detail-info__metadata d-flex justify-content-between">
        <div className="title">Compte émetteur</div>
        <div className="data text-end">{selectedWallet ? selectedWallet.label : '-'}</div>
      </div>
      <div className="transfer-detail-info__metadata">
        <div className="title">Destinataire</div>
        <div className="data">{values.externalFullname || functions.formatPhone(values.externalReference)}</div>
      </div>
      {/* END */}
      <div className="transfer-detail-info__metadata">
        <div className="title">Solde disponible</div>
        <div className="data">{selectedWallet ? functions.formatMoney(selectedWallet.balance) : '-'}</div>
      </div>
      <div className="transfer-detail-info__metadata">
        <div className="title">Service</div>
        <div className="data">
          {values.serviceSlug ? (
            <>
              <span>{getServiceTitle()}</span>
              {getServiceIcon()}
            </>
          ) : (
            '-'
          )}
        </div>
      </div>
      <AmountInput
        label="Montant du transfert"
        value={values.amount}
        hasError={errors.amount}
        errorMsg={errors.amount ? getValidationState(values.amount, 'amount') : ''}
        handleChange={handleChange}
        name="amount"
        isMobile={isMobile}
      />
      <Input
        id="comment"
        type="text"
        name="comment"
        placeholder="Ex : Remboursement"
        value={values.comment}
        onChange={handleChange}
      />
      <RapidTransferDetailsAlert {...props} />
      {summaryError && <Text color="error">{summaryError}</Text>}
    </div>
  );
};

export default TransferDetail;
