import {
  GET_ACCOUNT_DOCUMENTS_SUCCESS,
  GET_USER_DOUCMENTS_REQUEST,
  GET_USER_DOUCMENTS_SUCCESS,
  DOCUMENT_SEND_REQUEST,
  DOCUMENT_SEND_SUCCESS,
  DOCUMENT_SEND_FAILURE
} from '../actionTypes';

const actions = [
  GET_ACCOUNT_DOCUMENTS_SUCCESS,
  GET_USER_DOUCMENTS_REQUEST,
  GET_USER_DOUCMENTS_SUCCESS,
  DOCUMENT_SEND_REQUEST,
  DOCUMENT_SEND_SUCCESS,
  DOCUMENT_SEND_FAILURE
];

const initialState = {
  accountDocuments: null,
  userDocuments: null,
  cardId: null,
  uploadSlug: null
};

export default [initialState, actions];
