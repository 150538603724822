const tasks = {
  allTasks: 'Toutes les tâches',
  myTasks: 'Mes tâches',
  initiateTasks: 'Tâches initiées',
  senedTasks: 'Tâches envoyées',
  replayedTasks: 'Tâches à rejouer',
  doneTasks: 'Tâches terminées'
};

const statusType = {
  draft: {
    slug: 'draft',
    title: 'Brouillon'
  },
  'to-validate': {
    slug: 'to-validate',
    title: 'En attente de signature(s)'
  },
  pending: {
    slug: 'pending',
    title: 'En cours de traitement'
  },
  'to-retry': {
    slug: 'to-retry',
    title: 'À rejouer'
  },
  complete: {
    slug: 'complete',
    title: 'Terminé'
  }
};

export default {tasks, statusType};
