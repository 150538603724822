import {FILTER_HEADER, UPDATE_BREADCRUMB, UPDATE_APP_NAVIGATION} from '../actionTypes';

export const setContentReload =
  (contentReload = null) =>
  async dispatch => {
    dispatch({
      type: FILTER_HEADER,
      payload: {contentReload}
    });
  };

export const updateBreadcrumb =
  (breadcrumb = null) =>
  async dispatch => {
    dispatch({
      type: UPDATE_BREADCRUMB,
      payload: {breadcrumb}
    });
  };

export const updateAppNavigation =
  (appNavigation = null) =>
  async dispatch => {
    dispatch({
      type: UPDATE_APP_NAVIGATION,
      payload: {appNavigation}
    });
  };
