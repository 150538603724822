import React from 'react';
import Icon from '../Icon/Icon';
import Button from '../../Inputs/Button/Button';
import cx from 'classnames';

import './Alert.scss';

const Alert = props => {
  const {
    children,
    className,
    color = 'primary',
    orientation = 'inline',
    primaryLabel = null,
    primaryPrefixIcon = null,
    primarySuffixIcon = null,
    onClickPrimary = null,
    secondaryLabel = null,
    secondaryPrefixIcon = null,
    secondarySuffixIcon = null,
    onClickSecondary = null,
    onClose = null,
    ...attrs
  } = props;

  return (
    <div className={cx('jla-alert', `jla-alert--${orientation}`, `jla-alert--${color}`, className)} {...attrs}>
      <div className="jla-alert__content">
        <div className="jla-alert__children">{children}</div>
        <div className="jla-alert__actions">
          {orientation === 'inline' && (
            <>
              {primaryLabel && onClickPrimary && (
                <Button onClick={onClickPrimary} btnType="primary" color={color} tag="button" prefixIcon={primaryPrefixIcon} suffixIcon={primarySuffixIcon}>
                  {primaryLabel}
                </Button>
              )}
              {secondaryLabel && onClickSecondary && (
                <Button onClick={onClickSecondary} btnType="tertiary" color="primary" tag="button" prefixIcon={secondaryPrefixIcon} suffixIcon={secondarySuffixIcon}>
                  {secondaryLabel}
                </Button>
              )}
            </>
          )}

          {onClose && (
            <Button
              className="jla-alert__close"
              onClick={e => {
                e.preventDefault();
                onClose();
              }}
              btnType="ghost"
              color="neutral"
              tag="button"
            >
              <Icon name="x-mark-filled" />
            </Button>
          )}
        </div>
      </div>
      {(primaryLabel || secondaryLabel) && (orientation === 'stacked' || orientation === 'stacked-full') && (
        <div className={cx('jla-alert__footer', { 'jla-alert__footer-stack-full': orientation === 'stacked-full' })}>
          {primaryLabel && onClickPrimary && (
            <Button onClick={onClickPrimary} btnType="primary" color={color} tag="button" prefixIcon={primaryPrefixIcon} suffixIcon={primarySuffixIcon}>
              {primaryLabel}
            </Button>
          )}
          {secondaryLabel && onClickSecondary && (
            <Button onClick={onClickSecondary} btnType="tertiary" color="primary" tag="button" prefixIcon={secondaryPrefixIcon} suffixIcon={secondarySuffixIcon}>
              {secondaryLabel}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default Alert;
