import React from 'react';
import {Redirect} from 'react-router-dom';
import {PageWrapper} from 'components/UI';
import {Button} from '@julaya/common/ui/atoms';

const image = require('@julaya/common/assets/images/app/no-data.png').default;

const NotFound = ({isAuth}) => {
  if (isAuth) {
    return <Redirect to="/" />;
  }
  return (
    <PageWrapper className="d-flex flex-column flex-grow-1">
      <div className="d-flex flex-column flex-grow-1 align-items-center justify-content-center">
        <img style={{width: '300px', height: '225px'}} src={image} alt="NO PAGE" />
        <h3 className="mt-5 text-center">La page recherchée n'a pas été trouvée</h3>
        <div>
          <Button className="mt-2" link="/" status="white" text="Retour à l'accueil" />
        </div>
      </div>
    </PageWrapper>
  );
};

export default NotFound;
