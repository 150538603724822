import React, {useState, useRef, useEffect} from 'react';
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import {useDidUpdate, useClickOutsideMenu} from '@julaya/common/hooks';
import cx from 'classnames';
import './DatePicker.scss';
import Icon from '../../DataDisplay/Icon/Icon';
import TextHelper from '../../DataDisplay/TextHelper/TextHelper';

const DatePicker = ({
  className,
  label,
  hintText,
  icon,
  feedback,
  warning,
  error,
  success,
  placement = 'bottom',
  placeholder = '',
  disabled = false,
  defaultStartDate = '',
  onChangeStartDate,
  defaultEndDate = '',
  onChangeEndDate,
  dateFormat = 'DD/MM/YYYY',
  open: _open = false,
  variant = 'range', // single or range
  ...attrs
}) => {
  const [open, setOpen] = useState(_open);
  const [currentValue, setCurrentValue] = useState('startDate');
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [value, setValue] = useState(startDate);
  const datetimeModal = useRef();

  // Toggle modal
  const toggler = () => {
    if (!disabled) {
      if (!open) {
        setOpen('jla-datepicker');
      } else {
        setOpen(false);
      }
    }
  };

  // Click outside modal
  useClickOutsideMenu('jla-datepicker', datetimeModal, '.jla-datepicker', open, toggler);

  const onChangeFunction = value => {
    if (value === null) {
      setStartDate('');
      typeof onChangeStartDate === 'function' && onChangeStartDate('');

      if (variant === 'range' && typeof onChangeEndDate === 'function') {
        onChangeEndDate('');
      }

      setCurrentValue('startDate');
    }
    const date = value instanceof moment ? value.format(dateFormat) : '';

    if (currentValue === 'startDate') {
      setStartDate(date);
      typeof onChangeStartDate === 'function' && onChangeStartDate(date);
      setValue(`${date}`);
      variant === 'range' && setCurrentValue('endDate');
    } else {
      if (variant === 'range' && typeof onChangeEndDate === 'function') {
        onChangeEndDate(date);
      }
      // Reverse order if selected end date is before start date
      if (moment(date, dateFormat).isBefore(moment(startDate, dateFormat))) {
        setValue(`${date} - ${startDate}`);
      } else if (moment(date, dateFormat).isAfter(moment(startDate, dateFormat))) {
        setValue(`${startDate} - ${date}`);
      }
      setCurrentValue('startDate');
    }
  };

  useEffect(() => {
    if (open) {
      setCurrentValue('startDate');
    }
  }, [open]);

  useDidUpdate(() => {
    setValue(startDate ? `${startDate}` : value);
  }, [startDate]);

  useEffect(() => {
    if (!defaultStartDate) {
      setValue('');
      setStartDate('');
      setCurrentValue('startDate');
    }
  }, [defaultStartDate]);

  let prefix = null;
  if (icon) {
    if (typeof icon === 'string') {
      prefix = <Icon name={icon} className="jla-datepicker__prefix" />;
    } else {
      prefix = icon;
    }
  }

  // prepare feedback
  let feedbackType = '';
  let feedbackText = null;
  if (feedback) {
    feedbackText = feedback;
  }

  /* feedback icons */
  if (error) {
    feedbackText = typeof error === 'string' ? error.trim() : '';
    feedbackType = 'error';
  } else if (warning) {
    feedbackText = typeof warning === 'string' ? warning.trim() : '';
    feedbackType = 'warning';
  } else if (success) {
    feedbackText = typeof success === 'string' ? success.trim() : '';
    feedbackType = 'success';
  }

  // prepare text classes
  const classList = cx('jla-datepicker', `jla-datepicker--${placement}`, className, {
    [`jla-datepicker--warning`]: !!warning,
    [`jla-datepicker--error`]: !!error,
    [`jla-datepicker--success`]: !!success,
    [`jla-datepicker--disabled`]: !!attrs.disabled,
    [`jla-datepicker--open`]: open
  });

  const renderValue = () => {
    if (value) {
      return value;
    } else {
      return <span className={cx('jla-datepicker__placeholder', {'jla-datepicker__placeholder--hidden': !placeholder})}>{placeholder || `0`}</span>;
    }
  };

  // Custom day renderer for styling
  const renderDay = (props, currentDate, selectedDate) => {
    let selectedStartDate = selectedDate;
    let selectedEndDate = null;
    if (startDate) {
      if (selectedStartDate.isAfter(moment(startDate, dateFormat))) {
        selectedStartDate = moment(startDate, dateFormat);
        selectedEndDate = selectedDate;
      } else if (selectedStartDate.isBefore(moment(startDate, dateFormat))) {
        selectedEndDate = moment(startDate, dateFormat);
      }
    }

    // Selected days
    const isActive = currentDate.isSame(selectedStartDate) || (selectedEndDate && currentDate.isSame(selectedEndDate));

    // Days between active range
    const isInActiveRange =
      selectedStartDate && selectedEndDate && !selectedStartDate.isSame(selectedEndDate) && currentDate.isBetween(selectedStartDate, selectedEndDate);

    // Days not within current month
    const isNotInCurrentMonth = currentDate.isBefore(moment().startOf('month')) || currentDate.isAfter(moment().endOf('month'));

    const isToday = currentDate.isSame(moment().startOf('day'));

    return (
      <td
        {...props}
        className={cx('rdtDay', {
          ['rdtActive']: isActive,
          ['startDate']: isActive && selectedEndDate && currentDate.isSame(selectedStartDate) && currentValue === 'startDate',
          ['endDate']: isActive && selectedEndDate && currentDate.isSame(selectedEndDate) && currentValue === 'startDate',
          ['rdtInActiveRange']: isInActiveRange,
          ['rdtToday']: isToday,
          ['rdtNotInCurrentMonth']: isNotInCurrentMonth
        })}>
        {!isNotInCurrentMonth ? currentDate.date() : `0${currentDate.date()}`.slice(-2)}
      </td>
    );
  };

  return (
    <div
      className={cx(`jla-datepicker`, {
        active: open
      })}>
      {label && (
        <div
          className={cx('jla-datepicker__label', {
            'jla-datepicker__label--disabled': !!disabled
          })}>
          <label>{label}</label>
          {hintText && <div className="jla-datepicker__label-hint">{hintText}</div>}
        </div>
      )}

      <div className={classList} {...attrs}>
        <button
          className={cx('jla-datepicker__input', {
            'jla-datepicker__input--prefix': !!prefix
          })}
          type="button"
          onClick={() => toggler('jla-datepicker')}>
          {prefix}
          {renderValue()}
          <Icon name="chevron-down" className="jla-datepicker__suffix" />
        </button>
        <div className="jla-datepicker__body" ref={datetimeModal}>
          <ReactDatetime
            timeFormat={false}
            initialValue={currentValue === 'startDate' ? defaultStartDate : defaultEndDate}
            onChange={onChangeFunction}
            open={!!open}
            renderInput={() => null}
            renderDay={renderDay}
          />
        </div>
      </div>

      <TextHelper text={feedbackText} type={feedbackType} className="jla-datepicker__feedback" />
    </div>
  );
};

export default DatePicker;
