import CryptoJS from 'crypto-js';
const get = async (key, parse = true) => {
  if (!key) return null;

  try {
    const digest = await localStorage.getItem(CryptoJS.SHA256(`${key}`).toString().substring(0, 15));

    if (digest) {
      const val = CryptoJS.AES.decrypt(digest, `${process.env.REACT_APP_SECRET}`).toString(CryptoJS.enc.Utf8);
      if (parse) {
        return await JSON.parse(val);
      } else {
        return val;
      }
    }
    return null;
  } catch (error) {
    return null;
  }
};

const set = async (key, val) => {
  await localStorage.setItem(
    CryptoJS.SHA256(`${key}`).toString().substring(0, 15),
    CryptoJS.AES.encrypt(`${JSON.stringify(val)}`, `${process.env.REACT_APP_SECRET}`).toString()
  );
};

const remove = async key => {
  await localStorage.removeItem(CryptoJS.SHA256(`${key}`).toString().substring(0, 15));
};
const clear = async () => {
  // await localStorage.clear();
  await localStorage.removeItem(CryptoJS.SHA256('accessToken').toString().substring(0, 15));
  await localStorage.removeItem(CryptoJS.SHA256('user').toString().substring(0, 15));
};

export default {get, set, remove, clear};
