import React from 'react';

const StepHeader = ({title}) => {
  return (
    <div className="multiStepFormMobile__step__header">
      <div className="title">{title}</div>
    </div>
  );
};

export default StepHeader;
