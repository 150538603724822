import React from 'react';
import cx from 'classnames';

import Icon from '../Icon/Icon';

import './Avatar.scss';

const Avatar = props => {
  const {className, avatar, firstName = '', lastName = '', icon, color = 'primary', size = 'md', style = {}, ...attrs} = props;

  const classes = cx('jla-avatar', `jla-avatar--${size}`, `jla-avatar--${color}`, className);

  let content = <div className="jla-avatar__initials">{`${firstName[0] || ''}${lastName[0] || ''}`.toUpperCase()}</div>;

  if (icon) {
    content = <Icon name={icon} className="jla-avatar__icon" />;
  }

  if (avatar) {
    style.backgroundImage = `url(${avatar})`;
    content = null;
  }

  return (
    <div className={classes} style={style} {...attrs}>
      {content}
    </div>
  );
};

export default Avatar;
