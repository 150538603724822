/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useRef, useMemo} from 'react';
import {matchPath} from 'react-router-dom';
import {Overlay, IconContainer, Searchbar} from 'components/UI';
import {Spinner} from 'reactstrap';

import {HistoryItem} from 'components/History';
import {moment} from '@julaya/common/utils';

import {useHistoryFilter} from '@julaya/common/hooks';

import Filter from './Filter';

const History = props => {
  const {
    history,
    historyData,
    getWalletDetails,
    walletDetailsLoading,
    getHistory,
    historyLoading,
    historyError,
    setSelectedItem,
    historyCount,
    match: {
      params: {walletType, walletId}
    }
  } = props;

  const filter = useHistoryFilter(getHistory, walletType, walletId);
  const [showFilter, setShowFilter] = useState(false);
  const [fullHistory, setFullHistory] = useState({});
  const [render, setRender] = useState(false);
  const innerRef = useRef(null);

  const hasMore = useMemo(() => {
    const maxNbPage = Math.ceil(historyCount / 20) || 1;
    if (filter.page < maxNbPage) {
      return true;
    }
    return false;
  }, [historyCount, historyData.length, historyLoading]);

  const match = matchPath(history.location.pathname, {
    path: '/history/filter'
  });

  useEffect(() => {
    if (match && match.isExact) {
      setShowFilter(true);
    } else {
      setShowFilter(false);
    }
  }, [history.location.pathname, match]);

  useEffect(() => {
    if (historyLoading === false && historyError === '') {
      if (historyData.length === 0 && filter.page === 1) {
        setFullHistory([]);
      } else {
        if (filter.page > 1) {
          const data = formatHistory(historyData, fullHistory);
          setFullHistory(data);
        } else {
          setFullHistory(formatHistory(historyData));
        }
        setRender(!render);
      }
    }
  }, [JSON.stringify(historyData), historyLoading]);
  useEffect(() => {
    getWalletDetails(walletId);
  }, []);

  const handleOnScroll = () => {
    const el = document.querySelector('.app-content');

    const curPage = (Math.round(el.scrollTop / window.innerHeight) || 1) + 1;
    if (curPage > filter.page && !showFilter && (innerRef?.current?.clientHeight - el.scrollTop) / window.innerHeight < 1.25) {
      filter.setPage(curPage);
    }
  };

  useEffect(() => {
    document.querySelector('.app-content').addEventListener('scroll', handleOnScroll);
    return () => {
      const el = document.querySelector('.app-content');
      if (el) {
        el.removeEventListener('scroll', handleOnScroll);
      }
    };
  }, [filter.page]);

  const toggleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <>
      <div className="page-wrapper d-flex flex-column flex-grow-1" ref={innerRef}>
        <Searchbar
          value={filter.query}
          onChange={filter.setQuery}
          onSubmitQuerySearch={filter.callGetHistory}
          name="history-search-only-query"
          placeholder="Chercher un destinataire"
          button={{
            onClick: toggleShowFilter
          }}
        />
        {/* Loading indicator */}
        {filter.page === 1 && (walletDetailsLoading || historyLoading) ? (
          <div className="d-flex flex-grow-1 d-flex align-items-center justify-content-center">
            <Spinner color="primary" style={{width: '5rem', height: '5rem'}} />
          </div>
        ) : (
          <>
            {/* History List */}
            <div className="historyList">
              {Object.keys(fullHistory).map((item, i) => (
                <div key={`groub-by-${i}`} className="mb-2">
                  <div className="title">{item}</div>
                  <div className="history mb-3">
                    {fullHistory[item].map((item, i) => (
                      <HistoryItem key={`${item.uniqId}-item-${i}`} item={item} walletId={walletId} setSelectedItem={setSelectedItem} />
                    ))}
                  </div>
                </div>
              ))}
            </div>
            {/* Has more indicator */}
            {hasMore === true ||
              (hasMore === false && historyLoading && (
                <button
                  type="type"
                  className="btn btn-block btn-lg btn-outline-primary mb-4"
                  disabled={historyLoading}
                  onClick={e => {
                    e.preventDefault();
                    filter.setPage(filter.page + 1);
                  }}>
                  {historyLoading ? (
                    <>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    </>
                  ) : (
                    <span className="text-bold">Voir plus</span>
                  )}
                </button>
              ))}
          </>
        )}
      </div>
      {/* Filter Overlay */}
      <Overlay
        open={showFilter}
        toggle={() => {
          setShowFilter(false);
        }}
        backdrop={true}>
        <Overlay.Header className="d-flex align-items-center">
          <a
            href="#julaya"
            onClick={e => {
              e.preventDefault();
              toggleShowFilter();
            }}>
            <IconContainer icon="jla-icon-x-mark" color="muted" bg="grey" />
          </a>
          <div className="ml-3 m-0 h5 font-weight-bold">Filtres</div>
        </Overlay.Header>
        <Overlay.Content>
          <Filter {...props} onClose={toggleShowFilter} filter={filter} walletType={walletType} />
        </Overlay.Content>
        <Overlay.Footer>
          <button
            type="button"
            className="btn btn-block btn-primary"
            onClick={() => {
              filter.callGetHistory();
              setShowFilter(false);
            }}>
            Appliquer les filtres
          </button>
        </Overlay.Footer>
      </Overlay>
    </>
  );
};

const formatHistory = (data, acc = {}) => {
  return data.reduce((acc, cur) => {
    let groupBy = moment(cur.groupBy, 'D/M/YYYY').format('MMMM Y');

    if (groupBy === 'Invalid date') {
      groupBy = moment(cur.groupBy, 'YYYY-M-D').format('MMMM Y');
    }

    if (groupBy === 'Invalid date') {
      groupBy = 'Transactions';
    }

    if (Array.isArray(acc[groupBy])) {
      acc[groupBy].push(cur);
    } else {
      acc[groupBy] = [cur];
    }
    return acc;
  }, acc);
};

export default History;
