/* eslint-disable jsx-a11y/role-supports-aria-props */
import { Icon } from '@julaya/common/system';
import moment from 'moment';
import { useRef } from 'react';
import { Col, Container, Row } from 'reactstrap';

const Receipts = ({item, attachedReceipt, attachReceiptLoading, attachReceiptError, onFileLoaded}) => {
  const inputRef = useRef(null);

  const getImageName = image => {
    return image?.split('/').pop();
  };

  const getImageUploadedData = date => {
    return moment(date).format('DD/MM/yyyy');
  };

  let attachmentText = '';
  let attachmentTextColor = null;
  let content;

  if (attachedReceipt !== null) {
    attachmentText = `Document ajouté avec succès`;
    attachmentTextColor = 'text-success';
  }
  if (attachReceiptError) {
    attachmentText = `Des erreurs sont survenues lors du chargement de votre document `;
    attachmentTextColor = 'text-danger';
  }

  if (item.download) {
    content = (
      <div className="py-3">
        <div className="mb-1 d-flex justify-content-between align-items-center">
          <div>Facture</div>
          <a className="btn btn-primary btn-sm" href={item.download} target="_blank" rel="noopener noreferrer">
            Télécharger
          </a>
        </div>
      </div>
    );
  } else if (item.receipts && item.receipts.length) {
    content = (
      <div className="py-3">
        <div className="mb-2 d-flex justify-content-between align-items-center">
          <button
            className="btn btn-block btn-primary"
            disabled={attachReceiptLoading}
            onClick={e => {
              e.preventDefault();
              inputRef.current.click();
            }}>
            {attachReceiptLoading && (
              <>
                <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
              </>
            )}
            Ajouter
          </button>
        </div>

        {item.receipts && item.receipts.length ? (
          <>
            <Container className="px-0">
              {item.receipts.map((receipt, i) => {
                return (
                  <Row className="no-gutters my-2" style={{display: "flex", flexDirection:"row", alignItems: "center"}}key={`receipts-${i}`}>
                    <Col xs={18} className="d-flex align-items-center">
                      <div className="mr-2">
                        <div
                          className="avatar-small"
                          style={{width: '3rem', height: '3rem', backgroundSize: 'cover', backgroundImage: `url(${receipt.data})`}}></div>
                      </div>
                      <div className="d-flex justify-content-center flex-column flex-1 overflow-hidden">
                        <div className='overflow-hidden'>{getImageName(receipt.data)}</div>
                        <div>{getImageUploadedData(receipt.updatedAt)}</div>
                      </div>
                    </Col>
                    <Col xs={6} className="d-flex justify-content-end">
                      <a href={receipt.data} rel="noopener noreferrer" target="_blank" tabIndex="0">
                        <i className="icon-eye h6"></i>
                      </a>
                    </Col>
                  </Row>
                );
              })}
            </Container>
            {attachmentText && attachedReceipt && attachedReceipt.uniqId === item.uniqId && (
              <div className={`${attachmentTextColor} pt-1`}>{attachmentText}</div>
            )}
          </>
        ) : null}
      </div>
    );
  } else {
    content = attachReceiptLoading ? (
      <div className="py-3">
        <div className={`file-upload text-muted px-3 py-2`}>
          <span className="spinner-border" role="status" aria-hidden="true"></span>
        </div>
      </div>
    ) : (
      <div className="py-3">
        {attachmentText && <div className={`${attachmentTextColor} pt-1`}>{attachmentText}</div>}
        <a
          href="#julaya"
          onClick={e => {
            e.preventDefault();
            inputRef.current.click();
          }}>
          <div className={`file-upload text-muted px-3 py-2`}>
            <Icon name="arrow-left-on-rectangle" className="mb-0 pr-1 text-secondary" size="h3" />
            <span className="text-secondary">Ajouter un justificatif au format PNG, JPG</span>
          </div>
        </a>
      </div>
    );
  }

  return (
    <>
      {content}
      <input ref={inputRef} type="file" className="hidden-file-input" onChange={e => onFileLoaded('receipt', e)} />
    </>
  );
};

export default Receipts;
