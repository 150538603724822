export const getValidationState = str => {
  if (str === '') {
    return 'Champ obligatoire';
  } else if (str.length < 4) {
    return 'Vous devez indiquer au moins 10 caractères';
  }
  return false;
};

export const getValidationStateTwoFactor = str => {
  if (str === '') {
    return 'Champ obligatoire';
  } else if (str.length < 6) {
    return 'Vous devez indiquer au moins 6 caractères';
  }
  return false;
};

export const getValidationStatePassword = (str, str2 = null) => {
  if (str === '') {
    return 'Champ obligatoire';
  } else if (str.length < 4) {
    return 'Vous devez indiquer 4 caractères';
  } else if (str2 && str !== str2) {
    return 'Les mots de passe de correspondent pas.';
  }
  return false;
};
