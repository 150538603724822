const transactionTypes = {
  3: 'Transfert Julaya',
  5: 'Transfert Mobile Money',
  21: 'Approvisionnement bancaire',
  8: 'Crédit téléphonique',
  28: 'Transfert Wave',
  24: 'Transfert Wizall',
  18: 'Transfert carte bancaire',
  17: 'Transfert bancaire',
  29: 'Transfert international',
  31: 'Décaissement crédit',
  32: 'Remboursement crédit'
};
const cardTransactionTypes = {
  1: 'Rechargement CB',
  2: 'Retrait CB',
  3: 'Autorisation CB'
};

const transactionTypeMap = {
  subscription: {icon: 'jla-icon-arrows-right-left', title: 'Abonnement'},
  airtime: {icon: 'jla-icon-device-mobile-phone', title: 'Crédit Téléphonique'},
  referal: {icon: 'jla-icon-arrows-right-left', title: "Apporteur d'affaire"},
  'bank-disposal': {icon: 'jla-icon-wallet', title: 'Approvisionnement'},
  buy: {icon: 'jla-icon-wallet', title: 'Rechargement MoMo/CB'},
  'bank-transfer': {icon: 'jla-icon-bank', title: 'Virement Bancaire'},
  'cb-transfer': {icon: 'jla-icon-credit-card', title: 'Transfert vers CB'},
  transfer: {icon: 'jla-icon-julaya-circle', title: 'Julaya'},
  purchase: {icon: 'jla-icon-credit-card', title: 'Achat de carte'},
  'wizall-transfer': {icon: 'jla-icon-arrows-right-left', title: 'Wizall'},
  disposal: {icon: 'jla-icon-arrows-right-left', title: 'Mobile Money'},
  'momo-deposit': {icon: 'jla-icon-arrows-right-left', title: 'Dépot Wave'},
  'merchant-payment': {icon: 'jla-icon-arrows-right-left', title: 'Paiement Wave'},
  'wave-transfer': {icon: 'jla-icon-arrows-right-left', title: 'Transfert wave'},
  'international-transfer': {
    icon: 'jla-icon-arrows-right-left',
    title: 'Transfert international'
  },
  'loan-disbursement': {icon: 'jla-icon-bills', title: 'Décaissement crédit'},
  'loan-repayment': {icon: 'jla-icon-bills', title: 'Remboursement crédit'},

  // extra TransactionTag types
  load: {icon: 'jla-icon-device-mobile-phone', title: 'Crédit'},
  deduction: {icon: 'jla-icon-arrows-right-left', title: 'Débit'},
  authorisation: {icon: 'jla-icon-arrows-right-left', title: 'Autorisation'},
  task: {icon: 'jla-icon-task', title: 'Tâche'}
};

const transactionStatuses = {
  waiting: 'En attente',
  failed: 'Echec',
  done: 'Succès'
};

const bankOptions = [
  {value: '', label: 'Banque...'},
  {label: 'AFRILAND FIRST BANK', value: 'AFRILAND-FIRST-BANK'},
  {label: "BACI - Banque Atlantique - Côte d'Ivoire", value: 'BACI'},
  {label: "Banque d'Abidjan", value: 'BDA'},
  {label: "Banque de l'Union Côte d'Ivoire", value: 'BDU-CI'},
  {label: 'Banque Malienne de Solidarité', value: 'BMS'},
  {label: 'Banque Megionale de Marché ', value: 'BRM-CI'},
  {label: "BBG-CI - Bridge Bank Group Côte d'Ivoire", value: 'BBG-CI'},
  {label: "BGFIBANK COTE D'IVOIRE", value: 'BGFIBank'},
  {label: "BHCI - Banque de l'Habitat de Côte d'Ivoire", value: 'BHCI'},
  {
    label: "BICICI - Banque Internationale pour le Commerce et l'Industrie de la Côte d'Ivoire",
    value: 'BICICI'
  },
  {label: "BNI - Banque Nationale d'Investissement", value: 'BNI'},
  {label: "BOA Côte d'Ivoire - Bank Of Africa", value: 'BOA'},
  {
    label: "BSIC - Banque Sahélo-Saharienne pour l'Investissement et le Commerce - Côte d'Ivoire",
    value: 'BSIC-CI'
  },
  {label: "CIBCI - COFIPA Investment Bank - Côte d'Ivoire", value: 'CIBCI'},
  {label: 'CITIBANK CI ', value: 'CITIBANK'},
  {
    label: "CNCE - Caisse Nationale des Caisses d'Epargne de la Côte d'Ivoire",
    value: 'CNCE'
  },
  {label: 'CORIS BANK INTERNATIONALE', value: 'CBI-CI'},
  {label: "Diamond Bank Bénin (succursale de Côte d'Ivoire)", value: 'DBCI'},
  {label: "Ecobank - Côte d'Ivoire", value: 'ECOBANK-CI'},
  {label: 'FIDELIS - Fidelis finance', value: 'FIDELIS'},
  {
    label: "GTBANK-CI - Guaranty Trust Bank Cote d'Ivoire",
    value: 'GTBANK-CI'
  },
  {label: 'NSIA BANQUE', value: 'NSIA'},
  {label: 'ORABANK', value: 'ORABANK'},
  {label: 'SAFCA - Alios finance', value: 'SAFCA'},
  {
    label: "SCBCI - Standard Chartered Bank Côte d'Ivoire (SCB) ",
    value: 'SCBCI'
  },
  {
    label: "SGCI - Société Générale de Côte d'Ivoire",
    value: 'SGCI'
  },
  {label: 'Société Ivoirienne de Banque (SIB)', value: 'SIB'},
  {label: "Stanbic Côte d'Ivoire", value: 'STABIC BANK'},
  {label: 'United Bank for Africa (UBA)', value: 'UBA'},
  {label: 'VERSUS BANK S.A.', value: 'VERSUSBANK'}
];

const countryCodeOptions = [
  {value: '00225', label: '00225'},
  {value: '00221', label: '00221'}
];
const countryCodeDefault = '00225';

const serviceSlugIcons = {
  'disposal-mtn-civ': require('@julaya/common/assets/images/operators/mtn-money.png').default,
  'disposal-moov-civ': require('@julaya/common/assets/images/operators/moov-money.png').default,
  'momo-deposit-moov-civ': require('@julaya/common/assets/images/operators/moov-money.png').default,
  'momo-deposit-collect-wave-civ': require('@julaya/common/assets/images/operators/wave.png').default,
  'momo-deposit-collect-wave-sen': require('@julaya/common/assets/images/operators/wave.png').default,
  'merchant-payment-wave-civ': require('@julaya/common/assets/images/operators/wave.png').default,
  'merchant-payment-wave-sen': require('@julaya/common/assets/images/operators/wave.png').default,
  'disposal-orange-civ': require('@julaya/common/assets/images/operators/orange-money.png').default,
  'disposal-orange-sen': require('@julaya/common/assets/images/operators/orange-money.png').default,
  'disposal-emoney-sen': require('@julaya/common/assets/images/operators/e-money.png').default,
  'disposal-free-sen': require('@julaya/common/assets/images/operators/free-money.png').default,
  'disposal-celtiis-ben': require('@julaya/common/assets/images/operators/celtiis.png').default,
  'airtime-mtn-civ': require('@julaya/common/assets/images/operators/mtn.png').default,
  'airtime-moov-civ': require('@julaya/common/assets/images/operators/moov.png').default,
  'airtime-orange-civ': require('@julaya/common/assets/images/operators/orange.png').default,
  'bank-disposal-uba-civ':
    require('@julaya/common/assets/images/operators/uba.jpeg').default, 
  'bank-disposal-ecobank-civ': require('@julaya/common/assets/images/operators/ecobank.webp').default,
  'bank-disposal-ecobank-sen': require('@julaya/common/assets/images/operators/ecobank.webp').default,
  'bank-disposal-sgbs-sen':
    require("@julaya/common/assets/images/operators/societe-generale.png").default, 
  'bank-disposal-boa-sen': require('@julaya/common/assets/images/operators/boa.png').default,
  'bank-disposal-uba-sen':
    require('@julaya/common/assets/images/operators/uba.jpeg').default, 
  'bank-disposal-cbao-sen': require("@julaya/common/assets/images/operators/sib.png").default,
  'bank-disposal-sgbci-civ':
    require("@julaya/common/assets/images/operators/societe-generale.png").default, 
  'bank-disposal-bdu-civ': require('@julaya/common/assets/images/operators/bdu.png').default,
  'bank-disposal-orabank-civ': require('@julaya/common/assets/images/operators/orabank.png').default,
  'bank-disposal-nsia-civ': require('@julaya/common/assets/images/operators/nsia.png').default,
  'bank-disposal-bicici-civ': require('@julaya/common/assets/images/operators/bicici.png').default,
  'bank-disposal-boa-civ': require('@julaya/common/assets/images/operators/boa.png').default,
  'bank-disposal-gtb-civ': require('@julaya/common/assets/images/operators/gtb.png').default,
  'bank-disposal-sib-civ': require("@julaya/common/assets/images/operators/sib.png").default,
  'bank-disposal-dgtcp-civ': require('@julaya/common/assets/images/operators/dgtcp.png').default,

  'bank-transfer-waemu-civ': require('@julaya/common/assets/images/operators/bank.png').default,
  'bank-transfer-waemu-sen': require('@julaya/common/assets/images/operators/bank.png').default,

  'transfer-julaya-civ': require('@julaya/common/assets/images/operators/julaya.png').default,
  'transfer-julaya-sen': require('@julaya/common/assets/images/operators/julaya.png').default,
  'transfer-julaya-ben': require('@julaya/common/assets/images/operators/julaya.png').default,
  'transfer-julaya-internal-civ': require('@julaya/common/assets/images/operators/julaya.png').default,
  'transfer-julaya-internal-sen': require('@julaya/common/assets/images/operators/julaya.png').default,
  'transfer-julaya-internal-ben': require('@julaya/common/assets/images/operators/julaya.png').default,
  'transfer-julaya-external-civ': require('@julaya/common/assets/images/operators/julaya.png').default,
  'transfer-julaya-external-sen': require('@julaya/common/assets/images/operators/julaya.png').default,
  'transfer-julaya-external-ben': require('@julaya/common/assets/images/operators/julaya.png').default,
  'wave-transfer-civ': require('@julaya/common/assets/images/operators/wave.png').default,
  'wave-transfer-sen': require('@julaya/common/assets/images/operators/wave.png').default,
  load: require('@julaya/common/assets/images/operators/cb-transfer.png').default,
  deduction: require('@julaya/common/assets/images/operators/cb-transfer.png').default,
  authorisation: require('@julaya/common/assets/images/operators/cb-transfer.png').default,
  'cb-transfer-tutuka-civ': require('@julaya/common/assets/images/operators/cb-transfer.png').default,
  'cb-transfer-tutuka-sen': require('@julaya/common/assets/images/operators/cb-transfer.png').default,
  'cb-transfer-tutuka-ben': require('@julaya/common/assets/images/operators/cb-transfer.png').default,
  'wizall-transfer-cash-civ': require('@julaya/common/assets/images/operators/wizall.png').default,
  'international-transfer-mfs-civ': require('@julaya/common/assets/images/operators/transfer-international.png').default,
  'international-transfer-mfs-sen': require('@julaya/common/assets/images/operators/transfer-international.png').default,
  'bank-transfer-civ': require('@julaya/common/assets/images/operators/bank.png').default,
  'bank-transfer-sen': require('@julaya/common/assets/images/operators/bank.png').default,
  'loan-disbursement-civ': require('@julaya/common/assets/images/operators/julaya.png').default,
  'loan-disbursement-sen': require('@julaya/common/assets/images/operators/julaya.png').default,
  'disposal-mtn-ben': require('@julaya/common/assets/images/operators/mtn-money.png').default,
  'disposal-moov-ben': require('@julaya/common/assets/images/operators/moov-money.png').default,
  'cb-transfer-djamo-civ': require('@julaya/common/assets/images/operators/djamo.png').default,
  'cb-transfer-djamo-sen': require('@julaya/common/assets/images/operators/djamo.png').default,
  'bank-disposal-ecobank-ben': require('@julaya/common/assets/images/operators/ecobank.webp').default,
  'bank-disposal-boa-ben': require('@julaya/common/assets/images/operators/boa.png').default,
  "bank-disposal-mansa-civ": require('@julaya/common/assets/images/operators/mansabank.png').default,
  "bank-disposal-mansa-ben": require('@julaya/common/assets/images/operators/mansabank.png').default,
  "bank-disposal-mansa-sen": require('@julaya/common/assets/images/operators/mansabank.png').default,
};

/*
  |
  | App transactionType groups
  | -----------------------------
  */
// TODO: internationalisation
const transactionTypeGroupsApp = {
  'money-transfer': {
    acl: 'transactionsInit',
    title: "Transfert d'argent",
    icon: require('@julaya/common/assets/images/category-transfer-illustrations/transfer.svg').default,
    family: 'transfer',
    slug: 'money-transfer',
    servicesLabel: 'Opérateur mobile money',
    modal: true,
    services: [
      {
        title: 'Orange',
        icon: require('@julaya/common/assets/images/operators/orange-money.png').default,
        slug: 'disposal',
        params: 'disposal-orange',
        active: true,
        className: 'col-12'
      },
      {
        title: 'MTN',
        icon: require('@julaya/common/assets/images/operators/mtn-money.png').default,
        slug: 'disposal',
        params: 'disposal-mtn',
        className: 'col-12 mb-15'
      },
      {
        title: 'Moov',
        icon: require('@julaya/common/assets/images/operators/moov-money.png').default,
        slug: 'disposal',
        params: 'disposal-moov',
        className: 'col-12 mb-15'
      },
      {
        title: 'Julaya',
        icon: require('@julaya/common/assets/images/operators/julaya.png').default,
        slug: 'transfer',
        params: 'transfer-julaya',
        className: 'col-12 mb-15'
      }
    ]
  },
  'bank-disposal': {
    acl: 'transactionReceive',
    title: 'Recharger mon compte',
    icon: require('@julaya/common/assets/images/category-transfer-illustrations/bank-disposal.svg').default,
    modal: false,
    family: 'bank-disposal',
    slug: 'bank-disposal',
    services: [
      {
        title: 'SGCI',
        icon: require("@julaya/common/assets/images/operators/societe-generale.png").default, 
        slug: 'bank-disposal',
        params: 'bank-disposal-sgbci',
        bankInfos: {
          rib: {
            key: '62',
            bankCode: 'CI008',
            counterCode: '01111',
            accountNumber: '011180018947'
          },
          link: 'https://societegenerale.ci/fr/votre-banque/nos-agences/',
          name: 'Société Générale (SGCI)',
          accountOwner: "Julaya Côte d'Ivoire"
        }
      },
      {
        title: 'Ecobank',
        icon: require('@julaya/common/assets/images/operators/ecobank.webp').default,
        slug: 'bank-disposal',
        params: 'bank-disposal-ecobank',
        bankInfos: {
          rib: {
            key: '92',
            bankCode: 'CI059',
            counterCode: '01056',
            accountNumber: '120981509001'
          },
          link: "https://www.google.com/maps/search/ecobank+cote+d'ivoire+agences/",
          name: 'Ecobank',
          accountOwner: "Julaya Côte d'Ivoire"
        }
      },
      {
        title: 'UBA',
        icon: require('@julaya/common/assets/images/operators/uba.jpeg').default, 
        slug: 'bank-disposal',
        params: 'bank-disposal-uba',
        bankInfos: {
          rib: {
            key: '49',
            bankCode: 'CI150',
            counterCode: '01005',
            accountNumber: '105090000791'
          },
          link: 'https://www.ubacotedivoire.com/help/atm-branch-locator/',
          name: 'United Bank for Africa (UBA)',
          accountOwner: "Julaya Côte d'Ivoire"
        }
      },

      {
        title: 'Orabank',
        icon: require('@julaya/common/assets/images/operators/orabank.png').default,
        slug: 'bank-disposal',
        params: 'bank-disposal-orabank',
        bankInfos: {
          rib: {
            key: '23',
            bankCode: 'CI121',
            counterCode: '01301',
            accountNumber: '032989500201'
          },
          link: 'https://www.orabank.net/fr/filiale/cote-divoire/reseau-dagences',
          name: "Orabank Côte d'Ivoire",
          accountOwner: "Julaya Côte d'Ivoire"
        }
      },

      {
        title: 'SIB',
        icon: require("@julaya/common/assets/images/operators/sib.png").default,
        slug: 'bank-disposal',
        params: 'bank-disposal-sib',
        bankInfos: {
          rib: {
            key: '01',
            bankCode: 'CI007',
            counterCode: '01030',
            accountNumber: '900012167180'
          },
          link: 'http://www.sib.ci/nos-agences/',
          name: 'Société Ivoirienne de Banque (SIB)',
          accountOwner: "Julaya Côte d'Ivoire"
        }
      },
      {
        title: 'BDU',
        icon: require('@julaya/common/assets/images/operators/bdu.png').default,
        slug: 'bank-disposal',
        params: 'bank-disposal-bdu',
        bankInfos: {
          rib: {
            key: '14',
            bankCode: 'CI180',
            counterCode: '01001',
            accountNumber: '020401121652'
          },
          link: 'https://bduci.com/notre-reseau/',
          name: "Banque De l'Union (BDU)",
          accountOwner: "Julaya Côte d'Ivoire"
        }
      },
      {
        title: 'NSIA',
        icon: require('@julaya/common/assets/images/operators/nsia.png').default,
        slug: 'bank-disposal',
        params: 'bank-disposal-nsia',
        bankInfos: {
          rib: {
            key: '79',
            bankCode: 'CI0142',
            counterCode: '01212',
            accountNumber: '065461302001'
          },
          link: 'https://www.nsiabanque.ci/particuliers/nos-agences/',
          name: 'NSIA Banque CI',
          accountOwner: "Julaya Côte d'Ivoire"
        }
      },
      {
        title: 'BOA',
        icon: require('@julaya/common/assets/images/operators/boa.png').default,
        slug: 'bank-disposal',
        params: 'bank-disposal-boa',
        bankInfos: {
          rib: {
            key: '44',
            bankCode: 'CI032',
            counterCode: '01001',
            accountNumber: '001349540014'
          },
          link: 'https://boacoteivoire.com/',
          name: 'Bank of Africa (BOA)',
          accountOwner: "Julaya Côte d'Ivoire"
        }
      },
      {
        title: 'BICICI',
        icon: require('@julaya/common/assets/images/operators/bicici.png').default,
        slug: 'bank-disposal',
        params: 'bank-disposal-bicici',
        bankInfos: {
          rib: {
            key: '34',
            bankCode: 'CI006',
            counterCode: '01550',
            accountNumber: '013461000065'
          },
          link: 'https://www.bicici.com/trouver-une-agence/',
          name: "Banque Internationale pour le Commerce et l'Industrie de la Côte d'Ivoire (BICICI)",
          accountOwner: "Julaya Côte d'Ivoire"
        }
      },
      {
        title: 'GTB',
        icon: require('@julaya/common/assets/images/operators/gtb.png').default,
        slug: 'bank-disposal',
        params: 'bank-disposal-gtb',
        bankInfos: {
          rib: {
            key: '14',
            bankCode: 'CI163',
            counterCode: '01202',
            accountNumber: '000000214935'
          },
          link: 'https://www.gtbankci.com/localisation/',
          name: 'Guaranty Trust Bank (GTB)',
          accountOwner: "Julaya Côte d'Ivoire"
        }
      },
      {
        title: 'DGTCP',
        icon: require('@julaya/common/assets/images/operators/dgtcp.png').default,
        slug: 'bank-disposal',
        params: 'bank-disposal-dgtcp',
        bankInfos: {
          rib: {
            key: '79',
            bankCode: 'CI650',
            counterCode: '01001',
            accountNumber: '010471690008'
          },
          link: 'https://www.tresor.gouv.ci/tres/contacts-utiles/',
          name: 'Direction Générale Du Trésor Et De La Comptabilité Publique (DGTCP)',
          accountOwner: "Julaya Côte d'Ivoire"
        }
      }
    ],
    tutorial: 'bankDisposal'
  },
  'buy-services': {
    acl: 'transactionsInit',
    title: 'Crédit téléphonique',
    icon: require('@julaya/common/assets/images/category-transfer-illustrations/airtime.svg').default,
    family: 'airtime',
    slug: 'buy-services',
    servicesLabel: 'Opérateur téléphonique',
    modal: false,
    services: [
      {
        title: 'Orange',
        icon: require('@julaya/common/assets/images/operators/orange.png').default,
        slug: 'airtime',
        params: 'airtime-orange'
      },
      {
        title: 'Mtn',
        icon: require('@julaya/common/assets/images/operators/mtn.png').default,
        slug: 'airtime',
        params: 'airtime-mtn'
      },
      {
        title: 'Moov',
        icon: require('@julaya/common/assets/images/operators/moov.png').default,
        slug: 'airtime',
        params: 'airtime-moov'
      }
    ]
  },
  'bank-transfer': {
    acl: 'transactionsInit',
    title: 'Virement bancaire',
    icon: require('@julaya/common/assets/images/category-transfer-illustrations/bank-transfer.svg').default,
    modal: false,
    family: 'bank-transfer',
    slug: 'bank-transfer',
    params: 'bank-transfer'
  }
};

const exportFormats = {
  csv: 'CSV',
  qif: 'QIF',
  xlsx: 'Excel (.xlsx)'
};

const servicesMap = {
  'disposal-mtn-civ': {
    title: 'MTN Money',
    img: require('@julaya/common/assets/images/operators/mtn-money.png').default,
    phoneFormat: true
  },
  'disposal-mtn-ben': {
    title: 'MTN Money',
    img: require('@julaya/common/assets/images/operators/mtn-money.png').default,
    phoneFormat: true
  },
  'disposal-moov-ben': {
    title: 'Moov Money',
    img: require('@julaya/common/assets/images/operators/moov-money.png').default,
    phoneFormat: true
  },
  'transfer-julaya-civ': {
    title: 'Julaya',
    img: require('@julaya/common/assets/images/operators/julaya.png').default,
    phoneFormat: true
  },
  'transfer-julaya-sen': {
    title: 'Julaya',
    img: require('@julaya/common/assets/images/operators/julaya.png').default,
    phoneFormat: true
  },
  'transfer-julaya-ben': {
    title: 'Julaya',
    img: require('@julaya/common/assets/images/operators/julaya.png').default,
    phoneFormat: true
  },
  'transfer-julaya-internal-civ': {
    title: 'Julaya',
    img: require('@julaya/common/assets/images/operators/julaya.png').default,
    phoneFormat: true
  },
  'transfer-julaya-internal-sen': {
    title: 'Julaya',
    img: require('@julaya/common/assets/images/operators/julaya.png').default,
    phoneFormat: true
  },
  'transfer-julaya-internal-ben': {
    title: 'Julaya',
    img: require('@julaya/common/assets/images/operators/julaya.png').default,
    phoneFormat: true
  },
  'transfer-julaya-external-civ': {
    title: 'Julaya',
    img: require('@julaya/common/assets/images/operators/julaya.png').default,
    phoneFormat: true
  },
  'transfer-julaya-external-sen': {
    title: 'Julaya',
    img: require('@julaya/common/assets/images/operators/julaya.png').default,
    phoneFormat: true
  },
  'transfer-julaya-external-ben': {
    title: 'Julaya',
    img: require('@julaya/common/assets/images/operators/julaya.png').default,
    phoneFormat: true
  },
  'disposal-orange-civ': {
    title: 'Orange Money',
    img: require('@julaya/common/assets/images/operators/orange-money.png').default,
    phoneFormat: true
  },
  'disposal-orange-sen': {
    title: 'Orange Money',
    img: require('@julaya/common/assets/images/operators/orange-money.png').default,
    phoneFormat: true
  },
  'disposal-free-sen': {
    title: 'Free Money',
    img: require('@julaya/common/assets/images/operators/free-money.png').default,
    phoneFormat: true
  },
  'disposal-emoney-sen': {
    title: 'EMoney',
    img: require('@julaya/common/assets/images/operators/e-money.png').default,
    phoneFormat: true
  },
  'disposal-moov-civ': {
    title: 'Moov Money',
    img: require('@julaya/common/assets/images/operators/moov-money.png').default,
    phoneFormat: true
  },
  'disposal-celtiis-ben': {
    title: 'Celtiis',
    img: require('@julaya/common/assets/images/operators/celtiis.png').default,
    phoneFormat: true
  },
  'wizall-transfer-cash-civ': {
    title: 'Wizall',
    img: require('@julaya/common/assets/images/operators/wizall.png').default,
    phoneFormat: true
  },
  'airtime-mtn-civ': {
    title: 'MTN',
    img: require('@julaya/common/assets/images/operators/mtn.png').default,
    phoneFormat: true
  },
  'airtime-moov-civ': {
    title: 'Moov',
    img: require('@julaya/common/assets/images/operators/moov.png').default,
    phoneFormat: true
  },
  'airtime-orange-civ': {
    title: 'Orange',
    img: require('@julaya/common/assets/images/operators/orange.png').default,
    phoneFormat: true
  },
  'bank-transfer-civ': {
    title: 'Banque',
    img: require('@julaya/common/assets/images/operators/bank.png').default,
    phoneFormat: false
  },
  'bank-transfer-sen': {
    title: 'Banque',
    img: require('@julaya/common/assets/images/operators/bank.png').default,
    phoneFormat: false
  },
  'bank-transfer-waemu-civ': {
    title: 'Banque',
    img: require('@julaya/common/assets/images/operators/bank.png').default,
    phoneFormat: false
  },
  'bank-transfer-waemu-sen': {
    title: 'Banque',
    img: require('@julaya/common/assets/images/operators/bank.png').default,
    phoneFormat: false
  },
  'cb-transfer-tutuka-civ': {
    title: 'Carte bancaire',
    img: require('@julaya/common/assets/images/operators/cb-transfer.png').default,
    phoneFormat: false
  },
  'cb-transfer-tutuka-sen': {
    title: 'Carte bancaire',
    img: require('@julaya/common/assets/images/operators/cb-transfer.png').default,
    phoneFormat: false
  },
  'cb-transfer-tutuka-ben': {
    title: 'Carte bancaire',
    img: require('@julaya/common/assets/images/operators/cb-transfer.png').default,
    phoneFormat: false
  },
  'cb-transfer-djamo-civ': {
    title: 'Carte Djamo',
    img: require('@julaya/common/assets/images/operators/djamo.png').default,
    phoneFormat: true
  },
  'cb-transfer-djamo-sen': {
    title: 'Carte Djamo',
    img: require('@julaya/common/assets/images/operators/djamo.png').default,
    phoneFormat: true
  },
  'wave-transfer-civ': {
    title: 'Wave',
    img: require('@julaya/common/assets/images/operators/wave.png').default,
    phoneFormat: true
  },
  'wave-transfer-sen': {
    title: 'Wave',
    img: require('@julaya/common/assets/images/operators/wave.png').default,
    phoneFormat: true
  },
  'international-transfer-mfs-civ': {
    title: 'Transfert international', // TODO: internationalisation
    img: require('@julaya/common/assets/images/operators/transfer-international.png').default,
    phoneFormat: true
  },
  'international-transfer-mfs-sen': {
    title: 'Transfert international', // TODO: internationalisation
    img: require('@julaya/common/assets/images/operators/transfer-international.png').default,
    phoneFormat: true
  }
};

const transactionTypesMap = {
  transfer: {
    type: 'image',
    title: 'JULAYA',
    img: require('@julaya/common/assets/images/operators/julaya.png').default,
    phoneFormat: true
  },
  'wizall-transfer': {
    type: 'image',
    title: 'Wizall',
    img: require('@julaya/common/assets/images/operators/wizall.png').default,
    phoneFormat: true
  },
  'wave-transfer': {
    type: 'image',
    title: 'Wave',
    img: require('@julaya/common/assets/images/operators/wave.png').default,
    phoneFormat: true
  },
  disposal: {
    type: 'icon',
    title: 'Mobile Money',
    img: 'send',
    phoneFormat: true
  },
  'international-transfer': {
    type: 'icon',
    title: 'Mobile Money International',
    img: 'globe',
    phoneFormat: false
  },
  'bank-transfer': {
    type: 'icon',
    title: 'Compte bancaire',
    img: 'bank',
    phoneFormat: false
  },
  airtime: {
    type: 'icon',
    title: 'Crédit téléphonique',
    img: 'airtime',
    phoneFormat: true
  },
  'cb-transfer': {
    type: 'icon',
    title: 'Carte bancaire',
    img: 'credit-card',
    phoneFormat: false
  }
};

const transactionsErrorReasonMap = {
  INVALID_PHONE_NUMBER: 'Le numéro de téléphone saisi est incorrect. Veuillez le corriger et essayer à nouveau.',
  ERROR_OCCURED: 'Une erreur est survenue. Veuillez ressayer ultérieurement.',
  ACCOUNT_NOT_AUTHORIZED: "Le compte choisi n'est pas autorisé à recevoir de l'argent. ",
  SUCCESSFUL: 'La transaction a été traité avec succès.',
  ACCOUNT_SUSPENDED: 'Le compte Mobile Money du destinataire est suspendu.',
  INVALID_AMOUNT: 'Le montant de la transaction est superieur au montant maximum defini pour ce service.',
  ACCOUNT_DAILY_LIMIT_REACHED: "Le compte vers lequel vous essayez d'envoyer l'argent a atteint la limite mensuelle de transaction.",
  ACCOUNT_MONTHLY_LIMIT_REACHED: "Le compte vers lequel vous essayez d'envoyer l'argent a atteint la limite journalière de transaction.",
  ACCOUNT_WEEKLY_LIMIT_REACHED: "Le compte vers lequel vous essayez d'envoyer l'argent a atteint la limite hebdomadaire de transaction.",
  ACCOUNT_LIMIT_REACHED: "Le compte vers lequel vous essayez d'envoyer l'argent a atteint sa limite de solde. ",
  ACCOUNT_BLOCKED_OR_DISABLED: 'Le compte du destinataire est désactivé ou bloqué.',
  IDENTICAL_DEPOSIT: 'Vous venez de réaliser un dépôt identique.',
  ACCOUNT_NOT_FOUND: "Le destinataire ne dispose pas d'un compte Mobile Money.",
  BATCH_CHECKS_NOT_ENOUGHT_MONEY: "Vous n'avez pas assez d'argent sur votre compte.",
  BATCH_CHECKS_MAX_SOLDE: "Le compte vers lequel vous essayez d'envoyer l'argent a atteint sa limite de solde.",
  BATCH_CHECKS_MAX_CASHIN: "Le compte vers lequel vous essayez d'envoyer l'argent a atteint sa limite de solde.",
  SIMILAR_TRANSACTION_ALREADY_EXISTS: 'Une transaction similaire a été créée vers ce compte il y a moins de 5 minutes.'
};

export default {
  transactionTypes,
  cardTransactionTypes,
  transactionTypeMap,
  transactionStatuses,
  bankOptions,
  countryCodeOptions,
  countryCodeDefault,
  serviceSlugIcons,
  transactionTypeGroupsApp,
  exportFormats,
  servicesMap,
  transactionTypesMap,
  transactionsErrorReasonMap
};
