import React from 'react';

const IconContainer = ({icon = 'empty', color = 'primary', bg = 'light-primary'}) => {
  return (
    <div className={`icon-container text-${color} bg-${bg}`}>
      <i className={`${icon}`} />
    </div>
  );
};

export default IconContainer;
