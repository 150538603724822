import React, {useState, useEffect} from 'react';
import {InputFile, Card} from '@julaya/common/ui/molecules';
import {Alert, Text} from '@julaya/common/ui/atoms';
import {Icon} from '../../../../system';

import './index.scss';

const validProofs = [
  {
    image: 'transfer-bank-disposal-illustrations/screen.png',
    title: 'Chèques',
    description: 'Une photo de la décharge du chèque par Julaya.'
  },
  {
    image: 'transfer-bank-disposal-illustrations/bank-deposit.png',
    title: 'Virement physique',
    description: 'Ordre de virement signé et déchargé par la banque.'
  },
  {
    image: 'transfer-bank-disposal-illustrations/money.png',
    title: 'Virement d’espèces',
    description: "Bordereau de versement d'espèces déchargé par la banque."
  },
  {
    image: 'transfer-bank-disposal-illustrations/bank-check.png',
    title: 'Chèques',
    description: 'Une photo de la décharge du chèque par Julaya.'
  }
];

const DisposalProof = ({disposalProof, setDisposalProof, disposalProofError, setNextButtonDisabled}) => {
  const [openAlert, setOpenAlert] = useState(true);

  useEffect(() => {
    if (disposalProof) {
      setNextButtonDisabled(false);
    } else {
      setNextButtonDisabled(true);
    }
  }, [disposalProof]);

  return (
    <>
      <div className="disposalProof-container">
        <InputFile onSetFile={setDisposalProof} />
        <Text color="error">{disposalProofError}</Text>
        <hr />
        <Alert title="Quels sont les justificatifs valides ?" open={openAlert} setOpen={setOpenAlert}>
          <Alert.Content>
            {validProofs.map((element, i) => {
              return <Card key={i} image={element.image} title={element.title} description={element.description} next={i < validProofs.length - 1} />;
            })}
          </Alert.Content>
        </Alert>
        <div className="px-2 d-flex disposalProof_alert">
          <Icon name="information-circle" className="mr-1" size="h5" />
          <span>
            Vous n’avez pas de justificatif ? Effectuez votre dépôt parmi nos banques partenaires puis veillez à bien garder votre récépicé de dépôt
            bancaire.
          </span>
        </div>
        {/* <hr />
				<Button action={() => setDrawer(true)} variant="badge" status="primary" text="Je n'ai pas de justificatif" full={true} /> */}
      </div>
      {/* <NoProofDrawer drawer={drawer} setDrawer={setDrawer} /> */}
    </>
  );
};

export default DisposalProof;
