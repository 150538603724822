import React from "react";

const ErrorDisplay = ({
  errorText = "Des erreurs sont survenues",
  actionText = "Cliquer pour réessayer",
  onClick = null,
}) => {
  return (
    <div className="d-flex flex-grow-1 justify-content-center align-items-center">
      {onClick !== null ? (
        <div className="d-flex flex-column align-items-center">
          <div className="h4 pb-2 text-center">{errorText}</div>
          <a
            href="#julaya"
            onClick={(e) => {
              e.preventDefault();
              onClick();
            }}
          >
            {actionText}
          </a>
        </div>
      ) : (
        <div className="h4">{errorText}</div>
      )}
    </div>
  );
};

export default ErrorDisplay;
