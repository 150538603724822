import React, { useCallback } from 'react';
import cx from "classnames";
import './Pagination.scss';

const PaginationPage = props => {
  const {
    type = 'page',
    page = 1,
    pageSize = 10,
    total = 0,
    pageCount = 5,
    position = 'center',
    onChanged,
  } = props;

  const isShowPage = type === 'page';
  const numberOfPage = Math.ceil(total / pageSize);
  let listPage, startBy, endBy;
  if (isShowPage) {
    listPage = [];
    startBy = Math.max(page - Math.floor(pageCount / 2), 1);
    endBy = Math.min(startBy + pageCount, numberOfPage + 1);
    let idx = startBy;
    while (idx < endBy) {
      listPage.push(idx);
      idx++;
    }
  }
  const onSelect = (value) => {
    if (page === value) {
      return;
    }
    onChanged(value);
  };
  const onNext = () => {
    if (page === numberOfPage) {
      return;
    }
    onChanged(Math.min(page + 1, numberOfPage));
  };
  const onPrev = () => {
    if (page === 1) {
      return;
    }
    onChanged(Math.max(page - 1, 1));
  };
  const renderPaginationPage = useCallback(() => {
    return (
      <div
        className={cx(
          'pagination',
          { [`pagination--${position}`]: true }
        )}
      >
        <div className="pagination__wrapper">
          {
            isShowPage ? (
              <>
                <div
                  className={cx(
                    'pagination__page-item',
                    { 'pagination__page-item--disabled': page === 1 }
                  )}
                  onClick={onPrev}
                >
                  <i className="icon-chevron-left"></i>
                </div>
                {/* START: first page */}
                {
                  listPage[0] > 1 && (
                    <div className="pagination__page-item" onClick={() => { onSelect(1); }}>
                      1
                    </div>
                  )
                }
                {/* END: first page */}
                {
                  listPage[0] > 2 && (
                    <div className="pagination__page-item pagination__page-item--disabled">
                      ...
                    </div>
                  )
                }
                {
                  listPage.map((tmpPage, idx) => {
                    return (
                      <div
                        key={idx}
                        className={cx(
                          'pagination__page-item',
                          { 'pagination__page-item--active': tmpPage === page }
                        )}
                        onClick={() => { onSelect(tmpPage); }}
                      >
                        {tmpPage}
                      </div>
                    )
                  })
                }
                {
                  listPage[listPage.length - 1] < numberOfPage - 1 && (
                    <div className="pagination__page-item pagination__page-item--disabled">
                      ...
                    </div>
                  )
                }
                {/* START: last page */}
                {
                  listPage[listPage.length - 1] < numberOfPage && (
                    <div className="pagination__page-item" onClick={() => { onSelect(numberOfPage); }}>
                      {numberOfPage}
                    </div>
                  )
                }
                {/* END: last page */}
                <div
                  className={cx(
                    'pagination__page-item',
                    { 'pagination__page-item--disabled': page === numberOfPage }
                  )}
                  onClick={onNext}
                >
                  <i className="icon-chevron-right"></i>
                </div>
              </>
            ) : (
              <>
                <div className="pagination__table-bar-description">Page {page} sur {numberOfPage}</div>
                <div className="pagination__table-bar-action">
                  <div className="pagination__table-bar-item pagination__table-bar-item--next" onClick={onPrev}>
                    <i className="icon-chevron-left"></i>
                  </div>
                  <div className="pagination__table-bar-item pagination__table-bar-item--prev" onClick={onNext}>
                    <i className="icon-chevron-right"></i>
                  </div>
                </div>
              </>
            )
          }
        </div>
      </div>
    )
  }, [
    type,
    page,
    pageSize,
    total,
    pageCount,
    position,
  ]);
  return <>{renderPaginationPage()}</>
};

export default PaginationPage;
