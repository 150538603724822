/* eslint-disable react-hooks/exhaustive-deps */
import { ErrorDisplay, PageWrapper } from 'components/UI';
import { useEffect, useState } from 'react';

import WalletList from './WalletList';

const vals = {
  wallets: {
    itemType: 'wallet',
    pageTitle: 'Mes comptes',
    pageError: 'Aucun compte trouvé'
  },
  cards: {
    itemType: 'card',
    pageTitle: 'Cartes bancaires',
    pageError: 'Aucune carte trouvee trouvé'
  }
};

const AccountsList = props => {
  const {
    getUserWallets,
    landingWallets,
    landingWalletTypeParams,
    landingWalletsError,
    landingWalletsLoading,
    history,
    userCompanies,
    currentCompanyId,
        match: {
      params: {walletType}
    }
  } = props;

  // const [totalAmount, setTotalAmount] = useState(0);
  const [items, setItems] = useState([]);

  if (!['wallets', 'cards'].includes(walletType)) {
    history.replace('/');
  }

  useEffect(() => {
    if (
      !Array.isArray(landingWallets[walletType]) ||
      landingWallets[walletType].length === 0 ||
      ![null, vals[walletType]?.itemType.toUpperCase()].includes(landingWalletTypeParams)
    ) {
      getUserWallets(
        {
          type: vals[walletType]?.itemType.toUpperCase(),
          access: 'READ'
        },
        'landing'
      );
    }
  }, [getUserWallets]);

  useEffect(() => {
    setItems(landingWallets);
  }, [landingWallets]);

  return (
    <PageWrapper>
      <h5 className="mb-2">
        {vals[walletType]?.pageTitle}
        {!landingWalletsLoading && <span className="text-muted">({items.length})</span>}
      </h5>

      {landingWalletsError ? (
        <div className="mt-3">
          <ErrorDisplay
            errorText={landingWalletsError || 'Aucun compte trouvé'}
            onClick={() =>
              getUserWallets(
                {
                  type: vals[walletType]?.itemType.toUpperCase(),
                  access: 'READ'
                },
                'landing'
              )
            }
          />
        </div>
      ) : (
        <>
          {!(!landingWalletsLoading && !items.length) && (
            <>
              <WalletList {...props} wallets={items} walletType={vals[walletType]?.itemType} currentCompany={userCompanies.find(company => company.id === currentCompanyId) || ''}/>
            </>
          )}
        </>
      )}
    </PageWrapper>
  );
};

export default AccountsList;
