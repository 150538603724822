import React, {useState} from 'react';
import cx from 'classnames';
import StepHeader from './StepHeader';
import StepFooter from './StepFooter';

const Step = ({
  title,
  stepCount,
  currentStep,
  setCurrentStep,
  setCompletedStep,
  completedStep,
  ctaLoading,
  formData,
  onSubmit,
  children,
  step,
  canSkip,
  showCancel,
  cta,
  height = null,
  ...rest
}) => {
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  let styles = {};
  if (height) {
    styles = {
      maxHeight: `${height}rem`
      // overflow: "scroll"
    };
  }
  return (
    <div
      className={cx('multiStepForm__step', {
        '--active': currentStep === step,
        '--completed': currentStep > step,
        '--disabled': currentStep < step
      })}
    >
      <StepHeader title={title} step={step} currentStep={currentStep} setCurrentStep={setCurrentStep} completedStep={completedStep} />
      <form className="multiStepForm__step__content" onSubmit={onSubmit}>
        {React.Children.map(children, (child, i) => {
          const props = {
            nextButtonDisabled,
            setNextButtonDisabled,
            onSubmit,
            stepCount,
            currentStep,
            setCurrentStep,
            step,
            ...formData,
            ...rest,
            handleChange: e => {
              setCompletedStep(step);
              formData.handleChange(e);
            }
          };
          return (
            <div className="content-inner" style={styles}>
              {React.cloneElement(child, props)}
            </div>
          );
        })}
        <StepFooter
          cta={cta}
          nextButtonDisabled={nextButtonDisabled}
          showCancel={showCancel}
          canSkip={canSkip}
          step={step}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          ctaLoading={ctaLoading}
        ></StepFooter>
      </form>
    </div>
  );
};

export default Step;
