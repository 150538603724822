/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react';
import {Link} from 'react-router-dom';
import cx from 'classnames';

import {useDialog} from '@julaya/common/hooks';
import {JSpinner, ErrorDisplay, PageWrapper} from 'components/UI';

import DetailBody from './DetailBody';
import DetailSignature from './DetailSignators';
import OperationStatus from 'components/UI/Tasks/OperationStatus';
import DetailComment from './DetailComment';
import {Button} from '@julaya/common/ui/atoms';
import {Icon} from '@julaya/common/system';

const TaskDetail = props => {
  const {
    user,
    getBatchDetails,
    batchDetails,
    batchDetailsLoading,
    batchDetailsError,

    deleteBatch,

    match: {
      params: {batchId}
    }
  } = props;

  // const [markedAsDone, setMarkedAsdone] = useState(false)
  // const [markAsDonePasswordModal, toggleMarkAsDonePasswordModal] = useDialog()

  // Remove draft
  const removeElement = e => {
    e.preventDefault();
    deleteBatch(batchId, 'tasks');
  };

  // Quick actions
  // const proceed = (code, otp) => {
  // 	markBatchAsDone(selectedBatch.id, code, otp)
  // }

  useEffect(() => {
    getBatchDetails(batchId);
  }, []);

  const [approversDropdown, toggleApproversDropdown] = useDialog();
  // const [passwordModal, togglePasswordModal] = useDialog()

  const status = batchDetails && batchDetails.status;

  return (
    <>
      {batchDetailsLoading ? (
        <div className="mt-4 w-100 d-flex align-items-center justify-content-center">
          <JSpinner />
        </div>
      ) : // In case of an Error , the error lsg will be shown to the user with retry CTA
      batchDetailsError ? (
        <ErrorDisplay errorText={batchDetailsError} onClick={() => getBatchDetails(batchId)} />
      ) : (
        <PageWrapper>
          {batchDetails ? (
            <div className="task-detail ">
              {/* Header */}
              <div className="task-detail__header">
                <Button variant="badge" icon="jla-icon-task" rounded />
                <h1 className="title ml-3 mb-0">{batchDetails.title}</h1>
              </div>

              <div className="border-bottom my-3"></div>

              {/* Task Details */}
              <div className="d-flex align-items-center mb-3">
                <Icon className="mr-3 text-secondary" name="information-circle" size="h5" />
                <span className="font-weight-bold text-muted">Détails de la tâche</span>
              </div>
              <DetailBody batchDetails={batchDetails} />

              <div className="border-bottom my-3"></div>

              {/* Task Functionalities */}
              <div className="d-flex align-items-center mb-3">
                <Icon className="mr-3 text-secondary" name="gear" size="h5" />
                <div className="font-weight-bold text-muted">Fonctionnalités</div>
              </div>

              {/* Signataires */}
              <DetailSignature approversDropdown={approversDropdown} toggleApproversDropdown={toggleApproversDropdown} batchDetails={batchDetails} />

              {/* Voir les transactions */}
              {status === 'draft' || status === 'waiting' || status === 'to-validate' ? (
                <div className={cx('right-side__transactions my-3 disabled')}>
                  <div className="d-flex align-items-center text-muted">
                    <i className={`jla-icon-list-bullet h4 mb-0 text-primary mr-3`}></i>
                    Liste des transactions
                  </div>
                  <i className={`jla-icon-lock arrow p-2 text-black rounded align-self-center`}></i>
                </div>
              ) : (
                <Link
                  to={`/tasks/details/${batchDetails?.walletId}/${batchDetails?.id}/transactions`}
                  className={cx('right-side__transactions my-3')}>
                  <div className="d-flex align-items-center text-muted">
                    <i className={`jla-icon-list-bullet h4 mb-0 text-primary mr-3`}></i>
                    Liste des transactions
                  </div>
                  <i className={`jla-icon-chevron-right arrow p-2 text-black rounded align-self-center`}></i>
                </Link>
              )}

              <div className="border-bottom my-3"></div>

              {/* Operations Status */}
              <div className="d-flex align-items-center mb-3">
                <Icon className="mr-3 text-secondary" name="double-check-circle" size="h5" />
                <div className="font-weight-bold text-muted">Statut de la tâche</div>
              </div>
              <OperationStatus status={status} selectedBatch={batchDetails} user={user} />
              {/* Additionnal link */}
              <div className="d-flex additionnal-link justify-content-center mt-3">
                {status === 'draft' && (
                  <a href="#julaya" onClick={removeElement}>
                    {status === 'draft' ? 'Supprimer le brouillon' : 'Supprimer cette tâche'}
                  </a>
                )}
              </div>

              <div className="border-bottom my-3"></div>

              {/* Task Comments */}
              <div className="d-flex align-items-center">
                <Icon className="mr-3 text-secondary" name="chat-bubble" size="h5" />
                <span className="ml-2 text-muted">Activité de la tâche</span>
              </div>
              <DetailComment {...props} selectedBatch={batchDetails} />
            </div>
          ) : (
            <div className="mt-4 w-100 d-flex align-items-center justify-content-center">
              <JSpinner />
            </div>
          )}
        </PageWrapper>
      )}
    </>
  );
};

export default TaskDetail;
