import {useEffect} from 'react';
import {Radio, Text} from '@julaya/common/ui/atoms';
import {Card} from '@julaya/common/ui/molecules';
import {Alert, Bullet, Icon, Tag, Typography} from '@julaya/common/system';
import {methodsList} from './BankDisposal.constants';

const MethodSelect = props => {
  const {values, handleChange, errors, getValidationState, setNextButtonDisabled} = props;

  useEffect(() => {
    if (values.disposalMethod) {
      setNextButtonDisabled(false);
    } else {
      setNextButtonDisabled(true);
    }
  }, [values.disposalMethod]);

  return (
    <>
      <p>Transférez des fonds sur votre compte Julaya en utilisant le mode de paiement de votre choix. </p>
      <Alert color="primary">
        <Bullet bullet={<Icon className="text-secondary" name="question-mark-circle" size="h5" color="gradiant-pink" />} type="icon">
          <Typography lead>Attention, certaines méthodes peuvent prendre plus ou moins de temps à être traitées.</Typography>
          <Typography variant="small" color="neutral">
            En effet, les délais de traitement peuvent varier en fonction du délais de réponse de nos partenaires.
          </Typography>
        </Bullet>
      </Alert>
      <hr />
      <div>
        {methodsList.map((method, i) => {
          return (
            <>
              <Radio
                className="position-relative d-flex align-items-center"
                key={i}
                status="neutral"
                selected={values.disposalMethod === method.slug}
                onChange={() => {
                  handleChange({
                    target: {
                      name: 'disposalMethod',
                      value: method.slug
                    }
                  });
                }}>
                <Card status="gradiant-pink" icon={method.icon} title={method.title} description={method.description} rounded />
                {method.isFaster && (
                  <Tag className="text-white-space-pre p-1" style={{right: '16px', gap: '8px'}} prefixIcon="bolt" color="primary" iconColor="white">
                    Plus rapide
                  </Tag>
                )}
              </Radio>
              {values.disposalMethod === method.slug && (
                <>
                  {method.slug === 'bank' && (
                    <Alert color="primary" className="mb-3">
                      <Bullet bullet={<Icon name="bulb" size="h5" color="primary" />} type="icon">
                        <Typography lead>
                          Le virement interbancaire vous permet de gagner du temps sur le traitement de votre demande d’approvisionnement.
                        </Typography>
                      </Bullet>
                    </Alert>
                  )}
                  {method.slug === 'check' && (
                    <Alert color="primary" className="mb-3">
                      <Bullet bullet={<Icon name="bulb" size="h5" color="primary" />} type="icon">
                        <Typography lead>
                          Le dépôt chèque interbancaire vous permet de gagner du temps sur le traitement de votre demande d’approvisionnement.
                        </Typography>
                      </Bullet>
                    </Alert>
                  )}
                  {method.details}
                </>
              )}
            </>
          );
        })}

        {errors.disposalMethod && <Text color="error">{getValidationState(values.disposalMethod, 'disposalMethod')}</Text>}
        {/* 
				<hr />
				<Button variant="badge" icon="icon-deposit" text="Voir les banques partenaires" size="small" full /> 
				*/}
      </div>
    </>
  );
};

export default MethodSelect;
