import {
  GET_NOTIFICATION_SUCCESS,
  GET_NEW_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_SETTINGS_SUCCESS,
  ARCHIVE_ALL_NOTIFICATION_SUCCESS,
  ARCHIVE_NOTIFICATION_SUCCESS,
} from '../actionTypes';

const actions = [GET_NOTIFICATION_SUCCESS, GET_NEW_NOTIFICATION_SUCCESS, GET_NOTIFICATION_SETTINGS_SUCCESS, ARCHIVE_ALL_NOTIFICATION_SUCCESS, ARCHIVE_NOTIFICATION_SUCCESS];

const initialState = {
  notificationsListItems: null,
  notificationsArchiveListItems: null,
  notificationsNew: {},
  notificationsSettings: null
};

export default [initialState, actions];
