import { Icon } from '@julaya/common/system';
import cx from 'classnames';

const types = {
  wallet: {
    icon: 'wallet',
    title: 'Compte'
  },
  card: {
    icon: 'credit-card',
    title: 'Carte bancaire'
  }
};

const countries = {
  civ: {
    flag: require('@julaya/common/assets/images/flags/ci.png').default
  },
  sen: {
    flag: require('@julaya/common/assets/images/flags/sn.png').default
  },
  ben: {
    flag: require('@julaya/common/assets/images/flags/bj.png').default
  }
};

const Wallet = props => {
  const {
    loading,
    label,
    reference,
    balance,
    country,
    countryName,
    type = 'wallet',
    variant = 'light',
    selected = false,
    onClick = null,
    arrow = false,
  } = props;

  if (loading) {
    return (
      <div className={cx(`wallet-card wallet-card--${variant} `, {loading})}>
        <div className="wallet__info">
          <div className="d-flex justify-content-between align-items-center mb-3 text-body">
            <div className="wallet__label">
              <div className="placeholder-line" style={{width: '4rem', height: '1rem'}}></div>
            </div>
            <div className="wallet__balance">
              <div className="placeholder-line" style={{width: '8rem', height: '1rem'}}></div>
            </div>
          </div>

          <div className="wallet__reference mb-2">
            <div className="placeholder-line" style={{width: '6rem', height: '1rem'}}></div>
          </div>
          <div className="wallet__meta">
            <div className={`wallet__type wallet__type--${type}`}>
              <div className="placeholder-square"></div>
              <div className="ml-2 placeholder-line" style={{width: '3rem'}}></div>
            </div>

            <div className={`ml-3 wallet__type wallet__type--${type}`}>
              <div className="placeholder-square"></div>
              <div className="ml-2 placeholder-line" style={{width: '3rem'}}></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const wallet = (
    <div className={`wallet-card wallet-card--${variant}`}>
      <div className="wallet__info">
        <div className="wallet__info--container mb-3 text-body">
          <div className="wallet__label text-truncate">{label}</div>
          <div className="wallet__balance">{balance}</div>
        </div>

        <div className="wallet__reference">{reference}</div>
        <div className="wallet__meta">
          <div className={`wallet__type wallet__type--${type}`}>
            <Icon name={types[type].icon} color="lilas" size="h2" />
            <span className="wallet__type-label">{types[type].title}</span>
          </div>

          {country && (
            <div className={`wallet__country wallet__country--${country}`}>
              <img src={`${countries[country].flag}`} alt={'Pays'} />
              <span className="wallet__country-label">{countryName}</span>
            </div>
          )}
        </div>
      </div>

      {arrow && (
        <div className="wallet__arrow">
          <i className="icon-chevron-right ml-2"></i>
        </div>
      )}
    </div>
  );

  return onClick ? (
    <a
      href="#julaya"
      onClick={e => {
        e.preventDefault();
        onClick();
      }}
      className={cx('wallet-wrapper', {
        selected: selected
      })}>
      {wallet}
    </a>
  ) : (
    wallet
  );
};

export default Wallet;
