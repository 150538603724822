import React from 'react';
import cx from 'classnames';
import Content from './Content';

import './Alert.scss';
import {Icon} from '../../../system';

/*
/ UI | Atoms | Alert
*/

/**
 * This component define all the behavior of Alert
 */
const Alert = ({className, children, status = 'primary', title, icon, open, setOpen}) => {
  return (
    <div
      onClick={e => {
        e.preventDefault();
        children && setOpen(!open);
      }}
      className={cx(`alert_container  --${status} ${className}`, {
        '--collapse': children
      })}>
      <div className={cx(`alert_container__header`)}>
        <div className={cx(`alert_container__title`)}>
          {icon && <Icon name={icon} size="h5" />}
          {title && <span>{title}</span>}
        </div>
        {children && <Icon name={open ? 'chevron-up' : 'chevron-down'} size="h5" />}
      </div>
      {children && open && <div className="alert_container__content">{children}</div>}
    </div>
  );
};

Alert.Content = Content;

export default Alert;
