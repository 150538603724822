import {useState} from 'react';

export default initialValues => {
  const [values, setValues] = useState(initialValues);

  const [errors, setErrors] = useState(Object.keys(initialValues).reduce((acc, cur) => ({...acc, [cur]: false}), {}));
  const [touchedFields, setTouchedFields] = useState({});

  let addToValues = {};

  const handleChange = e => {
    let value;
    if (Array.isArray(e)) {
      e.map(el => handleChange(el, true));
    } else {
      if (e.target.type === 'checkbox') {
        value = e.target.checked;
      } else if (e.target.name === 'amount') {
        const val = parseInt(e.target.value.toString().replace(/\D/g, ''));
        value = isNaN(val) || val === 0 ? '' : val.toString();
      } else if (e.target.name === 'phone') {
        value = e.target.value.replace(/\D/g, '');
        // value = /^\d+$/.test(value) ? value.toString().trim() : false;
      } else if (e.target.name === 'id') {
        value = e.target.value.toString().trim();
      } else {
        value = e.target.value;
      }

      addToValues[e.target.name] = value === false && e.target?.type !== 'checkbox' ? '' : value;

      setErrors({
        ...errors,
        [e.target.name]: false
      });
    }

    setTouchedFields({
      ...touchedFields,
      [e.target.name]: true
    });

    setValues({
      ...values,
      ...addToValues
    });
  };

  const handleTouch = e => {
    setTouchedFields({
      ...touchedFields,
      [e.target.name]: true
    });
  };

  return [
    values,
    handleChange,
    errors,
    setErrors,
    (vals = null) => {
      setValues(vals !== null ? vals : initialValues);
      setErrors({});
      setTouchedFields({});
    },
    touchedFields,
    handleTouch
  ];
};
