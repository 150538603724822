import React, {useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import success from '@julaya/common/assets/images/rapid-transfer/rapid-transfer-success.png';
import {functions} from '@julaya/common/utils';
import {useDidUpdate} from '@julaya/common/hooks';
import {Typography, Spinner} from '@julaya/common/system';

const TransactionSuccess = ({
  total,
  newBalance,
  values,
  isMobile,
  history,
  transactionNotify,
  showSuccess,
  cancelTransactions,
  cancelTransactionsLoading,
  cancelTransactionsError
}) => {
  const [timeLeft, setTimeLeft] = useState();
  let interval;

  useDidUpdate(() => {
    if (cancelTransactionsLoading === false && cancelTransactionsError === '') {
      if (isMobile) {
        if (!transactionNotify?.isSent) {
          history.push(`/tasks/details/${transactionNotify?.id}`);
        } else {
          history.push(`/accounts/wallet/${values.walletId}/history`);
        }
      } else {
        history.push(`/history/wallet/${values.walletId}/${transactionNotify?.TaskItems[0]?.uuid}`);
      }
    }
  }, [cancelTransactionsLoading]);

  useEffect(() => {
    if (showSuccess) {
      setTimeLeft(15);
      interval = setInterval(() => {
        setTimeLeft(t => {
          if (t < 1) {
            clearInterval(interval);
          }
          return t - 1;
        });
      }, 1000);
    } else if (interval) {
      clearInterval(interval);
    }
    return () => interval && clearInterval(interval);
  }, [showSuccess]);

  if (timeLeft < 1) {
    const link = isMobile
      ? !transactionNotify?.isSent
        ? `/tasks/details/${transactionNotify?.id}`
        : `/accounts/wallet/${values.walletId}/history`
      : `/history/wallet/${values.walletId}/${transactionNotify?.TaskItems[0]?.uuid}`;

    return <Redirect to={link} />;
  }

  return (
    <>
      <header className="pb-2 text-center">
        <div className="mb-1 font-weight-bold">Votre opération a été enregistrée avec succès</div>
        <span>Vous serez redirigé vers l'historique dans {timeLeft} secondes</span>
      </header>

      <img src={success} alt="page not found" className="d-block mx-auto" height={200} />
      <Typography
        className="d-flex justify-content-center py-3"
        onClick={() => {
          if (!cancelTransactionsLoading) {
            cancelTransactions(values.walletId, {
              uuids: [transactionNotify?.TaskItems[0]?.uuid],
              comment: 'undo'
            });
          }
        }}
        underline>
        Une erreur ? Annuler l'opération {cancelTransactionsLoading && <Spinner color="black" size="xs" />}
      </Typography>

      <div className="d-flex justify-content-between align-items-center border-top py-3">
        <div>Montant total</div>
        <div className="font-weight-bold">-{functions.formatMoney(total)}</div>
      </div>
      <div className="d-flex justify-content-between align-items-center border-top py-3">
        <div>Nouveau solde</div>
        <div className="font-weight-bold text-primary">{functions.formatMoney(newBalance)}</div>
      </div>

      <div className="border-top mx--4 my-2"></div>

      <div className="container mt-4">
        <div className="row mt-3 mx--3">
          <div className="col-24">
            <button
              type="button"
              className="btn btn-block btn-primary"
              onClick={() => {
                if (isMobile) {
                  if (!transactionNotify?.isSent) {
                    history.push(`/tasks/details/${transactionNotify?.id}`);
                  } else {
                    history.push(`/accounts/wallet/${values.walletId}/history`);
                  }
                } else {
                  history.push(`/history/wallet/${values.walletId}/${transactionNotify?.TaskItems[0]?.uuid}`);
                }
              }}>
              Continuer
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionSuccess;
