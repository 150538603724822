import cx from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useDialog, useDidUpdate, useForm } from '@julaya/common/hooks';
import { MultiStepForm, MultiStepFormMobile, PasswordModal } from '@julaya/common/ui/organismes';
import { PageWrapper } from 'components/UI';

import { constants } from '@julaya/common/utils';

import useSubscriptionRestrictions from 'hooks/useSubscriptionRestrictions';
import { getUserCompanies } from '../../../store/actions';
import BeneficiariesSelect from './BeneficiariesSelect';
import TransactionSuccess from './TransactionSuccess';
import TransferDetail from './TransferDetail';
import WalletSelect from './WalletSelect';

const getValidationState = (val, name) => {
  if (name === 'externalFullname') {
    if (val === '') {
      return 'Le nom du bénéficiaire doit être exact pour que le virement soit pris en compte';
    }
  } else if (name === 'externalReference' || name === 'serviceSlug' || name === 'typeSlug') {
    if (val === '') {
      return 'Veuillez sélectionner un compte bénéficiaire';
    }
  } else if (name === 'walletId') {
    if (val === '') {
      return 'Veuillez sélectionner un compte à recharger';
    }
  } else if (name === 'amount') {
    if (val === '') {
      return 'Veuillez enter un montant';
    }
    if (isNaN(parseInt(val))) {
      return 'Veuillez enter un montant valide';
    }
  }

  return false;
};

const RapidTransfer = props => {
  const {
    makeTransactionLoading,
    makeTransactionError,
    makeTransaction,
    tfaActive,
    transactionNotify,
    history,
    isMobile = false,
    fetchAuthUserExpensePolicies,
    expensePolicies,
    getServicesByQuery,
    cancelTransactions,
    cancelTransactionsLoading,
    cancelTransactionsError,
    companyId,
    userCompanies,

  } = props;

  const Component = isMobile ? MultiStepFormMobile : MultiStepForm;

  const [passwordModal, togglePasswordModal] = useDialog();
  const [showSuccess, setShowSuccess] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState(null);

  const [currentCompany, setCurrentCompany] = useState();
  const canSeeOnlyOne = useSubscriptionRestrictions(currentCompany, "ACCESS_ONLY_1_WALLET");

  useEffect(() => {
    if (Array.isArray(userCompanies) && userCompanies.length > 0) {
        setCurrentCompany(userCompanies.find(company => company.id === companyId) || '')
      } else {
        getUserCompanies();
      }
  }, [companyId, userCompanies])

  const uniqueId = useMemo(() => uuidv4(), []);

  const [values, handleChange, errors, setErrors] = useForm({
    taskUuid: uuidv4(),
    externalReference: '',
    externalFullname: '',
    amount: '',
    comment: '',
    serviceSlug: '',
    typeSlug: '',
    walletId: ''
  });

  useEffect(() => {
    fetchAuthUserExpensePolicies();
  }, []);

  useEffect(() => {
    if (values.walletId) {
      getServicesByQuery(values.walletId, {way: 'out'}, [['history']]);
    }
  }, [values.walletId]);

  useDidUpdate(() => {
    if (makeTransactionLoading === false && makeTransactionError === '') {
      setShowSuccess(true);
    }
  }, [makeTransactionLoading]);

  const onSubmit = (currentStep, next) => {
    if (currentStep === 0) {
      let mErrors = {};
      if (getValidationState(values.walletId, 'walletId')) {
        mErrors.walletId = true;
      }

      if (Object.keys(mErrors).length !== 0) {
        setErrors(mErrors);
      } else {
        next();
      }
    } else if (currentStep === 1) {
      let mErrors = {};
      if (getValidationState(values.externalReference, 'externalReference')) {
        mErrors.externalReference = true;
      }

      // Receiver name is mandatory for wire service (bank-transfer)
      const externalFullnameError = values.typeSlug === 'bank-transfer' && getValidationState(values.externalFullname, 'externalFullname');
      if (externalFullnameError) {
        mErrors.externalFullname = externalFullnameError;
      }

      if (getValidationState(values.serviceSlug, 'serviceSlug')) {
        mErrors.serviceSlug = true;
      }
      if (getValidationState(values.typeSlug, 'typeSlug')) {
        mErrors.typeSlug = true;
      }
      if (Object.keys(mErrors).length !== 0) {
        setErrors(mErrors);
      } else {
        next();
      }
    } else if (currentStep === 2) {
      let mErrors = {};
      if (getValidationState(values.walletId, 'walletId')) {
        mErrors.walletId = true;
      }
      if (getValidationState(values.amount, 'amount')) {
        mErrors.amount = true;
      }
      if (Object.keys(mErrors).length !== 0) {
        setErrors(mErrors);
      } else {
        togglePasswordModal();
      }
    }
  };

  const onSubmitPassword = (password, otp) => {
    let args = {
      taskUuid: values.taskUuid,
      walletId: values.walletId,
      way: 'out',
      typeSlug: values.typeSlug,
      title: 'Transfert Rapide',
      code: password,
      signatureKey: uniqueId,
      items: [
        {
          amount: values.amount,
          externalReference: values.externalReference,
          externalFullname: values.externalFullname,
          typeSlug: values.typeSlug,
          serviceSlug: values.service?.serviceSlug
        }
      ]
    };

    if (comment !== '') {
      args.items[0].comment = values.comment;
    }

    if (tfaActive) {
      args.otp = otp;
    }

    makeTransaction(args);
  };

  return (
    <>
      <PageWrapper className={cx({'d-flex flex-grow-1 bg-white': isMobile})} title="Transférer de l’argent">
        <Component
          formData={{
            values,
            handleChange,
            errors,
            setErrors,
            getValidationState,
            isMobile
          }}
          onSubmit={onSubmit}
          cta="Valider"
          showCancel="Retour"
          // ctaLoading={summaryLoading}
          selectedWallet={selectedWallet}
          setSelectedWallet={setSelectedWallet}
          {...props}>
          <Component.Step stepCard={true} title="Choisissez un compte à débiter">
            <WalletSelect />
          </Component.Step>
          <Component.Step stepCard={true} title="Choisissez un compte bénéficiaire">
            <BeneficiariesSelect canSeeOnlyOne={canSeeOnlyOne} />
          </Component.Step>

          <Component.Step stepCard={true} title="Renseignez les détails de votre transfert">
            <TransferDetail />
          </Component.Step>
        </Component>
      </PageWrapper>
      <PasswordModal
        showSuccess={showSuccess}
        open={passwordModal}
        toogleModal={togglePasswordModal}
        title="Transférer de l’argent"
        icon="jla-icon-bolt"
        color="primary"
        submitText="Confirmer"
        tfaActive={tfaActive}
        onSubmit={onSubmitPassword}
        loading={makeTransactionLoading}
        errorMsg={
          constants.transactionsErrorReasonMap[makeTransactionError]
            ? constants.transactionsErrorReasonMap[makeTransactionError]
            : makeTransactionError
        }
        message="Il ne vous reste plus qu’à entrer votre mot de passe pour valider l’opération en toute sécurité."
        messageIcon="jla-icon-lock"
        successComponent={
          <TransactionSuccess
            cancelTransactionsLoading={cancelTransactionsLoading}
            cancelTransactionsError={cancelTransactionsError}
            cancelTransactions={cancelTransactions}
            total={values.amount}
            newBalance={selectedWallet?.balance - parseInt(values.amount)}
            values={values}
            isMobile={isMobile}
            history={history}
            transactionNotify={transactionNotify}
            showSuccess={showSuccess}
          />
        }
      />
    </>
  );
};

export default RapidTransfer;
