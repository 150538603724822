import React from 'react';
import cx from 'classnames';
import Footer from './Footer';
import Content from './Content';

import {Button} from '@julaya/common/ui/atoms';

import './Modal.scss';

const Modal = ({className, toogleModal, open, children, title, icon, color, showSuccess}) => {
  return (
    <div
      className={cx(
        'jla-modal-ui',
        {
          open: open
        },
        className
      )}>
      <a
        href="#julaya"
        className="jla-modal-ui__overlay"
        onClick={e => {
          e.preventDefault();
          toogleModal();
        }}>
        {''}
      </a>
      <div className="jla-modal-ui__dialog">
        <div className="jla-modal-ui__header">
          <div className="font-weight-bold">
            <Button variant="badge" className="mr-2" status={color} icon={icon} size="medium" />
            {title}
          </div>
          <div>
            {!showSuccess && (
              <Button action={toogleModal} variant="badge" status="neutral" className="icon ml-2" icon="jla-icon-x-mark" size="medium" />
            )}
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

Modal.Content = Content;
Modal.Footer = Footer;

export default Modal;
