import {
  GET_PREPAID_CARD_DETAILS_REQUEST,
  GET_PREPAID_CARD_DETAILS_SUCCESS,
  ADMIN_GET_PREPAID_CARD_DETAILS_REQUEST,
  ADMIN_GET_PREPAID_CARD_DETAILS_SUCCESS,
  GET_PREPAID_CARD_USERS_LIST_REQUEST,
  GET_PREPAID_CARD_USERS_LIST_SUCCESS,
  CHECK_PREPAID_CARD_HOLDER_REQUEST,
  CHECK_PREPAID_CARD_HOLDER_SUCCESS,
  STOP_PREPAID_CARD_SUCCESS
} from '../actionTypes';

const actions = [
  GET_PREPAID_CARD_DETAILS_REQUEST,
  GET_PREPAID_CARD_DETAILS_SUCCESS,
  ADMIN_GET_PREPAID_CARD_DETAILS_REQUEST,
  ADMIN_GET_PREPAID_CARD_DETAILS_SUCCESS,
  GET_PREPAID_CARD_USERS_LIST_REQUEST,
  GET_PREPAID_CARD_USERS_LIST_SUCCESS,
  CHECK_PREPAID_CARD_HOLDER_REQUEST,
  CHECK_PREPAID_CARD_HOLDER_SUCCESS,
  STOP_PREPAID_CARD_SUCCESS
];

const initialState = {
  prepaidCards: [],
  prepaidCardsCount: 0,
  prepaidCardsPage: 1,
  prepaidCardDetails: null,
  adminPrepaidCardDetails: null,
  prepaidCardUserList: [],
  prepaidCardCheckHolder: null
};

export default [initialState, actions];
