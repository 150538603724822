import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "@julaya/common/store/actions";
import selector from "@julaya/common/store/selectors";

import Component from "./components";

const mapStateToProps = ({ auth, history, loading, error, wallets }) => {
  return {
    ...selector(
      {
        history: "GET_HISTORY",

        walletDetails: "GET_WALLET_DETAILS",
      },
      { loading, error }
    ),

    historyData: history.history,
    historyCount: history.historyCount,
    selectedItem: history.selectedItem,
    wallet: wallets.walletDetails,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
