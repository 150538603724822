import {network, functions, storage} from '@julaya/common/utils';
import {
  TASK_MANAGER,
  //
  TASK_CREATE_REQUEST,
  TASK_CREATE_SUCCESS,
  TASK_CREATE_FAILURE,

  //
  TASK_UPDATE_REQUEST,
  TASK_UPDATE_SUCCESS,
  TASK_UPDATE_FAILURE,

  //
  TASK_DELETE_REQUEST,
  TASK_DELETE_SUCCESS,
  TASK_DELETE_FAILURE,

  //
  TASK_SIGN_REQUEST,
  TASK_SIGN_SUCCESS,
  TASK_SIGN_FAILURE,

  //
  TASK_RETRY_REQUEST,
  TASK_RETRY_SUCCESS,
  TASK_RETRY_FAILURE,

  //
  TASK_DETAILS_REQUEST,
  TASK_DETAILS_SUCCESS,
  TASK_DETAILS_FAILURE,
  //
  API_CHECK_REQUEST,
  API_CHECK_SUCCESS,
  API_CHECK_FAILURE,
  TOGGLE_IS_MENU_OPEN,

  //
  REFUND_TASK_REQUEST,
  REFUND_TASK_SUCCESS,
  REFUND_TASK_FAILURE,

  //
  CANCEL_TASK_REQUEST,
  CANCEL_TASK_SUCCESS,
  CANCEL_TASK_FAILURE
} from '../actionTypes';
import CryptoJS from 'crypto-js';

// MANAGER

export const resetTaskManager = () => dispatch => {
  dispatch({
    type: TASK_MANAGER,
    payload: {
      taskId: null,
      includeReceiverFees: false,
      walletId: null,
      wallet: null,
      title: '',
      description: '',
      initiatorId: null,
      status: null,
      smsTemplate: null,
      way: null,
      type: null,
      updatedAt: null,
      createdAt: null,
      taskUuid: null,
      metadata: null,
      taskItems: [],
      signatures: [],
      activities: [],
      retriedTransactions: [],
      taskEditable: false,

      //

      addItemsQueue: [],
      addEditItemsProgess: null,
      editItemsQueue: [],
      managerError: null,
      files: [],
      currentTaskItem: null,
      currentTaskItemIndex: null,
      currentTaskItemError: null,
      notEnoughBalance: null
    }
  });
};

export const initTaskManager =
  (taskId, taskEditable = false) =>
  async dispatch => {
    dispatch(resetTaskManager());
    await dispatch(getTaskDetails(taskId, true));
    const taskItems = await network.request(`/tasks/${taskId}/items`, 'GET');
    dispatch({
      type: TASK_MANAGER,
      payload: {
        taskItems: taskItems.data,
        invalidTaskItemCount: taskItems?.data?.filter(x => x.errorCode).length || 0,
        taskEditable
      }
    });
    if (taskItems.count) {
      dispatch({
        type: TASK_MANAGER,
        payload: {
          taskItems: taskItems.data,
          invalidTaskItemCount: taskItems?.data?.filter(x => x.errorCode).length || 0
        }
      });
    }
  };

// TASK
// create a task
export const createTask = task => async (dispatch, getState) => {
  dispatch({
    type: TASK_CREATE_REQUEST
  });

  try {
    const {
      transaction: {isMenuOpen}
    } = getState();

    const response = await network.request(`/tasks/create`, 'POST', task);

    dispatch({
      type: TASK_CREATE_SUCCESS,
      payload: {
        taskId: response.id
      }
    });

    dispatch(initTaskManager(response.id, true));

    if (isMenuOpen === 'task-modal') {
      dispatch({
        type: TOGGLE_IS_MENU_OPEN,
        payload: {isMenuOpen: false}
      });
    }
  } catch (error) {
    dispatch({type: TASK_CREATE_FAILURE, error: error.message});
  }
};

//  delete a task
export const deleteTask = taskId => async dispatch => {
  dispatch({
    type: TASK_DELETE_REQUEST
  });

  try {
    await network.request(`/tasks/${taskId}`, 'DELETE');

    dispatch({
      type: TASK_DELETE_SUCCESS
    });
  } catch (error) {
    dispatch({type: TASK_DELETE_FAILURE, error: error.message});
  }
};

//  retry a task
export const retryTask = (taskId, params) => async dispatch => {
  dispatch({
    type: TASK_RETRY_REQUEST
  });

  try {
    const res = await network.request(`/tasks/${taskId}/retry`, 'POST', params);

    dispatch({
      type: TASK_RETRY_SUCCESS,
      payload: {
        retriedTransactions: res
      }
    });
  } catch (error) {
    dispatch({type: TASK_RETRY_FAILURE, error: error.message});
  }
};

//  sign a task
export const signTask = (taskId, code, otp, uuid) => async dispatch => {
  dispatch({
    type: TASK_SIGN_REQUEST
  });

  try {
    const params = {
      otp,
      password: await functions.encryptCode(code),
      signatureKey: uuid
    };

    const response = await network.request(`/tasks/${taskId}/sign`, 'POST', params);

    dispatch({
      type: TASK_SIGN_SUCCESS,
      payload: {
        taskSignedResult: response
      }
    });
  } catch (error) {
    dispatch({type: TASK_SIGN_FAILURE, error: error.message});
  }
};

// update a task
export const updateTask = (taskId, params) => async (dispatch, getState) => {
  dispatch({
    type: TASK_UPDATE_REQUEST
  });

  try {
    const {
      transaction: {isMenuOpen}
    } = getState();

    const response = await network.request(`/tasks/${taskId}`, 'PUT', params);

    const {TaskSignatures, TaskActivities, Wallet, ...task} = response;

    dispatch({
      type: TASK_UPDATE_SUCCESS,
      payload: {
        taskId: task.id,
        wallet: Wallet,
        signatures: TaskSignatures,
        activities: TaskActivities,
        ...task
      }
    });
    if (isMenuOpen === 'task-modal') {
      dispatch({
        type: TOGGLE_IS_MENU_OPEN,
        payload: {isMenuOpen: false}
      });
    }
  } catch (error) {
    dispatch({type: TASK_UPDATE_FAILURE, error: error.message});
  }
};

// get a task
export const getTaskDetails =
  (taskId, editMode = false) =>
  async dispatch => {
    dispatch({
      type: TASK_DETAILS_REQUEST,
      payload: {notEnoughBalance: null}
    });

    try {
      const response = await network.request(`/tasks/${taskId}`, 'GET');
      const {TaskSignatures, TaskActivities, Wallet, ...task} = response;

      let notEnoughBalance = null;

      if (task.errorCode === 'BATCH_CHECKS_NOT_ENOUGHT_MONEY') {
        notEnoughBalance = response.sum - Wallet.realBalance;
      }

      if (editMode && !['draft', 'to-validate'].includes(task.status)) {
        throw new Error('Cette tâche ne peut pas être modifiée');
      }

      dispatch({
        type: TASK_DETAILS_SUCCESS,
        payload: {
          taskId: task.id,
          wallet: Wallet,
          signatures: TaskSignatures,
          activities: TaskActivities,
          notEnoughBalance,
          ...task
        }
      });
    } catch (error) {
      dispatch({type: TASK_DETAILS_FAILURE, error: error.message});
    }
  };

// TASK ITEMS

// add item
export const addTaskItem = () => async dispatch => {
  dispatch({
    type: TASK_MANAGER,
    payload: {
      taskEditable: false,
      currentTaskItemIndex: 'NEW',
      currentTaskItem: {
        externalReference: '',
        externalFullname: '',
        amount: '',
        fees: '',
        comment: '',
        service: '',
        isBeneficiary: false,
        beneficiary: null,
        serviceSlug: null
      },
      currentTaskItemError: null
    }
  });
};

// edit item
export const editTaskItem = index => async (dispatch, getState) => {
  try {
    const {
      taskManager: {taskItems}
    } = getState();

    dispatch({
      type: TASK_MANAGER,
      payload: {
        currentTaskItem: taskItems[index],
        currentTaskItemIndex: index,
        currentTaskItemError: null
      }
    });
  } catch (error) {
    dispatch({
      type: TASK_MANAGER
    });
  }
};

// delete item
export const deleteTaskItem = index => async (dispatch, getState) => {
  dispatch({
    type: API_CHECK_REQUEST
  });
  const {
    taskManager: {taskId, taskItems, invalidTaskItemCount}
  } = getState();

  try {
    let items = [...taskItems];
    let invalidCount = invalidTaskItemCount;

    const item = items[index];

    if (invalidCount > 0 && items[index].errorCode) {
      invalidCount -= 1;
    }

    await network.request(`/tasks/${taskId}/items`, 'DELETE', {ids: [items[index].id]});

    items.splice(index, 1);

    dispatch({
      type: TASK_MANAGER,
      payload: {
        taskItems: items,
        invalidTaskItemCount: items?.filter(x => x.errorCode).length || 0
      }
    });

    if (item.errorCode === 'IDENTICAL_DEPOSIT') {
      const correspondingItems = items.filter(
        x => x.externalReference === item.externalReference && parseInt(x.amount) === parseInt(item.amount) && x.errorCode === 'IDENTICAL_DEPOSIT'
      );

      if (correspondingItems.length > 0) {
        dispatch(addTaskItems(correspondingItems));
      }
    }

    await dispatch({
      type: API_CHECK_SUCCESS
    });

    dispatch(getTaskDetails(taskId));
  } catch (error) {
    dispatch({
      type: API_CHECK_FAILURE,
      error: error.message
    });
  }
};

// process items

// saveTaskItem,
export const saveTaskItem = (item, index) => async (dispatch, getState) => {
  if (index === 'NEW') {
    const {
      taskManager: {taskItems}
    } = getState();

    const items = [...taskItems];
    items.push({...item, insertAmount: true});
    dispatch({
      type: TASK_MANAGER,
      payload: {
        taskItems: items,
        currentTaskItemIndex: null,
        currentTaskItem: null,
        currentTaskItemError: null
      }
    });
  } else {
    dispatch({
      type: TASK_MANAGER,
      payload: {
        currentTaskItemIndex: null,
        currentTaskItem: null,
        currentTaskItemError: null
      }
    });
    delete item.insertAmount;
    const itemsToAdd = [item];
    if (item.errorCode === 'IDENTICAL_DEPOSIT') {
      const {
        taskManager: {taskItems}
      } = getState();
      const correspondingItems = taskItems.filter(
        x =>
          x.externalReference === taskItems[index].externalReference &&
          x.id !== taskItems[index].id &&
          parseInt(x.amount) === parseInt(taskItems[index].amount) &&
          x.errorCode === 'IDENTICAL_DEPOSIT'
      );

      if (correspondingItems.length > 0) {
        itemsToAdd.push(...correspondingItems);
      }
    }
    dispatch(addTaskItems(itemsToAdd));
  }
};

// cancelEdit,
export const cancelTaskItemEdit = () => async dispatch => {
  dispatch({
    type: TASK_MANAGER,
    payload: {
      currentTaskItem: null,
      currentTaskItemIndex: null,
      currentTaskItemError: null
    }
  });
};

export const addTaskItems = newTaskItems => async (dispatch, getState) => {
  const {
    taskManager: {taskItems, addItemsQueue, editItemsQueue, addEditItemsProgess}
  } = getState();

  const typeSlugs = {
    'transfert-mobile-money': 'disposal',
    'transfert-julaya': 'transfer',
    'achat-credit-telephonique': 'airtime',
    'virement-bancaire': 'bank-transfer',
    'transfert-wizall': 'wizall-transfer',
    'transfert-wave': 'wave-transfer',
    'rechargement-cb': 'cb-transfer'
  };

  const items = [...taskItems];

  const {itemsToAdd, itemsToEdit, formatedItems} = newTaskItems.reduce(
    (acc, item, j) => {
      let i = items.findIndex(i => i.id === item.id);

      if (i === -1) {
        i = taskItems.length + j;
      }

      const checkItem = formatCheckItem(item, i);

      if (checkItem) {
        let typeSlug = 'disposal';

        if (typeSlugs[item.typeSlug]) {
          typeSlug = typeSlugs[item.typeSlug];
        } else if (Object.values(typeSlugs).includes(item.typeSlug)) {
          typeSlug = item.typeSlug;
        }

        if (typeof item.id == 'number') {
          acc.itemsToEdit.push({...checkItem, typeSlug});
        } else {
          acc.itemsToAdd.push({...checkItem, typeSlug});
        }

        acc.formatedItems[i] = {...item, typeSlug, loading: true, errorCode: undefined};
      }

      return acc;
    },
    {
      itemsToAdd: [],
      itemsToEdit: [],
      formatedItems: [...items]
    }
  );

  let progress = {
    current: 0,
    total: 0
  };

  if (addEditItemsProgess) {
    progress = {
      current: addEditItemsProgess.current,
      total: addEditItemsProgess.total + itemsToAdd.length + itemsToEdit.length
    };
  } else {
    progress = {
      current: 0,
      total: itemsToAdd.length + itemsToEdit.length
    };
  }

  dispatch({
    type: TASK_MANAGER,
    payload: {
      taskEditable: false,
      taskItems: formatedItems,
      addItemsQueue: [...addItemsQueue, ...itemsToAdd],
      editItemsQueue: [...editItemsQueue, ...itemsToEdit],
      addEditItemsProgess: progress
    }
  });

  if (progress.total !== progress.current) {
    dispatch(itemsApiCall());
  }
};

const itemsApiCall = () => async (dispatch, getState) => {
  dispatch({
    type: API_CHECK_REQUEST,
    payload: {
      currentCheckItems: [],
      currentCheckItemIndexes: []
    }
  });
  try {
    const {
      taskManager: {taskId, taskItems, addItemsQueue, editItemsQueue, addEditItemsProgess}
    } = getState();

    let count = 0;
    let items = [...taskItems];

    if (addItemsQueue.length > 0) {
      const {itemsToAdd, indexes} = addItemsQueue.splice(0, 10).reduce(
        (acc, item) => {
          acc.itemsToAdd.push(item.data);
          acc.indexes.push(item.index);
          return acc;
        },
        {itemsToAdd: [], indexes: []}
      );

      dispatch({
        type: TASK_MANAGER,
        payload: {
          currentCheckItems: [...itemsToAdd],
          currentCheckItemIndexes: [...indexes]
        }
      });

      const response = await network.request(`/tasks/${taskId}/items`, 'POST', itemsToAdd);

      count = response.count;

      indexes.forEach((index, i) => {
        items[index] = {...items[index], ...response.data[i], loading: false};
      });
    } else if (editItemsQueue.length > 0) {
      const {itemsToEdit, indexes} = editItemsQueue.splice(0, 10).reduce(
        (acc, item) => {
          acc.itemsToEdit.push(item.data);
          acc.indexes.push(item.index);
          return acc;
        },
        {itemsToEdit: [], indexes: []}
      );

      dispatch({
        type: TASK_MANAGER,
        payload: {
          currentCheckItems: [...itemsToEdit],
          currentCheckItemIndexes: [...indexes]
        }
      });

      const response = await network.request(`/tasks/${taskId}/items`, 'PUT', itemsToEdit);

      count = response.count;
      indexes.forEach((index, i) => {
        items[index] = {...items[index], ...response.data[i], loading: false};
      });
    }

    dispatch({
      type: TASK_MANAGER,
      payload: {
        addEditItemsProgess: {current: addEditItemsProgess.current + count, total: addEditItemsProgess.total},
        taskItems: items,
        addItemsQueue: [...addItemsQueue],
        editItemsQueue: [...editItemsQueue]
      }
    });

    if (addItemsQueue.length === 0 && editItemsQueue.length === 0) {
      dispatch(getTaskDetails(taskId, true));
      const {
        taskManager: {taskItems}
      } = getState();

      dispatch({
        type: TASK_MANAGER,
        payload: {addEditItemsProgess: null, invalidTaskItemCount: taskItems.filter(x => x.errorCode).length}
      });

      await dispatch({
        type: API_CHECK_SUCCESS,
        payload: {
          currentCheckItems: [],
          currentCheckItemIndexes: []
        }
      });
    } else {
      await dispatch({
        type: API_CHECK_SUCCESS
      });
      dispatch(itemsApiCall());
    }
  } catch (error) {
    const {
      taskManager: {currentCheckItems, currentCheckItemIndexes, addEditItemsProgess, taskItems: items}
    } = getState();

    currentCheckItemIndexes.forEach((index, i) => {
      items[index] = {...items[index], ...currentCheckItems[i], loading: false, errorCode: 'SOME_ERROR_OCCURED'};
    });

    dispatch({
      type: API_CHECK_FAILURE,
      error: error.message,
      payload: {
        addEditItemsProgess: {
          current: addEditItemsProgess.current + currentCheckItems.length,
          total: addEditItemsProgess.total
        },
        invalidTaskItemCount: items.filter(x => x.errorCode).length,
        taskItems: items
      }
    });

    dispatch(itemsApiCall());
  }
};

// UTILS

const formatCheckItem = (
  {id = undefined, amount, externalReference, externalFullname = '', typeSlug = '', serviceSlug = '', comment = ''},
  index
) => {
  if (!typeSlug) {
    typeSlug = 'disposal';
  }

  const typeSlugs = {
    'transfert-mobile-money': 'disposal',
    'transfert-julaya': 'transfer',
    'achat-credit-telephonique': 'airtime',
    'virement-bancaire': 'bank-transfer',
    'transfert-wizall': 'wizall-transfer',
    'transfert-wave': 'wave-transfer',
    'rechargement-cb': 'cb-transfer'
  };

  return {
    index,
    data: {
      id,
      amount,
      externalReference,
      externalFullname,
      typeSlug: typeSlugs[typeSlug] ? typeSlugs[typeSlug] : typeSlug,
      serviceSlug,
      comment
    }
  };
};

export const toggleOmniCanalHelpOverlay =
  (state = false) =>
  async dispatch => {
    dispatch({
      type: TASK_MANAGER,
      payload: {
        omniCanalHelpOverlayOpen: state
      }
    });
  };

export const refundTask = (taskId, params) => async dispatch => {
  dispatch({
    type: REFUND_TASK_REQUEST
  });

  const accessToken = await storage.get('accessToken');
  params.password = CryptoJS.AES.encrypt(`${params.password}`, `${accessToken.id}`).toString();

  try {
    const url = `/tasks/${taskId}/refund`;
    await network.request(url, 'POST', params);

    dispatch({
      type: REFUND_TASK_SUCCESS
    });
  } catch (error) {
    dispatch({type: REFUND_TASK_FAILURE, error: error.message});
  }
};

export const cancelTask = (taskId, params) => async dispatch => {
  dispatch({
    type: CANCEL_TASK_REQUEST
  });

  try {
    const url = `/tasks/${taskId}/cancel`;
    await network.request(url, 'POST', params);

    dispatch({
      type: CANCEL_TASK_SUCCESS
    });
  } catch (error) {
    dispatch({type: CANCEL_TASK_FAILURE, error: error.message});
  }
};
