const text = {
  redDark: 'text-danger',
  green: 'text-success',
  yellow: 'text-warning',
  greyLight: 'text-muted'
};
const bg = {
  greyLight: 'danger',
  green: 'success',
  yellow: 'warning',
  grey: 'grey'
};
const status = {
  draft: {
    bg: 'grey',
    icon: 'icon-pencil',
    prefixIcon: 'pencil-filled',
    text: 'Brouillon'
  },
  'to-validate': {
    bg: 'grey',
    icon: 'icon-thumb-up',
    text: 'En attente de signature'
  },
  pending: {
    bg: 'warning',
    icon: 'icon-clock',
    text: 'En cours',
    prefixIcon: 'clock'
  },
  retry: {
    bg: 'danger',
    icon: 'icon-arrow-path',
    prefixIcon: 'retry',
    text: 'A rejouer'
  },
  waiting: {
    bg: 'warning',
    icon: 'icon-clock',
    prefixIcon: 'clock',
    text: 'En cours de traitement'
  },
  success: {
    bg: 'success',
    icon: 'icon-check-circle',
    prefixIcon: 'check-circle',
    text: 'Validé'
  },
  done: {
    bg: 'success',
    icon: 'icon-check-circle',
    prefixIcon: 'check-circle',
    text: 'Terminé'
  },
  complete: {
    bg: 'success',
    icon: 'icon-check-circle',
    prefixIcon: 'check-circle',
    text: 'Terminé'
  },
  failed: {
    bg: 'error',
    icon: 'icon-x-circle',
    prefixIcon: 'x-circle',
    text: 'Echec'
  },
  archived: {
    bg: 'danger',
    icon: 'icon-x-circle',
    prefixIcon: 'x-circle',
    text: 'Archivé'
  },
  'refund-pending': {
    bg: 'warning',
    icon: 'icon-retry',
    prefixIcon: 'receipt-clock',
    text: 'Annul. en cours'
  },
  'refund-failed': {
    bg: 'danger',
    icon: 'icon-retry',
    prefixIcon: 'receipt-refused',
    text: 'Annul. en échec'
  },
  refunded: {
    bg: 'neutral',
    icon: 'icon-retry',
    prefixIcon: 'receipt-refund',
    text: 'Annulée'
  },
  'to-retry': {
    bg: 'warning',
    icon: 'icon-retry',
    prefixIcon: 'retry',
    text: 'Remboursé'
  },
  cancelled: {
    bg: 'error',
    icon: 'icon-x-circle',
    prefixIcon: 'x-circle',
    text: 'Annulé'
  }
};

export default {text, bg, status};
