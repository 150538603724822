import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "@julaya/common/store/actions";
import selector from "@julaya/common/store/selectors";

import Component from "./components";

const mapStateToProps = ({
  auth,
  taskManager,
  loading,
  error,
  transaction,
}) => {
  return {
    ...selector(
      {
        editTask: "TASK_EDIT",
        taskDetails: "TASK_DETAILS",
        getServices: [
          "GET_SERVICES_BY_EXTERNAL_REFERENCE",
          "GET_LATEST_RECEIVERS",
        ],
        deleteTask: "DELETE_TASK",
        apiCheck: "API_CHECK",
        signTask: "TASK_SIGN",
      },
      { loading, error }
    ),

    services: transaction.services,
    userBalance: auth.userBalance,
    user: auth.user,
    latestReceivers: transaction.latestReceivers,
    servicesTags: transaction.servicesTags,
    ...taskManager,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
