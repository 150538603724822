import * as actions from "@julaya/common/store/actions";
import selector from "@julaya/common/store/selectors";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Component from "./components";

const mapStateToProps = ({ auth, transaction, loading, error, wallets }) => {
  return {
    ...selector(
      {
        summary: "GET_SUMMARY",
        servicesByType: "GET_SERVICES_LIST_BY_TYPE",
        makeTransaction: "MAKE_TRANSACTION",
        attachDepositReceipt: "ATTACH_DEPOSIT_RECEIPT",
        bankDisposalWallets: "GET_BANK_DISPOSAL_WALLETS",
      },
      { loading, error }
    ),

    user: auth.user,
    summaryObj: transaction.summary,
    isMenuOpen: transaction.isMenuOpen,

    tfaActive: auth.tfaActive,

    currentCompanyId: auth.currentCompanyId,
    userCompanies: auth.companiesList,

    transactionNotify: transaction.transactionNotify,
    bankDisposalAmount: transaction.bankDisposalAmount,
    servicesByType: transaction.servicesByType,
    services: transaction.services,
    bankDisposalWallets: wallets.bank_disposalWallets?.wallets,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
