/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {useHistory} from 'react-router-dom';

import {Amount, TransactionIcon} from 'components/UI';

import {moment, functions} from '@julaya/common/utils';

const HistoryItem = props => {
  const {setSelectedItem, item} = props;
  const history = useHistory();

  return (
    <a
      key={item.uniqId}
      href="#julaya"
      onClick={e => {
        e.preventDefault();
        setSelectedItem({...item, walletId: props.walletId});
        history.push('/history/details');
      }}
      className="history-item w-100">
      <TransactionIcon item={item} iconDisplay={'default'} />

      <div className="d-flex flex-column flex-grow-1 text-truncate pl-2">
        <div className="title text-black font-weight-bold text-truncate">{getReceiver(item)[0]}</div>
        <div className="text-muted date text-truncate">{getDescription(item)}</div>
      </div>

      <Amount item={item} />
    </a>
  );
};

const getReceiver = ({externalReference, externalFullname, TransactionType}) => {
  let details = ['Transaction Julaya'];
  try {
    switch (TransactionType?.slug.toLowerCase()) {
      case 'cb-transfer':
        details = [`${functions.formatPhone(externalReference)}`, externalFullname];
        break;
      case 'transfer':
        details = [`${functions.formatPhone(externalReference)}`, externalFullname];
        break;
      case 'disposal':
        details = [`${functions.formatPhone(externalReference)}`, externalFullname];
        break;
      case 'airtime':
        details = [`${functions.formatPhone(externalReference)}`, externalFullname];
        break;
      case 'wizall-transfer':
        details = [`${functions.formatPhone(externalReference)}`, externalFullname];
        break;
      case 'wave-transfer':
        details = [`${functions.formatPhone(externalReference)}`, externalFullname];
        break;
      case 'momo-deposit':
        details = [`${functions.formatPhone(externalReference)}`, externalFullname];
        break;
      case 'merchant-payment':
        details = [`${functions.formatPhone(externalReference)}`, externalFullname];
        break;
      case 'bank-transfer':
        details = [externalFullname, externalReference];
        break;
      case 'deduction':
        details = [externalReference];
        break;
      case 'load':
        details = [externalReference];
        break;
      case 'authorisation':
        details = [externalReference];
        break;
      case 'bank-disposal':
        details = [`Julaya`];
        break;
      case 'international-transfer':
        details = [`${functions.formatPhone(externalReference)}`, externalFullname];
        break;
      default:
        break;
    }
  } catch (error) {}

  return details;
};

const getDescription = ({comment, createdAt, service}) => {
  // if (comment) {
  //   return comment;
  // } else if (service) {
  //   return service;
  // }
  return moment(createdAt).format('DD MMMM YYYY - HH:mm');
};

export default HistoryItem;
