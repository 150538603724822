import { functions, constants } from "@julaya/common/utils";
const TransactionCard = ({ item }) => {
  const img =
    constants.serviceSlugIcons[
      item?.Service?.slug || item?.serviceSlug?.toLowerCase()
    ];
  return (
    <div className="task-transfers__card">
      <div>
        <div className="block-title text-muted">Identifiant :</div>
        <div className="identifier__container">
          <img src={img} alt="Services" height="32px" />
          <div className="identifier__info">
            <div className="type font-weight-bold">
              {item.type || item.externalFullname}
            </div>
            <div className="reference text-muted ">
              {item.externalReference}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="block-title text-muted">Montant :</div>
        <div className="info_text mt-3">
          {functions.formatMoney(item.amount)}
        </div>
      </div>
      <div>
        <div className="block-title text-muted">Commentaire :</div>
        <div className="info_text">{item.comment || "-"}</div>
      </div>
      <div>
        <div className="block-title text-muted">Frais :</div>
        <div className="info_text">
          {(item.fees && item.fees === 0
            ? "-"
            : functions.formatMoney(item.fees)) || "-"}
        </div>
      </div>
    </div>
  );
};
export default TransactionCard;
