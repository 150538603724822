import {network, functions} from '@julaya/common/utils';

import {
  CREATE_PREPAID_CARD_REQUEST,
  CREATE_PREPAID_CARD_SUCCESS,
  CREATE_PREPAID_CARD_FAILURE,
  GET_PREPAID_CARD_DETAILS_REQUEST,
  GET_PREPAID_CARD_DETAILS_SUCCESS,
  GET_PREPAID_CARD_DETAILS_FAILURE,
  ADMIN_GET_PREPAID_CARD_DETAILS_REQUEST,
  ADMIN_GET_PREPAID_CARD_DETAILS_SUCCESS,
  ADMIN_GET_PREPAID_CARD_DETAILS_FAILURE,
  STOP_PREPAID_CARD_REQUEST,
  STOP_PREPAID_CARD_SUCCESS,
  STOP_PREPAID_CARD_FAILURE,
  GET_PREPAID_CARD_USERS_LIST_REQUEST,
  GET_PREPAID_CARD_USERS_LIST_SUCCESS,
  GET_PREPAID_CARD_USERS_LIST_FAILURE,
  CHECK_PREPAID_CARD_HOLDER_REQUEST, //
  CHECK_PREPAID_CARD_HOLDER_SUCCESS,
  CHECK_PREPAID_CARD_HOLDER_FAILURE, //
  UPDATE_PREPAID_CARD_PIN_REQUEST,
  UPDATE_PREPAID_CARD_PIN_SUCCESS,
  UPDATE_PREPAID_CARD_PIN_FAILURE
} from '../actionTypes';

export const getPrepaidCardDetails = (cardId, code, otp) => async (dispatch, getState) => {
  dispatch({
    type: GET_PREPAID_CARD_DETAILS_REQUEST,
    payload: {prepaidCardDetails: null}
  });

  try {
    const {
      auth: {tfaActive}
    } = getState();
    const args = {
      password: await functions.encryptCode(code)
    };

    if (tfaActive) {
      args.otp = otp;
    }

    const response = await network.request(`/wallets/${cardId}/card/pan`, 'POST', args);

    dispatch({
      type: GET_PREPAID_CARD_DETAILS_SUCCESS,
      payload: {prepaidCardDetails: response}
    });
  } catch (error) {
    dispatch({type: GET_PREPAID_CARD_DETAILS_FAILURE, error: error.message});
  }
};

export const adminGetPrepaidCardDetails = (cardId, code, otp) => async (dispatch, getState) => {
  dispatch({
    type: ADMIN_GET_PREPAID_CARD_DETAILS_REQUEST,
    payload: {adminPrepaidCardDetails: null}
  });

  try {
    const {
      auth: {tfaActive}
    } = getState();
    const args = {
      password: await functions.encryptCode(code)
    };

    if (tfaActive) {
      args.otp = otp;
    }

    const response = await network.request(`/admin/wallets/${cardId}/card/pan`, 'POST', args);

    dispatch({
      type: ADMIN_GET_PREPAID_CARD_DETAILS_SUCCESS,
      payload: {adminPrepaidCardDetails: response}
    });
  } catch (error) {
    dispatch({type: ADMIN_GET_PREPAID_CARD_DETAILS_FAILURE, error: error.message});
  }
};

export const getCreatePrepaidCardUserList = () => async dispatch => {
  dispatch({
    type: GET_PREPAID_CARD_USERS_LIST_REQUEST,
    payload: {prepaidCardUserList: []}
  });

  try {
    const {users} = await network.request(`/admin/users/list`, 'POST', {
      role: ['USER', 'ADMIN', 'READ'],
      page: -1
    });

    dispatch({
      type: GET_PREPAID_CARD_USERS_LIST_SUCCESS,
      payload: {prepaidCardUserList: users.map(user => ({...user, name: `${user.firstname} ${user.lastname}`}))}
    });
  } catch (error) {
    dispatch({type: GET_PREPAID_CARD_USERS_LIST_FAILURE, error: error.message});
  }
};

export const createPrepaidCard = (params, code, otp) => async (dispatch, getState) => {
  dispatch({
    type: CREATE_PREPAID_CARD_REQUEST
  });

  try {
    const {
      auth: {tfaActive}
    } = getState();

    const args = {
      ...params,
      password: await functions.encryptCode(code)
    };

    if (tfaActive) {
      args.otp = otp;
    }
    await network.request(`/admin/wallets`, 'POST', args);

    dispatch({
      type: CREATE_PREPAID_CARD_SUCCESS
    });
  } catch (error) {
    dispatch({type: CREATE_PREPAID_CARD_FAILURE, error: error.message});
  }
};

export const checkPrepaidCardHolder = userData => async dispatch => {
  dispatch({
    type: CHECK_PREPAID_CARD_HOLDER_REQUEST,
    payload: {prepaidCardCheckHolder: null}
  });

  try {
    const user = await network.request(`/admin/users/${userData.id}`, 'GET');

    dispatch({
      type: CHECK_PREPAID_CARD_HOLDER_SUCCESS,
      payload: {
        prepaidCardCheckHolder: {
          accessType: user.role,
          countryCode: user.countryCode,
          hasPhysicalCard: user.Wallets.some(wallet => wallet.type === 'physical'),
          id: user.id,
          name: user.firstname + ' ' + user.lastname,
          phone: user.phone,
          status: user.status,
          kycStatus: user.kycStatus
        }
      }
    });
  } catch (error) {
    dispatch({type: CHECK_PREPAID_CARD_HOLDER_FAILURE, error: error.message});
  }
};

export const stopPrepaidCard = (cardId, code, otp) => async (dispatch, getState) => {
  dispatch({
    type: STOP_PREPAID_CARD_REQUEST
  });

  try {
    const {
      auth: {tfaActive}
    } = getState();

    const args = {
      password: await functions.encryptCode(code)
    };

    if (tfaActive) {
      args.otp = otp;
    }

    const card = await network.request(`/wallets/${cardId}/card/activate`, 'POST', args);

    dispatch({
      type: STOP_PREPAID_CARD_SUCCESS,
      payload: {prepaidCardDetails: card}
    });
  } catch (error) {
    dispatch({type: STOP_PREPAID_CARD_FAILURE, error: error.message});
  }
};

export const hidePrepaidCardDetails = () => dispatch => {
  dispatch({
    type: GET_PREPAID_CARD_DETAILS_SUCCESS,
    payload: {prepaidCardDetails: null}
  });
};

export const clearCheckPrepaidCardHolder = () => async dispatch => {
  dispatch({
    type: CHECK_PREPAID_CARD_HOLDER_SUCCESS,
    payload: {prepaidCardCheckHolder: null}
  });
};

export const updatePrepaidCardPin = (cardId, pin, code, otp) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_PREPAID_CARD_PIN_REQUEST
  });

  try {
    const {
      auth: {tfaActive}
    } = getState();
    const args = {
      cardId,
      pin,
      code: await functions.encryptCode(code)
    };

    if (tfaActive) {
      args.otp = otp;
    }

    await network.request(`/prepaid-cards/${cardId}/pin`, 'POST', args);

    dispatch({
      type: UPDATE_PREPAID_CARD_PIN_SUCCESS
    });
  } catch (error) {
    dispatch({type: UPDATE_PREPAID_CARD_PIN_FAILURE, error: error.message});
  }
};
