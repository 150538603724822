import React from 'react';
import './SearchForm.scss';

const SearchForm = ({value, onChange, name, placeholder = 'Rechercher...', variant = 'grey', className = ''}) => {
  return (
    <div className={`search-form ${variant} ${className}`}>
      <div className="search-form__body">
        <i className="jla-icon-magnifying-glass search-form__icon"></i>
        <input
          className="search-form__input"
          placeholder={placeholder}
          onChange={e => onChange(e.target.value)}
          value={value}
          name={name || 'query'}
          type="text"
          id={name || 'query'}
          autoComplete="off"
        />
      </div>
    </div>
  );
};

export default SearchForm;

//USAGE
{
  /* <SearchForm value={query} onChange={setQuery} name='bank-disposal-select-search' variant='grey' /> */
}
