import {network} from '@julaya/common/utils';
import {ADMIN_DASHBOARD_INFO_REQUEST, ADMIN_DASHBOARD_INFO_SUCCESS, ADMIN_DASHBOARD_INFO_FAILURE} from '../actionTypes';

export const getAdminDashboardInfo = () => async dispatch => {
  dispatch({type: ADMIN_DASHBOARD_INFO_REQUEST});
  try {
    const response = await network.request(`/admin/dashboard`, 'GET');
    dispatch({type: ADMIN_DASHBOARD_INFO_SUCCESS, payload: {adminDashboardInfo: response}});
  } catch (error) {
    dispatch({type: ADMIN_DASHBOARD_INFO_FAILURE, error: error.message});
  }
};
