import { functions } from "@julaya/common/utils";
import { Wallet } from "components/UI";
import useSubscriptionRestrictions from "hooks/useSubscriptionRestrictions";
import { Link } from "react-router-dom";

const WalletList = ({ wallets, landingWalletsLoading, walletType, currentCompany}) => {
    const canSeeOnlyOne = useSubscriptionRestrictions(currentCompany, "ACCESS_ONLY_1_WALLET");

    if (landingWalletsLoading) {
        return (
            <div>
        {[0, 1, 2].map((i) => (
            <Wallet key={i} loading variant="light" />
        ))}
      </div>
    );
}

return (
    <div>
      <div className="wallet-radio-buttons">
        {wallets.map((element, i) => {
            return (
              (canSeeOnlyOne && !element.isDefault) ?  <Wallet
              label={element.label}
              reference={element.reference}
              balance={functions.formatMoney(element.balance)}
              country={element.country}
              countryName={element.countryName}
              type={walletType}
              variant="disabled"
            /> :
            <Link key={i} to={`/accounts/${walletType}/${element.id}`}>
              <Wallet
                label={element.label}
                reference={element.reference}
                balance={functions.formatMoney(element.balance)}
                country={element.country}
                countryName={element.countryName}
                type={walletType}
                variant="light"
              />
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default WalletList;
