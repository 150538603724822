/* eslint-disable eqeqeq */
import React, {useState} from 'react';
import {DropdownToggle, DropdownMenu, DropdownItem, ButtonDropdown} from 'reactstrap';
import cx from 'classnames';

const DropdownSelect = ({label, value, values, onChange}) => {
  const [dropdownOpen, setOpen] = useState(false);

  const onChangeFunction = val => {
    if (includes(value, val)) {
      const filteredValue = value.filter(item => item != val);
      onChange(filteredValue);
    } else {
      value = [...value, val];
      onChange(value);
    }
    toggle();
  };

  const includes = (arr, val) => {
    return Array.isArray(arr) && arr.includes(val.toString());
  };

  const toggle = () => setOpen(!dropdownOpen);
  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} className="d-block">
      <DropdownToggle caret color="light" className="btn-block custom-caret text-left text-muted d-flex justify-content-between align-items-center">
        {label} {value.length > 0 && ` (${value.length})`}
      </DropdownToggle>
      <DropdownMenu>
        {Object.keys(values).map(val => (
          <DropdownItem
            key={val}
            onClick={e => {
              e.preventDefault();
              onChangeFunction(val.toString());
            }}
            className="d-flex justify-content-between align-items-center text-muted">
            <span
              className={cx({
                'font-weight-bold': includes(value, val)
              })}>
              {values[val]}
            </span>
            {includes(value, val) && <i className="icon-cancel ml-2"></i>}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default DropdownSelect;
