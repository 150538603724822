import React from "react";
import cx from "classnames";
import { functions } from "@julaya/common/utils";

// TODO: check

// "strikethrough text-muted": status === "failed" && strike,
// "text-muted": status === "waiting",
// "text-danger": status === "done" && moneyWay === "out",
// "text-success": status === "done" && moneyWay === "in",

const Amount = ({
  item: { amount, status, TransactionType },
  strike = true,
}) => {
  const moneyWay = TransactionType?.way;
  const sign = moneyWay === "out" ? "-" : "+";

  return (
    <span
      className={cx("h5 m-0 text-nowrap", {
        "text-danger": status !== "waiting" && moneyWay === "out",
        "text-success": status !== "waiting" && moneyWay === "in",
        "text-muted": status === "waiting",
        "strikethrough text-muted": status === "failed" && strike,
      })}
    >
      {sign}
      {functions.formatMoney(amount)}
    </span>
  );
};

export default Amount;
