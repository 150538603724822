import React from 'react';
import './ErrorDisplay.scss';

const ErrorDisplay = ({errorText = 'Des erreurs sont survenues', linkText = 'Cliquer pour réessayer', onClick = null}) => {
  return (
    <div className="error-display">
      <div className="error-display__message">{errorText}</div>

      {onClick && (
        <a
          className="error-display__link"
          href="#julaya"
          onClick={e => {
            e.preventDefault();
            onClick();
          }}
        >
          {linkText}
        </a>
      )}
    </div>
  );
};

export default ErrorDisplay;

// USAGE EXAMPLE
