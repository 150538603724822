import React from 'react';
import {Text} from '@julaya/common/ui/atoms';
import {constants} from '@julaya/common/utils';
import {Icon} from '@julaya/common/system';

const countries = {
  civ: {
    name: "Cote d'Ivoire",
    flag: require('@julaya/common/assets/images/flags/ci.png').default
  },
  sen: {
    name: 'Sénégal',
    flag: require('@julaya/common/assets/images/flags/sn.png').default
  },
  ben: {
    name: 'Bénin',
    flag: require('@julaya/common/assets/images/flags/bn.png').default
  }
};

const ReceiverItem = ({onClick, receiver}) => {
  let img = require('@julaya/common/assets/images/operators/julaya.png').default;
  if (constants.serviceSlugIcons[receiver.serviceSelected.serviceSlug.toLowerCase()]) {
    img = constants.serviceSlugIcons[receiver.serviceSelected.serviceSlug.toLowerCase()];
  }

  return (
    <a
      href="#julaya"
      onClick={e => {
        e.preventDefault();
        onClick(img);
      }}
      className="beneficiaries-select-drawer__service-item">
      <img src={img} alt="" />

      <div className="beneficiaries-select-drawer__service-item-content">
        <div>
          <Text bold>{receiver.serviceSelected.externalFullname ?? receiver.serviceSelected.serviceTitle}</Text>
          <Text bold className="beneficiaries-select-drawer__service-item-ext-ref">
            {receiver.serviceSelected.externalReference}
          </Text>
        </div>
        <div>
          <div className="beneficiaries-select-drawer__service-item-country">
            <img src={countries[receiver.country.slug]?.flag} alt="" style={{width: 15}} />
            <span>{countries[receiver.country.slug]?.name}</span>
          </div>
          <div className="beneficiaries-select-drawer__service-item-type">
            <Icon name={constants.transactionTypeMap[receiver.typeSlug]?.icon} />
            <span>{constants.transactionTypeMap?.[receiver.typeSlug]?.title}</span>
          </div>
        </div>
      </div>
      <Icon className="ml-2" name={'chevron-right'} size="h5" />
    </a>
  );
};

export default ReceiverItem;
