/* eslint-disable react-hooks/exhaustive-deps */
import { useDidUpdate } from '@julaya/common/hooks';
import { Text } from '@julaya/common/ui/atoms';
import { ErrorDisplay } from '@julaya/common/ui/molecules';
import { DrawerSelector } from '@julaya/common/ui/organismes';
import { functions } from '@julaya/common/utils';
import { useEffect } from 'react';

const WalletSelect = props => {
  const {
    values,
    handleChange,
    errors,
    bankDisposalWallets,
    getUserWallets,
    bankDisposalWalletsLoading,
    bankDisposalWalletsError,
    getValidationState,
    selectedWallet,
    setSelectedWallet,
    setNextButtonDisabled,
    canSeeOnlyOne
  } = props;
  
  useEffect(() => {
    if (values.walletId) {
      setNextButtonDisabled(false);
    } else {
      setNextButtonDisabled(true);
    }
  }, [values.walletId]);

  useDidUpdate(() => {
    if (bankDisposalWalletsLoading === false && bankDisposalWalletsError === '' && bankDisposalWallets?.length === 1) {
      setSelectedWallet({
        label: `${bankDisposalWallets[0].label} - ${bankDisposalWallets[0].reference}`,
        tag: 'jla-icon-wallet',
        balance: bankDisposalWallets[0].balance
      });
      handleChange({
        target: {
          name: 'walletId',
          value: bankDisposalWallets[0].id
        }
      });
    }
  }, [bankDisposalWalletsLoading]);

  useEffect(() => {
    getUserWallets(
      {
        type: 'WALLET',
        access: 'TOPUP'
      },
      'bank_disposal'
    );
  }, []);

  if (bankDisposalWalletsLoading) {
    return (
      <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center mb-3  mt-3">
        <span
          className="spinner-border spinner-border-lg text-primary"
          style={{width: '3rem', height: '3rem'}}
          role="status"
          aria-hidden="true"></span>
      </div>
    );
  }
  if (bankDisposalWalletsError || bankDisposalWallets?.length === 0) {
    return (
      <div className="mt-3">
        <ErrorDisplay
          errorText={bankDisposalWalletsError || 'Aucun compte trouvé'}
          onClick={() =>
            getUserWallets(
              {
                type: 'WALLET',
                access: 'TOPUP'
              },
              'bank_disposal'
            )
          }
        />
      </div>
    );
  }

  return (
    <div className="bank-disposal-info">
      {bankDisposalWallets && (
        <DrawerSelector
          placeholder="Sélectionnez un compte à créditer"
          selectedItem={selectedWallet}
          options={bankDisposalWallets
            .filter(wallet => !wallet.isCard)
            .map(wallet => {
              return {
                value: wallet,
                tag: 'jla-icon-wallet',
                label: `${wallet.label} - ${wallet.reference}`,
                subtitle: `${functions.formatMoney(wallet.balance)}`,
                disabled: canSeeOnlyOne && !wallet.isDefault
              };
            })}
          onSelect={v => {
            setSelectedWallet({
              label: `${v.label} - ${v.reference}`,
              tag: 'jla-icon-wallet',
              balance: v.balance
            });
            handleChange({
              target: {
                name: 'walletId',
                value: v.id
              }
            });
          }}
          drawerTitle="Compte à créditer"
          drawerIcon="jla-icon-wallet"
          drawerColor="primary"
        />
      )}
      {errors.walletId && <Text color="error">{getValidationState(values.walletId, 'walletId')}</Text>}
    </div>
  );
};

export default WalletSelect;
