/* eslint-disable react-hooks/exhaustive-deps */
import React, {useMemo, useEffect, useState} from 'react';
import {Switch} from 'react-router-dom';
import {Spinner} from 'reactstrap';

import routing from '../../../routing';
import {useEventTimeout} from '@julaya/common/hooks';
import {constants} from '@julaya/common/utils';
import Header from './Header';
import Menu from './Menu';

const AppLayout = props => {
  const {logout} = props;
  const [menu, setMenu] = useState(null);

  const setLockScreen = useEventTimeout(false, logout, constants.logoutTimeout);

  useEffect(() => {
    if (window.location.pathname === '/susbcription') {
      setLockScreen(true);
    } else {
      setLockScreen(false);
    }
  }, [window.location.pathname]);

  const pathname = window.location.pathname;
  const hubspot = document.querySelector('body div#hubspot-messages-iframe-container');

  useEffect(() => {
    if (hubspot) {
      hubspot.classList.add('custom-hubspot');
    }
  }, [hubspot, pathname]);

  const routes = useMemo(() => {
    return routing.getRoutes(routing.appRoutes);
  }, []);

  return (
    <div className="layout">
      <Header {...props} />
      <div className="app-content vw-100">
        {routes ? <Switch>{routes}</Switch> : <Spinner type="grow" color="info" style={{width: '5rem', height: '5rem'}} />}
      </div>
      <Menu {...props} menu={menu} setMenu={setMenu} />
    </div>
  );
};

export default AppLayout;
