import React from 'react';
import cx from 'classnames';
import Footer from './Footer';
import Content from './Content';

import {Button} from '@julaya/common/ui/atoms';
import './Drawer.scss';

const Drawer = ({setOpen, open, children, title, icon, color, additionalComponent = null}) => {
  return (
    <div
      className={cx('drawer', {
        open: open
      })}>
      <a
        href="#julaya"
        className="drawer__overlay"
        onClick={e => {
          e.preventDefault();
          setOpen(false);
        }}>
        {''}
      </a>
      <div className="drawer__inner">
        <div className="drawer__header">
          <div className="font-weight-bold">
            <div className="button_container --badge --gradiant-pink text-secondary icon mr-2 --medium">
              <i className={`badge__icon ${icon}`}></i>
            </div>
            {title}
          </div>
          <div>
            {additionalComponent}{' '}
            <Button action={() => setOpen(false)} variant="badge" status="neutral" className="icon ml-2" icon="jla-icon-x-mark" size="medium" />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

Drawer.Content = Content;
Drawer.Footer = Footer;

export default Drawer;

// EXAMPLE USAGE
{
  /* <Drawer
    setOpen={setDrawer}
    open={drawer}
    title='Drawer title'
    icon='icon-empty'
    color='primary'
    additionalComponent={<AdditionalComponent />}
  >
    <Drawer.Content>
      <div style={{ height: "40rem" }}>content</div>
    </Drawer.Content>
      NOTE: start | between | end | full
    <Drawer.Footer align='full'>
      <div style={{ backgroundColor: "red" }}>1</div>
      <div style={{ backgroundColor: "blue" }}>2</div>
    </Drawer.Footer>
  </Drawer> */
}
