const loadingSelector =
  actions =>
  ({loading}) => {
    return !!actions.reduce((acc, action) => acc || loading[action], false);
  };

const errorSelector = actions => state => {
  return (
    actions
      .reduce((acc, action) => {
        if (state.error[action]) {
          acc.push(state.error[action]);
        }
        return acc;
      }, [])
      .shift() || ''
  );
};

const selector = (items = {}, {loading, error}) =>
  Object.keys(items).reduce((acc, item) => {
    acc[`${item}Loading`] = loadingSelector(Array.isArray(items[item]) ? items[item] : [items[item]])({loading});
    acc[`${item}Error`] = errorSelector(Array.isArray(items[item]) ? items[item] : [items[item]])({error});

    return acc;
  }, {});

export {errorSelector, loadingSelector};
export default selector;
