const COUNTRIES = [
  {id: 'af', name: 'Afghanistan', flag: '🇦🇫', alpha2: 'af', alpha3: 'afg', ioc: 'afg', countryCode: '0093'},
  {id: 'ax', name: 'Åland Islands', flag: '🇦🇽', alpha2: 'ax', alpha3: 'ala', ioc: ''},
  {id: 'al', name: 'Albania', flag: '🇦🇱', alpha2: 'al', alpha3: 'alb', ioc: 'alb', countryCode: '00355'},
  {id: 'dz', name: 'Algeria', flag: '🇩🇿', alpha2: 'dz', alpha3: 'dza', ioc: 'alg', countryCode: '00213'},
  {id: 'as', name: 'American Samoa', flag: '🇦🇸', alpha2: 'as', alpha3: 'asm', ioc: 'asa', countryCode: '00684'},
  {id: 'ad', name: 'Andorra', flag: '🇦🇩', alpha2: 'ad', alpha3: 'and', ioc: 'and', countryCode: '00376'},
  {id: 'ao', name: 'Angola', flag: '🇦🇴', alpha2: 'ao', alpha3: 'ago', ioc: 'ang', countryCode: '00244'},
  {id: 'ai', name: 'Anguilla', flag: '🇦🇮', alpha2: 'ai', alpha3: 'aia', ioc: '', countryCode: '00264'},
  {id: 'aq', name: 'Antarctica', flag: '🇦🇶', alpha2: 'aq', alpha3: 'ata', ioc: '', countryCode: '00672'},
  {id: 'ag', name: 'Antigua And Barbuda', flag: '🇦🇬', alpha2: 'ag', alpha3: 'atg', ioc: 'ant', countryCode: '00268'},
  {id: 'ar', name: 'Argentina', flag: '🇦🇷', alpha2: 'ar', alpha3: 'arg', ioc: 'arg', countryCode: '0054'},
  {id: 'am', name: 'Armenia', flag: '🇦🇲', alpha2: 'am', alpha3: 'arm', ioc: 'arm', countryCode: '00374'},
  {id: 'aw', name: 'Aruba', flag: '🇦🇼', alpha2: 'aw', alpha3: 'abw', ioc: 'aru', countryCode: '00297'},
  {id: 'au', name: 'Australia', flag: '🇦🇺', alpha2: 'au', alpha3: 'aus', ioc: 'aus', countryCode: '0061'},
  {id: 'at', name: 'Austria', flag: '🇦🇹', alpha2: 'at', alpha3: 'aut', ioc: 'aut', countryCode: '0043'},
  {id: 'az', name: 'Azerbaijan', flag: '🇦🇿', alpha2: 'az', alpha3: 'aze', ioc: 'aze', countryCode: '00994'},
  {id: 'bs', name: 'Bahamas', flag: '🇧🇸', alpha2: 'bs', alpha3: 'bhs', ioc: 'bah', countryCode: '00242'},
  {id: 'bh', name: 'Bahrain', flag: '🇧🇭', alpha2: 'bh', alpha3: 'bhr', ioc: 'brn', countryCode: '00973'},
  {id: 'bd', name: 'Bangladesh', flag: '🇧🇩', alpha2: 'bd', alpha3: 'bgd', ioc: 'ban', countryCode: '00880'},
  {id: 'bb', name: 'Barbados', flag: '🇧🇧', alpha2: 'bb', alpha3: 'brb', ioc: 'bar', countryCode: '00246'},
  {id: 'by', name: 'Belarus', flag: '🇧🇾', alpha2: 'by', alpha3: 'blr', ioc: 'blr', countryCode: '00375'},
  {id: 'be', name: 'Belgium', flag: '🇧🇪', alpha2: 'be', alpha3: 'bel', ioc: 'bel', countryCode: '0032'},
  {id: 'bz', name: 'Belize', flag: '🇧🇿', alpha2: 'bz', alpha3: 'blz', ioc: 'biz', countryCode: '00501'},
  {id: 'bj', name: 'Benin', flag: '🇧🇯', alpha2: 'bj', alpha3: 'ben', ioc: 'ben', countryCode: '00229'},
  {id: 'bm', name: 'Bermuda', flag: '🇧🇲', alpha2: 'bm', alpha3: 'bmu', ioc: 'ber', countryCode: '00441'},
  {id: 'bt', name: 'Bhutan', flag: '🇧🇹', alpha2: 'bt', alpha3: 'btn', ioc: 'bhu', countryCode: '00975'},
  {
    id: 'bo',
    name: 'Bolivia, Plurinational State Of',
    flag: '🇧🇴',
    alpha2: 'bo',
    alpha3: 'bol',
    ioc: 'bol',
    countryCode: '00591'
  },
  {id: 'bq', name: 'Bonaire, Saint Eustatius And Saba', flag: '🇧🇶', alpha2: 'bq', alpha3: 'bes', ioc: ''},
  {id: 'ba', name: 'Bosnia & Herzegovina', flag: '🇧🇦', alpha2: 'ba', alpha3: 'bih', ioc: 'bih', countryCode: '00387'},
  {id: 'bw', name: 'Botswana', flag: '🇧🇼', alpha2: 'bw', alpha3: 'bwa', ioc: 'bot', countryCode: '00267'},
  {id: 'bv', name: 'Bouvet Island', flag: '🇧🇻', alpha2: 'bv', alpha3: 'bvt', ioc: ''},
  {id: 'br', name: 'Brazil', flag: '🇧🇷', alpha2: 'br', alpha3: 'bra', ioc: 'bra', countryCode: '0055'},
  {
    id: 'io',
    name: 'British Indian Ocean Territory',
    flag: '🇮🇴',
    alpha2: 'io',
    alpha3: 'iot',
    ioc: '',
    countryCode: '00246'
  },
  {id: 'bn', name: 'Brunei Darussalam', flag: '🇧🇳', alpha2: 'bn', alpha3: 'brn', ioc: 'bru', countryCode: '00673'},
  {id: 'bg', name: 'Bulgaria', flag: '🇧🇬', alpha2: 'bg', alpha3: 'bgr', ioc: 'bul', countryCode: '00359'},
  {id: 'bf', name: 'Burkina Faso', flag: '🇧🇫', alpha2: 'bf', alpha3: 'bfa', ioc: 'bur', countryCode: '00226'},
  {id: 'bi', name: 'Burundi', flag: '🇧🇮', alpha2: 'bi', alpha3: 'bdi', ioc: 'bdi', countryCode: '00257'},
  {id: 'cv', name: 'Cabo Verde', flag: '🇨🇻', alpha2: 'cv', alpha3: 'cpv', ioc: 'cpv', countryCode: '00238'},
  {id: 'kh', name: 'Cambodia', flag: '🇰🇭', alpha2: 'kh', alpha3: 'khm', ioc: 'cam', countryCode: '00855'},
  {id: 'cm', name: 'Cameroon', flag: '🇨🇲', alpha2: 'cm', alpha3: 'cmr', ioc: 'cmr', countryCode: '00237'},
  {id: 'ca', name: 'Canada', flag: '🇨🇦', alpha2: 'ca', alpha3: 'can', ioc: 'can', countryCode: '001'},
  {id: 'ky', name: 'Cayman Islands', flag: '🇰🇾', alpha2: 'ky', alpha3: 'cym', ioc: 'cay', countryCode: '00345'},
  {
    id: 'cf',
    name: 'Central African Republic',
    flag: '🇨🇫',
    alpha2: 'cf',
    alpha3: 'caf',
    ioc: 'caf',
    countryCode: '00236'
  },
  {id: 'td', name: 'Chad', flag: '🇹🇩', alpha2: 'td', alpha3: 'tcd', ioc: 'cha', countryCode: '00235'},
  {id: 'cl', name: 'Chile', flag: '🇨🇱', alpha2: 'cl', alpha3: 'chl', ioc: 'chi', countryCode: '0056'},
  {id: 'cn', name: 'China', flag: '🇨🇳', alpha2: 'cn', alpha3: 'chn', ioc: 'chn', countryCode: '0086'},
  {id: 'cx', name: 'Christmas Island', flag: '🇨🇽', alpha2: 'cx', alpha3: 'cxr', ioc: '', countryCode: '0061'},
  {id: 'cc', name: 'Cocos (Keeling) Islands', flag: '🇨🇨', alpha2: 'cc', alpha3: 'cck', ioc: '', countryCode: '0061'},
  {id: 'co', name: 'Colombia', flag: '🇨🇴', alpha2: 'co', alpha3: 'col', ioc: 'col', countryCode: '0057'},
  {id: 'km', name: 'Comoros', flag: '🇰🇲', alpha2: 'km', alpha3: 'com', ioc: 'com', countryCode: '00269'},
  {id: 'ck', name: 'Cook Islands', flag: '🇨🇰', alpha2: 'ck', alpha3: 'cok', ioc: 'cok', countryCode: '00682'},
  {id: 'cr', name: 'Costa Rica', flag: '🇨🇷', alpha2: 'cr', alpha3: 'cri', ioc: 'crc', countryCode: '00506'},
  {id: 'hr', name: 'Croatia', flag: '🇭🇷', alpha2: 'hr', alpha3: 'hrv', ioc: 'cro', countryCode: '00385'},
  {id: 'cu', name: 'Cuba', flag: '🇨🇺', alpha2: 'cu', alpha3: 'cub', ioc: 'cub', countryCode: '0053'},
  {id: 'cw', name: 'Curacao', flag: '🇨🇼', alpha2: 'cw', alpha3: 'cuw', ioc: '', countryCode: '00599'},
  {id: 'cy', name: 'Cyprus', flag: '🇨🇾', alpha2: 'cy', alpha3: 'cyp', ioc: 'cyp', countryCode: '00357'},
  {id: 'cz', name: 'Czech Republic', flag: '🇨🇿', alpha2: 'cz', alpha3: 'cze', ioc: 'cze', countryCode: '00420'},
  {
    id: 'ci',
    name: "Cote d'Ivoire",
    flag: '🇨🇮',
    alpha2: 'ci',
    alpha3: 'civ',
    ioc: 'civ',
    highlight: true,
    countryCode: '00225'
  },
  {
    id: 'cd',
    name: 'Democratic Republic Of Congo',
    flag: '🇨🇩',
    alpha2: 'cd',
    alpha3: 'cod',
    ioc: 'cod',
    countryCode: '00243'
  },
  {id: 'dk', name: 'Denmark', flag: '🇩🇰', alpha2: 'dk', alpha3: 'dnk', ioc: 'den', countryCode: '0045'},
  {id: 'dj', name: 'Djibouti', flag: '🇩🇯', alpha2: 'dj', alpha3: 'dji', ioc: 'dji', countryCode: '00253'},
  {id: 'dm', name: 'Dominica', flag: '🇩🇲', alpha2: 'dm', alpha3: 'dma', ioc: 'dma', countryCode: '00767'},
  {id: 'do', name: 'Dominican Republic', flag: '🇩🇴', alpha2: 'do', alpha3: 'dom', ioc: 'dom', countryCode: '00849'},
  {id: 'ec', name: 'Ecuador', flag: '🇪🇨', alpha2: 'ec', alpha3: 'ecu', ioc: 'ecu', countryCode: '00593'},
  {id: 'eg', name: 'Egypt', flag: '🇪🇬', alpha2: 'eg', alpha3: 'egy', ioc: 'egy', countryCode: '0020'},
  {id: 'sv', name: 'El Salvador', flag: '🇸🇻', alpha2: 'sv', alpha3: 'slv', ioc: 'esa', countryCode: '00503'},
  {id: 'gq', name: 'Equatorial Guinea', flag: '🇬🇶', alpha2: 'gq', alpha3: 'gnq', ioc: 'geq', countryCode: '00240'},
  {id: 'er', name: 'Eritrea', flag: '🇪🇷', alpha2: 'er', alpha3: 'eri', ioc: 'eri', countryCode: '00291'},
  {id: 'ee', name: 'Estonia', flag: '🇪🇪', alpha2: 'ee', alpha3: 'est', ioc: 'est', countryCode: '00372'},
  {id: 'et', name: 'Ethiopia', flag: '🇪🇹', alpha2: 'et', alpha3: 'eth', ioc: 'eth', countryCode: '00251'},
  {id: 'fk', name: 'Falkland Islands', flag: '🇫🇰', alpha2: 'fk', alpha3: 'flk', ioc: '', countryCode: '00500'},
  {id: 'fo', name: 'Faroe Islands', flag: '🇫🇴', alpha2: 'fo', alpha3: 'fro', ioc: 'fai', countryCode: '00298'},
  {id: 'fj', name: 'Fiji', flag: '🇫🇯', alpha2: 'fj', alpha3: 'fji', ioc: 'fij', countryCode: '00679'},
  {id: 'fi', name: 'Finland', flag: '🇫🇮', alpha2: 'fi', alpha3: 'fin', ioc: 'fin', countryCode: '00358'},
  {id: 'fr', name: 'France', flag: '🇫🇷', alpha2: 'fr', alpha3: 'fra', ioc: 'fra', countryCode: '0033'},
  {id: 'gf', name: 'French Guiana', flag: '🇬🇫', alpha2: 'gf', alpha3: 'guf', ioc: ''},
  {id: 'pf', name: 'French Polynesia', flag: '🇵🇫', alpha2: 'pf', alpha3: 'pyf', ioc: '', countryCode: '00689'},
  {id: 'tf', name: 'French Southern Territories', flag: '🇹🇫', alpha2: 'tf', alpha3: 'atf', ioc: ''},
  {id: 'ga', name: 'Gabon', flag: '🇬🇦', alpha2: 'ga', alpha3: 'gab', ioc: 'gab', countryCode: '00241'},
  {id: 'gm', name: 'Gambia', flag: '🇬🇲', alpha2: 'gm', alpha3: 'gmb', ioc: 'gam', countryCode: '00220'},
  {id: 'ge', name: 'Georgia', flag: '🇬🇪', alpha2: 'ge', alpha3: 'geo', ioc: 'geo', countryCode: '00995'},
  {id: 'de', name: 'Germany', flag: '🇩🇪', alpha2: 'de', alpha3: 'deu', ioc: 'ger', countryCode: '0049'},
  {id: 'gh', name: 'Ghana', flag: '🇬🇭', alpha2: 'gh', alpha3: 'gha', ioc: 'gha', countryCode: '00233'},
  {id: 'gi', name: 'Gibraltar', flag: '🇬🇮', alpha2: 'gi', alpha3: 'gib', ioc: '', countryCode: '00350'},
  {id: 'gr', name: 'Greece', flag: '🇬🇷', alpha2: 'gr', alpha3: 'grc', ioc: 'gre', countryCode: '0030'},
  {id: 'gl', name: 'Greenland', flag: '🇬🇱', alpha2: 'gl', alpha3: 'grl', ioc: '', countryCode: '00299'},
  {id: 'gd', name: 'Grenada', flag: '🇬🇩', alpha2: 'gd', alpha3: 'grd', ioc: 'grn', countryCode: '00473'},
  {id: 'gp', name: 'Guadeloupe', flag: '🇬🇵', alpha2: 'gp', alpha3: 'glp', ioc: ''},
  {id: 'gu', name: 'Guam', flag: '🇬🇺', alpha2: 'gu', alpha3: 'gum', ioc: 'gum', countryCode: '00671'},
  {id: 'gt', name: 'Guatemala', flag: '🇬🇹', alpha2: 'gt', alpha3: 'gtm', ioc: 'gua', countryCode: '00502'},
  // { id: "gg", name: "Guernsey", flag: "🇬🇬", alpha2: "gg", alpha3: "ggy", ioc: "gci", countryCode: "001481" },
  {id: 'gn', name: 'Guinea', flag: '🇬🇳', alpha2: 'gn', alpha3: 'gin', ioc: 'gui', countryCode: '00224'},
  {id: 'gw', name: 'Guinea-bissau', flag: '🇬🇼', alpha2: 'gw', alpha3: 'gnb', ioc: 'gbs', countryCode: '00245'},
  {id: 'gy', name: 'Guyana', flag: '🇬🇾', alpha2: 'gy', alpha3: 'guy', ioc: 'guy', countryCode: '00592'},
  {id: 'ht', name: 'Haiti', flag: '🇭🇹', alpha2: 'ht', alpha3: 'hti', ioc: 'hai', countryCode: '00509'},
  {id: 'hm', name: 'Heard Island And McDonald Islands', flag: '🇭🇲', alpha2: 'hm', alpha3: 'hmd', ioc: ''},
  {id: 'hn', name: 'Honduras', flag: '🇭🇳', alpha2: 'hn', alpha3: 'hnd', ioc: 'hon', countryCode: '00504'},
  {id: 'hk', name: 'Hong Kong', flag: '🇭🇰', alpha2: 'hk', alpha3: 'hkg', ioc: 'hkg', countryCode: '00852'},
  {id: 'hu', name: 'Hungary', flag: '🇭🇺', alpha2: 'hu', alpha3: 'hun', ioc: 'hun', countryCode: '0036'},
  {id: 'is', name: 'Iceland', flag: '🇮🇸', alpha2: 'is', alpha3: 'isl', ioc: 'isl', countryCode: '00354'},
  {id: 'in', name: 'India', flag: '🇮🇳', alpha2: 'in', alpha3: 'ind', ioc: 'ind', countryCode: '0091'},
  {id: 'id', name: 'Indonesia', flag: '🇮🇩', alpha2: 'id', alpha3: 'idn', ioc: 'ina', countryCode: '0062'},
  {
    id: 'ir',
    name: 'Iran, Islamic Republic Of',
    flag: '🇮🇷',
    alpha2: 'ir',
    alpha3: 'irn',
    ioc: 'iri',
    countryCode: '0098'
  },
  {id: 'iq', name: 'Iraq', flag: '🇮🇶', alpha2: 'iq', alpha3: 'irq', ioc: 'irq', countryCode: '00964'},
  {id: 'ie', name: 'Ireland', flag: '🇮🇪', alpha2: 'ie', alpha3: 'irl', ioc: 'irl', countryCode: '00353'},
  // { id: "im", name: "Isle Of Man", flag: "🇮🇲", alpha2: "im", alpha3: "imn", ioc: "", countryCode: "001624" },
  {id: 'il', name: 'Israel', flag: '🇮🇱', alpha2: 'il', alpha3: 'isr', ioc: 'isr', countryCode: '00972'},
  {id: 'it', name: 'Italy', flag: '🇮🇹', alpha2: 'it', alpha3: 'ita', ioc: 'ita', countryCode: '0039'},
  {id: 'jm', name: 'Jamaica', flag: '🇯🇲', alpha2: 'jm', alpha3: 'jam', ioc: 'jam', countryCode: '00876'},
  {id: 'jp', name: 'Japan', flag: '🇯🇵', alpha2: 'jp', alpha3: 'jpn', ioc: 'jpn', countryCode: '0081'},
  // { id: "je", name: "Jersey", flag: "🇯🇪", alpha2: "je", alpha3: "jey", ioc: "jci", countryCode: "001534" },
  {id: 'jo', name: 'Jordan', flag: '🇯🇴', alpha2: 'jo', alpha3: 'jor', ioc: 'jor', countryCode: '00962'},
  {id: 'kz', name: 'Kazakhstan', flag: '🇰🇿', alpha2: 'kz', alpha3: 'kaz', ioc: 'kaz', countryCode: '007'},
  {id: 'ke', name: 'Kenya', flag: '🇰🇪', alpha2: 'ke', alpha3: 'ken', ioc: 'ken', countryCode: '00254'},
  {id: 'ki', name: 'Kiribati', flag: '🇰🇮', alpha2: 'ki', alpha3: 'kir', ioc: 'kir', countryCode: '00686'},
  {
    id: 'kp',
    name: "Korea, Democratic People's Republic Of",
    flag: '🇰🇵',
    alpha2: 'kp',
    alpha3: 'prk',
    ioc: 'prk',
    countryCode: '00850'
  },
  {id: 'kr', name: 'Korea, Republic Of', flag: '🇰🇷', alpha2: 'kr', alpha3: 'kor', ioc: 'kor', countryCode: '0082'},
  {id: 'kw', name: 'Kuwait', flag: '🇰🇼', alpha2: 'kw', alpha3: 'kwt', ioc: 'kuw', countryCode: '00965'},
  {id: 'kg', name: 'Kyrgyzstan', flag: '🇰🇬', alpha2: 'kg', alpha3: 'kgz', ioc: 'kgz', countryCode: '00996'},
  {
    id: 'la',
    name: "Lao People's Democratic Republic",
    flag: '🇱🇦',
    alpha2: 'la',
    alpha3: 'lao',
    ioc: 'lao',
    countryCode: '00856'
  },
  {id: 'lv', name: 'Latvia', flag: '🇱🇻', alpha2: 'lv', alpha3: 'lva', ioc: 'lat', countryCode: '00371'},
  {id: 'lb', name: 'Lebanon', flag: '🇱🇧', alpha2: 'lb', alpha3: 'lbn', ioc: 'lib', countryCode: '00961'},
  {id: 'ls', name: 'Lesotho', flag: '🇱🇸', alpha2: 'ls', alpha3: 'lso', ioc: 'les', countryCode: '00266'},
  {id: 'lr', name: 'Liberia', flag: '🇱🇷', alpha2: 'lr', alpha3: 'lbr', ioc: 'lbr', countryCode: '00231'},
  {id: 'ly', name: 'Libya', flag: '🇱🇾', alpha2: 'ly', alpha3: 'lby', ioc: 'lba', countryCode: '00218'},
  {id: 'li', name: 'Liechtenstein', flag: '🇱🇮', alpha2: 'li', alpha3: 'lie', ioc: 'lie', countryCode: '00423'},
  {id: 'lt', name: 'Lithuania', flag: '🇱🇹', alpha2: 'lt', alpha3: 'ltu', ioc: 'ltu', countryCode: '00370'},
  {id: 'lu', name: 'Luxembourg', flag: '🇱🇺', alpha2: 'lu', alpha3: 'lux', ioc: 'lux', countryCode: '00352'},
  {id: 'mo', name: 'Macao', flag: '🇲🇴', alpha2: 'mo', alpha3: 'mac', ioc: 'mac', countryCode: '00853'},
  {
    id: 'mk',
    name: 'Macedonia, The Former Yugoslav Republic Of',
    flag: '🇲🇰',
    alpha2: 'mk',
    alpha3: 'mkd',
    ioc: 'mkd',
    countryCode: '00389'
  },
  {id: 'mg', name: 'Madagascar', flag: '🇲🇬', alpha2: 'mg', alpha3: 'mdg', ioc: 'mad', countryCode: '00261'},
  {id: 'mw', name: 'Malawi', flag: '🇲🇼', alpha2: 'mw', alpha3: 'mwi', ioc: 'maw', countryCode: '00265'},
  {id: 'my', name: 'Malaysia', flag: '🇲🇾', alpha2: 'my', alpha3: 'mys', ioc: 'mas', countryCode: '0060'},
  {id: 'mv', name: 'Maldives', flag: '🇲🇻', alpha2: 'mv', alpha3: 'mdv', ioc: 'mdv', countryCode: '00960'},
  {id: 'ml', name: 'Mali', flag: '🇲🇱', alpha2: 'ml', alpha3: 'mli', ioc: 'mli', countryCode: '00223'},
  {id: 'mt', name: 'Malta', flag: '🇲🇹', alpha2: 'mt', alpha3: 'mlt', ioc: 'mlt', countryCode: '00356'},
  {id: 'mh', name: 'Marshall Islands', flag: '🇲🇭', alpha2: 'mh', alpha3: 'mhl', ioc: 'mhl', countryCode: '00692'},
  {id: 'mq', name: 'Martinique', flag: '🇲🇶', alpha2: 'mq', alpha3: 'mtq', ioc: ''},
  {id: 'mr', name: 'Mauritania', flag: '🇲🇷', alpha2: 'mr', alpha3: 'mrt', ioc: 'mtn', countryCode: '00222'},
  {id: 'mu', name: 'Mauritius', flag: '🇲🇺', alpha2: 'mu', alpha3: 'mus', ioc: 'mri', countryCode: '00230'},
  {id: 'yt', name: 'Mayotte', flag: '🇾🇹', alpha2: 'yt', alpha3: 'myt', ioc: '', countryCode: '00262'},
  {id: 'mx', name: 'Mexico', flag: '🇲🇽', alpha2: 'mx', alpha3: 'mex', ioc: 'mex', countryCode: '0052'},
  {
    id: 'fm',
    name: 'Micronesia, Federated States Of',
    flag: '🇫🇲',
    alpha2: 'fm',
    alpha3: 'fsm',
    ioc: 'fsm',
    countryCode: '00691'
  },
  {id: 'md', name: 'Moldova', flag: '🇲🇩', alpha2: 'md', alpha3: 'mda', ioc: 'mda', countryCode: '00373'},
  {id: 'mc', name: 'Monaco', flag: '🇲🇨', alpha2: 'mc', alpha3: 'mco', ioc: 'mon', countryCode: '00377'},
  {id: 'mn', name: 'Mongolia', flag: '🇲🇳', alpha2: 'mn', alpha3: 'mng', ioc: 'mgl', countryCode: '00976'},
  {id: 'me', name: 'Montenegro', flag: '🇲🇪', alpha2: 'me', alpha3: 'mne', ioc: 'mne', countryCode: '00382'},
  {id: 'ms', name: 'Montserrat', flag: '🇲🇸', alpha2: 'ms', alpha3: 'msr', ioc: '', countryCode: '00664'},
  {id: 'ma', name: 'Morocco', flag: '🇲🇦', alpha2: 'ma', alpha3: 'mar', ioc: 'mar', countryCode: '00212'},
  {id: 'mz', name: 'Mozambique', flag: '🇲🇿', alpha2: 'mz', alpha3: 'moz', ioc: 'moz', countryCode: '00258'},
  {id: 'mm', name: 'Myanmar', flag: '🇲🇲', alpha2: 'mm', alpha3: 'mmr', ioc: 'mya', countryCode: '0095'},
  {id: 'na', name: 'Namibia', flag: '🇳🇦', alpha2: 'na', alpha3: 'nam', ioc: 'nam', countryCode: '00264'},
  {id: 'nr', name: 'Nauru', flag: '🇳🇷', alpha2: 'nr', alpha3: 'nru', ioc: 'nru', countryCode: '00674'},
  {id: 'np', name: 'Nepal', flag: '🇳🇵', alpha2: 'np', alpha3: 'npl', ioc: 'nep', countryCode: '00977'},
  {id: 'nl', name: 'Netherlands', flag: '🇳🇱', alpha2: 'nl', alpha3: 'nld', ioc: 'ned', countryCode: '0031'},
  {id: 'nc', name: 'New Caledonia', flag: '🇳🇨', alpha2: 'nc', alpha3: 'ncl', ioc: '', countryCode: '00687'},
  {id: 'nz', name: 'New Zealand', flag: '🇳🇿', alpha2: 'nz', alpha3: 'nzl', ioc: 'nzl', countryCode: '0064'},
  {id: 'ni', name: 'Nicaragua', flag: '🇳🇮', alpha2: 'ni', alpha3: 'nic', ioc: 'nca', countryCode: '00505'},
  {id: 'ne', name: 'Niger', flag: '🇳🇪', alpha2: 'ne', alpha3: 'ner', ioc: 'nig', countryCode: '00227'},
  {id: 'ng', name: 'Nigeria', flag: '🇳🇬', alpha2: 'ng', alpha3: 'nga', ioc: 'ngr', countryCode: '00234'},
  {id: 'nu', name: 'Niue', flag: '🇳🇺', alpha2: 'nu', alpha3: 'niu', ioc: '', countryCode: '00683'},
  {id: 'nf', name: 'Norfolk Island', flag: '🇳🇫', alpha2: 'nf', alpha3: 'nfk', ioc: ''},
  {
    id: 'mp',
    name: 'Northern Mariana Islands',
    flag: '🇲🇵',
    alpha2: 'mp',
    alpha3: 'mnp',
    ioc: '',
    countryCode: '00670'
  },
  {id: 'no', name: 'Norway', flag: '🇳🇴', alpha2: 'no', alpha3: 'nor', ioc: 'nor', countryCode: '0047'},
  {id: 'om', name: 'Oman', flag: '🇴🇲', alpha2: 'om', alpha3: 'omn', ioc: 'oma', countryCode: '00968'},
  {id: 'pk', name: 'Pakistan', flag: '🇵🇰', alpha2: 'pk', alpha3: 'pak', ioc: 'pak', countryCode: '0092'},
  {id: 'pw', name: 'Palau', flag: '🇵🇼', alpha2: 'pw', alpha3: 'plw', ioc: 'plw', countryCode: '00680'},
  {
    id: 'ps',
    name: 'Palestinian Territory, Occupied',
    flag: '🇵🇸',
    alpha2: 'ps',
    alpha3: 'pse',
    ioc: 'ple',
    countryCode: '00970'
  },
  {id: 'pa', name: 'Panama', flag: '🇵🇦', alpha2: 'pa', alpha3: 'pan', ioc: 'pan', countryCode: '00507'},
  {id: 'pg', name: 'Papua New Guinea', flag: '🇵🇬', alpha2: 'pg', alpha3: 'png', ioc: 'png', countryCode: '00675'},
  {id: 'py', name: 'Paraguay', flag: '🇵🇾', alpha2: 'py', alpha3: 'pry', ioc: 'par', countryCode: '00595'},
  {id: 'pe', name: 'Peru', flag: '🇵🇪', alpha2: 'pe', alpha3: 'per', ioc: 'per', countryCode: '0051'},
  {id: 'ph', name: 'Philippines', flag: '🇵🇭', alpha2: 'ph', alpha3: 'phl', ioc: 'phi', countryCode: '0063'},
  {id: 'pn', name: 'Pitcairn', flag: '🇵🇳', alpha2: 'pn', alpha3: 'pcn', ioc: '', countryCode: '0064'},
  {id: 'pl', name: 'Poland', flag: '🇵🇱', alpha2: 'pl', alpha3: 'pol', ioc: 'pol', countryCode: '0048'},
  {id: 'pt', name: 'Portugal', flag: '🇵🇹', alpha2: 'pt', alpha3: 'prt', ioc: 'por', countryCode: '00351'},
  {id: 'pr', name: 'Puerto Rico', flag: '🇵🇷', alpha2: 'pr', alpha3: 'pri', ioc: 'pur', countryCode: '00939'},
  {id: 'qa', name: 'Qatar', flag: '🇶🇦', alpha2: 'qa', alpha3: 'qat', ioc: 'qat', countryCode: '00974'},
  {id: 'cg', name: 'Republic Of Congo', flag: '🇨🇬', alpha2: 'cg', alpha3: 'cog', ioc: 'cgo', countryCode: '00242'},
  {id: 're', name: 'Reunion', flag: '🇷🇪', alpha2: 're', alpha3: 'reu', ioc: '', countryCode: '00262'},
  {id: 'ro', name: 'Romania', flag: '🇷🇴', alpha2: 'ro', alpha3: 'rou', ioc: 'rou', countryCode: '0040'},
  {id: 'ru', name: 'Russian Federation', flag: '🇷🇺', alpha2: 'ru', alpha3: 'rus', ioc: 'rus', countryCode: '007'},
  {id: 'rw', name: 'Rwanda', flag: '🇷🇼', alpha2: 'rw', alpha3: 'rwa', ioc: 'rwa', countryCode: '00250'},
  {id: 'bl', name: 'Saint Barthélemy', flag: '🇧🇱', alpha2: 'bl', alpha3: 'blm', ioc: '', countryCode: '00590'},
  {
    id: 'sh',
    name: 'Saint Helena, Ascension And Tristan Da Cunha',
    flag: '🇸🇭',
    alpha2: 'sh',
    alpha3: 'shn',
    ioc: '',
    countryCode: '00290'
  },
  {
    id: 'kn',
    name: 'Saint Kitts And Nevis',
    flag: '🇰🇳',
    alpha2: 'kn',
    alpha3: 'kna',
    ioc: 'skn',
    countryCode: '00869'
  },
  {id: 'lc', name: 'Saint Lucia', flag: '🇱🇨', alpha2: 'lc', alpha3: 'lca', ioc: 'lca', countryCode: '00758'},
  {id: 'mf', name: 'Saint Martin', flag: '🇲🇫', alpha2: 'mf', alpha3: 'maf', ioc: '', countryCode: '00590'},
  {
    id: 'pm',
    name: 'Saint Pierre And Miquelon',
    flag: '🇵🇲',
    alpha2: 'pm',
    alpha3: 'spm',
    ioc: '',
    countryCode: '00508'
  },
  {
    id: 'vc',
    name: 'Saint Vincent And The Grenadines',
    flag: '🇻🇨',
    alpha2: 'vc',
    alpha3: 'vct',
    ioc: 'vin',
    countryCode: '00784'
  },
  {id: 'ws', name: 'Samoa', flag: '🇼🇸', alpha2: 'ws', alpha3: 'wsm', ioc: 'sam', countryCode: '00685'},
  {id: 'sm', name: 'San Marino', flag: '🇸🇲', alpha2: 'sm', alpha3: 'smr', ioc: 'smr', countryCode: '00378'},
  {
    id: 'st',
    name: 'Sao Tome and Principe',
    flag: '🇸🇹',
    alpha2: 'st',
    alpha3: 'stp',
    ioc: 'stp',
    countryCode: '00239'
  },
  {id: 'sa', name: 'Saudi Arabia', flag: '🇸🇦', alpha2: 'sa', alpha3: 'sau', ioc: 'ksa', countryCode: '00966'},
  {
    id: 'sn',
    name: 'Senegal',
    flag: '🇸🇳',
    alpha2: 'sn',
    alpha3: 'sen',
    ioc: 'sen',
    highlight: true,
    countryCode: '00221'
  },
  {id: 'rs', name: 'Serbia', flag: '🇷🇸', alpha2: 'rs', alpha3: 'srb', ioc: 'srb', countryCode: '00381'},
  {id: 'sc', name: 'Seychelles', flag: '🇸🇨', alpha2: 'sc', alpha3: 'syc', ioc: 'sey', countryCode: '00248'},
  {id: 'sl', name: 'Sierra Leone', flag: '🇸🇱', alpha2: 'sl', alpha3: 'sle', ioc: 'sle', countryCode: '00232'},
  {id: 'sg', name: 'Singapore', flag: '🇸🇬', alpha2: 'sg', alpha3: 'sgp', ioc: 'sin', countryCode: '0065'},
  {id: 'sx', name: 'Sint Maarten', flag: '🇸🇽', alpha2: 'sx', alpha3: 'sxm', ioc: '', countryCode: '00721'},
  {id: 'sk', name: 'Slovakia', flag: '🇸🇰', alpha2: 'sk', alpha3: 'svk', ioc: 'svk', countryCode: '00421'},
  {id: 'si', name: 'Slovenia', flag: '🇸🇮', alpha2: 'si', alpha3: 'svn', ioc: 'slo', countryCode: '00386'},
  {id: 'sb', name: 'Solomon Islands', flag: '🇸🇧', alpha2: 'sb', alpha3: 'slb', ioc: 'sol', countryCode: '00677'},
  {id: 'so', name: 'Somalia', flag: '🇸🇴', alpha2: 'so', alpha3: 'som', ioc: 'som', countryCode: '00252'},
  {id: 'za', name: 'South Africa', flag: '🇿🇦', alpha2: 'za', alpha3: 'zaf', ioc: 'rsa', countryCode: '0027'},
  {id: 'gs', name: 'South Georgia And The South Sandwich Islands', flag: '🇬🇸', alpha2: 'gs', alpha3: 'sgs', ioc: ''},
  {id: 'ss', name: 'South Sudan', flag: '🇸🇸', alpha2: 'ss', alpha3: 'ssd', ioc: 'ssd', countryCode: '00211'},
  {id: 'es', name: 'Spain', flag: '🇪🇸', alpha2: 'es', alpha3: 'esp', ioc: 'esp', countryCode: '0034'},
  {id: 'lk', name: 'Sri Lanka', flag: '🇱🇰', alpha2: 'lk', alpha3: 'lka', ioc: 'sri', countryCode: '0094'},
  {id: 'sd', name: 'Sudan', flag: '🇸🇩', alpha2: 'sd', alpha3: 'sdn', ioc: 'sud', countryCode: '00249'},
  {id: 'sr', name: 'Suriname', flag: '🇸🇷', alpha2: 'sr', alpha3: 'sur', ioc: 'sur', countryCode: '00597'},
  {id: 'sj', name: 'Svalbard And Jan Mayen', flag: '🇸🇯', alpha2: 'sj', alpha3: 'sjm', ioc: '', countryCode: '0047'},
  {id: 'sz', name: 'Swaziland', flag: '🇸🇿', alpha2: 'sz', alpha3: 'swz', ioc: 'swz', countryCode: '00268'},
  {id: 'se', name: 'Sweden', flag: '🇸🇪', alpha2: 'se', alpha3: 'swe', ioc: 'swe', countryCode: '0046'},
  {id: 'ch', name: 'Switzerland', flag: '🇨🇭', alpha2: 'ch', alpha3: 'che', ioc: 'sui', countryCode: '0041'},
  {id: 'sy', name: 'Syrian Arab Republic', flag: '🇸🇾', alpha2: 'sy', alpha3: 'syr', ioc: 'syr', countryCode: '00963'},
  {id: 'tw', name: 'Taiwan', flag: '🇹🇼', alpha2: 'tw', alpha3: 'twn', ioc: 'tpe', countryCode: '00886'},
  {id: 'tj', name: 'Tajikistan', flag: '🇹🇯', alpha2: 'tj', alpha3: 'tjk', ioc: 'tjk', countryCode: '00992'},
  {
    id: 'tz',
    name: 'Tanzania, United Republic Of',
    flag: '🇹🇿',
    alpha2: 'tz',
    alpha3: 'tza',
    ioc: 'tan',
    countryCode: '00255'
  },
  {id: 'th', name: 'Thailand', flag: '🇹🇭', alpha2: 'th', alpha3: 'tha', ioc: 'tha', countryCode: '0066'},
  {
    id: 'tl',
    name: 'Timor-Leste, Democratic Republic of',
    flag: '🇹🇱',
    alpha2: 'tl',
    alpha3: 'tls',
    ioc: 'tls',
    countryCode: '00670'
  },
  {id: 'tg', name: 'Togo', flag: '🇹🇬', alpha2: 'tg', alpha3: 'tgo', ioc: 'tog', countryCode: '00228'},
  {id: 'tk', name: 'Tokelau', flag: '🇹🇰', alpha2: 'tk', alpha3: 'tkl', ioc: '', countryCode: '00690'},
  {id: 'to', name: 'Tonga', flag: '🇹🇴', alpha2: 'to', alpha3: 'ton', ioc: 'tga', countryCode: '00676'},
  {id: 'tt', name: 'Trinidad And Tobago', flag: '🇹🇹', alpha2: 'tt', alpha3: 'tto', ioc: 'tto', countryCode: '00868'},
  {id: 'tn', name: 'Tunisia', flag: '🇹🇳', alpha2: 'tn', alpha3: 'tun', ioc: 'tun', countryCode: '00216'},
  {id: 'tr', name: 'Turkey', flag: '🇹🇷', alpha2: 'tr', alpha3: 'tur', ioc: 'tur', countryCode: '0090'},
  {id: 'tm', name: 'Turkmenistan', flag: '🇹🇲', alpha2: 'tm', alpha3: 'tkm', ioc: 'tkm', countryCode: '00993'},
  {
    id: 'tc',
    name: 'Turks And Caicos Islands',
    flag: '🇹🇨',
    alpha2: 'tc',
    alpha3: 'tca',
    ioc: '',
    countryCode: '00649'
  },
  {id: 'tv', name: 'Tuvalu', flag: '🇹🇻', alpha2: 'tv', alpha3: 'tuv', ioc: 'tuv', countryCode: '00688'},
  {id: 'ug', name: 'Uganda', flag: '🇺🇬', alpha2: 'ug', alpha3: 'uga', ioc: 'uga', countryCode: '00256'},
  {id: 'ua', name: 'Ukraine', flag: '🇺🇦', alpha2: 'ua', alpha3: 'ukr', ioc: 'ukr', countryCode: '00380'},
  {id: 'ae', name: 'United Arab Emirates', flag: '🇦🇪', alpha2: 'ae', alpha3: 'are', ioc: 'uae', countryCode: '00971'},
  {id: 'gb', name: 'United Kingdom', flag: '🇬🇧', alpha2: 'gb', alpha3: 'gbr', ioc: 'gbr', countryCode: '0044'},
  {id: 'us', name: 'United States', flag: '🇺🇸', alpha2: 'us', alpha3: 'usa', ioc: 'usa', countryCode: '001'},
  {id: 'um', name: 'United States Minor Outlying Islands', flag: '🇺🇲', alpha2: 'um', alpha3: 'umi', ioc: ''},
  {id: 'uy', name: 'Uruguay', flag: '🇺🇾', alpha2: 'uy', alpha3: 'ury', ioc: 'uru', countryCode: '00598'},
  {id: 'uz', name: 'Uzbekistan', flag: '🇺🇿', alpha2: 'uz', alpha3: 'uzb', ioc: 'uzb', countryCode: '00998'},
  {id: 'vu', name: 'Vanuatu', flag: '🇻🇺', alpha2: 'vu', alpha3: 'vut', ioc: 'van', countryCode: '00678'},
  {id: 'va', name: 'Vatican City State', flag: '🇻🇦', alpha2: 'va', alpha3: 'vat', ioc: '', countryCode: '00379'},
  {
    id: 've',
    name: 'Venezuela, Bolivarian Republic Of',
    flag: '🇻🇪',
    alpha2: 've',
    alpha3: 'ven',
    ioc: 'ven',
    countryCode: '0058'
  },
  {id: 'vn', name: 'Viet Nam', flag: '🇻🇳', alpha2: 'vn', alpha3: 'vnm', ioc: 'vie', countryCode: '0084'},
  {
    id: 'vg',
    name: 'Virgin Islands (British)',
    flag: '🇻🇬',
    alpha2: 'vg',
    alpha3: 'vgb',
    ioc: 'ivb',
    countryCode: '00284'
  },
  {id: 'vi', name: 'Virgin Islands (US)', flag: '🇻🇮', alpha2: 'vi', alpha3: 'vir', ioc: 'isv', countryCode: '00340'},
  {id: 'wf', name: 'Wallis And Futuna', flag: '🇼🇫', alpha2: 'wf', alpha3: 'wlf', ioc: '', countryCode: '00681'},
  {id: 'eh', name: 'Western Sahara', flag: '🇪🇭', alpha2: 'eh', alpha3: 'esh', ioc: '', countryCode: '00212'},
  {id: 'ye', name: 'Yemen', flag: '🇾🇪', alpha2: 'ye', alpha3: 'yem', ioc: 'yem', countryCode: '00967'},
  {id: 'zm', name: 'Zambia', flag: '🇿🇲', alpha2: 'zm', alpha3: 'zmb', ioc: 'zam', countryCode: '00260'},
  {id: 'zw', name: 'Zimbabwe', flag: '🇿🇼', alpha2: 'zw', alpha3: 'zwe', ioc: 'zim', countryCode: '00263'}
];

const prefixes = [
  {value: '', label: 'Préfixe'},
  {value: 'Monsieur', label: 'Mr'},
  {value: 'Madame', label: 'Mme'}
];

const genders = [
  {value: '', label: 'Préfixe'},
  {value: 'M', label: 'Mr'},
  {value: 'F', label: 'Mme'}
];

const userRoles = {
  ADMIN: 'Administrateur',
  'ADMIN-ONLY': 'Modérateur',
  USER: 'Signataire',
  READ: 'Lecture seule',
  COLLECTOR: 'Collecteur Wave'
};

const documentsStatus = {
  'profile-incomplete': {
    text: 'Informations manquantes',
    color: 'warning'
  },
  'document-incomplete': {
    text: 'Document(s) manquant(s)',
    color: 'neutral'
  },
  'document-pending': {
    text: 'Vérifications en cours',
    color: 'warning'
  },
  'document-refused': {
    text: 'Document(s) refusé(s)',
    color: 'error'
  },
  'document-validated': {
    text: 'Vérifiés',
    color: 'success'
  }
};

const userStatus = {
  archived: {
    icon: 'forbidden',
    color: 'neutral',
    text: 'Archivé'
  },
  unlinked: {
    icon: 'exclamation-circle',
    color: 'error',
    text: 'Aucun compte'
  },
  active: {
    icon: 'check-circle',
    color: 'success',
    text: 'Actif'
  },
  inactive: {
    icon: 'forbidden',
    color: 'neutral',
    text: 'Désactivé'
  }
};

const cardStatus = {
  'pending-link-card': {
    icon: 'link',
    color: 'warning',
    text: 'En cours de traitement'
  },
  'pending-activation': {
    icon: 'gear',
    color: 'warning',
    text: "En attente d'activation"
  },
  archived: {
    icon: 'forbidden',
    color: 'neutral',
    text: 'Archivé'
  },
  active: {
    icon: 'check-circle',
    color: 'success',
    text: 'Active'
  },
  inactive: {
    icon: 'forbidden',
    color: 'neutral',
    text: 'Désactivée'
  }
};

const cardTypes = {
  physical: {
    icon: 'credit-card',
    color: 'primary',
    text: 'Physique'
  },
  virtual: {
    icon: 'cloud',
    color: 'blue',
    text: 'Virtuelle'
  }
};

export default {COUNTRIES, prefixes, genders, userRoles, userStatus, documentsStatus, cardStatus, cardTypes};
