import { constants } from '@julaya/common/utils';
import { TextHelper } from '../../../../../system';
import './ReceiverInfoDisplay.scss';

const ReceiverInfoDisplay = ({receiver, handleChange, values, errors, setErrors, setNextButtonDisabled}) => {
  const detailsList = ['accountHolderName', 'externalReference', 'typeGroup', 'service'];

  return (
    <div className="rapid-transfer-receiver-info">
      {detailsList.map((item, i) => {
        const elem = details[item];
        const title = elem.getTitle(receiver);
        const value = elem.getValue(receiver, handleChange, values, errors, setErrors, setNextButtonDisabled);
        return typeof value === 'string' ? (
          <div className="receiver-info__elem" key={`${item}-${i}`}>
            <div>
              <div className="receiver-info__elem-title">{title}</div>
              <div className="receiver-info__elem-value">{value}</div>
            </div>
          </div>
        ) : (
          <div className="receiver-info__elem --full" key={`${item}-${i}`}>
            <div className="receiver-info__elem-title">{title}</div>
            <div className="receiver-info__elem-value">{value}</div>
          </div>
        );
      })}
    </div>
  );
};

const details = {
  accountHolderName: {
    getTitle: () => {
      return 'Titulaire du compte';
    },
    getValue: (receiver, handleChange, values, errors, setErrors, setNextButtonDisabled) => {
      const placeholder = 'Nom du destinataire';

      if (!receiver || !receiver.serviceSelected) {
        return '';
      }

      return (
        receiver.serviceSelected.isExternalFullnameVerified || (
          <div className="form-group">
            <input
              id="externalFullname"
              className="form-control bg-grey mb-2"
              placeholder={placeholder}
              name="externalFullname"
              onChange={event => {
                handleChange(event);

                if (receiver.typeSlug === 'bank-transfer' && event.target.value.trim() === '') {
                  setNextButtonDisabled(true);
                  setErrors(prevErrors => ({
                    ...prevErrors,
                    externalFullname: 'Le nom du bénéficiaire doit être exact pour que le virement soit pris en compte'
                  }));
                } else {
                  setNextButtonDisabled(false);
                }
              }}
              value={values.externalFullname}
              autoComplete="off"
            />
            {errors.externalFullname ? <TextHelper text={errors.externalFullname} type="error" /> : null}
          </div>
        )
      );
    }
  },
  externalReference: {
    getTitle: receiver => {
      return ['disposal', 'airtime'].includes(receiver.typeSlug) ? 'N° de téléphone' : 'Référence';
    },
    getValue: receiver => {
      return receiver.serviceSelected ? receiver.serviceSelected.externalReference : '';
    }
  },
  typeGroup: {
    getTitle: () => {
      return 'Type de transaction';
    },
    getValue: receiver => {
      return receiver ? receiver.typeTitle : '';
    }
  },
  service: {
    getTitle: () => {
      return 'Moyen de transfert';
    },
    getValue: receiver => {
      return receiver && receiver.serviceSelected && constants.servicesMap[receiver.serviceSelected.serviceSlug.toLowerCase()]
        ? constants.servicesMap[receiver.serviceSelected.serviceSlug.toLowerCase()].title
        : '';
    }
  }
};

export default ReceiverInfoDisplay;
