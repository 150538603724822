import {
  GET_WALLETS_SUCCESS,
  GET_ADMIN_WALLETS_SUCCESS,
  GET_USER_WALLETS_SUCCESS,
  GET_WALLET_DETAILS_REQUEST,
  GET_WALLET_DETAILS_SUCCESS,
  GET_ADMIN_WALLET_DETAILS_REQUEST,
  GET_ADMIN_WALLET_DETAILS_SUCCESS,
  GET_WALLET_USERS_SUCCESS,
  GET_USER_WALLETS_REQUEST,
  TOGGLE_WALLET_SELECT_OPEN,
  ARCHIVE_WALLET_SUCCESS
} from '../actionTypes';

const actions = [
  GET_WALLETS_SUCCESS,
  GET_ADMIN_WALLETS_SUCCESS,
  GET_USER_WALLETS_SUCCESS,
  GET_WALLET_DETAILS_REQUEST,
  GET_WALLET_DETAILS_SUCCESS,
  GET_ADMIN_WALLET_DETAILS_REQUEST,
  GET_ADMIN_WALLET_DETAILS_SUCCESS,
  TOGGLE_WALLET_SELECT_OPEN,
  GET_WALLET_USERS_SUCCESS,
  GET_USER_WALLETS_REQUEST,
  ARCHIVE_WALLET_SUCCESS
];

const initialState = {
  adminWallets: {count: 0, data: []},
  wallets: {wallets: [], cards: []},
  userWallets: {cards: [], wallets: []},
  lastUpdatedBalance: null,
  walletsCount: 0,
  walletDetails: null,
  adminWalletDetails: null,
  isWalletSelectOpen: false,
  users: []
};

export default [initialState, actions];
