/* eslint-disable react-hooks/exhaustive-deps */
import { useDialog, useDidUpdate, useForm } from '@julaya/common/hooks';
import { MultiStepForm, MultiStepFormMobile, PasswordModal } from '@julaya/common/ui/organismes';
import cx from 'classnames';
import { PageWrapper } from 'components/UI';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { constants } from '@julaya/common/utils';

import useSubscriptionRestrictions from 'hooks/useSubscriptionRestrictions';
import { getUserCompanies } from '../../../store/actions';
import BankDisposalSuccess from './BankDisposalSuccess';
import BankSelect from './BankSelect';
import DisposalInfo from './DisposalInfo';
import DisposalProof from './DisposalProof/index';
import MethodSelect from './MethodSelect';
import WalletSelect from './WalletSelect';

const getValidationState = (val, name) => {
  if (name === 'disposalMethod') {
    if (val === '') {
      return 'Veuillez selectionner un moyen de rechangement';
    }
  } else if (name === 'walletId') {
    if (val === null) {
      return 'Veuillez selectionner un compte à recharger';
    }
  } else if (name === 'service') {
    if (val === null) {
      return 'Veuillez selectionner une banque';
    }
  } else if (name === 'amount') {
    if (val === '') {
      return 'Veuillez enter un montant';
    }
    if (isNaN(parseInt(val))) {
      return 'Veuillez enter un montant valide';
    }
  }

  return false;
};

const BankDisposal = props => {
  const {
    makeTransactionLoading,
    makeTransactionError,
    makeTransaction,
    servicesByTypeLoading,
    servicesByTypeError,
    servicesByType,
    getServicesByType,
    transactionNotify,
    bankDisposalAmount,
    tfaActive,
    history,
    isMobile = false,
    cancelTransactions,
    cancelTransactionsLoading,
    cancelTransactionsError,
    userCompanies,
    currentCompanyId,
    
  } = props;

  const Component = isMobile ? MultiStepFormMobile : MultiStepForm;

  const typeSlug = 'bank-disposal';
  const [passwordModal, togglePasswordModal] = useDialog();

  const [values, handleChange, errors, setErrors] = useForm({
    taskUuid: uuidv4(),
    signatureKey: uuidv4(),
    disposalMethod: '',
    walletId: null,
    service: null,
    amount: bankDisposalAmount || '',
    comment: '',
    preferredBank: false
  });

  const [disposalProof, setDisposalProof] = useState(null);
  const [disposalProofError, setDisposalProofError] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [selectedWallet, setSelectedWallet] = useState();

  const [currentCompany, setCurrentCompany] = useState();

  const canSeeOnlyOne = useSubscriptionRestrictions(currentCompany, "ACCESS_ONLY_1_WALLET");

  useEffect(() => {
    if (Array.isArray(userCompanies) && userCompanies.length > 0) {
        setCurrentCompany(userCompanies.find(company => company.id === currentCompanyId) || '')
      } else {
        getUserCompanies();
      }
  }, [currentCompanyId, userCompanies])

  useEffect(() => {
    if (values.walletId) {
      getServicesByType(typeSlug, values.walletId);
    }
  }, [values.walletId]);

  useDidUpdate(() => {
    if (makeTransactionLoading === false && makeTransactionError === '') {
      setShowSuccess(true);
    }
  }, [makeTransactionLoading]);

  useDidUpdate(() => {
    if (servicesByTypeLoading === false && servicesByTypeError === '') {
      servicesByType.forEach(item => {
        if (item.preferredBank) {
          handleChange({
            target: {
              name: 'service',
              value: item
            }
          });
          handleChange({
            target: {
              name: 'preferredBank',
              type: 'checkbox',
              checked: true
            }
          });
        }
      });
    }
  }, [servicesByTypeLoading]);

  const onSubmit = (currentStep, next) => {
    if (currentStep === 0) {
      let mErrors = {};
      if (getValidationState(values.walletId, 'walletId')) {
        mErrors.walletId = true;
      }

      if (Object.keys(mErrors).length !== 0) {
        setErrors(mErrors);
      } else {
        next();
      }
    } else if (currentStep === 1) {
      let mErrors = {};
      if (getValidationState(values.disposalMethod, 'disposalMethod')) {
        mErrors.disposalMethod = true;
      }
      if (Object.keys(mErrors).length !== 0) {
        setErrors(mErrors);
      } else {
        next();
      }
    } else if (currentStep === 2) {
      let mErrors = {};
      if (getValidationState(values.service, 'service')) {
        mErrors.service = true;
      }
      if (Object.keys(mErrors).length !== 0) {
        setErrors(mErrors);
      } else {
        next();
      }
    } else if (currentStep === 3) {
      if (!disposalProof) {
        setDisposalProofError('Veuillez choisir un document justificatif');
      } else {
        if (!(disposalProof.type.startsWith('image/') || disposalProof.type.startsWith('application/pdf'))) {
          setDisposalProofError('Veuillez choisir un fichier image ou pdf');
        } else if (disposalProof.size > 2 * 1024 * 1024) {
          setDisposalProofError('Veuillez choisir un fichier qui fait moins de 2Mo.');
        } else {
          next();
        }
      }
    } else if (currentStep === 4) {
      let mErrors = {};
      if (getValidationState(values.walletId, 'walletId')) {
        mErrors.walletId = true;
      }
      if (getValidationState(values.amount, 'amount')) {
        mErrors.amount = true;
      }
      if (Object.keys(mErrors).length !== 0) {
        setErrors(mErrors);
      } else {
        togglePasswordModal();
      }
    }
  };

  const onSubmitPassword = (password, otp) => {
    let args = {
      taskUuid: values.taskUuid,
      signatureKey: values.signatureKey,
      walletId: values.walletId,
      typeSlug,
      way: 'in',
      title: 'Rechargement',
      code: password,
      items: [
        {
          amount: values.amount,
          externalReference: ' ',
          serviceSlug: values.service?.serviceSlug,
          typeSlug,
          tags: values.disposalMethod
        }
      ]
    };

    if (comment !== '') {
      args.items[0].comment = values.comment;
    }

    if (tfaActive) {
      args.otp = otp;
    }
    const attachementParams = {
      file: disposalProof,
      walletId: values.walletId
    };

    makeTransaction(args, attachementParams);
  };

  return (
    <>
      <PageWrapper className={cx({'d-flex flex-grow-1 bg-white': isMobile})} title="Recharger un compte">
        <Component
          formData={{
            values,
            handleChange,
            errors,
            setErrors,
            getValidationState,
            setDisposalProof,
            disposalProofError,
            disposalProof
          }}
          typeSlug={typeSlug}
          onSubmit={onSubmit}
          cta="Valider"
          showCancel="Retour"
          selectedWallet={selectedWallet}
          setSelectedWallet={setSelectedWallet}
          {...props}>
          <Component.Step stepCard={true} title="Choisissez compte à recharger">
            <WalletSelect canSeeOnlyOne={canSeeOnlyOne} />
          </Component.Step>

          <Component.Step stepCard={true} title="Choisissez un moyen pour recharger votre compte.">
            <MethodSelect />
          </Component.Step>

          <Component.Step stepCard={true} title="Choix de la banque de dépôt">
            <BankSelect />
          </Component.Step>

          <Component.Step stepCard={true} title="Vérification du justificatif de dépôt">
            <DisposalProof />
          </Component.Step>

          <Component.Step stepCard={true} title="Renseignez votre demande d’appro.">
            <DisposalInfo />
          </Component.Step>
        </Component>
      </PageWrapper>
      <PasswordModal
        showSuccess={showSuccess}
        open={passwordModal}
        toogleModal={togglePasswordModal}
        title="Recharger mon compte"
        icon="jla-icon-wallet"
        color="primary"
        submitText="Confirmer"
        tfaActive={tfaActive}
        onSubmit={onSubmitPassword}
        loading={makeTransactionLoading}
        errorMsg={
          constants.transactionsErrorReasonMap[makeTransactionError]
            ? constants.transactionsErrorReasonMap[makeTransactionError]
            : makeTransactionError
        }
        message="Il ne vous reste plus qu’à entrer votre mot de passe pour valider l’opération en toute sécurité."
        messageIcon="jla-icon-lock"
        successComponent={
          <BankDisposalSuccess
            cancelTransactionsLoading={cancelTransactionsLoading}
            cancelTransactionsError={cancelTransactionsError}
            cancelTransactions={cancelTransactions}
            total={values.amount}
            newBalance={selectedWallet?.balance + parseInt(values.amount)}
            values={values}
            showSuccess={showSuccess}
            transactionNotify={transactionNotify}
            isMobile={isMobile}
            history={history}
          />
        }
      />
    </>
  );
};

export default BankDisposal;
