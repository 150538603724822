import {
  HYDRATE_AUTH_STATE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  USER_INFO_SUCCESS,
  LOGOUT,
  TEMP_CODE_REQUEST,
  TEMP_CODE_SUCCESS,
  UPDATE_USER_INFO_SUCCESS,
  UPLOAD_AVATAR_SUCCESS,
  GENERATE_2FA_KEY_SUCCESS,
  ACTIVATE_2FA_KEY_SUCCESS,
  DELETE_2FA_KEY_SUCCESS,
  GET_SUBACCOUNT_SUCCESS,
  CREATE_SUBACCOUNT_SUCCESS,
  EDIT_SUBACCOUNT_SUCCESS,
  GET_SUBACCOUNT_DETAILS_SUCCESS,
  GET_PARENT_SUCCESS,
  INVALID_APP_VERSION,
  GET_USER_COMPANIES_REQUEST,
  GET_USER_COMPANIES_SUCCESS,
  GET_USER_COMPANIES_FAILURE,
  GET_USER_EXPENSE_POLICY_SUCCESS,
  SET_USER_PREFERRED_COMPANY_SUCCESS,
  ASSIGN_SUBACCOUNT_ROLE_SUCCESS
} from '../actionTypes';

const actions = [
  HYDRATE_AUTH_STATE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  USER_INFO_SUCCESS,
  LOGOUT,
  TEMP_CODE_REQUEST,
  TEMP_CODE_SUCCESS,
  UPDATE_USER_INFO_SUCCESS,
  UPLOAD_AVATAR_SUCCESS,
  GENERATE_2FA_KEY_SUCCESS,
  ACTIVATE_2FA_KEY_SUCCESS,
  DELETE_2FA_KEY_SUCCESS,
  GET_SUBACCOUNT_SUCCESS,
  CREATE_SUBACCOUNT_SUCCESS,
  EDIT_SUBACCOUNT_SUCCESS,
  GET_SUBACCOUNT_DETAILS_SUCCESS,
  GET_PARENT_SUCCESS,
  INVALID_APP_VERSION,
  GET_USER_COMPANIES_REQUEST,
  GET_USER_COMPANIES_SUCCESS,
  GET_USER_COMPANIES_FAILURE,
  GET_USER_EXPENSE_POLICY_SUCCESS,
  SET_USER_PREFERRED_COMPANY_SUCCESS,
  ASSIGN_SUBACCOUNT_ROLE_SUCCESS
];

const initialState = {
  cguValidationRequired: false,
  cardList: null,
  theme: null,
  user: undefined,
  tfaActive: null,
  tfaKey: null,
  // tfaQrcode: null,
  code: null,
  avatar: undefined,
  // isSubaccount: null,
  isConnected: undefined,
  ownPassword: undefined,
  tempUser: undefined,
  timer: 0,
  tfaActivateSuccess: false,
  subaccountCount: 0,
  subaccountList: [],
  subaccountDetails: null,
  // parentId: null,
  invalidAppVersion: false,
  companiesList: [],
  currentCompanyId: null,
  preferredCompany: null,
  subaccountAssignedWallets: [],
  canSend: false,
  canTopUp: false,
  logoutInit: false,
  expensePolicies: null
};

export default [initialState, actions];
