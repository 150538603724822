/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import {functions} from '@julaya/common/utils';
import {useDidUpdate} from '@julaya/common/hooks';
import {ErrorDisplay} from '@julaya/common/ui/molecules';
import {Text} from '@julaya/common/ui/atoms';
import {DrawerSelector} from '@julaya/common/ui/organismes';

const WalletSelect = props => {
  const {
    values,
    handleChange,
    errors,
    rapidTransferWallets,
    getUserWallets,
    rapidTransferWalletsLoading,
    rapidTransferWalletsError,
    getValidationState,
    selectedWallet,
    setSelectedWallet,
    setNextButtonDisabled
  } = props;

  useEffect(() => {
    if (values.walletId) {
      setNextButtonDisabled(false);
    } else {
      setNextButtonDisabled(true);
    }
  }, [values.walletId]);

  useEffect(() => {
    getUserWallets(
      {
        type: 'WALLET',
        access: 'SEND'
      },
      'rapid_transfer'
    );
  }, []);

  useDidUpdate(() => {
    if (rapidTransferWalletsLoading === false && rapidTransferWalletsError === '' && rapidTransferWallets?.length === 1) {
      setSelectedWallet({
        label: `${rapidTransferWallets[0].label} - ${rapidTransferWallets[0].reference}`,
        id: rapidTransferWallets[0].id,
        tag: 'jla-icon-wallet',
        balance: rapidTransferWallets[0].balance
      });
      handleChange({
        target: {
          name: 'walletId',
          value: rapidTransferWallets[0].id
        }
      });
    }
  }, [rapidTransferWalletsLoading]);

  if (rapidTransferWalletsLoading) {
    return (
      <div className="d-flex flex-column flex-grow-1 justify-content-center align-items-center mb-3  mt-3">
        <span
          className="spinner-border spinner-border-lg text-primary"
          style={{width: '3rem', height: '3rem'}}
          role="status"
          aria-hidden="true"></span>
      </div>
    );
  }
  if (rapidTransferWalletsError || rapidTransferWallets?.length === 0) {
    return (
      <div className="mt-3">
        <ErrorDisplay
          errorText={rapidTransferWalletsError || 'Aucun compte trouvé'}
          onClick={() =>
            getUserWallets(
              {
                type: 'WALLET',
                access: 'SEND'
              },
              'rapid_transfer'
            )
          }
        />
      </div>
    );
  }

  return (
    <div className="transfer-detail-info">
      {rapidTransferWallets && (
        <DrawerSelector
          placeholder="Sélectionnez un compte à débiter"
          selectedItem={selectedWallet}
          options={rapidTransferWallets
            .filter(wallet => !wallet.isCard)
            .map(wallet => {
              return {
                value: wallet,
                tag: 'jla-icon-wallet',
                label: `${wallet.label} - ${wallet.reference}`,
                subtitle: `${functions.formatMoney(wallet.balance)}`
              };
            })}
          onSelect={v => {
            setSelectedWallet({
              label: `${v.label} - ${v.reference}`,
              tag: 'jla-icon-wallet',
              id: v.id,
              balance: v.balance
            });
            handleChange({
              target: {
                name: 'walletId',
                value: v.id
              }
            });
            setNextButtonDisabled(false);
          }}
          drawerTitle="Compte à débiter"
          drawerIcon="jla-icon-wallet"
          drawerColor="primary"
        />
      )}
      {errors.walletId && <Text color="error">{getValidationState(values.walletId, 'walletId')}</Text>}
    </div>
  );
};

export default WalletSelect;
