import * as actions from "@julaya/common/store/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import selector from "@julaya/common/store/selectors";

import Component from "./components";

const mapStateToProps = ({ auth, loading, error, wallets }) => {
  return {
    ...selector(
      {
        landingWallets: "GET_LANDING_WALLETS",
      },
      { loading, error }
    ),

    user: auth.user,

    userCompanies: auth.companiesList,
    currentCompanyId: auth.currentCompanyId,
    landingWallets: wallets?.landingWallets?.wallets || [],
    landingWalletTypeParams: wallets.landingWallets?.type || null,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
