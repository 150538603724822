/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import {functions} from '@julaya/common/utils';
import {AmountInput, Input} from '@julaya/common/ui/atoms';
import {methodsList} from '../BankDisposal.constants';

import './DisposalInfo.scss';

const DisposalInfo = props => {
  const {values, handleChange, errors, getValidationState, selectedWallet, setNextButtonDisabled} = props;

  useEffect(() => {
    if (values.amount) {
      setNextButtonDisabled(false);
    } else {
      setNextButtonDisabled(true);
    }
  }, [values.amount]);

  const method = methodsList.find(m => m.slug === values.disposalMethod);

  return (
    <div className="bank-disposal-info">
      <div className="bank-disposal-info__metadata">
        <div className="title">Méthode choisie</div>
        <div className="data">{method?.title}</div>
      </div>
      <div className="bank-disposal-info__metadata">
        <div className="title">Délais estimé</div>
        <div className="data">{method?.description}</div>
      </div>
      <hr />
      <div className="bank-disposal-info__metadata">
        <div className="title">Compte à recharger</div>
        <div className="data">{selectedWallet?.label}</div>
      </div>
      <div className="bank-disposal-info__metadata">
        <div className="title">Solde disponible</div>
        <div className="data">{selectedWallet ? functions.formatMoney(selectedWallet.balance) : '-'}</div>
      </div>
      <div className="bank-disposal-info__metadata">
        <div className="title">Banque partenaire</div>
        <div className="data">
          {values.service ? (
            <>
              <span> {values.service.serviceTitle}</span>
              <img src={values.service?.options?.logo} />
            </>
          ) : (
            '-'
          )}
        </div>
      </div>

      <AmountInput
        label="Montant à recharger"
        value={values.amount}
        hasError={errors.amount}
        errorMsg={errors.amount ? getValidationState(values.amount, 'amount') : ''}
        handleChange={handleChange}
        name="amount"
      />

      <Input id="comment" type="text" name="comment" placeholder="Ex : Remboursement" value={values.comment} onChange={handleChange} />
    </div>
  );
};

export default DisposalInfo;
