import React, {useMemo} from 'react';
import cx from 'classnames';

import './Checkbox.scss';

const Checkbox = props => {
  const {id, className, heading, description, label, hintText, ...attrs} = props;

  const componentId = useMemo(() => {
    return id || `jla-checkbox-${(Math.random() + 1).toString(36).substring(7)}`;
  }, []);

  const classList = cx('jla-checkbox', className);

  return (
    <>
      {label && (
        <div
          className={cx('jla-checkbox__label', {
            'jla-checkbox__label--disabled': !!attrs.disabled
          })}>
          <label htmlFor={componentId}>{label}</label>
          {hintText && <div className="jla-checkbox__label-hint">{hintText}</div>}
        </div>
      )}
      <div className={classList}>
        <input type="checkbox" className="jla-checkbox__input" id={componentId} {...attrs} />
        <label className="jla-checkbox__ui" htmlFor={componentId}></label>
        {heading && (
          <div className="jla-checkbox__heading-wrap">
            <label className="jla-checkbox__heading" htmlFor={componentId}>
              {heading}
            </label>
            {description && <div className="jla-checkbox__heading-descr">{description}</div>}
          </div>
        )}
      </div>
    </>
  );
};

export default Checkbox;
