import React from 'react';
import cx from 'classnames';

import Cell from '../Cell/Cell';


import './Body.scss';

const Body = props => {
  const {headers, dataTable} = props;
  return (
    <tbody className="jla-table-body">
      {dataTable.map((data, idxRow) => {
        return (
          <tr key={`${idxRow}`} className={cx({clickable:  typeof data.onRowClicked === 'function'})} onClick={data.onRowClicked}>
            {headers.map((cellConfig, idxHead) => {
              return (
                <td
                  key={`${idxHead}-${idxRow}`}
                  style={{
                    width: `${!!cellConfig.width ? `${cellConfig.width}` : 'unset'}`,
                    textAlign: `${!!cellConfig.align ? `${cellConfig.align}` : 'left'}`
                  }}>
                    <Cell {...props} data={data} cellConfig={cellConfig} />
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

export default Body;
