/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import moment from "moment";
import copy from "copy-to-clipboard";
import cx from "classnames";
import { functions } from "@julaya/common/utils";

const logoCardJulaya =
  require(`@julaya/common/assets/images/operators/mastercard.png`).default;

const cardBg =
  require(`@julaya/common/assets/images/cards/gold-texture.png`).default;

const CardInfo = (props) => {
  const { prepaidCardDetails, selectedCard } = props;

  const [cardInfo, setCardInfo] = useState({
    active: selectedCard.status === "activated",
    balance: selectedCard.balance,
    expiresMonth: "**",
    expiresYear: "**",
    cvc: "***",
    cardNumber: "**** **** **** " + (selectedCard.pan || "****"),
    label: selectedCard.label,
  });

  useEffect(() => {
    if (prepaidCardDetails) {
      const expiryMonth = moment(prepaidCardDetails.expiryDate).format("MM");
      const expiryYear = moment(prepaidCardDetails.expiryDate).format("YY");
      setCardInfo({
        balance: prepaidCardDetails.balance,
        active: prepaidCardDetails.isActive,
        expiresMonth: expiryMonth,
        expiresYear: expiryYear,
        cvc: prepaidCardDetails.cvv2,
        cardNumber:
          prepaidCardDetails?.cardNumber?.match(/.{4}/g).join(" ") ||
          selectedCard.pan,
      });
    } else {
      setCardInfo({
        active: selectedCard.isActive,
        balance: selectedCard.balance,
        expiresMonth: "**",
        expiresYear: "**",
        cvc: "***",
        cardNumber: "**** **** **** " + (selectedCard.pan || "****"),
      });
    }
  }, [JSON.stringify(prepaidCardDetails)]);

  return (
    <div className="card-info-container mt-3">
      <div className="card-info" style={{ backgroundImage: `url(${cardBg})` }}>
        {/* Is blocked */}
        {!cardInfo.active && (
          <div className="blocked-card">
            <div>
              <i className="icon-lock mb-2 d-block"></i>
              <div>Carte désactivée</div>
            </div>
          </div>
        )}

        <div
          className={cx("card-info-inner", {
            blocked: !cardInfo.active,
          })}
        >
          <div className="header d-flex justify-content-between align-items-center">
            <div className={cx("card-status", { active: cardInfo.active })}>
              {cardInfo.active && <div className="h5 mb-0">Carte active</div>}
            </div>
            <div className="amount">
              <span className="h2 mb-0 mr-1">
                {functions.formatMoney(cardInfo.balance, null)}{" "}
              </span>
              <span>FCFA</span>
            </div>
          </div>
          <div>
            <div className="mb-2">
              <div className="content d-flex">
                {cardInfo.cardNumber.split(" ").map((data, i) => {
                  return (
                    <h3 className="mr-4 mb-3" key={i}>
                      {data}
                    </h3>
                  );
                })}
                {!!prepaidCardDetails && (
                  <a
                    href="#julaya"
                    title="Cliquer pour copier"
                    onClick={(e) => {
                      e.preventDefault();
                      copy(prepaidCardDetails.cardNumber);
                    }}
                  >
                    <i className="icon-copy h6 ml-3"></i>
                  </a>
                )}
              </div>
            </div>
            <div className="card-footer">
              <div className="d-flex">
                <div className="mr-4">
                  <h5 className="font-weight-normal">EXP</h5>
                  <h5>
                    {cardInfo.expiresMonth}/{cardInfo.expiresYear}
                  </h5>
                </div>

                <div>
                  <h5 className="font-weight-normal">CVV</h5>
                  <h5>{cardInfo.cvc}</h5>
                </div>
              </div>

              <div
                className="logo"
                style={{ backgroundImage: `url(${logoCardJulaya})` }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardInfo;
