/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import cx from "classnames";

const Overlay = ({
  children,
  open,
  toggle,
  backdrop = true,
  className = "",
}) => {
  const handleClick = (e) => {
    if (e.target === e.currentTarget && backdrop) {
      toggle();
    }
  };

  useEffect(() => {
    const el = document.querySelector(".overlay");
    if (el) {
      el.addEventListener("click", handleClick);

      return () => {
        el.removeEventListener("click", handleClick);
      };
    }
  }, []);

  return (
    <div
      className={cx(`overlay`, {
        open: open,
      })}
    >
      <div className={`overlay-inner ${className}`}>{children}</div>
    </div>
  );
};

Overlay.Content = ({ className = "", children }) => {
  return <div className={`overlay-content ${className}`}>{children}</div>;
};
Overlay.Header = ({ className = "", children }) => {
  return <header className={`${className}`}>{children}</header>;
};
Overlay.Footer = ({ className = "", children }) => {
  return <footer className={`${className}`}>{children}</footer>;
};

export default Overlay;
