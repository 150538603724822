import {LOGOUT} from '../actionTypes';
const base =
  (initialState, actions, resetOnLogout = true) =>
  (state = initialState, action) => {
    const {type, payload} = action;
    if (type === LOGOUT && resetOnLogout) {
      return {...initialState};
    } else if (actions.includes(type) && typeof payload === 'object') {
      return {...state, ...payload};
    }
    return state;
  };
export default base;
