/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';

import TransactionCard from 'components/UI/Tasks/TransactionCard';
import {JSpinner, ErrorDisplay, Pagination, PageWrapper} from 'components/UI';

const TaskTransactions = props => {
  const {
    getHistory,
    historyLoading,
    historyError,
    historyData,
    historyCount,
    match: {
      params: {batchId, walletType = 'wallet', walletId}
    }
  } = props;

  const [page, setPage] = useState(1);

  // TODO : Add support for multiple Pages , needs optimisation

  useEffect(() => {
    const historyParams = {
      minDate: '',
      maxDate: '',
      status: [],
      query: '',
      transactionType: [],
      initiator: [],
      validator: [],
      page,
      walletType: walletType?.toUpperCase(),
      taskIds: [batchId]
    };
    getHistory(walletId, historyParams);
  }, [page]);

  return (
    <PageWrapper>
      <div className="task-transfers mb-5">
        {historyLoading ? (
          <div className="mt-4 w-100 d-flex align-items-center justify-content-center">
            <JSpinner />
          </div>
        ) : // In case of an Error , the error lsg will be shown to the user with retry CTA
        historyError ? (
          <div className="mt-4 w-100 d-flex align-items-center justify-content-center">
            <ErrorDisplay
              errorText={historyError}
              onClick={() =>
                getHistory(walletId, {
                  minDate: '',
                  maxDate: '',
                  status: [],
                  query: '',
                  transactionType: [],
                  initiator: [],
                  validator: [],
                  page: 1,
                  walletType: walletType?.toUpperCase(),
                  taskIds: [batchId]
                })
              }
            />
          </div>
        ) : historyData ? (
          historyData.map((item, index) => {
            return <TransactionCard key={index} item={item} />;
          })
        ) : (
          <div className="mt-4 w-100 d-flex align-items-center justify-content-center">
            <JSpinner />
          </div>
        )}
      </div>
      {/* TODO: optimize that pagination */}
      {page * 20 < historyCount && (
        <footer className="footer justify-content-between p-2" style={{left: '1rem'}}>
          <div className="pl-2">20 par page</div>
          <div>
            <Pagination count={historyCount} length={historyData.length} loading={historyLoading} page={page} setPage={setPage} />
          </div>
        </footer>
      )}
    </PageWrapper>
  );
};
export default TaskTransactions;
