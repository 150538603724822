const loadingData = (state = {}, action) => {
  const {type} = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type);

  if (!matches) return state;

  const [, requestName, requestState] = matches;
  const currentState = state[requestName] || 0;
  if (requestState === 'REQUEST') {
    return {
      ...state,
      [requestName]: currentState + 1
    };
  } else {
    return {
      ...state,
      [requestName]: currentState > 0 ? currentState - 1 : 0
    };
  }
};

export default loadingData;
