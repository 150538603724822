import React, {useState, useMemo} from 'react';
import cx from 'classnames';

// TODO import { useIntl } from "react-intl";

import Icon from '../../DataDisplay/Icon/Icon';
import TextHelper from '../../DataDisplay/TextHelper/TextHelper';

import './Text.scss';

const Text = props => {
  const {
    id,
    prefixIcon,
    suffixIcon,
    feedback,
    warning,
    error,
    success,
    className,
    label,
    hintText,
    white = false,
    switchable = false,
    ...attrs
  } = props;

  const [viewPassword, setViewPassword] = useState(false);

  const componentId = useMemo(() => {
    return id || `jla-text-${(Math.random() + 1).toString(36).substring(7)}`;
  }, []);

  // TODO const { formatMessage: t } = useIntl();

  // prepare prefix and suffix icons
  let prefix = null;
  let suffix = null;

  if (prefixIcon) {
    if (typeof prefixIcon === 'string') {
      prefix = <Icon name={prefixIcon} className="jla-text__prefix" />;
    } else {
      prefix = prefixIcon;
    }
  }

  if (suffixIcon) {
    if (typeof suffixIcon === 'string') {
      suffix = <Icon name={suffixIcon} className="jla-text__suffix" />;
    } else {
      suffix = suffixIcon;
    }
  }

  // prepare view password
  if (attrs.type === 'password' && switchable) {
    suffix = (
      <a
        href="julaya"
        className="jla-text__suffix"
        onClick={e => {
          e.preventDefault();
          setViewPassword(!viewPassword);
        }}>
        <Icon name={viewPassword ? 'eye-off' : 'eye'} className="jla-text__suffix" onClick={() => setViewPassword(!viewPassword)} />
      </a>
    );
    attrs.type = viewPassword ? 'text' : 'password';
  }

  // prepare feedback
  let feedbackType = '';
  let feedbackText = null;
  if (feedback) {
    feedbackText = feedback;
  }

  /* feedback icons */
  if (error) {
    feedbackText = typeof error === 'string' ? error.trim() : '';
    feedbackType = 'error';
  } else if (warning) {
    feedbackText = typeof warning === 'string' ? warning.trim() : '';
    feedbackType = 'warning';
  } else if (success) {
    feedbackText = typeof success === 'string' ? success.trim() : '';
    feedbackType = 'success';
  }

  // prepare text classes
  const classList = cx('jla-text', className, {
    [`jla-text--warning`]: !!warning,
    [`jla-text--error`]: !!error,
    [`jla-text--success`]: !!success,
    [`jla-text--bg-white`]: !!white,
    [`jla-text--disabled`]: !!attrs.disabled
  });

  return (
    <>
      {label && (
        <div
          className={cx('jla-text__label', {
            'jla-text__label--disabled': !!attrs.disabled
          })}>
          <label htmlFor={componentId}>{label}</label>
          {hintText && <div className="jla-text__label-hint">{hintText}</div>}
        </div>
      )}
      <div className={classList}>
        {prefix}
        <input
          {...attrs}
          id={componentId}
          type={attrs.type || 'text'}
          className={cx('jla-text__input', {
            'jla-text__input--prefix': !!prefix,
            'jla-text__input--suffix': !!suffix
          })}
        />
        {suffix}
      </div>
      <TextHelper text={feedbackText} type={feedbackType} className="jla-text__feedback" />
    </>
  );
};

export default Text;
