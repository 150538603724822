/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useRef} from 'react';
import cx from 'classnames';
import {functions} from '@julaya/common/utils';
import {PageWrapper} from 'components/UI';
import avatar from '@julaya/common/assets/images/app/default-avatar.png';
import {useForm, useDidUpdate} from '@julaya/common/hooks';

const getValidationState = (val, name) => {
  if (name === 'firstname' || name === 'lastname') {
    if (val === '') {
      return 'Champs obligatoire';
    }
  } else if (name === 'email') {
    if (!functions.validateEmail(val)) {
      return 'Adresse mail invalide';
    }
  }

  return false;
};

const ProfileEdit = ({user, updateUserInfoLoading, updateUserInfoError, updateUserInfo, uploadAvatarLoading, uploadAvatarError, uploadAvatar}) => {
  const userAvatar = user.avatar !== null ? user.avatar : avatar;
  const [updateUserInfoSuccess, setUpdateUserInfoSuccess] = useState('');
  const [uploadAvatarSuccess, setUploadAvatarSuccess] = useState('');
  const {firstname, lastname, email, phone, countryCode} = user;
  const [values, handleChange, errors, setErrors] = useForm({
    firstname,
    lastname,
    email
  });

  const inputRef = useRef(null);

  useDidUpdate(() => {
    if (updateUserInfoLoading === false && updateUserInfoError === '') {
      setUpdateUserInfoSuccess('Vos informations ont été mises à jour avec succès');
    }
  }, [updateUserInfoLoading, updateUserInfoError]);

  useDidUpdate(() => {
    if (uploadAvatarLoading === false && uploadAvatarError === '') {
      setUploadAvatarSuccess('Vos informations ont été mises à jour avec succès');
    }
  }, [uploadAvatarLoading, uploadAvatarError]);

  const onSubmit = e => {
    e.preventDefault();
    let mErrors = {};
    if (getValidationState(values.firstname, 'firstname')) {
      mErrors.firstname = true;
    }
    if (getValidationState(values.lastname, 'lastname')) {
      mErrors.lastname = true;
    }
    if (values.email !== '' && getValidationState(values.email, 'email')) {
      mErrors.email = true;
    }

    setUpdateUserInfoSuccess('');
    setUploadAvatarSuccess('');

    if (Object.keys(mErrors).length !== 0) {
      setErrors(mErrors);
    } else {
      const args = {
        firstname: values.firstname,
        lastname: values.lastname
      };
      if (values.email !== '') {
        args.email = values.email;
      }
      updateUserInfo(args);
    }
  };

  const onFileLoaded = e => {
    e.preventDefault();
    var files = e.target.files;

    setUpdateUserInfoSuccess('');
    setUploadAvatarSuccess('');

    const data = {
      avatar: files[0]
    };
    uploadAvatar(data);
    e.target.value = null;
  };

  return (
    <PageWrapper>
      <div className="px-1 py-1">
        <div className="mt-3 d-flex justify-content-center">
          <img src={userAvatar} alt="avatar" className="avatar-lg img-thumbnail" />
        </div>
        <div className="d-flex justify-content-center" style={{marginTop: '-20px', marginRight: '-80px'}}>
          <button type="button" className="rounded bg-white border p-2" onClick={() => inputRef.current.click()}>
            <i className="jla-icon-camera h5 m-1"></i>
          </button>
          <input ref={inputRef} type="file" className="hidden-file-input" onChange={onFileLoaded} />
        </div>
      </div>

      <form className="needs-validation d-flex flex-column flex-grow-1" onSubmit={onSubmit}>
        <div className="d-flex flex-column flex-grow-1">
          <div className="d-flex">
            <div className="form-group mr-3">
              <label className="text-muted" htmlFor="lastname">
                Nom
              </label>
              <input
                id="lastname"
                className={cx('form-control bg-white rounded', {
                  'is-invalid': errors.lastname
                })}
                type="text"
                name="lastname"
                placeholder="Nom"
                value={values.lastname}
                onChange={handleChange}
                autoComplete="off"
                required
              />
              <div className="invalid-feedback">{getValidationState(values.lastname, 'lastname')}</div>
            </div>
            <div className="form-group">
              <label className="text-muted" htmlFor="firstname">
                Prénom(s)
              </label>
              <input
                id="firstname"
                className={cx('form-control bg-white rounded', {
                  'is-invalid': errors.firstname
                })}
                type="text"
                name="firstname"
                placeholder="Prénoms"
                value={values.firstname}
                onChange={handleChange}
                autoComplete="off"
                required
              />
            </div>
            <div className="invalid-feedback">{getValidationState(values.firstname, 'firstname')}</div>
          </div>
          <div className="form-group">
            <label className="text-muted" htmlFor="email">
              Email
            </label>

            <input
              id="email"
              className={cx('form-control bg-white rounded', {
                'is-invalid': errors.email
              })}
              type="email"
              name="email"
              placeholder="email@email.com"
              value={values.email}
              onChange={handleChange}
              autoComplete="off"
              required
            />
            <div className="invalid-feedback">{getValidationState(values.email, 'email')}</div>
          </div>
          <div className="form-group">
            <label className="text-muted" htmlFor="email">
              Numéro de téléphone
            </label>

            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text pr-1 pl-2 bg-white" id="phone-input">
                  {countryCode && countryCode.replace('00', '+')}
                </span>
              </div>
              <input
                id="phone"
                className="form-control bg-white"
                type="text"
                name="phone"
                disabled={true}
                aria-describedby="phone-input"
                value={phone}
              />
              <div className="input-group-append">
                <span className="input-group-text bg-white text-muted"></span>
              </div>
            </div>
          </div>

          {updateUserInfoError && (
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center">
                <i className="icon-warning text-danger h4 pr-2 m-0"></i>
                <span className="text-danger">{updateUserInfoError}</span>
              </div>
            </div>
          )}
          {updateUserInfoSuccess && (
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center">
                <i className="text-success icon-info-circle h4 pr-2 m-0"></i>
                <span className="text-success">{updateUserInfoSuccess}</span>
              </div>
            </div>
          )}

          {uploadAvatarError && (
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center">
                <i className="icon-warning text-danger h4 pr-2 m-0"></i>
                <span className="text-danger">{uploadAvatarError}</span>
              </div>
            </div>
          )}

          {uploadAvatarSuccess && (
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center">
                <i className="text-success icon-info-circle h4 pr-2 m-0"></i>
                <span className="text-success">{uploadAvatarSuccess}</span>
              </div>
            </div>
          )}
        </div>
        <button type="submit" className="btn btn-lg btn-block btn-primary mt-4" disabled={updateUserInfoLoading || uploadAvatarLoading}>
          {(updateUserInfoLoading || uploadAvatarLoading) && (
            <>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </>
          )}
          <span className="text-bold text-white">Terminer</span>
        </button>
      </form>
    </PageWrapper>
  );
};

export default ProfileEdit;
