/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from 'react';
import {useDidUpdate} from '@julaya/common/hooks';

const VisualFeedback = props => {
  const {operationsCount, total, userBalance, apiCheckLoading, invalidOperationsCount, apiCheckProgess} = props;
  const [verifErrors, setVerifErrors] = useState(0);

  useDidUpdate(() => {
    if (apiCheckLoading === false) {
      setVerifErrors(invalidOperationsCount);
    }
  }, [apiCheckLoading, invalidOperationsCount]);

  let percent = (apiCheckProgess && (apiCheckProgess.current * 100) / apiCheckProgess.total) || 1;

  if (apiCheckLoading && apiCheckProgess) {
    return (
      <div className="feedback min-width feedback-warning d-flex align-items-center mb-3">
        <div className="mr-3">
          Vérification ({apiCheckProgess.current}/{apiCheckProgess.total})
        </div>
        <div className="loader d-block" style={{height: 10}}>
          <div className="loader-inner" style={{width: `${percent}%`}}></div>
        </div>
      </div>
    );
  }

  if (parseInt(total) > parseInt(userBalance)) {
    return (
      <div className="feedback min-width feedback-danger d-flex align-items-center mb-3">
        <i className="jla-icon-x-circle"></i>
        <span>Solde insuffisant</span>
      </div>
    );
  }

  if (verifErrors > 0) {
    return (
      <div className="feedback min-width feedback-danger d-flex align-items-center mb-3">
        <i className="jla-icon-x-circle"></i>
        <span>{verifErrors} opération(s) à vérifier</span>
      </div>
    );
  }

  if (operationsCount === 0) {
    return (
      <div className="feedback min-width feedback-gray-dark d-flex align-items-center mb-3">
        <i className="jla-icon-check-circle"></i>
        <span>Aucune transaction à vérifier</span>
      </div>
    );
  }

  return (
    <div className="feedback min-width feedback-success d-flex align-items-center mb-3">
      <i className="jla-icon-check-circle"></i>
      <span>Les opérations sont valides!</span>
    </div>
  );
};

export default VisualFeedback;
