import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as actions from '@julaya/common/store/actions';
import selector from '@julaya/common/store/selectors';

import Component from './components';

const mapStateToProps = ({auth, history, loading, error, transaction, wallets}) => {
  return {
    ...selector(
      {
        attachReceipt: 'ATTACH_RECEIPT',
        attachDepositReceipt: 'ATTACH_DEPOSIT_RECEIPT',
        editTransactionParams: 'EDIT_TRANSACTION_PARAMS',
        editComment: 'EDIT_TRANSACTION_COMMENT'
      },
      {loading, error}
    ),
    wallet: wallets.walletDetails,
    tfaActive: auth.tfaActive,
    user: auth.user,
    selectedItem: history.selectedItem,
    attachedReceipt: history.attachedReceipt,
    attachedDepositReceipt: history.attachedDepositReceipt,
    transactionTypeGroups: transaction.transactionTypeGroups
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
