import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "@julaya/common/store/actions";
import { loadingSelector, errorSelector } from "@julaya/common/store/selectors";

import Component from "./components";

const mapStateToProps = ({ auth, loading, error }) => {
  return {
    loginError: errorSelector(["LOGIN"])({ error }),
    loginLoading: loadingSelector(["LOGIN"])({ loading }),

    isConnected: auth.isConnected,
    ownPassword: auth.ownPassword,

    tempUser: auth.tempUser,
    user: auth.user,
    timer: auth.timer,

    passwordUpdateLoading: loadingSelector(["PASSWORD_UPDATE"])({ loading }),
    passwordUpdateError: errorSelector(["PASSWORD_UPDATE"])({ error }),

    tempCodeReqLoading: loadingSelector(["TEMP_CODE"])({ loading }),
    tempCodeReqError: errorSelector(["TEMP_CODE"])({ error }),

    tempLoginLoading: loadingSelector(["TEMP_LOGIN"])({ loading }),
    tempLoginError: errorSelector(["TEMP_LOGIN"])({ error }),
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
