import React, {useState} from 'react';
import cx from 'classnames';
import moment from 'moment';

import {Button, Text} from '@julaya/common/ui/atoms';
import {Drawer} from '@julaya/common/ui/molecules';
import './InputFile.scss';
import {Tag} from '../../../system';

const downloadIcon = require('@julaya/common/assets/images/app/download.png').default;

const InputFile = ({onSetFile}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [file, setFile] = useState(null);
  const hiddenFileInput = React.useRef(null);
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event, file) => {
    const fileUploaded = file ? file : event.target.files[0];
    if (fileUploaded) {
      setPreviewFile({
        url: URL.createObjectURL(fileUploaded),
        name: fileUploaded.name,
        lastModified: fileUploaded.lastModified,
        lastModifiedDate: fileUploaded.lastModifiedDate,
        obj: fileUploaded
      });
      setDrawer(true);
    }
  };

  const onDragOver = e => {
    e.stopPropagation();
    e.preventDefault();
    setIsDragging(true);
  };

  const onDragEnter = e => {
    e.stopPropagation();
  };

  const onDragLeave = e => {
    e.stopPropagation();
    setIsDragging(false);
  };

  const onFileDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    let file = e.dataTransfer.files[0];
    handleChange(e, file);
  };

  return (
    <>
      <div
        onClick={handleClick}
        className={cx('input-file_container', {
          [`input-file_container--drag-over`]: isDragging
        })}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onFileDrop}>
        {file ? (
          <>
            <Button
              variant="badge"
              status="primary"
              text="Modifier le téléchargement"
              full={true}
              action={() => {
                setFile(null);
                setPreviewFile(null);
              }}
            />
            <div className={cx('input-file_container__preview')}>
              <div>
                <img src={file && file.url} />
                <div>
                  <Text bold>{file && file.name}</Text>
                  <Text>{file && file.lastModified && moment(file.lastModified).format('LLLL')}</Text>
                </div>
              </div>
              <i className="jla-icon-pencil"></i>
            </div>
          </>
        ) : (
          <div className={cx('input-file_container__input')}>
            <img src={downloadIcon} alt="Download" style={{width: '32px', height: '32px'}} />
            <Text color="primary" bold>
              Choisir à travers ma galerie
            </Text>
          </div>
        )}

        <input type="file" ref={hiddenFileInput} onChange={handleChange} style={{display: 'none'}} />
      </div>
      <Drawer setOpen={setDrawer} open={drawer} title="Ajout du justificatif" icon="jla-icon-photo" color="primary">
        <Drawer.Content>
          <div className="input-file-preview_container">
            <img className="input-file-preview_container__img" src={previewFile && previewFile.url} />
            <Text bold className="input-file-preview_container__name">
              <b>{previewFile && previewFile.name}</b>
            </Text>
            <Text className="input-file-preview_container__content">
              Veillez à ce que votre document soit suffisament lisible avant de procéder à l’ajout.
            </Text>
          </div>
        </Drawer.Content>
        <Drawer.Footer align="end">
          <div className="d-flex w-100 flex-column">
            <Button
              className="mb-3"
              status="secondary"
              onClick={() => {
                setPreviewFile(null);
                setDrawer(false);
              }}
              text="Modifier"
              full
            />
            <Button
              action={() => {
                setFile(previewFile);
                setDrawer(false);
                onSetFile(previewFile.obj);
              }}
              status="primary"
              className="icon"
              size="large"
              text="Ajouter le justificatif"
              full
            />
          </div>
        </Drawer.Footer>
      </Drawer>
    </>
  );
};

export default InputFile;
