import * as actions from '@julaya/common/store/actions';
import selector from '@julaya/common/store/selectors';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Component from './components';

const mapStateToProps = ({auth, transaction, loading, error, wallets}) => {
  return {
    ...selector(
      {
        summary: 'GET_SUMMARY',
        makeTransaction: 'MAKE_TRANSACTION',
        userWallets: 'GET_USER_WALLETS',
        getServices: ['GET_SERVICES_BY_EXTERNAL_REFERENCE', 'GET_LATEST_RECEIVERS'],
        rapidTransferWallets: 'GET_RAPID_TRANSFER_WALLETS'
      },
      {loading, error}
    ),

    user: auth.user,
    companyId: auth.currentCompanyId,
    userCompanies: auth.companiesList,

    expensePolicies: auth.expensePolicies?.policies,
    summaryObj: transaction.summary,
    rapidTransferData: transaction.rapidTransferData,
    transactionNotify: transaction.transactionNotify,
    tfaActive: auth.tfaActive,
    rapidTransferWallets: wallets.rapid_transferWallets?.wallets,
    services: transaction.services,
    latestReceivers: transaction.latestReceivers,
    servicesTags: transaction.servicesTags
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
