import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "@julaya/common/store/actions";
import selector from "@julaya/common/store/selectors";

import Component from "./components";

const mapStateToProps = ({
  auth,
  history,
  loading,
  error,
  wallets,
  prepaidCards,
}) => {
  return {
    ...selector(
      {
        history: "GET_HISTORY",
        walletDetails: "GET_WALLET_DETAILS",
        getPrepaidCardDetails: "GET_PREPAID_CARD_DETAILS",
        stopPrepaidCard: "STOP_PREPAID_CARD",
        updatePrepaidCardPin: "UPDATE_PREPAID_CARD_PIN",
      },
      { loading, error }
    ),

    historyData: history.history,
    historyCount: history.historyCount,
    selectedItem: history.selectedItem,
    wallet: wallets.walletDetails,
    prepaidCardDetails: prepaidCards.prepaidCardDetails,
    tfaActive: auth.tfaActive,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
