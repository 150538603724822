import {useEffect, useMemo, useState} from 'react';

import {functions, network} from '@julaya/common/utils';
import {Alert, Bullet, Icon, Typography} from '@julaya/common/system';

const unitOfTimes = {
  day: 'jour',
  week: 'semaine',
  month: 'mois'
};

export function RapidTransferDetailsAlert({expensePolicies, selectedWallet, companyId}) {
  const [wallet, setWallet] = useState();

  useEffect(() => {
    const fetchWallet = async () => {
      if (selectedWallet) {
        const res = await network.request(`/wallets/${selectedWallet.id}`, 'GET');
        setWallet(res);
      }
    };
    fetchWallet();
  }, [selectedWallet]);

  const expensePolicy = useMemo(() => {
    return selectedWallet?.id
      ? expensePolicies
          ?.filter(expensePolicy => expensePolicy.companyId === companyId)
          ?.find(expensePolicy => !expensePolicy.excludedWallets?.some(excludedWallet => excludedWallet.id === selectedWallet?.id))
      : null;
  }, [selectedWallet, expensePolicies]);

  const expenseWalletData = useMemo(
    () => (expensePolicy && selectedWallet?.id ? expensePolicy.spentMoneyPerWallet.find(item => item.walletId === selectedWallet.id) : null),
    [expensePolicy, selectedWallet]
  );

  const spentMoney = expenseWalletData?.spentMoney ?? 0;
  const remainingBalance = expensePolicy ? expensePolicy?.limitPerUnitOfTime - spentMoney : null;

  if (wallet?.canSendWoLimits) {
    return null;
  } else if (wallet?.canInit) {
    if (!expensePolicy) {
      return (
        <Alert color="warning">
          <Bullet bullet={<Icon color="warning" name="information-circle" size="h5" />} type={'icon'}>
            <Typography lead>Une fois validé, retrouvez votre transfert dans la section “Tâches, en attente de signature(s)”</Typography>
            <Typography>Dès que le nombre de signatures requis par votre entreprise sera atteint, le transfert sera envoyé.</Typography>
          </Bullet>
        </Alert>
      );
    } else if (remainingBalance) {
      return (
        <Alert color="warning">
          <Bullet bullet={<Icon color="warning" name="information-circle" size="h5" />} type={'icon'}>
            <Typography lead>Transfert sans approbation autorisés</Typography>
            <Typography>{`Vous êtes autorisé à effectuer des transferts de ${functions.formatMoney(
              expensePolicy.limitPerOperation
            )} par transaction unitaire, dans la limite de ${functions.formatMoney(expensePolicy.limitPerUnitOfTime)} par ${
              unitOfTimes[expensePolicy.unitOfTime]
            }. Si vous dépassez cette limite, votre transfert sera effectué comme une tâche en attente de signature(s).`}</Typography>
          </Bullet>
        </Alert>
      );
    }
    return (
      <Alert color="warning">
        <Bullet bullet={<Icon color="warning" name="information-circle" size="h5" />} type={'icon'}>
          <Typography lead>Limite de dépense sans approbation dépassée</Typography>
          <Typography>
            Votre transfert rapide sera effectué comme une tâche. Retrouvez votre transfert dans la section “Tâches, en attente de signature(s)”.
          </Typography>
        </Bullet>
      </Alert>
    );
  } else if (remainingBalance && expensePolicy) {
    return (
      <Alert color="warning">
        <Bullet bullet={<Icon color="warning" name="information-circle" size="h5" />} type={'icon'}>
          <Typography lead>Transfert sans approbation autorisés</Typography>
          <Typography>{`Vous êtes autorisé à effectuer des transferts de ${functions.formatMoney(
            expensePolicy.limitPerOperation
          )} par transaction unitaire, dans la limite de ${functions.formatMoney(expensePolicy.limitPerUnitOfTime)} par ${
            unitOfTimes[expensePolicy.unitOfTime]
          }. Si vous dépassez cette limite, votre transfert sera effectué comme une tâche en attente de signature(s).`}</Typography>
        </Bullet>
      </Alert>
    );
  } else if (!remainingBalance && expensePolicy) {
    return (
      <Alert color="error">
        <Bullet bullet={<Icon color="error" name="x-circle" size="h5" />} type={'icon'}>
          <Typography lead>Transfert impossible</Typography>
          <Typography>
            Vous avez atteint la limite de dépense sans approbation qui vous est autorisée, selon la règle de paiements établie par votre entreprise.
          </Typography>
        </Bullet>
      </Alert>
    );
  }

  return null;
}
