import React, {useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import {Button} from '@julaya/common/ui/atoms';
import success from '@julaya/common/assets/images/rapid-transfer/rapid-transfer-success.png';
import {functions} from '@julaya/common/utils';
import {useDidUpdate} from '@julaya/common/hooks';

import {Typography, Spinner} from '@julaya/common/system';

import './BankDisposalSuccess.scss';

const BankDisposalSuccess = ({
  total,
  newBalance,
  values,
  transactionNotify,
  isMobile,
  history,
  showSuccess,
  cancelTransactions,
  cancelTransactionsLoading,
  cancelTransactionsError
}) => {
  const [timeLeft, setTimeLeft] = useState();
  let interval;

  useDidUpdate(() => {
    if (cancelTransactionsLoading === false && cancelTransactionsError === '') {
      if (isMobile) {
        history.push(`/accounts/wallet/${values.walletId}/history`);
      } else {
        history.push(`/history/wallet/${values.walletId}/${transactionNotify?.TaskItems[0]?.uuid}`);
      }
    }
  }, [cancelTransactionsLoading]);

  useEffect(() => {
    if (showSuccess) {
      setTimeLeft(15);
      interval = setInterval(() => {
        setTimeLeft(t => {
          if (t < 1) {
            clearInterval(interval);
          }
          return t - 1;
        });
      }, 1000);
    } else if (interval) {
      clearInterval(interval);
    }
    return () => interval && clearInterval(interval);
  }, [showSuccess]);

  if (timeLeft < 1) {
    const link = isMobile
      ? `/accounts/wallet/${values.walletId}/history`
      : `/history/wallet/${values.walletId}/${transactionNotify?.TaskItems[0]?.uuid}`;

    return <Redirect to={link} />;
  }
  return (
    <div className="bank-disposal-success">
      <header className="pb-2 text-center">
        <div className="mb-1 font-weight-bold">Votre opération a été enregistrée avec succès</div>
        <span>Vous serez redirigé vers l'historique dans {timeLeft} secondes</span>
      </header>

      <img src={success} alt="page not found" className="d-block mx-auto" height={200} />

      <Typography
        className={'text-center mt-2'}
        onClick={() => {
          if (!cancelTransactionsLoading) {
            cancelTransactions(values.walletId, {
              uuids: [transactionNotify?.TaskItems[0]?.uuid],
              comment: 'undo'
            });
          }
        }}
        underline>
        Une erreur ? Annuler l'opération {cancelTransactionsLoading && <Spinner color="black" size="xs" />}
      </Typography>

      <div className="bank-disposal-success__metadata">
        <div className="title">Montant total</div>
        <div className="data"> + {functions.formatMoney(total)}</div>
      </div>
      <hr />
      <div className="bank-disposal-success__metadata">
        <div className="title">Nouveau solde</div>
        <div className="data lilas">{functions.formatMoney(newBalance)}</div>
      </div>

      <div className="bank-disposal-success__footer">
        <Button
          status="primary"
          text="Consulter l'historique"
          action={() => {
            if (isMobile) {
              history.push(`/accounts/wallet/${values.walletId}/history`);
            } else {
              history.push(`/history/wallet/${values.walletId}/${transactionNotify?.TaskItems[0]?.uuid}`);
            }
          }}
          full
        />
      </div>
    </div>
  );
};

export default BankDisposalSuccess;
