import React, {useState} from 'react';
import cx from 'classnames';
import StepHeader from './StepHeader';
import StepFooter from './StepFooter';

const Step = ({
  title,
  stepCount,
  currentStep,
  setCurrentStep,
  ctaLoading,
  formData,
  onSubmit,
  children,
  step,
  canSkip,
  showCancel,
  cta,
  updateAppNavigation,
  ...rest
}) => {
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);

  return (
    <div
      className={cx('multiStepFormMobile__step', {
        '--active': currentStep === step
      })}
    >
      <StepHeader title={title} />
      <form className="multiStepFormMobile__step__content" onSubmit={onSubmit}>
        {React.Children.map(children, (child, i) => {
          const props = {
            nextButtonDisabled,
            setNextButtonDisabled,
            onSubmit,
            stepCount,
            currentStep,
            setCurrentStep,
            step,
            ...formData,
            ...rest
          };
          return <div className="content-inner">{React.cloneElement(child, props)}</div>;
        })}
        <StepFooter
          cta={cta}
          nextButtonDisabled={nextButtonDisabled}
          showCancel={showCancel}
          canSkip={canSkip}
          step={step}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          ctaLoading={ctaLoading}
        />
      </form>
    </div>
  );
};

export default Step;
