/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, {useEffect, useState} from 'react';
import cx from 'classnames';
import UpdatePassword from '../updatePassword';
import {PopupButton} from '@typeform/embed-react';
import {useForm, useDialog} from '@julaya/common/hooks';
import {functions} from '@julaya/common/utils';
import {getValidationState, getValidationStateTwoFactor} from '../helper/helper';

const Login = props => {
  const {login, isConnected, ownPassword, theme, loginLoading, loginError} = props;
  const [values, handleChange, errors, setErrors, resetFields] = useForm({
    id: '',
    password: '',
    twoFactor: ''
  });

  const [twoFactorDisplay, setTwoFactorDisplay] = useState(false);
  const [modal, toggleModal] = useDialog();

  /*
  |
  | Get logo HTMl
  | ----------------
  */
  const getLogo = () => {
    if (theme?.logo) {
      return <img className="img-fluid logo-custom" src={theme.logo} alt="" />;
    } else {
      return <img className="img-fluid" src={require('@julaya/common/assets/images/julaya-pro-logo.png').default} alt="JULAYA PRO" />;
    }
  };

  /*
  |
  | Height on App or Desktop
  | --------------------------
  | Type Form Sign Up
  |
  */
  useEffect(() => {
    document.querySelector('#app').classList.remove('bg-body');
    document.querySelector('.auth-layout').classList.remove('vh-100');
    document.querySelector('.auth-layout').classList.add('min-vh-100');
  }, []);

  /*
  |
  | Check connexion
  | ----------------
  */
  useEffect(() => {
    if (isConnected === true && ownPassword === false) {
      resetFields();
      if (modal !== true) toggleModal();
    }
  }, [isConnected, ownPassword]);

  /*
  |
  | Error managing
  | ----------------
  */
  const onSubmit = async e => {
    e.preventDefault();
    let mErrors = {};
    if (getValidationState(values.id)) {
      mErrors.id = true;
    }
    if (getValidationState(values.password)) {
      mErrors.password = true;
    }
    if (twoFactorDisplay && getValidationStateTwoFactor(values.twoFactor)) {
      mErrors.twoFactor = true;
    }

    if (Object.keys(mErrors).length !== 0) {
      setErrors(mErrors);
    } else {
      const res = await login({
        identifier: functions.newPhoneFormat(values.id.trim()),
        password: values.password,
        ...(twoFactorDisplay ? {otp: values.twoFactor} : {})
      });
      if (res?.tfaActive) {
        setTwoFactorDisplay(true);
      }
    }
  };

  const handleCancelTwoFa = () => {
    setTwoFactorDisplay(false);
    resetFields();
  };

  return (
    <>
      <div id="page-login">
        <div className="container-fluid px-0 h-100">
          <div className="row h-100">
            <div className="col-lg-12">
              {modal ? (
                <UpdatePassword {...props} toggleModal={toggleModal} />
              ) : twoFactorDisplay ? (
                <div className="login">
                  <a href="#Julaya" className="reset__back mb-4" onClick={handleCancelTwoFa}>
                    <i className="jla-icon-chevron-left"></i>
                    Retour
                  </a>
                  <h1 className="login__title">Authentification forte</h1>
                  <p className="login__subtitle text-muted mb-4">
                    Afin de garantir la sécurité de votre compte, veuillez entrer le code généré par votre application Google Authentificator.
                  </p>
                  <form onSubmit={onSubmit} className="login__form">
                    <div className="form-group ">
                      <div className="font-weight-bold">Code d’authentification deux facteurs</div>
                      <input
                        id="id"
                        className={cx('form-control bg-grey', {
                          'is-invalid': errors.twoFactor
                        })}
                        type="text"
                        name="twoFactor"
                        placeholder="Code temporaire"
                        aria-describedby="phone-input"
                        value={values.twoFactor}
                        onChange={handleChange}
                        autoComplete="off"
                        required
                      />
                      <div className="invalid-feedback">{getValidationStateTwoFactor(values.twoFactor)}</div>
                    </div>
                    {loginError && <p className="text-danger">{loginError}</p>}
                    <button type="submit" className="btn btn-block btn-primary mt-4" disabled={loginLoading}>
                      {loginLoading ? (
                        <>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          {' Connexion'}
                        </>
                      ) : (
                        'Se connecter'
                      )}
                    </button>
                  </form>
                </div>
              ) : (
                <div className="login">
                  <div className="login__logo mb-4" id="sidebar-logo">
                    {getLogo()}
                  </div>
                  <h1 className="login__title">Nous sommes heureux de vous revoir,</h1>
                  <p className="login__subtitle text-muted mb-4">
                    Consultez votre historique et faites des paiements en vous connectant sur votre compte.
                  </p>
                  <form onSubmit={onSubmit} className="login__form">
                    <div className="form-group ">
                      <div className="font-weight-bold">Identifiant</div>
                      <input
                        id="id"
                        className={cx('form-control bg-grey', {
                          'is-invalid': errors.id
                        })}
                        type="text"
                        name="id"
                        placeholder="Identifiant de connexion"
                        aria-describedby="phone-input"
                        value={values.id}
                        onChange={handleChange}
                        autoComplete="off"
                        required
                      />
                      <div className="invalid-feedback">{getValidationState(values.id)}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="font-weight-bold">Mot de passe</div>
                      <a
                        href="#julaya"
                        onClick={e => {
                          toggleModal();
                          e.preventDefault();
                        }}
                        className="text-secondary text-underline">
                        Mot de passe oublié ?
                      </a>
                    </div>

                    <div className="form-group form-group__password">
                      <div className="form-control bg-grey">
                        <input
                          id="password"
                          className={cx('', {
                            'is-invalid': errors.password
                          })}
                          type="password"
                          name="password"
                          placeholder="Mot de passe"
                          value={values.password}
                          onChange={handleChange}
                          autoComplete="off"
                          required
                        />
                      </div>
                      <div className="invalid-feedback">{getValidationState(values.password)}</div>
                    </div>
                    {loginError && <p className="text-danger">{loginError}</p>}
                    <button type="submit" className="btn btn-block btn-primary mt-4" disabled={loginLoading}>
                      {loginLoading ? (
                        <>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          {' Connexion'}
                        </>
                      ) : (
                        'Se connecter'
                      )}
                    </button>
                  </form>
                  <div className="mt-4">
                    <p className="login__register text-muted">
                      Pas encore de compte ?
                      <PopupButton className="text-secondary" id="z0u0K4YZ" style={{fontSize: 14}}>
                        Inscrivez vous
                      </PopupButton>
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
