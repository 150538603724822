import React from 'react';
import cx from 'classnames';
import {useDialog} from '@julaya/common/hooks';
import './Collapse.scss';

const Collapse = ({children, title, icon, iconColor, bg, isOpen = false, chevron = true, flat = false}) => {
  const [collapse, toggleCollapse] = useDialog(isOpen);

  return (
    <div
      className={cx('jla-collapse', {
        '--open': (collapse && chevron) || chevron === false,
        '--flat': flat,
        '--white': bg === 'white'
      })}
    >
      <a
        className="jla-collapse__header"
        href="#julaya"
        onClick={e => {
          e.preventDefault();
          toggleCollapse();
        }}
      >
        <div className="jla-collapse__title">
          {icon && <i className={`icon-${icon} --${iconColor}`}></i>}
          {title}
        </div>

        {chevron && <i className={`icon-chevron-down jla-collapse__chevron`}></i>}
      </a>
      <div className="jla-collapse__content">
        <div className="jla-collapse__content-inner">{children}</div>
      </div>
    </div>
  );
};

export default Collapse;

// USAGE EXAMPLE
