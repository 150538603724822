import React, {createContext, useContext, useMemo, useEffect} from 'react';
import cx from 'classnames';
import {useDidUpdate} from '@julaya/common/hooks';

import Bullet from '../../DataDisplay/Bullet/Bullet';
import Typography from '../../DataDisplay/Typography/Typography';
import Avatar from '../../DataDisplay/Avatar/Avatar';
import Icon from '../../DataDisplay/Icon/Icon';
import Button from '../../Inputs/Button/Button';
import Surface from '../Surface/Surface';

import './Drawer.scss';

const DrawerContext = createContext({});

const Header = props => {
  const {title = '', subtitle = '', ...args} = props;

  const toogleDrawer = useContext(DrawerContext);

  return (
    <Surface.Header justify="between">
      <Bullet bullet={<Avatar {...args} />}>
        <Typography lead>{title}</Typography>
        {subtitle && <Typography variant="small">{subtitle}</Typography>}
      </Bullet>
      <Button onClick={toogleDrawer} btnType='tertiary' color='primary' type='button'>
        <Icon name='x-mark-filled' />
      </Button>
    </Surface.Header>
  );
};

const Body = ({children, ...args}) => {
  return (
    <Surface.Body {...args} grow>
      {children}
    </Surface.Body>
  );
};

const Footer = props => {
  const {cancelBtn, saveBtn, onCancel = () => {}, onSave = () => {}, stretched} = props;
  const toogleDrawer = useContext(DrawerContext);
  const footerProps = {};
  const cancelProps = {
    color: 'neutral',
    btnType: 'secondary',
    onClick: () => {
      onCancel();
      toogleDrawer();
    }
  };
  const saveProps = {color: 'primary', onClick: onSave};

  if (cancelBtn) {
    if (typeof cancelBtn === 'string') cancelProps.children = cancelBtn;
    else cancelProps.children = 'Annuler';
  }

  if (saveBtn) {
    if (typeof saveBtn === 'string') saveProps.children = saveBtn;
    else saveProps.children = 'Valider';
  }

  if (cancelBtn && saveBtn) {
    saveProps.block = true;
    cancelProps.block = true;
  } else {
    footerProps.justify = 'end';
  }

  return (
    <Surface.Footer gap="md" {...footerProps}>
      {cancelBtn && <Button {...cancelProps} />}
      {saveBtn && <Button {...saveProps} />}
    </Surface.Footer>
  );
};

const Drawer = props => {
  const {
    children,
    isOpen = false,
    isMobile = false,
    toogleDrawer = () => {},
    keyboard = true, // allow ESC to be pressed to close drawer
    onOpened = () => {}, // callback when the modal was opened fully
    onClosed = () => {} // callback when the modal has been closed fully
  } = props;

  // share the toogle function with other elements
  const value = useMemo(() => toogleDrawer, [toogleDrawer]);

  useEffect(() => {
    if (keyboard) {
      const handleKeyUp = e => {
        if (isOpen && e.keyCode === 27) {
          e.preventDefault();
          e.stopPropagation();
          toogleDrawer();
        }
      };
      document.addEventListener('keyup', handleKeyUp);

      return () => {
        document.removeEventListener('keyup', handleKeyUp);
      };
    }
  }, [keyboard, isOpen]);

  useDidUpdate(() => {
    if (isOpen) {
      onOpened();
    } else {
      onClosed();
    }
  }, [isOpen]);

  return (
    <DrawerContext.Provider value={value}>
      <div
        className={cx('jla-drawer', {
          'jla-drawer--open': isOpen,
          'jla-drawer--mobile': isMobile,
        })}
      >
        <a
          href="#julaya"
          className="jla-drawer__overlay"
          onClick={e => {
            e.preventDefault();
            toogleDrawer();
          }}
        >
          {''}
        </a>
        <div className="jla-drawer__inner">{children}</div>
      </div>
    </DrawerContext.Provider>
  );
};

Header.displayName = 'Drawer.Header';
Body.displayName = 'Drawer.Body';
Footer.displayName = 'Drawer.Footer';

Drawer.Header = Header;
Drawer.Body = Body;
Drawer.Footer = Footer;

export default Drawer;
