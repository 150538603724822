import React from 'react';
import {Button} from '@julaya/common/ui/atoms';
import copy from 'copy-to-clipboard';
import './BankInfoDisplay.scss';

const BankInfoDisplay = ({bank}) => {
  const details1 = ['accountOwner', 'counterCode', 'bankCode', 'accountNumber', 'ribKey'];

  const details2 = ['rib']; // TODO: iban, "bic"
  return (
    <div className="bank-info">
      {details1.map((item, i) => {
        const elem = details[item];
        const value = elem.getValue(bank);
        return (
          <div className="bank-info__elem" key={`${item}-${i}`}>
            <div>
              <div className="bank-info__elem-title">{elem.title}</div>
              <div className="bank-info__elem-value">{value}</div>
            </div>
          </div>
        );
      })}

      <hr className="bank-info__elem-separator" />
      {details2.map((item, i) => {
        const elem = details[item];
        const value = elem.getValue(bank);
        return (
          <div className="bank-info__elem" key={`${item}-${i}`}>
            <div>
              <div className="bank-info__elem-title">{elem.title}</div>
              <div className="bank-info__elem-value">{value}</div>
            </div>
          </div>
        );
      })}

      <hr className="bank-info__elem-separator" />

      <a className="bank-info__elem-find" href={bank.options.link} target="_blank" rel="noopener noreferrer">
        <i className="jla-icon-map-pin"></i>
        Trouver une agence
      </a>
    </div>
  );
};

const details = {
  ribKey: {
    title: 'Clé RIB',
    getValue: ({options}) => {
      return options.rib.key;
    }
  },
  bankCode: {
    title: 'Code banque',
    getValue: ({options}) => {
      return options.rib.bankCode;
    }
  },
  accountNumber: {
    title: 'N° de compte',
    getValue: ({options}) => {
      return options.rib.accountNumber;
    }
  },
  counterCode: {
    title: 'Code guichet',
    getValue: ({options}) => {
      return options.rib.counterCode;
    }
  },
  accountOwner: {
    title: 'Titulaire du compte',
    getValue: ({options}) => {
      return options.accountOwner;
    }
  },
  rib: {
    title: 'RIB',
    getValue: ({options}) => {
      return `${options.rib.bankCode}${options.rib.counterCode}${options.rib.accountNumber}${options.rib.key}`;
    }
  },
  bic: {
    title: 'BIC',
    getValue: ({options}) => {
      return `TODO: options.bic`;
    }
  }
};

export default BankInfoDisplay;

// USAGE EXAMPLE
{
  /* <BankInfoDisplay bank={selectedBank} /> */
}
