import React from 'react';

import './Input.scss';

const Input = ({label = null, className = '', errorMsg = null, ...attributes}) => {
  return (
    <div className="form-element">
      {label && <label className="form-element__label">{label}</label>}
      <input className="form-element__input" {...attributes} />
      {errorMsg && <div className="form-element__invalid-feedback">{errorMsg}</div>}
    </div>
  );
};

export default Input;
