/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from '@julaya/common/ui/atoms';
import { functions } from '@julaya/common/utils';
import { ErrorDisplay, PageWrapper } from 'components/UI';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import WalletList from './WalletList';

const Dashboard = props => {
  const {
    currentCompanyId,
    userCompanies,
    getUserWallets,
    landingWallets,
    lastUpdatedBalance,
    landingWalletsError,
    landingWalletsLoading,
    updateAppNavigation,
    getUserCompanies,
    canSend,
    canTopUp
  } = props;
  const [totalAmount, setTotalAmount] = useState(0);
  const [wallets, setWallets] = useState([]);
  const [showMoreWallets, setShowMoreWallets] = useState(false);

  useEffect(() => {
    getUserWallets(
      {
        type: 'ALL',
        access: 'READ'
      },
      'landing',
      true
    );
  }, []);

  useEffect(() => {
    let currentCompany = '';
    if (Array.isArray(userCompanies) && userCompanies.length > 0) {
      currentCompany = userCompanies.find(company => company.id === currentCompanyId)?.name || '';

      updateAppNavigation({
        title: currentCompany,
        icon: 'reload',
        action: () => {
          getUserWallets(
            {
              type: 'ALL',
              access: 'READ'
            },
            'landing',
            true
          );
        }
      });
    } else {
      getUserCompanies();
    }

    return () => {
      updateAppNavigation();
    };
  }, [userCompanies, currentCompanyId]);

  useEffect(() => {
    // total amount
    const totalW = landingWallets
      .filter(wallet => !wallet.isCard)
      .reduce((acc, w) => {
        w.balance ? (acc += parseInt(w.balance)) : (acc += 0);
        return acc;
      }, 0);
    const totalC = landingWallets
      .filter(wallet => wallet.isCard)
      .reduce((acc, c) => {
        c.balance ? (acc += parseInt(c.balance)) : (acc += 0);
        return acc;
      }, 0);

    setTotalAmount(totalW + totalC);

    if (landingWallets.filter(wallet => !wallet.isCard).length > 3) {
      setWallets(landingWallets.filter(wallet => !wallet.isCard).slice(0, 3));
      setShowMoreWallets(true);
    } else {
      setWallets(landingWallets.filter(wallet => !wallet.isCard));
      setShowMoreWallets(false);
    }
  }, [landingWallets]);

  return landingWalletsError ? (
    <div>
      <ErrorDisplay
        errorText={landingWalletsError || 'Aucun compte trouvé'}
        onClick={() =>
          getUserWallets(
            {
              access: 'READ'
            },
            'landing'
          )
        }
      />
    </div>
  ) : (
    <PageWrapper>
      <div className="font-weight-bold text-muted mb-2 h6">Solde total des comptes</div>
      {landingWalletsLoading ? (
        <h1 className="d-flex">
          <div className="placeholder-line" style={{width: '8rem', height: '2rem'}}></div>
          <span style={{visibility: 'hidden'}}>0</span>
        </h1>
      ) : (
        <h1>{functions.formatMoney(totalAmount)}</h1>
      )}
      {lastUpdatedBalance && <div className="text-muted mb-3 h6 font-weight-normal">{lastUpdatedBalance}</div>}
      <div className="d-flex mb-3">
        <Button disabled={!canTopUp} status="white" link="/bank-disposal" icon="jla-icon-wallet" text="Recharger" full />
        <Button disabled={!canSend} className="ml-2" link="/transfer/rapid-transfer" icon="jla-icon-arrows-right-left" text="Envoyer" full />
      </div>

      {!(!landingWalletsLoading && !wallets.length) && (
        <>
          <h5>Mes comptes {!landingWalletsLoading && <span className="text-muted">({wallets.length})</span>}</h5>
          <WalletList {...props} wallets={wallets} walletType="wallet" currentCompany={userCompanies.find(company => company.id === currentCompanyId) || ''}/>

          {showMoreWallets && (
            <Link className="btn btn-block btn-primary" to={`/accounts/wallets`}>
              Voir plus
            </Link>
          )}
        </>
      )}
      <div className="my-4"></div>
    </PageWrapper>
  );
};

export default Dashboard;
