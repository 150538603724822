import {useEffect} from 'react';

const useClickOutsideMenu = (target, ref, el, state, toogle) => {
  useEffect(() => {
    const listener = event => {
      if (state === target) {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        let isToogler = false;

        let path = event.path || event.composedPath();

        path.forEach(node => {
          if (node.classList && node.classList.contains('menu-toogler')) {
            isToogler = true;
          }
        });

        if (isToogler) {
          return;
        }

        const parent = document.querySelector(el);

        let close = true;
        path.forEach(node => {
          if (node == parent) {
            close = false;
          }
        });

        if (close) {
          toogle();
        }
      }
    };

    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('click', listener);
    };
  }, [target, ref, el, state]);
};

export default useClickOutsideMenu;
