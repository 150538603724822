export const HYDRATE_AUTH_STATE = 'HYDRATE_AUTH_STATE';
export const HYDRATE_UI_STATE = 'HYDRATE_UI_STATE';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM';
export const SET_STATE_TRANSACTION = 'SET_STATE_TRANSACTION';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT = 'LOGOUT';

export const USER_INFO_REQUEST = 'USER_INFO_REQUEST';
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const USER_INFO_FAILURE = 'USER_INFO_FAILURE';

export const TEMP_CODE_REQUEST = 'TEMP_CODE_REQUEST';
export const TEMP_CODE_SUCCESS = 'TEMP_CODE_SUCCESS';
export const TEMP_CODE_FAILURE = 'TEMP_CODE_FAILURE';

export const PASSWORD_UPDATE_REQUEST = 'PASSWORD_UPDATE_REQUEST';
export const PASSWORD_UPDATE_SUCCESS = 'PASSWORD_UPDATE_SUCCESS';
export const PASSWORD_UPDATE_FAILURE = 'PASSWORD_UPDATE_FAILURE';

export const GET_HISTORY_REQUEST = 'GET_HISTORY_REQUEST';
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS';
export const GET_HISTORY_FAILURE = 'GET_HISTORY_FAILURE';

export const EXPORT_HISTORY_REQUEST = 'EXPORT_HISTORY_REQUEST';
export const EXPORT_HISTORY_SUCCESS = 'EXPORT_HISTORY_SUCCESS';
export const EXPORT_HISTORY_FAILURE = 'EXPORT_HISTORY_FAILURE';

export const GET_BATCH_DETAILS_REQUEST = 'GET_BATCH_DETAILS_REQUEST';
export const GET_BATCH_DETAILS_SUCCESS = 'GET_BATCH_DETAILS_SUCCESS';
export const GET_BATCH_DETAILS_FAILURE = 'GET_BATCH_DETAILS_FAILURE';

export const GET_BATCHES_HISTORY_REQUEST = 'GET_BATCHES_HISTORY_REQUEST';
export const GET_BATCHES_HISTORY_SUCCESS = 'GET_BATCHES_HISTORY_SUCCESS';
export const GET_BATCHES_HISTORY_FAILURE = 'GET_BATCHES_HISTORY_FAILURE';

export const DELETE_BATCH_REQUEST = 'DELETE_BATCH_REQUEST';
export const DELETE_BATCH_SUCCESS = 'DELETE_BATCH_SUCCESS';
export const DELETE_BATCH_FAILURE = 'DELETE_BATCH_FAILURE';

export const ATTACH_RECEIPT_REQUEST = 'ATTACH_RECEIPT_REQUEST';
export const ATTACH_RECEIPT_SUCCESS = 'ATTACH_RECEIPT_SUCCESS';
export const ATTACH_RECEIPT_FAILURE = 'ATTACH_RECEIPT_FAILURE';

export const ATTACH_DEPOSIT_RECEIPT_REQUEST = 'ATTACH_DEPOSIT_RECEIPT_REQUEST';
export const ATTACH_DEPOSIT_RECEIPT_SUCCESS = 'ATTACH_DEPOSIT_RECEIPT_SUCCESS';
export const ATTACH_DEPOSIT_RECEIPT_FAILURE = 'ATTACH_DEPOSIT_RECEIPT_FAILURE';

export const GET_SUMMARY_REQUEST = 'GET_SUMMARY_REQUEST';
export const GET_SUMMARY_SUCCESS = 'GET_SUMMARY_SUCCESS';
export const GET_SUMMARY_FAILURE = 'GET_SUMMARY_FAILURE';

export const MAKE_TRANSACTION_REQUEST = 'MAKE_TRANSACTION_REQUEST';
export const MAKE_TRANSACTION_SUCCESS = 'MAKE_TRANSACTION_SUCCESS';
export const MAKE_TRANSACTION_FAILURE = 'MAKE_TRANSACTION_FAILURE';

export const GET_SUMMARY_BULK_REQUEST = 'GET_SUMMARY_BULK_REQUEST';
export const GET_SUMMARY_BULK_SUCCESS = 'GET_SUMMARY_BULK_SUCCESS';
export const GET_SUMMARY_BULK_FAILURE = 'GET_SUMMARY_BULK_FAILURE';

export const MAKE_TRANSACTION_BULK_REQUEST = 'MAKE_TRANSACTION_BULK_REQUEST';
export const MAKE_TRANSACTION_BULK_SUCCESS = 'MAKE_TRANSACTION_BULK_SUCCESS';
export const MAKE_TRANSACTION_BULK_FAILURE = 'MAKE_TRANSACTION_BULK_FAILURE';

export const UPDATE_USER_INFO_REQUEST = 'UPDATE_USER_INFO_REQUEST';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_FAILURE = 'UPDATE_USER_INFO_FAILURE';

export const UPLOAD_AVATAR_REQUEST = 'UPLOAD_AVATAR_REQUEST';
export const UPLOAD_AVATAR_SUCCESS = 'UPLOAD_AVATAR_SUCCESS';
export const UPLOAD_AVATAR_FAILURE = 'UPLOAD_AVATAR_FAILURE';

export const GET_ACCOUNT_DOCUMENTS_REQUEST = 'GET_ACCOUNT_DOCUMENTS_REQUEST';
export const GET_ACCOUNT_DOCUMENTS_SUCCESS = 'GET_ACCOUNT_DOCUMENTS_SUCCESS';
export const GET_ACCOUNT_DOCUMENTS_FAILURE = 'GET_ACCOUNT_DOCUMENTS_FAILURE';

export const GET_USER_DOUCMENTS_REQUEST = 'GET_USER_DOUCMENTS_REQUEST';
export const GET_USER_DOUCMENTS_SUCCESS = 'GET_USER_DOUCMENTS_SUCCESS';
export const GET_USER_DOUCMENTS_FAILURE = 'GET_USER_DOUCMENTS_FAILURE';

export const DOCUMENT_SEND_REQUEST = 'DOCUMENT_SEND_REQUEST';
export const DOCUMENT_SEND_SUCCESS = 'DOCUMENT_SEND_SUCCESS';
export const DOCUMENT_SEND_FAILURE = 'DOCUMENT_SEND_FAILURE';

export const DOCUMENT_SELECT = 'DOCUMENT_SELECT';

export const GENERATE_2FA_KEY_REQUEST = 'GENERATE_2FA_KEY_REQUEST';
export const GENERATE_2FA_KEY_SUCCESS = 'GENERATE_2FA_KEY_SUCCESS';
export const GENERATE_2FA_KEY_FAILURE = 'GENERATE_2FA_KEY_FAILURE';

export const ACTIVATE_2FA_KEY_REQUEST = 'ACTIVATE_2FA_KEY_REQUEST';
export const ACTIVATE_2FA_KEY_SUCCESS = 'ACTIVATE_2FA_KEY_SUCCESS';
export const ACTIVATE_2FA_KEY_FAILURE = 'ACTIVATE_2FA_KEY_FAILURE';

export const DELETE_2FA_KEY_REQUEST = 'DELETE_2FA_KEY_REQUEST';
export const DELETE_2FA_KEY_SUCCESS = 'DELETE_2FA_KEY_SUCCESS';
export const DELETE_2FA_KEY_FAILURE = 'DELETE_2FA_KEY_FAILURE';

export const CREATE_SUBACCOUNT_REQUEST = 'CREATE_SUBACCOUNT_REQUEST';
export const CREATE_SUBACCOUNT_SUCCESS = 'CREATE_SUBACCOUNT_SUCCESS';
export const CREATE_SUBACCOUNT_FAILURE = 'CREATE_SUBACCOUNT_FAILURE';

export const EDIT_SUBACCOUNT_REQUEST = 'EDIT_SUBACCOUNT_REQUEST';
export const EDIT_SUBACCOUNT_SUCCESS = 'EDIT_SUBACCOUNT_SUCCESS';
export const EDIT_SUBACCOUNT_FAILURE = 'EDIT_SUBACCOUNT_FAILURE';

export const GET_SUBACCOUNT_REQUEST = 'GET_SUBACCOUNT_REQUEST';
export const GET_SUBACCOUNT_SUCCESS = 'GET_SUBACCOUNT_SUCCESS';
export const GET_SUBACCOUNT_FAILURE = 'GET_SUBACCOUNT_FAILURE';

export const GET_SUBACCOUNT_DETAILS_REQUEST = 'GET_SUBACCOUNT_DETAILS_REQUEST';
export const GET_SUBACCOUNT_DETAILS_SUCCESS = 'GET_SUBACCOUNT_DETAILS_SUCCESS';
export const GET_SUBACCOUNT_DETAILS_FAILURE = 'GET_SUBACCOUNT_DETAILS_FAILURE';

export const GET_PARENT_REQUEST = 'GET_PARENT_REQUEST';
export const GET_PARENT_SUCCESS = 'GET_PARENT_SUCCESS';
export const GET_PARENT_FAILURE = 'GET_PARENT_FAILURE';

export const GET_FLUX_REQUEST = 'GET_FLUX_REQUEST';
export const GET_FLUX_SUCCESS = 'GET_FLUX_SUCCESS';
export const GET_FLUX_FAILURE = 'GET_FLUX_FAILURE';

export const GET_DASHBOARD_QUALITY_REQUEST = 'GET_DASHBOARD_QUALITY_REQUEST';
export const GET_DASHBOARD_QUALITY_SUCCESS = 'GET_DASHBOARD_QUALITY_SUCCESS';
export const GET_DASHBOARD_QUALITY_FAILURE = 'GET_DASHBOARD_QUALITY_FAILURE';

export const GET_DASHBOARD_CONTRIBUTORS_REQUEST = 'GET_DASHBOARD_CONTRIBUTORS_REQUEST';
export const GET_DASHBOARD_CONTRIBUTORS_SUCCESS = 'GET_DASHBOARD_CONTRIBUTORS_SUCCESS';
export const GET_DASHBOARD_CONTRIBUTORS_FAILURE = 'GET_DASHBOARD_CONTRIBUTORS_FAILURE';

export const GET_DASHBOARD_HISTORY_REQUEST = 'GET_DASHBOARD_HISTORY_REQUEST';
export const GET_DASHBOARD_HISTORY_SUCCESS = 'GET_DASHBOARD_HISTORY_SUCCESS';
export const GET_DASHBOARD_HISTORY_FAILURE = 'GET_DASHBOARD_HISTORY_FAILURE';

export const SHOW_CREDIT = 'SHOW_CREDIT';

export const GET_DASHBOARD_BATCHES_HISTORY_REQUEST = 'GET_DASHBOARD_BATCHES_HISTORY_REQUEST';
export const GET_DASHBOARD_BATCHES_HISTORY_SUCCESS = 'GET_DASHBOARD_BATCHES_HISTORY_SUCCESS';
export const GET_DASHBOARD_BATCHES_HISTORY_FAILURE = 'GET_DASHBOARD_BATCHES_HISTORY_FAILURE';

export const DELETE_ATTACHED_FILE_REQUEST = 'DELETE_ATTACHED_FILE_REQUEST';
export const DELETE_ATTACHED_FILE_SUCCESS = 'DELETE_ATTACHED_FILE_SUCCESS';
export const DELETE_ATTACHED_FILE_FAILURE = 'DELETE_ATTACHED_FILE_FAILURE';

export const EDIT_TRANSACTION_PARAMS_REQUEST = 'EDIT_TRANSACTION_PARAMS_REQUEST';
export const EDIT_TRANSACTION_PARAMS_SUCCESS = 'EDIT_TRANSACTION_PARAMS_SUCCESS';
export const EDIT_TRANSACTION_PARAMS_FAILURE = 'EDIT_TRANSACTION_PARAMS_FAILURE';
export const TRANSACTION_BULK_FORM = 'TRANSACTION_BULK_FORM';

export const GET_SERVICES_BY_EXTERNAL_REFERENCE_REQUEST = 'GET_SERVICES_BY_EXTERNAL_REFERENCE_REQUEST';
export const GET_SERVICES_BY_EXTERNAL_REFERENCE_SUCCESS = 'GET_SERVICES_BY_EXTERNAL_REFERENCE_SUCCESS';
export const GET_SERVICES_BY_EXTERNAL_REFERENCE_FAILURE = 'GET_SERVICES_BY_EXTERNAL_REFERENCE_FAILURE';

export const API_CHECK_REQUEST = 'API_CHECK_REQUEST';
export const API_CHECK_SUCCESS = 'API_CHECK_SUCCESS';
export const API_CHECK_FAILURE = 'API_CHECK_FAILURE';

export const TOGGLE_IS_MENU_OPEN = 'TOGGLE_IS_MENU_OPEN';
export const TOGGLE_WALLET_SELECT_OPEN = 'TOGGLE_WALLET_SELECT_OPEN';

export const GET_SUBACCOUNT_LIST_REQUEST = 'GET_SUBACCOUNT_LIST_REQUEST';
export const GET_SUBACCOUNT_LIST_SUCCESS = 'GET_SUBACCOUNT_LIST_SUCCESS';
export const GET_SUBACCOUNT_LIST_FAILURE = 'GET_SUBACCOUNT_LIST_FAILURE';

export const MARK_BATCH_AS_DONE_REQUEST = 'MARK_BATCH_AS_DONE_REQUEST';
export const MARK_BATCH_AS_DONE_SUCCESS = 'MARK_BATCH_AS_DONE_SUCCESS';
export const MARK_BATCH_AS_DONE_FAILURE = 'MARK_BATCH_AS_DONE_FAILURE';

export const ARCHIVE_TRANSACTIONS_FAIL_REQUEST = 'ARCHIVE_TRANSACTIONS_FAIL_REQUEST';
export const ARCHIVE_TRANSACTIONS_FAIL_SUCCESS = 'ARCHIVE_TRANSACTIONS_FAIL_SUCCESS';
export const ARCHIVE_TRANSACTIONS_FAIL_FAILURE = 'ARCHIVE_TRANSACTIONS_FAIL_FAILURE';

export const RETRY_TRANSACTIONS_FAIL_REQUEST = 'RETRY_TRANSACTIONS_FAIL_REQUEST';
export const RETRY_TRANSACTIONS_FAIL_SUCCESS = 'RETRY_TRANSACTIONS_FAIL_SUCCESS';
export const RETRY_TRANSACTIONS_FAIL_FAILURE = 'RETRY_TRANSACTIONS_FAIL_FAILURE';

export const REFUND_TRANSACTIONS_REQUEST = 'REFUND_TRANSACTIONS_REQUEST';
export const REFUND_TRANSACTIONS_SUCCESS = 'REFUND_TRANSACTIONS_SUCCESS';
export const REFUND_TRANSACTIONS_FAILURE = 'REFUND_TRANSACTIONS_FAILURE';

export const CANCEL_TRANSACTIONS_REQUEST = 'CANCEL_TRANSACTIONS_REQUEST';
export const CANCEL_TRANSACTIONS_SUCCESS = 'CANCEL_TRANSACTIONS_SUCCESS';
export const CANCEL_TRANSACTIONS_FAILURE = 'CANCEL_TRANSACTIONS_FAILURE';

export const REFUND_TASK_REQUEST = 'REFUND_TASK_REQUEST';
export const REFUND_TASK_SUCCESS = 'REFUND_TASK_SUCCESS';
export const REFUND_TASK_FAILURE = 'REFUND_TASK_FAILURE';

export const CANCEL_TASK_REQUEST = 'CANCEL_TASK_REQUEST';
export const CANCEL_TASK_SUCCESS = 'CANCEL_TASK_SUCCESS';
export const CANCEL_TASK_FAILURE = 'CANCEL_TASK_FAILURE';

export const KEEP_AS_DRAFT_REQUEST = 'KEEP_AS_DRAFT_REQUEST';
export const KEEP_AS_DRAFT_SUCCESS = 'KEEP_AS_DRAFT_SUCCESS';
export const KEEP_AS_DRAFT_FAILURE = 'KEEP_AS_DRAFT_FAILURE';

export const GET_BATCHES_TASKS_REQUEST = 'GET_BATCHES_TASKS_REQUEST';
export const GET_BATCHES_TASKS_SUCCESS = 'GET_BATCHES_TASKS_SUCCESS';
export const GET_BATCHES_TASKS_FAILURE = 'GET_BATCHES_TASKS_FAILURE';

export const GET_BATCH_ACTIVITY_REQUEST = 'GET_BATCH_ACTIVITY_REQUEST';
export const GET_BATCH_ACTIVITY_SUCCESS = 'GET_BATCH_ACTIVITY_SUCCESS';
export const GET_BATCH_ACTIVITY_FAILURE = 'GET_BATCH_ACTIVITY_FAILURE';

export const ADD_BATCH_COMMENTS_REQUEST = 'ADD_BATCH_COMMENTS_REQUEST';
export const ADD_BATCH_COMMENTS_SUCCESS = 'ADD_BATCH_COMMENTS_SUCCESS';
export const ADD_BATCH_COMMENTS_FAILURE = 'ADD_BATCH_COMMENTS_FAILURE';

export const GET_BENEFICIARIES_REQUEST = 'GET_BENEFICIARIES_REQUEST';
export const GET_BENEFICIARIES_SUCCESS = 'GET_BENEFICIARIES_SUCCESS';
export const GET_BENEFICIARIES_FAILURE = 'GET_BENEFICIARIES_FAILURE';

export const GET_BENEFICIARY_DETAILS_REQUEST = 'GET_BENEFICIARY_DETAILS_REQUEST';
export const GET_BENEFICIARY_DETAILS_SUCCESS = 'GET_BENEFICIARY_DETAILS_SUCCESS';
export const GET_BENEFICIARY_DETAILS_FAILURE = 'GET_BENEFICIARY_DETAILS_FAILURE';

export const CREATE_BENEFICIARY_REQUEST = 'CREATE_BENEFICIARY_REQUEST';
export const CREATE_BENEFICIARY_SUCCESS = 'CREATE_BENEFICIARY_SUCCESS';
export const CREATE_BENEFICIARY_FAILURE = 'CREATE_BENEFICIARY_FAILURE';

export const UPDATE_BENEFICIARY_REQUEST = 'UPDATE_BENEFICIARY_REQUEST';
export const UPDATE_BENEFICIARY_SUCCESS = 'UPDATE_BENEFICIARY_SUCCESS';
export const UPDATE_BENEFICIARY_FAILURE = 'UPDATE_BENEFICIARY_FAILURE';

export const DELETE_BENEFICIARY_REQUEST = 'DELETE_BENEFICIARY_REQUEST';
export const DELETE_BENEFICIARY_SUCCESS = 'DELETE_BENEFICIARY_SUCCESS';
export const DELETE_BENEFICIARY_FAILURE = 'DELETE_BENEFICIARY_FAILURE';

export const INVALID_APP_VERSION = 'INVALID_APP_VERSION';

export const CREATE_PREPAID_CARD_REQUEST = 'CREATE_PREPAID_CARD_REQUEST';
export const CREATE_PREPAID_CARD_SUCCESS = 'CREATE_PREPAID_CARD_SUCCESS';
export const CREATE_PREPAID_CARD_FAILURE = 'CREATE_PREPAID_CARD_FAILURE';

export const GET_PREPAID_CARD_DETAILS_REQUEST = 'GET_PREPAID_CARD_DETAILS_REQUEST';
export const GET_PREPAID_CARD_DETAILS_SUCCESS = 'GET_PREPAID_CARD_DETAILS_SUCCESS';
export const GET_PREPAID_CARD_DETAILS_FAILURE = 'GET_PREPAID_CARD_DETAILS_FAILURE';

export const ADMIN_GET_PREPAID_CARD_DETAILS_REQUEST = 'ADMIN_GET_PREPAID_CARD_DETAILS_REQUEST';
export const ADMIN_GET_PREPAID_CARD_DETAILS_SUCCESS = 'ADMIN_GET_PREPAID_CARD_DETAILS_SUCCESS';
export const ADMIN_GET_PREPAID_CARD_DETAILS_FAILURE = 'ADMIN_GET_PREPAID_CARD_DETAILS_FAILURE';

export const STOP_PREPAID_CARD_REQUEST = 'STOP_PREPAID_CARD_REQUEST';
export const STOP_PREPAID_CARD_SUCCESS = 'STOP_PREPAID_CARD_SUCCESS';
export const STOP_PREPAID_CARD_FAILURE = 'STOP_PREPAID_CARD_FAILURE';

export const FILTER_HEADER = 'FILTER_HEADER';

export const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_FAILURE = 'GET_NOTIFICATION_FAILURE';

export const GET_NEW_NOTIFICATION_REQUEST = 'GET_NEW_NOTIFICATION_REQUEST';
export const GET_NEW_NOTIFICATION_SUCCESS = 'GET_NEW_NOTIFICATION_SUCCESS';
export const GET_NEW_NOTIFICATION_FAILURE = 'GET_NEW_NOTIFICATION_FAILURE';

export const ARCHIVE_NOTIFICATION_REQUEST = 'ARCHIVE_NOTIFICATION_REQUEST';
export const ARCHIVE_NOTIFICATION_SUCCESS = 'ARCHIVE_NOTIFICATION_SUCCESS';
export const ARCHIVE_NOTIFICATION_FAILURE = 'ARCHIVE_NOTIFICATION_FAILURE';

export const ARCHIVE_ALL_NOTIFICATION_REQUEST = 'ARCHIVE_ALL_NOTIFICATION_REQUEST';
export const ARCHIVE_ALL_NOTIFICATION_SUCCESS = 'ARCHIVE_ALL_NOTIFICATION_SUCCESS';
export const ARCHIVE_ALL_NOTIFICATION_FAILURE = 'ARCHIVE_ALL_NOTIFICATION_FAILURE';

export const GET_NOTIFICATION_SETTINGS_REQUEST = 'GET_NOTIFICATION_SETTINGS_REQUEST';
export const GET_NOTIFICATION_SETTINGS_SUCCESS = 'GET_NOTIFICATION_SETTINGS_SUCCESS';
export const GET_NOTIFICATION_SETTINGS_FAILURE = 'GET_NOTIFICATION_SETTINGS_FAILURE';

export const READ_NOTIFICATIONS_REQUEST = 'READ_NOTIFICATIONS_REQUEST';
export const READ_NOTIFICATIONS_SUCCESS = 'READ_NOTIFICATIONS_SUCCESS';
export const READ_NOTIFICATIONS_FAILURE = 'READ_NOTIFICATIONS_FAILURE';

export const EDIT_NOTIFICATION_SETTINGS_REQUEST = 'EDIT_NOTIFICATION_SETTINGS_REQUEST';
export const EDIT_NOTIFICATION_SETTINGS_SUCCESS = 'EDIT_NOTIFICATION_SETTINGS_SUCCESS';
export const EDIT_NOTIFICATION_SETTINGS_FAILURE = 'EDIT_NOTIFICATION_SETTINGS_FAILURE';

export const GET_WALLETS_REQUEST = 'GET_WALLETS_REQUEST';
export const GET_WALLETS_SUCCESS = 'GET_WALLETS_SUCCESS';
export const GET_WALLETS_FAILURE = 'GET_WALLETS_FAILURE';

export const GET_ADMIN_WALLETS_REQUEST = 'GET_ADMIN_WALLETS_REQUEST';
export const GET_ADMIN_WALLETS_SUCCESS = 'GET_ADMIN_WALLETS_SUCCESS';
export const GET_ADMIN_WALLETS_FAILURE = 'GET_ADMIN_WALLETS_FAILURE';

export const GET_WALLET_DETAILS_REQUEST = 'GET_WALLET_DETAILS_REQUEST';
export const GET_WALLET_DETAILS_SUCCESS = 'GET_WALLET_DETAILS_SUCCESS';
export const GET_WALLET_DETAILS_FAILURE = 'GET_WALLET_DETAILS_FAILURE';

export const GET_ADMIN_WALLET_DETAILS_REQUEST = 'GET_ADMIN_WALLET_DETAILS_REQUEST';
export const GET_ADMIN_WALLET_DETAILS_SUCCESS = 'GET_ADMIN_WALLET_DETAILS_SUCCESS';
export const GET_ADMIN_WALLET_DETAILS_FAILURE = 'GET_ADMIN_WALLET_DETAILS_FAILURE';

export const GET_WALLET_USERS_REQUEST = 'GET_WALLET_USERS_REQUEST';
export const GET_WALLET_USERS_SUCCESS = 'GET_WALLET_USERS_SUCCESS';
export const GET_WALLET_USERS_FAILURE = 'GET_WALLET_USERS_FAILURE';

export const GET_USER_WALLETS_REQUEST = 'GET_USER_WALLETS_REQUEST';
export const GET_USER_WALLETS_SUCCESS = 'GET_USER_WALLETS_SUCCESS';
export const GET_USER_WALLETS_FAILURE = 'GET_USER_WALLETS_FAILURE';

export const CREATE_WALLET_REQUEST = 'CREATE_WALLET_REQUEST';
export const CREATE_WALLET_SUCCESS = 'CREATE_WALLET_SUCCESS';
export const CREATE_WALLET_FAILURE = 'CREATE_WALLET_FAILURE';

export const UPDATE_WALLET_REQUEST = 'UPDATE_WALLET_REQUEST';
export const UPDATE_WALLET_SUCCESS = 'UPDATE_WALLET_SUCCESS';
export const UPDATE_WALLET_FAILURE = 'UPDATE_WALLET_FAILURE';

export const SWITCH_WALLET_REQUEST = 'SWITCH_WALLET_REQUEST';
export const SWITCH_WALLET_SUCCESS = 'SWITCH_WALLET_SUCCESS';
export const SWITCH_WALLET_FAILURE = 'SWITCH_WALLET_FAILURE';

export const ASSIGN_SUBACCOUNT_ROLE_REQUEST = 'ASSIGN_SUBACCOUNT_ROLE_REQUEST';
export const ASSIGN_SUBACCOUNT_ROLE_SUCCESS = 'ASSIGN_SUBACCOUNT_ROLE_SUCCESS';
export const ASSIGN_SUBACCOUNT_ROLE_FAILURE = 'ASSIGN_SUBACCOUNT_ROLE_FAILURE';

export const GET_PREPAID_CARD_USERS_LIST_REQUEST = 'GET_PREPAID_CARD_USERS_LIST_REQUEST';
export const GET_PREPAID_CARD_USERS_LIST_SUCCESS = 'GET_PREPAID_CARD_USERS_LIST_SUCCESS';
export const GET_PREPAID_CARD_USERS_LIST_FAILURE = 'GET_PREPAID_CARD_USERS_LIST_FAILURE';

export const CHECK_PREPAID_CARD_HOLDER_REQUEST = 'CHECK_PREPAID_CARD_HOLDER_REQUEST';
export const CHECK_PREPAID_CARD_HOLDER_SUCCESS = 'CHECK_PREPAID_CARD_HOLDER_SUCCESS';
export const CHECK_PREPAID_CARD_HOLDER_FAILURE = 'CHECK_PREPAID_CARD_HOLDER_FAILURE';

export const TASK_MANAGER = 'TASK_MANAGER';
export const SET_BANK_DISPOSAL_AMOUNT = 'SET_BANK_DISPOSAL_AMOUNT';

export const TASK_CREATE_EDIT_REQUEST = 'TASK_CREATE_EDIT_REQUEST';
export const TASK_CREATE_EDIT_SUCCESS = 'TASK_CREATE_EDIT_SUCCESS';
export const TASK_CREATE_EDIT_FAILURE = 'TASK_CREATE_EDIT_FAILURE';
export const CGU_VALIDATION_REQUEST = 'CGU_VALIDATION_REQUEST';
export const CGU_VALIDATION_SUCCESS = 'CGU_VALIDATION_SUCCESS';
export const CGU_VALIDATION_FAILURE = 'CGU_VALIDATION_FAILURE';

export const GET_SERVICES_LIST_BY_TYPE_REQUEST = 'GET_SERVICES_LIST_BY_TYPE_REQUEST';
export const GET_SERVICES_LIST_BY_TYPE_SUCCESS = 'GET_SERVICES_LIST_BY_TYPE_SUCCESS';
export const GET_SERVICES_LIST_BY_TYPE_FAILURE = 'GET_SERVICES_LIST_BY_TYPE_FAILURE';

export const GET_ACTIVITY_LOGS_REQUEST = 'GET_ACTIVITY_LOGS_REQUEST';
export const GET_ACTIVITY_LOGS_SUCCESS = 'GET_ACTIVITY_LOGS_SUCCESS';
export const GET_ACTIVITY_LOGS_FAILURE = 'GET_ACTIVITY_LOGS_FAILURE';

export const UPDATE_PREPAID_CARD_PIN_REQUEST = 'UPDATE_PREPAID_CARD_PIN_REQUEST';
export const UPDATE_PREPAID_CARD_PIN_SUCCESS = 'UPDATE_PREPAID_CARD_PIN_SUCCESS';
export const UPDATE_PREPAID_CARD_PIN_FAILURE = 'UPDATE_PREPAID_CARD_PIN_FAILURE';

export const UPDATE_APP_NAVIGATION = 'UPDATE_APP_NAVIGATION';
export const UPDATE_BREADCRUMB = 'UPDATE_BREADCRUMB';

export const GET_LATEST_RECEIVERS_REQUEST = 'GET_LATEST_RECEIVERS_REQUEST';
export const GET_LATEST_RECEIVERS_SUCCESS = 'GET_LATEST_RECEIVERS_SUCCESS';
export const GET_LATEST_RECEIVERS_FAILURE = 'GET_LATEST_RECEIVERS_FAILURE';

export const GET_USER_COMPANIES_REQUEST = 'GET_USER_COMPANIES_REQUEST';
export const GET_USER_COMPANIES_SUCCESS = 'GET_USER_COMPANIES_SUCCESS';
export const GET_USER_COMPANIES_FAILURE = 'GET_USER_COMPANIES_FAILURE';

export const GET_USER_EXPENSE_POLICY_SUCCESS = 'GET_USER_EXPENSE_POLICY_SUCCESS';

export const SWITCH_USER_COMPANY_REQUEST = 'SWITCH_USER_COMPANY_REQUEST';
export const SWITCH_USER_COMPANY_SUCCESS = 'SWITCH_USER_COMPANY_SUCCESS';
export const SWITCH_USER_COMPANY_FAILURE = 'SWITCH_USER_COMPANY_FAILURE';

export const SET_USER_PREFERRED_COMPANY_REQUEST = 'SET_USER_PREFERRED_COMPANY_REQUEST';
export const SET_USER_PREFERRED_COMPANY_SUCCESS = 'SET_USER_PREFERRED_COMPANY_SUCCESS';
export const SET_USER_PREFERRED_COMPANY_FAILURE = 'SET_USER_PREFERRED_COMPANY_FAILURE';

export const TASK_CREATE_REQUEST = 'TASK_CREATE_REQUEST';
export const TASK_CREATE_SUCCESS = 'TASK_CREATE_SUCCESS';
export const TASK_CREATE_FAILURE = 'TASK_CREATE_FAILURE';

export const TASK_UPDATE_REQUEST = 'TASK_UPDATE_REQUEST';
export const TASK_UPDATE_SUCCESS = 'TASK_UPDATE_SUCCESS';
export const TASK_UPDATE_FAILURE = 'TASK_UPDATE_FAILURE';

export const TASK_DELETE_REQUEST = 'TASK_DELETE_REQUEST';
export const TASK_DELETE_SUCCESS = 'TASK_DELETE_SUCCESS';
export const TASK_DELETE_FAILURE = 'TASK_DELETE_FAILURE';

export const TASK_SIGN_REQUEST = 'TASK_SIGN_REQUEST';
export const TASK_SIGN_SUCCESS = 'TASK_SIGN_SUCCESS';
export const TASK_SIGN_FAILURE = 'TASK_SIGN_FAILURE';

export const TASK_RETRY_REQUEST = 'TASK_RETRY_REQUEST';
export const TASK_RETRY_SUCCESS = 'TASK_RETRY_SUCCESS';
export const TASK_RETRY_FAILURE = 'TASK_RETRY_FAILURE';

export const TASK_DETAILS_REQUEST = 'TASK_DETAILS_REQUEST';
export const TASK_DETAILS_SUCCESS = 'TASK_DETAILS_SUCCESS';
export const TASK_DETAILS_FAILURE = 'TASK_DETAILS_FAILURE';

export const GET_USER_LIST_REQUEST = 'GET_USER_LIST_REQUEST';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAILURE = 'GET_USER_LIST_FAILURE';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const GET_IS_VALID_PHONE_REQUEST = 'GET_IS_VALID_PHONE_REQUEST';
export const GET_IS_VALID_PHONE_SUCCESS = 'GET_IS_VALID_PHONE_SUCCESS';
export const GET_IS_VALID_PHONE_FAILURE = 'GET_IS_VALID_PHONE_FAILURE';

export const ARCHIVE_WALLET_REQUEST = 'ARCHIVE_WALLET_REQUEST';
export const ARCHIVE_WALLET_SUCCESS = 'ARCHIVE_WALLET_SUCCESS';
export const ARCHIVE_WALLET_FAILURE = 'ARCHIVE_WALLET_FAILURE';

export const EDIT_TRANSACTION_COMMENT_REQUEST = 'EDIT_TRANSACTION_COMMENT_REQUEST';
export const EDIT_TRANSACTION_COMMENT_SUCCESS = 'EDIT_TRANSACTION_COMMENT_SUCCESS';
export const EDIT_TRANSACTION_COMMENT_FAILURE = 'EDIT_TRANSACTION_COMMENT_FAILURE';

export const ADMIN_DASHBOARD_INFO_REQUEST = 'ADMIN_DASHBOARD_INFO_REQUEST';
export const ADMIN_DASHBOARD_INFO_SUCCESS = 'ADMIN_DASHBOARD_INFO_SUCCESS';
export const ADMIN_DASHBOARD_INFO_FAILURE = 'ADMIN_DASHBOARD_INFO_FAILURE';
