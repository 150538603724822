import React from 'react';
import {Button} from '../../atoms';

const StepFooter = props => {
  const {cta, canSkip, step, currentStep, setCurrentStep, showCancel, ctaLoading, nextButtonDisabled} = props;

  let canSkipBtn,
    showCancelBtn = null;

  if (canSkip) {
    let canSkipLabel = 'Ignorer cette étape';

    if (typeof canSkip === 'string') {
      canSkipLabel = canSkip;
    }

    canSkipBtn = (
      <Button
        className="skipStep"
        status="neutral"
        text={canSkipLabel}
        action={() => {
          setCurrentStep(currentStep + 1);
        }}
      />
    );
  }

  if (showCancel && step > 0) {
    let showCancelLabel = 'Annuler';

    if (typeof showCancel === 'string') {
      showCancelLabel = showCancel;
    }

    showCancelBtn = (
      <Button
        status="neutral"
        className="skipStep"
        text={showCancelLabel}
        disabled={ctaLoading}
        action={() => {
          setCurrentStep(currentStep - 1);
        }}
      />
    );
  }
  return (
    <div className="multiStepForm__step__content__footer">
      {canSkipBtn}
      {showCancelBtn}
      <Button className="fit-content" text={cta} disabled={nextButtonDisabled || ctaLoading} loading={ctaLoading} submit cursor />
    </div>
  );
};

export default StepFooter;
