/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {Link} from 'react-router-dom';

import {Spinner} from 'reactstrap';
import {ErrorDisplay} from 'components/UI';
import {HistoryItem} from 'components/History';
// import { Badge } from "@julaya/common/ui/atoms";

import {useHistoryFilter} from '@julaya/common/hooks';

const History = props => {
  const {
    setSelectedItem,
    historyLoading,
    historyError,
    historyData,
    getHistory,
    match: {
      params: {walletType, walletId}
    }
  } = props;

  const {callGetHistory} = useHistoryFilter(getHistory, walletType, walletId);

  // const waitingBankDisposal = historyData.filter(
  // 	(item) => item.typeSlug === "bank-disposal" && item.status === "waiting"
  // );

  if (historyLoading) {
    return (
      <div className="mt-4 w-100 flex-grow-1 d-flex align-items-center justify-content-center">
        <Spinner color="primary" style={{width: '5rem', height: '5rem'}} />
      </div>
    );
  } else if (historyError) {
    return <ErrorDisplay errorText={historyError} onClick={callGetHistory} />;
  } else if (historyData.length) {
    return (
      <div className="historyList">
        <div>
          {/* <Badge
						link="/"
						icon="icon-time"
						status="pending"
						title="Demande d’appro. en attente"
						subtitle={`${waitingBankDisposal.length} transactions`}
					/> */}
          <div className="title">Dernières transactions</div>

          <div className="history mb-3">
            {historyData
              // .filter((item) => item.typeSlug !== "bank-disposal" && item.status === "waiting")
              .slice(0, 6)
              .map((item, i) => (
                <HistoryItem key={i} item={item} walletId={walletId} setSelectedItem={setSelectedItem} />
              ))}
          </div>
          <Link className="btn btn-block btn-primary" to={`/accounts/${walletType}/${walletId}/history`}>
            Afficher tout l’historique
          </Link>
        </div>
      </div>
    );
  } else {
    return <ErrorDisplay errorText="Aucune opération trouvée" />;
  }
};

export default History;
