/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {useDialog, useDidUpdate} from '@julaya/common/hooks';
import {PageWrapper, CardInfo} from 'components/UI';
import InfoCard from './CardInfo';
import PasswordModal from './PasswordModal';
import {Icon} from '@julaya/common/system';

const WalletGestion = props => {
  const {
    wallet,
    prepaidCardDetails,
    getPrepaidCardDetailsLoading,
    getPrepaidCardDetailsError,
    stopPrepaidCardLoading,
    stopPrepaidCardError,
    updatePrepaidCardPinLoading,
    updatePrepaidCardPinError,
    hidePrepaidCardDetails,
    match: {
      params: {walletType, walletId}
    },
    getWalletDetails
  } = props;

  const [action, setAction] = useState(null);
  const [pin, setPin] = useState(null);
  const [passwordModal, togglePasswordModal] = useDialog();

  const [cardInfo, setCardInfo] = useState({
    active: wallet?.status === 'activated' || true,
    balance: wallet?.balance || 0,
    expiresMonth: '**',
    expiresYear: '**',
    cvc: '***',
    cardNumber: '**** **** **** ' + (wallet?.pan || '****'),
    label: wallet?.label || ''
  });

  useEffect(() => {
    if (!wallet) {
      getWalletDetails(walletId, true);
    }
  }, []);

  useEffect(() => {
    if (prepaidCardDetails) {
      const expiryMonth = moment(prepaidCardDetails.expiryDate).format('MM');
      const expiryYear = moment(prepaidCardDetails.expiryDate).format('YY');
      setCardInfo({
        balance: prepaidCardDetails.balance,
        active: prepaidCardDetails.isActive,
        expiresMonth: expiryMonth,
        expiresYear: expiryYear,
        cvc: prepaidCardDetails.cvv2,
        cardNumber: prepaidCardDetails?.cardNumber?.match(/.{4}/g).join(' ') || wallet.pan
      });
    } else {
      setCardInfo({
        active: wallet?.isActive || true,
        balance: wallet?.balance || 0,
        expiresMonth: '**',
        expiresYear: '**',
        cvc: '***',
        cardNumber: '**** **** **** ' + (wallet?.pan || '****')
      });
    }
  }, [JSON.stringify(prepaidCardDetails)]);

  useDidUpdate(() => {
    if (getPrepaidCardDetailsLoading === false && getPrepaidCardDetailsError === '') {
      togglePasswordModal(false);
    }
  }, [getPrepaidCardDetailsLoading, getPrepaidCardDetailsError]);

  useDidUpdate(() => {
    if (stopPrepaidCardLoading === false && stopPrepaidCardError === '') {
      togglePasswordModal(false);
    }
  }, [stopPrepaidCardLoading, stopPrepaidCardError]);

  return (
    <>
      <PageWrapper className="flex-grow-1">
        {wallet && (
          <>
            {/* TODO : Add Blocked Card Banner */}
            {/* {!wallet.isActive && (
							
						)} */}

            {walletType === 'card' && (
              <div className="px-2">
                <CardInfo selectedCard={wallet} {...props} />
              </div>
            )}
            <div className="border-bottom my-3"></div>

            {/* Card Info Block */}
            <InfoCard
              label={wallet.label}
              reference={wallet.reference}
              country={wallet.country}
              countryName={wallet.countryName}
              type={wallet.type}
              variant="light"
            />
            <div className="border-bottom my-3"></div>

            {/* Card Actions Block */}

            {prepaidCardDetails ? (
              <button
                className="d-flex justify-content-between align-items-center btn btn-primary mb-3"
                disabled={!cardInfo.active}
                type="button"
                onClick={() => {
                  hidePrepaidCardDetails();
                }}>
                <div className="d-flex align-items-center">
                  <i className="icon-eye-off h4 m-0"></i>
                  <small className="ml-2">Cacher les informations</small>
                </div>

                <i className="icon-chevron-right"></i>
              </button>
            ) : (
              <button
                disabled={!cardInfo.active}
                className="d-flex justify-content-between align-items-center btn btn-primary mb-3"
                type="button"
                onClick={() => {
                  setAction('show-info');
                  togglePasswordModal(true);
                }}>
                <div className="d-flex align-items-center">
                  <Icon name="eye" size="h5" />
                  <small className="ml-2">Voir les informations</small>
                </div>

                <Icon name="chevron-right" size="h5" />
              </button>
            )}
            {wallet.type === 'physical' && (
              <button
                disabled={!cardInfo.active}
                type="button"
                className="d-flex justify-content-between align-items-center btn btn-light mb-3"
                onClick={e => {
                  setAction('change-pin');
                  togglePasswordModal(true);
                }}>
                <div className="d-flex align-items-center">
                  <i className="icon-key h4 m-0"></i>
                  <small className="ml-2">Changer pin</small>
                </div>

                <i className="icon-chevron-right"></i>
              </button>
            )}
            <button
              disabled={!cardInfo.active}
              type="button"
              className="d-flex justify-content-between align-items-center btn btn-white mb-3"
              onClick={e => {
                setAction('block-card');
                togglePasswordModal(true);
              }}>
              <div className="d-flex align-items-center">
                <Icon name="lock" size="h5" />
                <small className="ml-2">Bloquer ma carte</small>
              </div>

              <Icon name="chevron-right" size="h5" />
            </button>

            <p className="text-center text-danger font-weight-bold m-2">
              Pour supprimer définitivement votre carte, veuillez contacter le support client.
            </p>
          </>
        )}
      </PageWrapper>
      {passwordModal && (
        <PasswordModal
          {...props}
          showModal={passwordModal}
          toggleShowModal={togglePasswordModal}
          action={action}
          setAction={setAction}
          loading={getPrepaidCardDetailsLoading || stopPrepaidCardLoading || updatePrepaidCardPinLoading}
          error={getPrepaidCardDetailsError || stopPrepaidCardError || updatePrepaidCardPinError}
          pin={pin}
          setPin={setPin}
        />
      )}
    </>
  );
};

export default WalletGestion;
