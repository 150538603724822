import React from 'react';
import cx from 'classnames';

// TODO import { useIntl } from "react-intl";

import Icon from '../Icon/Icon';

import './Tag.scss';

const TagComponent = props => {
  const {children, tag: Tag = 'div', prefixIcon, suffixIcon, color = 'primary', iconColor, tagType = 'primary', size, className, ...attrs} = props;

  // TODO const { formatMessage: t } = useIntl();

  // prepare prefix and suffix icons
  const prefixClasses = cx('jla-tag__prefix', {
    'jla-tag__prefix-sm': size === 'sm',
    'jla-tag__prefix-lg': size === 'lg'
  });
  const suffixClasses = cx('jla-tag__suffix', {
    'jla-tag__suffix-sm': size === 'sm',
    'jla-tag__suffix-lg': size === 'lg'
  });
  let prefix = prefixIcon ? <Icon color={iconColor||color} name={prefixIcon} className={prefixClasses} /> : null;
  const suffix = suffixIcon ? <Icon color={iconColor || color}  name={suffixIcon} className={suffixClasses} /> : null;

  // prepare tag classes
  const classList = cx('jla-tag', `jla-tag--${color}-${tagType}`, className, {
    [`jla-tag--${size}`]: !!size
  });

  return (
    <Tag {...attrs} className={classList}>
      {prefix}
      <span>{children}</span>
      {suffix}
    </Tag>
  );
};

TagComponent.displayName = 'Tag';

export default TagComponent;
