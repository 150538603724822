/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {functions} from '@julaya/common/utils';

import VisualFeedback from './VisualFeedback';
import BatchDetails from './BatchDetails';
import {JSpinner} from 'components/UI';

const getCTALabel = (signatures, user, wallet) => {
  if (wallet.nbSignatures > 1) {
    const count = Array.isArray(signatures) ? signatures.length + 1 : 1;
    if (user.isSuperSignature) {
      return `Voir, signer et envoyer`;
    }
    return `Voir et signer (${count}/${wallet.nbSignatures})`;
  } else {
    return `Envoyer`;
  }
};

const RecapModal = props => {
  const {showModal, toggleShowModal, user, wallet, toggleTransfersList, togglePassword, metadata, signatures, includeReceiverFees} = props;
  const [additionalData, setAdditionalData] = useState({
    totalWithFees: 0,
    newBalance: 0
  });
  useEffect(() => {
    if (metadata) {
      const totalWithFees = (metadata?.sum || 0) + (metadata?.fees || 0) + (includeReceiverFees ? metadata?.opFees || 0 : 0);
      const newBalance = wallet.balance - totalWithFees;

      setAdditionalData({
        totalWithFees,
        newBalance
      });
    }
  }, [metadata]);

  const history = useHistory();

  const canSign = useMemo(() => {
    return signatures
      ? !signatures.some(signature => {
          return signature.userId === user?.id;
        })
      : true;
  }, [user, signatures]);

  return (
    <div className={`modal-associates-types-container ${showModal ? 'open' : ''}`}>
      <div
        className="modal-associates-types d-flex flex-column"
        style={{
          minHeight: '460px',
          height: '60%',
          borderRadius: '15px 15px 0px 0px'
        }}>
        {/* Header */}
        <div className="modal-associates-head">
          <div
            className="d-flex flex-row align-items-center justify-content-between"
            style={{
              width: '100%'
            }}>
            <div className="d-flex align-items-center">
              <div className="h5 mb-0" style={{fontSize: '1.2rem'}}>
                Récapitulatif
              </div>
            </div>
          </div>
        </div>

        {/* Check if there is a loading */}
        {metadata === null ? (
          <div className="position-absolute" style={{top: ' 50%', right: '50%'}}>
            <JSpinner />
          </div>
        ) : (
          // Modal Body
          <div className="modal-associates-body flex-grow-1" style={{padding: '0'}}>
            {/* 1st Block */}
            <div className="border-bottom w-100 py-2">
              <div className="px-4">
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    toggleShowModal();
                    toggleTransfersList();
                  }}
                  className="w-100 pl-4 pr-2 py-2 d-flex justify-content-between bg-light rounded mb-3">
                  <div className="d-flex align-items-center text-muted">Voir la liste des opérations</div>
                  <div className="d-flex align-items-center">
                    <i className="jla-icon-chevron-right arrow p-2 text-black rounded align-self-center"></i>
                  </div>
                </a>
              </div>

              <div className="w-100 px-4 py-2 d-flex justify-content-between">
                <div className="text-muted" style={{padding: '0 7.5px'}}>
                  Nombre de transferts
                </div>
                <div className="font-weight-bold " style={{padding: '0 7.5px'}}>
                  {metadata?.total}
                </div>
              </div>
              <div className="w-100 px-4 py-2 d-flex justify-content-between">
                <div className="text-muted" style={{padding: '0 7.5px'}}>
                  Solde actuel
                </div>
                <div className="font-weight-bold" style={{padding: '0 7.5px'}}>
                  {functions.formatMoney(wallet?.balance)}
                </div>
              </div>
            </div>
            {/* 2nd Block */}
            <div className="border-bottom w-100 py-2">
              <div className="w-100 px-4 py-2 d-flex justify-content-between">
                <div className="text-muted" style={{padding: '0 7.5px'}}>
                  Montant du transfert
                </div>
                <div className="font-weight-bold" style={{padding: '0 7.5px'}}>
                  {functions.formatMoney(additionalData.totalWithFees)}
                </div>
              </div>
            </div>
            {/* 3rd Block */}
            <div className="border-bottom w-100 py-2">
              <div className="w-100 px-4 py-2 d-flex justify-content-between">
                <div style={{padding: '0 7.5px'}}>Nouveau solde</div>
                <div className="font-weight-bold text-primary" style={{padding: '0 7.5px'}}>
                  {functions.formatMoney(additionalData.newBalance)}
                </div>
              </div>
            </div>
            {/* 4th Block */}
            <div className="w-100 py-4 px-4 flex-grow-1 d-flex flex-column justify-content-between">
              <div className="mb-4">
                <VisualFeedback {...props} />
                <BatchDetails {...props} additionalData={additionalData} />
              </div>
              <div className="d-flex">
                <button
                  className="btn w-100 btn-light mr-2"
                  onClick={() => {
                    history.goBack();
                  }}>
                  Annuler
                </button>
                {canSign ? (
                  <button
                    className="btn w-100 btn-primary"
                    onClick={() => {
                      toggleShowModal();
                      togglePassword();
                    }}>
                    {getCTALabel(signatures, user, wallet)}
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecapModal;
