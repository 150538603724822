import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "@julaya/common/store/actions";
import selector from "@julaya/common/store/selectors";

import Component from "./components";

const mapStateToProps = ({ history, loading, error, auth, taskManager }) => {
  return {
    ...selector(
      {
        history: "GET_HISTORY", // historyLoading historyError
        batchesTasks: "GET_BATCHES_TASKS",
        batchesHistory: "GET_BATCHES_HISTORY",
        batchActivity: "GET_BATCH_ACTIVITY",
        addBatchActivity: "ADD_BATCH_COMMENTS",
        markBatchAsDone: "MARK_BATCH_AS_DONE",
        batchDetails: "GET_BATCH_DETAILS",
        batchDelete: "DELETE_BATCH",
      },
      { loading, error }
    ),
    batchDetails: history.batchDetails,
    historyData: history.history,
    historyCount: history.historyCount,
    batchActivity: history.batchActivity,
    batchesTasks: history.batchesTasks,
    user: auth.user,
    tfaActive: auth.tfaActive,

    batchesHistory: history.batchesHistory,
    batchesCount: history.batchesCount,
    batchesHistoryPage: history.batchesHistoryPage,

    ...taskManager,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
