/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from 'react';
import classnames from 'classnames';
import {useForm} from '@julaya/common/hooks';
import {getValidationState, getValidationStatePassword, getValidationStateTwoFactor} from '../helper/helper';
import cx from 'classnames';
import {functions} from '@julaya/common/utils';

const PasswordResetPhone = ({
  identifier,
  updatePassword,
  login,
  passwordUpdateLoading,
  passwordUpdateError,
  loginLoading,
  loginError,
  toggleModal,
  connectionInfo
}) => {
  const [twoFactorDisplay, setTwoFactorDisplay] = useState(false);

  const [values, handleChange, errors, setErrors] = useForm({
    password: '',
    newPassword: '',
    twoFactor: ''
  });

  const onSubmit = async e => {
    e.preventDefault();
    let mErrors = {};
    if (getValidationStatePassword(values.password)) {
      mErrors.password = true;
    }
    if (getValidationStatePassword(values.newPassword, values.password)) {
      mErrors.newPassword = true;
    }

    if (Object.keys(mErrors).length !== 0) {
      setErrors(mErrors);
    } else {
      const loginParams = {
        identifier: identifier,
        password: values.newPassword,
        ...(twoFactorDisplay ? {otp: values.twoFactor} : {})
      };

      const res = await updatePassword(
        {newPassword: values.newPassword, password: await functions.encryptCode(connectionInfo.password)},
        loginParams
      );

      if (res?.tfaActive) {
        setTwoFactorDisplay(true);
      }
    }
  };

  const handleConnection = async e => {
    e.preventDefault();
    let mErrors = {};
    if (getValidationState(values.password)) {
      mErrors.password = true;
    }
    if (twoFactorDisplay && getValidationStateTwoFactor(values.twoFactor)) {
      mErrors.twoFactor = true;
    }

    if (Object.keys(mErrors).length !== 0) {
      setErrors(mErrors);
    } else {
      login({
        identifier: identifier,
        password: values.password,
        ...(twoFactorDisplay ? {otp: values.twoFactor} : {})
      });
    }
  };

  return (
    <div className="custom-box">
      <a
        href="#Julaya"
        className="reset__back mb-4"
        onClick={() => {
          toggleModal();
        }}>
        <i className="jla-icon-chevron-left"></i>
        Retour
      </a>
      {twoFactorDisplay ? (
        <div className="login">
          <h1 className="login__title">Authentification forte</h1>
          <p className="login__subtitle text-muted mb-4">
            Afin de garantir la sécurité de votre compte, veuillez entrer le code généré par votre application Google Authentificator.
          </p>
          <form onSubmit={handleConnection} className="needs-validation">
            <div className="form-group ">
              <div className="font-weight-bold">Code d’authentification deux facteurs</div>
              <input
                id="id"
                className={cx('form-control bg-grey', {
                  'is-invalid': errors.twoFactor
                })}
                type="text"
                name="twoFactor"
                placeholder="Code temporaire"
                aria-describedby="phone-input"
                value={values.twoFactor}
                onChange={handleChange}
                autoComplete="off"
                required
              />
              <div className="invalid-feedback">{getValidationStateTwoFactor(values.twoFactor)}</div>
            </div>
            {loginError && <p className="text-danger">{loginError}</p>}
            <button type="submit" className="btn btn-block btn-primary mt-4" disabled={loginLoading}>
              {loginLoading ? (
                <>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  {' Connexion'}
                </>
              ) : (
                'Se connecter'
              )}
            </button>
          </form>
        </div>
      ) : (
        <>
          {/* Wording */}
          <h3 className="login__title">Choisissez un nouveau mot de passe</h3>
          <div className="text-muted">C’est bientôt terminé, il vous ne vous reste plus qu’à choisir un nouveau mot de passe sécurisé.</div>

          {/* Form */}
          <form className="needs-validation" onSubmit={onSubmit}>
            <div className="form-group mt-4">
              <input
                id="new-password"
                className={classnames('form-control bg-grey', {
                  'is-invalid': errors.password
                })}
                type="password"
                name="password"
                placeholder="Nouveau mot de passe"
                value={values.password}
                onChange={handleChange}
                autoComplete="off"
                required
              />
              <div className="invalid-feedback">{getValidationStatePassword(values.password)}</div>
            </div>

            <div className="form-group">
              <input
                id="new-conf-password"
                className={classnames('form-control bg-grey', {
                  'is-invalid': errors.newPassword
                })}
                type="password"
                name="newPassword"
                placeholder="Confirmez le mot de passe"
                value={values.newPassword}
                onChange={handleChange}
                autoComplete="off"
                required
              />
              <div className="invalid-feedback">{getValidationStatePassword(values.newPassword, values.password)}</div>
            </div>

            {passwordUpdateError && <p className="text-danger">{passwordUpdateError}</p>}
            <div className="mt-4">
              <button type="submit" className="btn btn-block btn-primary" disabled={passwordUpdateLoading || loginLoading}>
                {(passwordUpdateLoading || loginLoading) && (
                  <>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{' '}
                  </>
                )}
                Valider
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default PasswordResetPhone;
