import React, {useState} from 'react';
import cx from 'classnames';
import {functions} from '@julaya/common/utils';

import './AmountInput.scss';

const AmountInput = ({
  value,
  handleChange,
  hasError,
  label = 'Montant',
  errorMsg = 'Veuillez enter un montant valide',
  name = 'amount',
  isMobile = false
}) => {
  const [val, setVal] = useState(functions.formatMoney(value, false));

  const onChange = e => {
    const digits = parseInt(e.target.value.replace(/\D/g, ''));
    const val = isNaN(digits) || digits === 0 ? '' : digits.toString();
    handleChange({
      target: {
        name: name,
        value: val
      }
    });
    const formattedValue = functions.formatMoney(val, false);
    setVal(formattedValue);
  };

  return (
    <div className={cx('jla-amount-input')}>
      <div className={cx('jla-amount-input__container')}>
        <label className="jla-amount-input__label" htmlFor="amount">
          {label}
        </label>
        {val && (
          <div className="jla-amount-input__currency-container">
            <span className="jla-amount-input__amount">{val || 0}</span>
            <span> FCFA</span>
          </div>
        )}
        <input
          id="amount"
          className="jla-amount-input__text-input"
          type="text"
          name={name}
          placeholder="0 FCFA"
          value={val}
          onChange={onChange}
          autoComplete="off"
          onFocus={e => {
            isMobile &&
              setTimeout(() => {
                document.getElementById('amount').scrollIntoView({behavior: 'smooth'});
              }, 200);
          }}
        />
      </div>
      <div className="jla-amount-input__invalid-feedback">{errorMsg}</div>
    </div>
  );
};

export default AmountInput;
