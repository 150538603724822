import {
  TASK_DETAILS_SUCCESS,
  TASK_CREATE_SUCCESS,
  TASK_UPDATE_SUCCESS,
  TASK_MANAGER,
  TASK_DETAILS_REQUEST,
  API_CHECK_FAILURE,
  API_CHECK_SUCCESS,
  TASK_SIGN_SUCCESS
} from '../actionTypes';

const actions = [
  TASK_DETAILS_SUCCESS,
  TASK_CREATE_SUCCESS,
  TASK_UPDATE_SUCCESS,
  TASK_MANAGER,
  TASK_DETAILS_REQUEST,
  API_CHECK_FAILURE,
  API_CHECK_SUCCESS,
  TASK_SIGN_SUCCESS
];

const initialState = {
  taskId: null,
  includeReceiverFees: false,
  walletId: null,
  wallet: null,
  title: '',
  description: '',
  initiatorId: null,
  status: null,
  smsTemplate: null,
  way: null,
  type: null,
  updatedAt: null,
  createdAt: null,
  taskUuid: null,
  metadata: null,
  taskItems: [],
  signatures: [],
  activities: [],
  retriedTransactions: [],
  taskEditable: false,

  //

  addItemsQueue: [],
  addEditItemsProgess: null,
  editItemsQueue: [],
  managerError: null,
  files: [],
  currentTaskItem: null,
  currentTaskItemIndex: null,
  currentTaskItemError: null,
  notEnoughBalance: null,
  omniCanalHelpOverlayOpen: false,
  currentCheckItems: [],
  currentCheckItemIndexes: [],
  taskSignedResult: null
};

export default [initialState, actions];
