import React from 'react';

import { Typography } from "@julaya/common/system";


const Header = props => {
  const {headers} = props;
  return (
    <thead>
      <tr className="jla-table-header__row">
        {headers.map((cellConfig, idx) => {
          return (
            <th
              key={idx}
              style={{
                width: `${!!cellConfig.width ? `${cellConfig.width}` : 'unset'}`,
                textAlign: `${!!cellConfig.align ? `${cellConfig.align}` : 'left'}`
              }}>
              {cellConfig.headerTemplate ? (
                cellConfig.headerTemplate({...props, cellConfig})
              ) : (
                <div className="jla-table-header__cell">
                  <Typography variant={'xs'} color={'neutral'} lead>{cellConfig.label}</Typography>
                </div>
              )}
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default Header;
