import React from 'react';
import {useDidUpdate, useDebounce} from '@julaya/common/hooks';
import {Icon} from '@julaya/common/system';

// NOTE:
// button{
//     icon:'icon-xxxxx',
//     onClick: ()=>{}
// }

const Searchbar = ({
  value,
  onChange,
  className = '',
  bg = 'white',
  name = 'search-query',
  placeholder = 'Rechercher',
  onSubmitQuerySearch = null,
  button = null
}) => {
  const debouncedSearchTerm = useDebounce(value, 700);

  useDidUpdate(() => {
    if (typeof onSubmitQuerySearch === 'function') {
      onSubmitQuerySearch();
    }
  }, [debouncedSearchTerm]);

  const onChangeQuery = e => {
    onChange(e.target.value);
  };

  return (
    <div className={`d-flex ${className}`}>
      <div className={`input-group rounded bg-${bg} mb-2 align-items-center`}>
        <div className="input-group-prepend">
          <span className="input-group-text border-0  bg-transparent" id="basic-addon1">
            <i className="jla-icon-magnifying-glass h5 m-0"></i>
          </span>
        </div>
        <input
          name={name}
          type="text"
          className="form-control border-0 pl-0"
          id="query-rapid-transfer"
          placeholder={placeholder}
          aria-label={name}
          aria-describedby={name}
          autoComplete="off"
          value={value}
          onChange={onChangeQuery}
        />
      </div>
      {button && (
        <a
          href="#julaya"
          onClick={e => {
            e.preventDefault();
            button.onClick();
          }}
          className="btn btn-white border-0 ml-2 mb-2 p-2 d-flex align-items-center justify-content-center">
          <Icon name="adjustments-vertical" size="h5" />
        </a>
      )}
    </div>
  );
};

export default Searchbar;
