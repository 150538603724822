import React from 'react';
import cx from 'classnames';
import './Cell.scss';

import { Typography } from "@julaya/common/system";

const Cell = props => {
  const {data, cellConfig} = props;
  let prop = data[cellConfig.prop];

  if(prop instanceof Array){
    prop = (
      <div className='jla-table-cell__multiple'>
         <Typography variant={'xs'} lead>{prop[0]}</Typography>
         <Typography variant={'xs'} color={"neutral"}>{prop[1]}</Typography>
      </div>
    )
  }else if(typeof prop === 'string'){
    prop = (<Typography variant={'xs'} lead>{prop}</Typography>);
  }

  return (
    <div
      className={cx('jla-table-cell', {
        [`jla-table-cell--${cellConfig.align}`]: true
      })}>
        {prop}
    </div>
  );
};

export default Cell;
