import {network} from '@julaya/common/utils';
import {
  GET_NOTIFICATION_REQUEST,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAILURE,
  GET_NEW_NOTIFICATION_REQUEST,
  GET_NEW_NOTIFICATION_SUCCESS,
  GET_NEW_NOTIFICATION_FAILURE,
  ARCHIVE_ALL_NOTIFICATION_REQUEST,
  ARCHIVE_ALL_NOTIFICATION_SUCCESS,
  ARCHIVE_ALL_NOTIFICATION_FAILURE,
  ARCHIVE_NOTIFICATION_REQUEST,
  ARCHIVE_NOTIFICATION_SUCCESS,
  ARCHIVE_NOTIFICATION_FAILURE,
  GET_NOTIFICATION_SETTINGS_REQUEST,
  GET_NOTIFICATION_SETTINGS_SUCCESS,
  GET_NOTIFICATION_SETTINGS_FAILURE,
  EDIT_NOTIFICATION_SETTINGS_REQUEST,
  EDIT_NOTIFICATION_SETTINGS_SUCCESS,
  EDIT_NOTIFICATION_SETTINGS_FAILURE,
  READ_NOTIFICATIONS_REQUEST,
  READ_NOTIFICATIONS_SUCCESS,
  READ_NOTIFICATIONS_FAILURE
} from '../actionTypes';

/*
|
| GET NEW NOTIFICATIONS COUNT
| ------------------------------
*/
export const getNewNotifications = () => async dispatch => {
  dispatch({
    type: GET_NEW_NOTIFICATION_REQUEST
  });

  try {
    const response = await network.request(`/me/notifications/list`, 'POST', {
      page: -1,
      status: ['unread']
    });

    dispatch({
      type: GET_NEW_NOTIFICATION_SUCCESS,
      payload: {
        notificationsNew: response
      }
    });
  } catch (error) {
    dispatch({
      type: GET_NEW_NOTIFICATION_FAILURE,
      error: error.message
    });
  }
};

/*
|
| READ NOTIFICATIONS
| ------------------------------
*/
export const readNotifications = () => async dispatch => {
  dispatch({
    type: READ_NOTIFICATIONS_REQUEST
  });

  try {
    await network.request(`/me/notifications/status`, 'PUT', {
      status: 'read',
      all: true
    });

    dispatch({
      type: READ_NOTIFICATIONS_SUCCESS
    });
  } catch (error) {
    dispatch({
      type: READ_NOTIFICATIONS_FAILURE,
      error: error.message
    });
  }
};

/*
|
| GET NOTIFICATIONS
| ---------------------
*/
export const getNotifications =
  (
    params = {
      page: 1,
      status: ['read', 'unread']
    }
  ) =>
  async (dispatch, getState) => {
    dispatch({
      type: GET_NOTIFICATION_REQUEST
    });

    try {
      const {
        notifications: {notificationsListItems}
      } = getState();

      const response = await network.request(`/me/notifications/list`, 'POST', {
        ...params
      });

      let items;

      if (params.page === 1) {
        items = response;
      } else {
        const {count, data} = notificationsListItems;
        items = {
          count,
          data: [...data, ...response.data]
        };
      }

      dispatch({
        type: GET_NOTIFICATION_SUCCESS,
        payload: {
          notificationsListItems: items
        }
      });
    } catch (error) {
      dispatch({
        type: GET_NOTIFICATION_FAILURE,
        error: error.message
      });
    }
  };

/*
|
| ARCHIVE ALL NOTIFICATIONS
| --------------------------
*/
export const archiveAllNotifications = () => async (dispatch, getState) => {
  dispatch({
    type: ARCHIVE_ALL_NOTIFICATION_REQUEST
  });

  const {
    notifications: {notificationsListItems}
  } = getState();

  try {
    const ids = notificationsListItems?.data.map(item => item.uuid);

    if (ids.length) {
      await network.request(`/me/notifications/status`, 'PUT', {
        status: 'archived',
        notificationIds: ids,
        all: true
      });

      dispatch({
        type: ARCHIVE_ALL_NOTIFICATION_SUCCESS,
        payload: {
          notificationsListItems: null
        }
      });
    }
  } catch (error) {
    dispatch({
      type: ARCHIVE_ALL_NOTIFICATION_FAILURE,
      error: error.message
    });
  }
};

/*
|
| ARCHIVE SINGLE NOTIFICATION
| -------------------------------
*/
export const archiveNotification = id => async (dispatch, getState) => {
  dispatch({
    type: ARCHIVE_NOTIFICATION_REQUEST
  });

  try {
    const {
      notifications: {notificationsListItems}
    } = getState();

    await network.request(`/me/notifications/status`, 'PUT', {
      status: 'archived',
      notificationIds: [id],
      all: false
    });

    dispatch({
      type: ARCHIVE_NOTIFICATION_SUCCESS,
      payload: {
        notificationsListItems:
          notificationsListItems.count === 1
            ? null
            : {
                count: notificationsListItems.count - 1,
                data: notificationsListItems.data.filter(notification => notification.uuid !== id)
              }
      }
    });
  } catch (error) {
    dispatch({
      type: ARCHIVE_NOTIFICATION_FAILURE,
      error: error.message
    });
  }
};

/*
|
| GET SETTINGS
| ---------------------
*/
export const getSettingsNotifications = () => async (dispatch, getState) => {
  const {
    auth: {user}
  } = getState();

  dispatch({
    type: GET_NOTIFICATION_SETTINGS_REQUEST
  });

  try {
    const response = await network.request(`/me/subscriptions`, 'GET');

    dispatch({
      type: GET_NOTIFICATION_SETTINGS_SUCCESS,
      payload: {
        notificationsSettings: response
      }
    });
  } catch (error) {
    dispatch({
      type: GET_NOTIFICATION_SETTINGS_FAILURE,
      error: error.message
    });
  }
};

/*
|
| EDIT NOTIFICATIONS
| ---------------------
*/
export const editSettingsNotifications = params => async dispatch => {
  dispatch({
    type: EDIT_NOTIFICATION_SETTINGS_REQUEST
  });

  try {
    const response = await network.request(`/me/subscriptions`, 'PUT', {
      ...params
    });

    dispatch({
      type: EDIT_NOTIFICATION_SETTINGS_SUCCESS,
      payload: {
        notificationSettings: response
      }
    });
  } catch (error) {
    dispatch({
      type: EDIT_NOTIFICATION_SETTINGS_FAILURE,
      error: error.message
    });
  }
};
