/* eslint-disable react-hooks/exhaustive-deps */
import {useState} from 'react';
import cx from 'classnames';

import {useForm, useDidUpdate} from '@julaya/common/hooks';
import {JSpinner} from 'components/UI';

const getValidationState = (str, name) => {
  if (name === 'password') {
    if (str === '') {
      return 'Champ obligatoire';
    } else if (str.length < 4) {
      return 'Vous devez indiquer 4 caractères';
    }
  } else if (name === 'otp') {
    if (str === '') {
      return 'Champs obligatoire';
    }

    if (str.length !== 6) {
      return 'Le code doit comporter 6 caractères';
    }
  }
  return false;
};

const getValidationStatePassword = (str, str2 = null) => {
  if (str === '') {
    return 'Champ obligatoire';
  } else if (str.length < 4) {
    return 'Vous devez indiquer 4 caractères';
  } else if (str2 && str !== str2) {
    return 'Les mots de passe de correspondent pas.';
  }
  return false;
};

const PasswordModal = ({
  showModal,
  toggleShowModal,
  loading,
  error,
  setAction,
  tfaActive,
  getPrepaidCardDetails,
  stopPrepaidCard,
  action,
  wallet,
  updatePrepaidCardPin
}) => {
  const [values, handleChange, errors, setErrors, resetValues] = useForm({
    pin: '',
    verifyPin: '',
    password: '',
    otp: ''
  });

  const [pin, setPin] = useState(null);

  const [showLoader, setShowLoader] = useState(false);
  const [showChangePinSuccess, setChangePinSuccess] = useState(false);

  useDidUpdate(() => {
    if (loading === false && error === '') {
      handleChange({
        target: {
          name: 'password',
          value: ''
        }
      });
      handleChange({
        target: {
          name: 'otp',
          value: ''
        }
      });
    }
  }, [loading, error]);

  useDidUpdate(() => {
    if (loading === true) {
      if (!showLoader) {
        setShowLoader(true);
      }
    } else {
      if (action === 'change-pin-complete') {
        setChangePinSuccess(true);
      } else {
        setShowLoader(false);
      }
    }
  }, [loading]);

  const onSubmit = e => {
    e.preventDefault();
    let mErrors = {};
    if (action === 'change-pin') {
      if (getValidationStatePassword(values.pin)) {
        mErrors.pin = true;
      }
      if (getValidationStatePassword(values.verifyPin, values.pin)) {
        mErrors.verifyPin = true;
      }

      if (Object.keys(mErrors).length !== 0) {
        setErrors(mErrors);
      } else {
        setPin(values.pin);
        setAction('change-pin-complete');
        resetValues();
      }
    } else {
      if (getValidationState(values.password)) {
        mErrors.password = true;
      }
      if (tfaActive && getValidationState(values.otp)) {
        mErrors.otp = true;
      }

      if (Object.keys(mErrors).length !== 0) {
        setErrors(mErrors);
      } else {
        if (action === 'show-info') {
          getPrepaidCardDetails(wallet.id, values.password, values.otp);
        } else if (action === 'block-card') {
          stopPrepaidCard(wallet.id, values.password, values.otp);
        } else if (action === 'change-pin-complete') {
          updatePrepaidCardPin(wallet.id, pin, values.password, values.otp);
        }
        resetValues();
      }
    }
  };

  const ModalTitle = () => {
    if (action === 'show-info') {
      return (
        <div className="h5 mb-0" style={{fontSize: '1.2rem'}}>
          <i className="icon-eye h4 m-0 mr-2"></i> Voir les informations
        </div>
      );
    } else if (action === 'block-card') {
      return (
        <div className="h5 mb-0" style={{fontSize: '1.2rem'}}>
          <i className="icon-lock h4 m-0 mr-2"></i> Bloquer la carte
        </div>
      );
    } else if (action === 'change-pin') {
      return (
        <div className="h5 mb-0" style={{fontSize: '1.2rem'}}>
          <i className="icon-key h4 m-0 mr-2"></i> Indiquez votre nouveau pin
        </div>
      );
    } else {
      return (
        <div className="h5 mb-0" style={{fontSize: '1.2rem'}}>
          <i className="icon-lock h4 m-0 mr-2"></i> Indiquez votre mot de passe
        </div>
      );
    }
  };

  return (
    <div className={`modal-associates-types-container ${showModal ? 'open' : ''}`}>
      <div
        className="modal-associates-types"
        style={{
          height: '50%',
          borderRadius: '15px 15px 0px 0px'
        }}>
        {/* Modal Header */}
        <div className="modal-associates-head">
          <div
            className="d-flex flex-row align-items-center justify-content-between"
            style={{
              width: '100%'
            }}>
            <div className="d-flex align-items-center">{ModalTitle()}</div>
          </div>
        </div>
        {/* Modal Body */}
        {showLoader ? (
          showChangePinSuccess ? (
            <div className="d-flex flex-column align-items-center p-4">
              <img
                src={require('@julaya/common/assets/images/rapid-transfer/rapid-transfer-success.png').default}
                alt=""
                className={'img-fluid w-50'}
              />
              <div className="font-weight-bold h5 text-center mt-4">Votre changement de PIN a bien été effectué !</div>
              <button
                type="button"
                className="btn w-100 btn-primary mt-4"
                onClick={() => {
                  setAction(null);
                  toggleShowModal();
                }}>
                Fermer
              </button>
            </div>
          ) : (
            <div className="my-5 py-5">
              <JSpinner />
            </div>
          )
        ) : (
          <div className="modal-associates-body" style={{padding: '0'}}>
            <form className="needs-validation d-flex flex-column flex-grow-1" onSubmit={onSubmit}>
              <div className="container-fluid p-4 flex-grow-1">
                {action === 'change-pin' ? (
                  // {/* Modal Body : Show New PIN inputs */}
                  <>
                    <div className="form-group">
                      <input
                        id="new-pin"
                        className={cx('form-control', {
                          'is-invalid': errors.pin
                        })}
                        type="password"
                        name="pin"
                        placeholder="Nouveau pin"
                        value={values.pin}
                        onChange={handleChange}
                        autoComplete="off"
                        style={{
                          height: '3rem',
                          borderRadius: '10px',
                          border: '1px solid #979797'
                        }}
                        required
                      />
                      <div className="invalid-feedback">{getValidationStatePassword(values.pin)}</div>
                    </div>

                    <div className="form-group mt-2">
                      <input
                        id="new-conf-password"
                        className={cx('form-control', {
                          'is-invalid': errors.verifyPin
                        })}
                        type="password"
                        name="verifyPin"
                        placeholder="Confirmez le nouveau pin"
                        value={values.verifyPin}
                        onChange={handleChange}
                        autoComplete="off"
                        style={{
                          height: '3rem',
                          borderRadius: '10px',
                          border: '1px solid #979797'
                        }}
                        required
                      />
                      <div className="invalid-feedback">{getValidationStatePassword(values.verifyPin, values.pin)}</div>
                    </div>
                  </>
                ) : (
                  // {/* Modal Body : Show password & OTP inputs */}
                  <>
                    <div className="row">
                      <div className="col-24 form-group">
                        <label htmlFor="password">Mot de passe</label>
                        <input
                          id="password"
                          className={cx('form-control bg-white', {
                            'is-invalid': errors.password
                          })}
                          type="password"
                          name="password"
                          placeholder="Entrez votre mot de passe"
                          value={values.password}
                          onChange={handleChange}
                          autoComplete="off"
                          required
                          style={{
                            height: '3rem',
                            borderRadius: '10px',
                            border: '1px solid #979797'
                          }}
                        />
                        <div className="invalid-feedback">{getValidationState(values.password, 'password')}</div>
                      </div>
                    </div>

                    {tfaActive && (
                      <div className="row mt-2">
                        <div className="col-24 form-group">
                          <label className="text-muted" htmlFor="otp">
                            Code d’authentification deux facteurs
                          </label>
                          <input
                            id="otp"
                            className={cx('form-control', {
                              'is-invalid': errors.otp
                            })}
                            type="otp"
                            name="otp"
                            placeholder="Entrez votre code"
                            value={values.otp}
                            onChange={handleChange}
                            autoComplete="off"
                            required
                            style={{
                              height: '3rem',
                              borderRadius: '10px',
                              border: '1px solid #979797'
                            }}
                          />

                          <div className="invalid-feedback">{getValidationState(values.otp, 'otp')}</div>
                        </div>
                      </div>
                    )}
                    {error && (
                      <div className="row mt-2">
                        <div className="col-24 text-danger">{error}</div>
                      </div>
                    )}
                  </>
                )}
                <div className="d-flex p-4 w-100 position-absolute" style={{bottom: '0', right: '0'}}>
                  <button
                    type="button"
                    className="btn w-100 btn-light mr-2"
                    onClick={() => {
                      setAction(null);
                      toggleShowModal();
                    }}>
                    Annuler
                  </button>
                  <button type="submit" className="btn w-100 btn-primary" disabled={loading}>
                    Valider
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default PasswordModal;
