import {LOGOUT, CLEAR_ERRORS} from '../actionTypes';

const errorReducer = (state = {}, action) => {
  const {type, error} = action;

  if (type === LOGOUT || type === CLEAR_ERRORS) {
    return {};
  }

  const matches = /(.*)_(REQUEST|FAILURE)/.exec(type);

  // not a *_REQUEST / *_FAILURE actions, so we ignore them
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    // Store errorMessage
    // e.g. stores errorMessage when receiving GET_TODOS_FAILURE
    //      else clear errorMessage when receiving GET_TODOS_REQUEST
    [requestName]: requestState === 'FAILURE' ? error : ''
  };
};

export default errorReducer;
