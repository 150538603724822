import React, {useMemo, useState} from 'react';
import Step from './Step';

import './MultiStepForm.scss';

const MultiStepForm = ({children, formData, onSubmit, cta, ...rest}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [completedStep, setCompletedStep] = useState(0);

  const [stepCount] = useMemo(() => {
    let nbSteps = 0;
    React.Children.forEach(children, child => {
      if (child.props.stepCard) {
        nbSteps += 1;
      }
    });
    return [nbSteps];
  }, [children]);

  const validateForm = e => {
    e && e.preventDefault();
    onSubmit(currentStep, next);
  };

  const next = () => {
    if (currentStep < stepCount - 1) {
      const nextStep = currentStep + 1;
      setCurrentStep(nextStep);
      if (completedStep < nextStep) {
        setCompletedStep(nextStep);
      }
    }
  };

  return (
    children && (
      <div className="multiStepForm">
        {React.Children.map(children, (child, i) => {
          if (child.props.stepCard) {
            const props = {
              stepCount,
              currentStep,
              setCurrentStep,
              completedStep,
              setCompletedStep,
              step: i,
              formData,
              cta: i === stepCount - 1 ? cta : 'Continuer',
              onSubmit: validateForm,
              ...rest
            };

            return React.cloneElement(child, props);
          }
          return child;
        })}
      </div>
    )
  );
};

MultiStepForm.Step = Step;

export default MultiStepForm;
