const appName = 'Julaya Pro';
const apiUrl = process.env.REACT_APP_API_URL;
const platform = process.env.REACT_APP_PLATFORM;
const julayaOriginPro = 'Julaya.Pro';
const julayaOriginApp = 'Julaya.WebApp';
const secretToken = process.env.REACT_APP_SECRET;
const appVersion = '1.1.0'; // process.env.REACT_APP_VERSION;
const logoutTimeout = 300 * 1000;
const currency = 'FCFA';
const beamerID = 'PLEzKPCd36525';
const ROLES = ['ADMIN', 'READ', 'USER', 'ADMIN-ONLY'];

export default {
  appName,
  apiUrl,
  platform,
  julayaOriginPro,
  julayaOriginApp,
  secretToken,
  logoutTimeout,
  currency,
  appVersion,
  beamerID,
  ROLES
};
