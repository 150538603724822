import CryptoJS from 'crypto-js';
import store from '@julaya/common/store';
import {constants, storage} from '@julaya/common/utils';
import moment from 'moment';
import phoneNumberLib from './phoneNumberLib';

const validateEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const getInteger = str => {
  return /(\d+)/.exec(str !== null || str === '' ? str : '0')[0];
};

const timeConverter = (UNIX_timestamp, type) => {
  const a = new Date(UNIX_timestamp);
  const months = ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'];
  switch (type) {
    case 'month': {
      const year = a.getFullYear().toString().slice(-2);
      const month = months[a.getMonth()];
      return month + ' ' + year;
    }
    case 'day': {
      const day = a.getDate();
      const month = months[a.getMonth()];
      return day + ' ' + month;
    }
    case 'week': {
      return `semaine ${moment(a).isoWeeks()}`;
    }
    case 'all': {
      return `${a.getDate()} ${months[a.getMonth()]} ${a.getFullYear()} | ${a.getHours()}:${
        a.getMinutes() < 10 ? '0' + a.getUTCMinutes() : a.getUTCMinutes()
      }`;
    }
  }
};

const formatMoney = (money, currency = constants.currency) => {
  const currencyString = currency ? ` ${currency}` : '';

  return isNaN(parseInt(money)) ? '' : `${money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}${currencyString}`;
};

const formatPhone = phone => {
  const regExp = /^[0-9\s]*$/g;

  if (!regExp.test(phone)) {
    return phone;
  }

  let cleaned = ('' + phone).replace(/\D/g, '');

  if (cleaned == '') {
    return '';
  }

  const phoneInfo = phoneNumberLib.isValidPhone(cleaned);

  if (phoneInfo) {
    return `+${phoneInfo.dialingCode} ${formatPhone(phoneInfo.nationalPhone)}`;
  } else {
    // format 2 by 2
    if (cleaned.length % 2 == 0) {
      return cleaned.match(/.{1,2}/g).join(' ');
    }

    //format senegal
    if (cleaned.length === 9) {
      let match = cleaned.match(/^(\d{2})(\d{3})(\d{2})(\d{2})$/);
      if (match) {
        return match[1] + ' ' + match[2] + ' ' + match[3] + ' ' + match[4];
      }
    }

    // format others 123 45 67 89...
    let x = phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,2})(\d{0,2})(\d{0,2})(\d{0,2})/);
    return `${x[1] + ' ' + x[2] + ' ' + x[3] + ' ' + x[4] + ' ' + x[5]}`.trim();
  }
};

const getPagination = (page, length, currentPageLength, loading) => {
  let disabledLeft = true;
  let disabledRight = true;
  const maxNbPage = Math.ceil(length / 20) || 1;
  if (currentPageLength > 0 && !loading) {
    if (page > 1) {
      disabledLeft = false;
    }
    if (page < maxNbPage) {
      disabledRight = false;
    }
  }
  return {
    maxNbPage,
    disabledLeft,
    disabledRight
  };
};

const slugify = str => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaaeeeeiiiioooouuuunc------';

  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
};

const map = [
  {icon: 'icon-swap-horiz', image: 'airtime'},
  {icon: 'icon-swap-horiz', image: 'buy-crypto'},
  {icon: 'icon-rechargement', image: 'buy'},
  {icon: 'icon-swap-horiz', image: 'cashin'},
  {icon: 'icon-swap-horiz', image: 'cashout-fees'},
  {icon: 'icon-swap-horiz', image: 'cashout'},
  {icon: 'icon-swap-horiz', image: 'challenge'},
  {icon: 'icon-swap-horiz', image: 'disposal'},
  {icon: 'icon-swap-horiz', image: 'invoice'},
  {icon: 'icon-swap-horiz', image: 'referal-network'},
  {icon: 'icon-swap-horiz', image: 'referal'},
  {icon: 'reload', image: 'refund'},
  {icon: 'icon-swap-horiz', image: 'transfer-in'},
  {icon: 'icon-swap-horiz', image: 'transfer-out'},
  {icon: 'icon-swap-horiz', image: 'welcome'},
  {icon: 'icon-deposit', image: 'bank-transfer'},
  {icon: 'icon-deposit', image: 'bank-disposal'}
];

const checkUrl = s => {
  const regexp = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
  return regexp.test(s);
};

const getTransactionIcon = url => {
  let found = {};

  if (checkUrl(url)) {
    const imageName = url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.'));

    found =
      map.find(el => {
        return el.image === imageName;
      }) || {};

    if (!found.icon) {
      found.icon = 'icon-swap-horiz';
    }
  } else {
    found.icon = 'icon-swap-horiz';
  }

  return found.icon;
};

const isMobile = userAgent => {
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      userAgent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      userAgent.substr(0, 4)
    )
  ) {
    return true;
  }
  return false;
};

const parseQueryString = () => {
  const qs = window.location.search.replace('?', '');

  const items = qs.split('&');

  return items.reduce((data, item) => {
    const [key, val] = item.split('=');
    const value = decodeURIComponent(val);
    if (data[key] !== undefined) {
      if (!Array.isArray(data[key])) {
        data[key] = [data[key]];
      }

      data[key].push(value);
    } else {
      data[key] = value;
    }

    return data;
  }, {});
};

const replaceAll = (str, mapObj) => {
  var re = new RegExp('\\[' + Object.keys(mapObj).join('\\]|\\[') + '\\]', 'gi');
  return str.replace(re, matched => {
    return mapObj[matched.replace(/\[/g, '').replace(/\]/g, '')];
  });
};

const encryptCode = async code => {
  const accessToken = await storage.get('accessToken');
  return CryptoJS.AES.encrypt(`${code}`, `${accessToken.id}`).toString();
};

const convertCamelToKebab = string => {
  return string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
};

const convertKebabToCamel = str => {
  let arr = str.split('-');
  let capital = arr.map((item, index) => (index ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase() : item.toLowerCase()));
  let capitalString = capital.join('');

  return capitalString;
};

const newPhoneFormat = phone => {
  // const operators = {
  //   MOOV: ['01', '02', '03', '40', '41', '42', '43', '51', '52', '53', '70', '71', '72', '73'],
  //   MTN: ['04', '05', '06', '44', '45', '46', '54', '55', '56', '64', '65', '66', '74', '75', '76', '84', '85', '86', '95'],
  //   ORANGE: ['07', '08', '09', '47', '48', '49', '57', '58', '59', '67', '68', '69', '77', '78', '79', '87', '88', '89', '97']
  // };
  // if (phone.length === 8) {
  //   const prefix = String(phone).substring(0, 2);
  //   if (operators.ORANGE.includes(prefix)) {
  //     phone = `07${phone}`;
  //   } else if (operators.MTN.includes(prefix)) {
  //     phone = `05${phone}`;
  //   } else if (operators.MOOV.includes(prefix)) {
  //     phone = `01${phone}`;
  //   }
  // }

  phone = phone == '08137594' ? '0708137594' : phone;
  return phone;
};

const getBatchTotal = item => {
  if (item?.metadata?.total) {
    return `${item.metadata.total} destinataire${!isNaN(parseInt(item.metadata.total)) && parseInt(item.metadata.total) > 1 ? 's' : ''}`;
  }
  return `-`;
};

const getBatchSum = item => {
  var sum = '';
  if (item?.metadata?.sum) {
    sum = formatMoney(item.metadata.sum);
  }
  return `-${sum}`;
};

const getBatchFees = item => {
  let fees = '-';
  if (item?.metadata?.fees) {
    fees = formatMoney(item.metadata.fees);
  }
  return `${fees}`;
};

const getValidatorsName = approvers => {
  if (Array.isArray(approvers) && approvers.length > 1) {
    const [_, ...validators] = approvers;
    return validators.map(i => i.name).join(', ');
  }
  return null;
};

const isRetry = item => {
  if (item.metadata && item.metadata.nbFailed > 0 && item.metadata.nbPending === 0 && item.status !== 'complete' && item.status !== 'cancelled') {
    item.status = 'to-retry';
    return item;
  }
  return item;
};

const getContributorContact = countryCode => {
  switch (countryCode) {
    case '00221':
      return '77 338 4406';
    case '00225':
      return '22 01 86 16';
    default:
      break;
  }
};

const getFileExtension = filename => {
  if (filename && filename.split('.')) {
    return filename.split('.').pop();
  } else {
    return null;
  }
};

export const phoneNumber = phoneNumberLib;

export default {
  phoneNumber,
  formatMoney,
  newPhoneFormat,
  formatPhone,
  getPagination,
  validateEmail,
  slugify,
  getInteger,
  getTransactionIcon,
  isMobile,
  timeConverter,
  parseQueryString,
  replaceAll,
  encryptCode,
  convertCamelToKebab,
  convertKebabToCamel,
  getBatchTotal,
  getBatchSum,
  getBatchFees,
  getValidatorsName,
  isRetry,
  getContributorContact,
  getFileExtension
};
