/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';

import {useDebounce, useDialog} from '@julaya/common/hooks';

import {Searchbar, PageWrapper, Overlay} from 'components/UI';
import TasksKanban from 'components/UI/Tasks/TasksKanban';
import Filter from './Filter';
import {Icon} from '@julaya/common/system';

const Tasks = props => {
  const {currentCompanyId, getUserCompanies, updateAppNavigation, userCompanies, getBatchesTasks} = props;
  const [query, setQuery] = useState('');
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [showFilter, toggleShowFilter] = useDialog();

  // Change Header Reload Action & also the title by adding title:''

  useEffect(() => {
    let currentCompany = '';
    if (Array.isArray(userCompanies) && userCompanies.length > 0) {
      currentCompany = userCompanies.find(company => company.id === currentCompanyId)?.name || '';

      updateAppNavigation({
        title: currentCompany,
        icon: 'reload',
        action: () => {
          getBatchesTasks({
            query
          });
        }
      });
    } else {
      getUserCompanies();
    }

    return () => {
      updateAppNavigation();
    };
  }, [userCompanies, currentCompanyId]);

  // Query Handler
  const debouncedSearchTerm = useDebounce(query, 700);

  // CALL FOR KANBAN
  const callGetBatchesTasks = () => {
    getBatchesTasks({
      query
    });
  };

  return (
    <>
      <PageWrapper className="tasks">
        <Searchbar
          value={query}
          onChange={setQuery}
          onSubmitQuerySearch={callGetBatchesTasks}
          name="tasks-search-only-query"
          placeholder="Rechercher..."
          button={{
            onClick: toggleShowFilter
          }}
        />

        <TasksKanban debouncedSearchTerm={debouncedSearchTerm} callGetBatchesTasks={callGetBatchesTasks} selectedFilter={selectedFilter} {...props} />
      </PageWrapper>

      {/* Filter Overlay */}
      <Overlay
        open={showFilter}
        toggle={() => {
          toggleShowFilter();
        }}
        backdrop={true}>
        <Overlay.Header className="d-flex align-items-center">
          <a
            href="#julaya"
            onClick={e => {
              e.preventDefault();
              toggleShowFilter();
            }}>
            <Icon name="adjustments-vertical" size="h5" />
          </a>
          <div className="ml-3 m-0 h5 font-weight-bold">Filtres</div>
        </Overlay.Header>
        <Overlay.Content>
          <Filter {...props} query={query} setQuery={setQuery} status={selectedFilter} setStatus={setSelectedFilter} />
        </Overlay.Content>
        <Overlay.Footer>
          <button
            type="button"
            className="btn btn-block btn-primary"
            onClick={() => {
              toggleShowFilter();
            }}
            disabled={query === '' && selectedFilter.length === 0}>
            Appliquer les filtres
          </button>
        </Overlay.Footer>
      </Overlay>
    </>
  );
};

export default Tasks;
