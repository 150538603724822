/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import { Route, Redirect } from "react-router-dom";

//NotFound
import NotFound from "features/NotFound";

//Auth
import Login from "features/Login";

import EntrepriseSwitch from "features/EntrepriseSwitch";

//App
import Dashboard from "features/Dashboard";
import AccountsList from "features/AccountsList";
import Wallet from "features/Wallet";
import WalletGestion from "features/WalletGestion";
import History from "features/History";
import HistoryDetails from "features/HistoryDetails";

// Transfers
import BankDisposal from "features/BankDisposal";
import RapidTransfer from "features/RapidTransfer";

// => Tasks
import Tasks from "features/Tasks";
import TaskList from "features/TaskList";
import TaskDetail from "features/TaskDetail";
import TaskValidate from "features/TaskValidate";
import TaskTransactions from "features/TaskTransactions";

//
import Profile from "features/Profile";
import ProfileEdit from "features/ProfileEdit";

// NOTE:
// Ajouter menu: "wallets" | "profile" | "tasks" pour afficher le menu et highlight le menu-item concerné
// Ajouter goBack: true pour afficher chevron left (<) avec le clique history.goBack sur le HeaderBar
// Ajouter title: "TITLE" definir un titre sur le header

const authRoutes = [
  {
    path: "/",
    component: Login,
  },
];
const appRoutes = [
  {
    path: "/",
    component: EntrepriseSwitch,
    title: "Choix de l’entreprise",
  },
  {
    path: "/dashboard",
    component: Dashboard,
    menu: "wallets",
  },
  {
    path: "/tasks",
    component: Tasks,
    menu: "tasks",
    children: [
      {
        path: "/:batchType",
        goBack: true,
        title: "Retour",
        component: TaskList,
      },
      {
        path: "/details/:batchId",
        title: "Détails de la tâche",
        goBack: true,
        component: TaskDetail,
      },
      {
        path: "/details/:walletId/:batchId/transactions",
        component: TaskTransactions,
        goBack: true,
        title: "Liste des transactions",
      },
      {
        path: "/:walletId/:taskId/validate",
        component: TaskValidate,
      },
    ],
  },
  {
    path: "/accounts/:walletType/:walletId/history",
    component: History,
    goBack: true,
    title: "Historique de compte",
    menu: "wallets",
  },
  {
    path: "/bank-disposal",
    component: BankDisposal,
    goBack: true,
    title: "Recharger mon compte",
  },
  {
    path: "/accounts/:walletType",
    component: AccountsList,
    goBack: true,
    title: "Retour",
    menu: "wallets",
    children: [
      {
        path: "/:walletId",
        component: Wallet,
        goBack: true,
        title: "Retour",
        menu: "wallets",
      },
      {
        path: "/:walletId/gestion",
        component: WalletGestion,
        goBack: true,
        title: "Gérer ma carte",
        menu: "wallets",
      },
      {
        path: "/:walletId/transfer",
        component: RapidTransfer,
        goBack: true,
        title: "Transfert rapide",
        menu: "wallets",
      },
    ],
  },
  {
    goBack: true,
    title: "Transfert rapide",
    path: "/transfer/rapid-transfer",
    component: RapidTransfer,
  },
  {
    goBack: true,
    title: "Récapitulatif",
    menu: "wallets",
    path: "/history/details",
    component: HistoryDetails,
  },
  {
    path: "/profile",
    component: Profile,
    menu: "profile",
    children: [
      {
        path: "/edit",
        component: ProfileEdit,
        goBack: true,
        title: "Modifier le profil",
        menu: "profile",
      },
    ],
  },

  {
    path: "/password-reset",
    component: () => <Redirect to="/" />,
  },
  {
    path: "/tasks",
    component: Tasks,
    menu: "tasks",
    children: [
      {
        path: "/:batchType",
        goBack: true,
        title: "Retour",
        component: TaskList,
      },
      {
        path: "/details/:batchId",
        title: "Détails de la tâche",
        goBack: true,
        component: TaskDetail,
      },
      {
        path: "/details/:walletId/:batchId/transactions",
        component: TaskTransactions,
        goBack: true,
        title: "Liste des transactions",
      },
      {
        path: "/:walletId/:batchId/validate",
        component: TaskValidate,
      },
    ],
  },
  {
    path: "/accounts/:walletType/:walletId/history",
    component: History,
    goBack: true,
    title: "Historique de compte",
    menu: "wallets",
  },
  {
    path: "/bank-disposal",
    component: BankDisposal,
    goBack: true,
    title: "Recharger mon compte",
  },

  {
    goBack: true,
    title: "Récapitulatif",
    menu: "wallets",
    path: "/history/details",
    component: HistoryDetails,
  },
  {
    path: "/profile",
    component: Profile,
    menu: "profile",
    children: [
      {
        path: "/edit",
        component: ProfileEdit,
        goBack: true,
        title: "Modifier le profil",
        menu: "profile",
      },
    ],
  },

  {
    path: "/password-reset",
    component: () => <Redirect to="/" />,
  },
];

const getRoutes = (
  routes,
  isAuth = false,
  acl = null,
  parentPath = "",
  idx = ""
) => {
  const router = routes.reduce((acc, route, index) => {
    let children = [];
    const exact = route.hasOwnProperty("exact") ? route.exact : true;

    if (route.children !== undefined) {
      children = getRoutes(route.children, isAuth, acl, route.path, index);
    }
    acc.push(
      <Route
        path={`${parentPath === "/" ? "" : parentPath}${route.path}`}
        component={route.component}
        key={`route-${idx}${index}`}
        exact={exact}
      />
    );

    return [...acc, ...children];
  }, []);

  if (parentPath === "") {
    router.push(
      <Route
        render={(props) => <NotFound {...props} isAuth={isAuth} />}
        key={`route-not-found`}
      />
    );
  }
  return router;
};

const getNavigation = (routes, parentPath = "") => {
  const data = routes.reduce((acc, route) => {
    const path = `${parentPath === "/" ? "" : parentPath}${route.path}`;

    const navigation = {};

    if (route.menu) {
      navigation.menu = route.menu;
    }
    if (route.title) {
      navigation.title = route.title;
    }
    if (route.goBack) {
      navigation.goBack = route.goBack;
    }

    acc[path] = navigation;

    let children = {};
    if (route.children !== undefined) {
      children = getNavigation(
        route.children,
        `${parentPath === "/" ? "" : parentPath}${route.path}`
      );
    }

    return { ...acc, ...children };
  }, {});

  return data;
};

export default { appRoutes, authRoutes, getRoutes, getNavigation };
