/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import cx from 'classnames';
import {useForm, useDidUpdate, useDialog} from '@julaya/common/hooks';

const Comment = props => {
  const {
    item: {wallet, comment},
    editTransactionComment,
    editCommentLoading,
    editCommentError
  } = props;

  const [isEditing, toogleIsEditing] = useDialog();
  const [values, handleChange] = useForm({
    comment
  });

  useDidUpdate(() => {
    if (editCommentLoading === false && editCommentError === '') {
      toogleIsEditing();
    }
  }, [editCommentLoading]);

  useEffect(() => {
    handleChange({
      target: {
        name: 'comment',
        value: comment || ''
      }
    });
  }, [comment]);

  useEffect(() => {
    if (isEditing) {
      document.querySelector('#comment-textarea').focus();
    }
  }, [isEditing]);

  const onSubmit = e => {
    e.preventDefault();
    editTransactionComment(values);
  };

  if (wallet?.isCard === true) {
    return null;
  }

  if (comment) {
    return <span className={`text-black font-weight-bold`}>{comment}</span>;
  }

  return isEditing ? (
    <form onSubmit={onSubmit} className="mb-4">
      <textarea
        id={'comment-textarea'}
        className={cx('form-control border')}
        name={'comment'}
        placeholder={'Votre commentaire ici'}
        onChange={handleChange}
        autoComplete="off"
        rows="3"
        value={values.comment}></textarea>
      {editCommentError && <div className="text-danger text-sm text-center py-1">{editCommentError}</div>}

      <div className="pt-1">
        <a
          href="#julaya"
          onClick={e => {
            e.preventDefault();
            toogleIsEditing();
          }}
          className="btn btn-light btn-block">
          Annuler
        </a>

        <button type="submit" className="btn btn-primary btn-block" disabled={editCommentLoading}>
          {editCommentLoading && (
            <>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{' '}
            </>
          )}
          Enregistrer
        </button>
      </div>
    </form>
  ) : (
    <a
      href="#julaya"
      onClick={e => {
        e.preventDefault();
        toogleIsEditing();
      }}
      className={`text-primary font-italic`}>
      Ajouter un commentaire
    </a>
  );
};

export default Comment;
