import {Icon} from '@julaya/common/system';

const types = {
  virtual: {
    icon: 'cloud',
    title: 'Carte Virtuelle'
  },
  physical: {
    icon: 'credit-card',
    title: 'Carte physique'
  }
};

const countries = {
  civ: {
    flag: require('@julaya/common/assets/images/flags/ci.png').default
  },
  sen: {
    flag: require('@julaya/common/assets/images/flags/sn.png').default
  },
  ben: {
    flag: require('@julaya/common/assets/images/flags/bj.png').default
  }
};

const CardInfo = props => {
  const {label, reference, country, countryName, type, variant = 'light'} = props;

  return (
    <div className={`wallet-card wallet-card--${variant}`}>
      <div className="wallet__info">
        <div className="d-flex justify-content-center text-body">
          <div className="wallet__label">{label}</div>
          <div className="wallet__reference_dot">Token : {reference}</div>
        </div>

        <div className="wallet__meta justify-content-center">
          <div className={`wallet__type wallet__type--${type}`}>
            <Icon name={types[type].icon} size="h5" />
            <span className="wallet__type-label">{types[type].title}</span>
          </div>

          {country && (
            <div className={`wallet__country wallet__country--${country}`}>
              <img src={`${countries[country].flag}`} alt={'Pays'} />
              <span className="wallet__country-label">{countryName}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default CardInfo;
