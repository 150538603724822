import React from 'react';
import {Modal} from '@julaya/common/ui/molecules';
import {Button, Input, Text} from '@julaya/common/ui/atoms';

import {useForm} from '@julaya/common/hooks';
import './PasswordModal.scss';

const getValidationState = (str, name) => {
  if (name === 'password') {
    if (str === '') {
      return 'Champ obligatoire';
    } else if (str.length < 4) {
      return 'Vous devez indiquer 4 caractères';
    }
  } else if (name === 'otp') {
    if (str === '') {
      return 'Champs obligatoire';
    }

    if (str.length !== 6) {
      return 'Le code doit comporter 6 caractères';
    }
  }
  return false;
};

const PasswordModal = ({
  className,
  toogleModal,
  open,
  title,
  icon,
  color,
  submitText = 'Confirmer',
  cancelText = null,
  tfaActive = false,
  onSubmit: proceed,
  loading,
  message = null,
  messageIcon = null,
  errorMsg = null,
  showSuccess = false,
  successComponent,
  onCancel
}) => {
  const [values, handleChange, errors, setErrors, clearForm] = useForm({
    password: '',
    otp: ''
  });

  const onSubmit = e => {
    e.preventDefault();

    let mErrors = {};
    if (getValidationState(values.password, 'password')) {
      mErrors.password = true;
    }
    if (tfaActive && getValidationState(values.otp, 'otp')) {
      mErrors.otp = true;
    }

    if (Object.keys(mErrors).length !== 0) {
      setErrors(mErrors);
    } else {
      proceed(values.password, values.otp);
    }
  };

  const handleCloseModal = () => {
    if (!showSuccess) {
      if (onCancel) {
        onCancel();
      } else {
        toogleModal();
      }
    }
  };

  return (
    <Modal
      className={className}
      toogleModal={handleCloseModal}
      open={open}
      title={title}
      icon={icon}
      color={'gradiant-pink'}
      showSuccess={showSuccess}>
      <form onSubmit={onSubmit}>
        <Modal.Content>
          <div className="password-modal-content">
            {showSuccess ? (
              successComponent
            ) : (
              <>
                {message && (
                  <div className="password-modal-content__message-container">
                    {messageIcon && (
                      <div className="password-modal-content__icon">
                        <i className={messageIcon} />
                      </div>
                    )}
                    <div className="password-modal-content__message">{message}</div>
                  </div>
                )}
                <div className="font-weight-bold">Mot de passe</div>
                <Input
                  id="password"
                  type="password"
                  name="password"
                  placeholder="Entrez votre mot de passe"
                  value={values.password}
                  onChange={handleChange}
                  autoComplete="off"
                  errorMsg={errors.password ? getValidationState(values.password, 'password') : ''}
                />
                {tfaActive && (
                  <Input
                    label="Code d’authentification deux facteurs"
                    id="otp"
                    type="text"
                    name="otp"
                    placeholder="Entrez votre code temporaire"
                    value={values.otp}
                    onChange={handleChange}
                    errorMsg={errors.otp ? getValidationState(values.otp, 'otp') : ''}
                    autoComplete="off"
                  />
                )}

                {errorMsg && <Text color="error">{errorMsg}</Text>}
              </>
            )}
          </div>
        </Modal.Content>
        {!showSuccess && (
          <Modal.Footer align="full">
            {!!cancelText && <Button className="mr-2" status="neutral" text={cancelText} cursor action={handleCloseModal} />}
            <Button submit text={submitText} loading={loading} disabled={loading} cursor />
          </Modal.Footer>
        )}
      </form>
    </Modal>
  );
};

export default PasswordModal;
