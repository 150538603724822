import React from 'react';
import cx from 'classnames';

// TODO import { useIntl } from "react-intl";

import Icon from '../../DataDisplay/Icon/Icon';
import Spinner from '../../DataDisplay/Spinner/Spinner';

import './Button.scss';

const loaderMap = {
  '': 'xs',
  md: 'xs',
  lg: 'sm',
  sm: 'xs'
};

const Button = props => {
  const {
    children,
    tag: Tag = 'button',
    prefixIcon,
    suffixIcon,
    block,
    color = 'primary',
    btnType = 'primary',
    onClick: _onClick = null,
    size,
    status,
    className,
    disabled = false,
    nowrap,
    btnRef = null,
    ...attrs
  } = props;

  // TODO const { formatMessage: t } = useIntl();

  // prepare prefix and suffix icons
  const prefixClasses = cx('jla-btn__prefix', {
    'jla-btn__prefix-sm': size === 'sm',
    'jla-btn__prefix-lg': size === 'lg'
  });
  const suffixClasses = cx('jla-btn__suffix', {
    'jla-btn__suffix-sm': size === 'sm',
    'jla-btn__suffix-lg': size === 'lg'
  });
  let prefix = prefixIcon ? <Icon name={prefixIcon} className={prefixClasses} /> : null;
  const suffix = suffixIcon ? <Icon name={suffixIcon} className={suffixClasses} /> : null;

  // update prefix if status exists
  if (status === 'loading') {
    prefix = <Spinner size={loaderMap[size || '']} className={prefixClasses} />;
  }

  // add missing attributes for "a" tag and "button" tag
  if (Tag === 'a' && !attrs.href) {
    attrs.href = '#julaya';
  } else if (attrs.href) {
    Tag = 'a';
  } else if (Tag === 'button') {
    attrs.disabled = disabled;
    if (_onClick) {
      attrs.type = 'button';
    }
  }

  if (btnRef) {
    attrs.ref = btnRef;
  }

  // prepare onClick function
  const onClick = e => {
    if (_onClick) {
      if (disabled) {
        e.preventDefault();
        return;
      }

      if (_onClick) {
        return _onClick(e);
      }
    }
  };

  // add onClick to attributes
  if (_onClick) {
    attrs.onClick = onClick;
  }

  // prepare button classes
  const classList = cx('jla-btn', `jla-btn--${color}-${btnType}`, className, {
    'jla-btn--block': block,
    [`jla-btn--${color}-${btnType}-disabled`]: Tag !== 'button' && disabled,
    [`jla-btn--${size}`]: !!size,
    [`jla-btn--nowrap`]: nowrap
  });

  return (
    <Tag {...attrs} className={classList}>
      {prefix}
      <span>{children}</span>
      {suffix}
    </Tag>
  );
};

export default Button;
