import React, {createContext, useContext, useMemo, useEffect, useState} from 'react';
import cx from 'classnames';
import {useDidUpdate} from '@julaya/common/hooks';

import Bullet from '../../DataDisplay/Bullet/Bullet';
import Typography from '../../DataDisplay/Typography/Typography';
import Avatar from '../../DataDisplay/Avatar/Avatar';
import Icon from '../../DataDisplay/Icon/Icon';
import Button from '../../Inputs/Button/Button';
import Surface from '../Surface/Surface';

import './Modal.scss';

const ModalContext = createContext({});
const sizes = ['sm', 'md', 'lg', 'xl'];

const Header = props => {
  const {title = '', subtitle = '', ...args} = props;

  const toogleModal = useContext(ModalContext);

  return (
    <Surface.Header justify="between">
      <Bullet bullet={<Avatar {...args} />}>
        <Typography lead>{title}</Typography>
        {subtitle && <Typography variant="small">{subtitle}</Typography>}
      </Bullet>
      <Button onClick={toogleModal} btnType="tertiary" color="primary" type="button">
        <Icon name="x-mark-filled" />
      </Button>
    </Surface.Header>
  );
};

const Body = ({children, ...args}) => {
  return (
    <Surface.Body {...args} grow>
      {children}
    </Surface.Body>
  );
};

const Footer = props => {
  const {cancelBtn, saveBtn, onCancel = () => {}, onSave = () => {}, saveBtnType, saveBtnLoading} = props;
  const toogleModal = useContext(ModalContext);
  const footerProps = {};
  const cancelProps = {
    color: 'neutral',
    btnType: 'secondary',
    onClick: () => {
      onCancel();
      toogleModal();
    }
  };
  const saveProps = {
    color: 'primary',
    onClick: saveBtnType !== 'submit' ? onSave : undefined,
    type: saveBtnType,
    status: saveBtnLoading ? 'loading' : null,
    disabled: saveBtnLoading
  };

  if (cancelBtn) {
    if (typeof cancelBtn === 'string') cancelProps.children = cancelBtn;
    else cancelProps.children = 'Annuler';
  }

  if (saveBtn) {
    if (typeof saveBtn === 'string') saveProps.children = saveBtn;
    else saveProps.children = 'Valider';
  }

  if (cancelBtn && saveBtn) {
    saveProps.block = true;
    cancelProps.block = true;
  } else {
    footerProps.justify = 'end';
  }

  return (
    <Surface.Footer gap="md" {...footerProps}>
      {cancelBtn && <Button {...cancelProps} />}
      {saveBtn && <Button {...saveProps} />}
    </Surface.Footer>
  );
};

const Modal = props => {
  const {
    children,
    isOpen = false,
    toogleModal = () => {},
    keyboard = true, // allow ESC to be pressed to close drawer
    onOpened = () => {}, // callback when the modal was opened fully
    onClosed = () => {}, // callback when the modal has been closed fully,
    size = 'sm',

    unmountOnClose, // stop rendering component after it was closed
    backdrop, // if set to static the modal will not close when clicking outside
    overflowVisible
  } = props;

  const [unmount, setUnmount] = useState(false);
  const [closeBlocked, setCloseBlocked] = useState(false);

  // share the toogle function with other elements
  const value = useMemo(() => toogleModal, [toogleModal]);

  // if keyboard
  useEffect(() => {
    if (keyboard) {
      const handleKeyUp = e => {
        if (isOpen && e.keyCode === 27) {
          if (backdrop === 'static') {
            e.preventDefault();
            e.stopPropagation();
            handleAnimation();
          } else {
            if (keyboard) {
              e.preventDefault();
              e.stopPropagation();
              toogleModal();
            }
          }
        }
      };
      document.addEventListener('keyup', handleKeyUp);

      return () => {
        document.removeEventListener('keyup', handleKeyUp);
      };
    }
  }, [keyboard, isOpen]);

  // if unmountOnClose
  useDidUpdate(() => {
    if (isOpen) {
      onOpened();
      if (unmountOnClose && unmount) {
        setUnmount(false);
      }
    } else {
      onClosed();
      if (unmountOnClose) {
        setTimeout(() => {
          setUnmount(true);
        }, 500);
      }
    }
  }, [isOpen]);

  const handleAnimation = () => {
    setCloseBlocked(true);
    setTimeout(() => {
      setCloseBlocked(false);
    }, 120);
  };

  if (unmount) {
    return null;
  }

  return (
    <ModalContext.Provider value={value}>
      <div
        className={cx('jla-modal', {
          'jla-modal--open': isOpen,
          'jla-modal--backdrop-anim': closeBlocked
        })}>
        <a
          href="#jla"
          onClick={e => {
            e.preventDefault();
            e.preventDefault();
            if (backdrop === 'static') {
              handleAnimation();
            } else {
              toogleModal();
            }
          }}
          className={cx('jla-modal__overlay')}></a>

        <div className={`jla-modal__dialog jla-modal__dialog--centered  jla-modal__dialog--${sizes.includes(size) ? size : 'sm'}`}>
          <div
            className={cx('jla-modal__content', {
              'jla-modal__content--overflow-visible': overflowVisible
            })}>
            {children}
          </div>
        </div>
      </div>
    </ModalContext.Provider>
  );
};

Header.displayName = 'Modal.Header';
Body.displayName = 'Modal.Body';
Footer.displayName = 'Modal.Footer';

Modal.Header = Header;
Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;
