import React, {useMemo, useEffect} from 'react';
import {Switch} from 'react-router-dom';
import {Spinner} from 'reactstrap';

import routing from '../../../routing';

const AuthLayout = () => {
  const routes = useMemo(() => {
    return routing.getRoutes(routing.authRoutes, true);
  }, []);
  useEffect(() => {
    (function () {
      var d = document;
      var s = d.createElement('script');
      s.src = 'https://embed.typeform.com/embed.js';
      s.async = 1;
      d.getElementsByTagName('head')[0].appendChild(s);
    })();
  }, []);

  const pathname = window.location.pathname;
  const hubspot = document.querySelector('body div#hubspot-messages-iframe-container');

  useEffect(() => {
    if (hubspot) {
      hubspot.classList.remove('custom-hubspot');
    }
  }, [hubspot, pathname]);

  return (
    <div className="auth-layout">
      {routes ? <Switch>{routes}</Switch> : <Spinner type="grow" color="info" style={{width: '5rem', height: '5rem'}} />}
    </div>
  );
};

export default AuthLayout;
