import { BankDisposalStatus } from '@julaya/common/ui/organismes';
import { moment } from '@julaya/common/utils';
import { PageWrapper, TransactionIcon } from 'components/UI';
import { Redirect } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import DepositReceipt from './DepositReceipt';
import DetailRow from './DetailRow';
import { details } from './extra';
import Footer from './Footer';
import Receipts from './Receipts';

const Details = ({
  selectedItem,
  attachedReceipt,
  attachReceiptLoading,
  attachReceiptError,
  attachReceipt,
  attachedDepositReceipt,
  attachDepositReceiptLoading,
  attachDepositReceiptError,
  attachDepositReceipt,
  editTransactionParamsLoading,
  editTransactionParamsError,
  goTo,
  wallet,
  user,
  editTransactionComment,
  editCommentLoading,
  editCommentError
}) => {
  if (!selectedItem) {
    return <Redirect to={'/'} />;
  }

  const type =
    selectedItem.typeSlug === 'momo-deposit'
      ? 'momo-receipt'
      : selectedItem.typeSlug === 'bank-disposal'
      ? 'bank-receipt'
      : selectedItem.typeSlug === 'merchant-payment'
      ? 'merchant-payment'
      : null;

  const onFileLoaded = (type, e) => {
    e.preventDefault();
    var files = e.target.files;

    const data = {
      file: files[0],
      transactionId: selectedItem.uuid,
      walletId: selectedItem.walletId
    };

    const filesize = (files[0].size / 1024 / 1024).toFixed(4);
    if (filesize > 2) {
      alert('Veuillez selectionner un fichier de moins de 2MB');
    } else if (type === 'receipt') {
      attachReceipt(data, type);
    } else {
      attachDepositReceipt(data, type);
    }

    e.target.value = null;
  };

  return (
    <PageWrapper className="history-details">
      <div className="d-flex justify-content-between align-items-center pb-4">
        <div>
          <div className="font-weight-bold mb-1"> Détails de l'opération</div>
          <div className="text-sm text-muted">{selectedItem.type}</div>
        </div>

        <TransactionIcon item={selectedItem} invColor={false} />
      </div>
      <div className="border-top mx--4"></div>

      <div className="py-3">
        {selectedItem.status === 'waiting' && (
          <div className="pb-2">
            <div className="mb-1 font-weight-bold">Tout est bon ! Julaya s’occupe de traiter votre opération.</div>
          </div>
        )}
        <BankDisposalStatus selectedItem={selectedItem} bg="white" />
        {Object.keys(details).map(key => (
          <DetailRow
            {...{
              item: selectedItem,
              detailKey: key,
              key,
              goTo,
              editTransactionParamsLoading,
              editTransactionParamsError,
              wallet,
              user,
              editTransactionComment,
              editCommentLoading,
              editCommentError
            }}
          />
        ))}

        <div className="mx--2">
          <Container fluid className="text-muted">
            {selectedItem.parents && selectedItem.parents.length ? (
              <>
                <Row>
                  <Col xs={24}>
                    <div className="d-flex align-items-center mr-1 mb-2">
                      <i className="text-danger icon-cancel pr-1"></i>
                      <span>Historique de relance</span>
                    </div>
                  </Col>
                </Row>
                {selectedItem.parents.map(parent => (
                  <Row key={parent.uuid}>
                    <Col xs={24}>
                      <div className="title font-weight-bold">{moment(parent.createdAt).format('dddd D MMM. Y - HH:mm')}</div>

                      <div className={`title`}>{parent.uuid}</div>
                    </Col>
                  </Row>
                ))}
              </>
            ) : null}

            <div className="border-top mx--4"></div>

            <Row>
              <Col xs={24}>
                <Receipts
                  item={selectedItem}
                  attachedReceipt={attachedReceipt}
                  attachReceiptLoading={attachReceiptLoading}
                  attachReceiptError={attachReceiptError}
                  onFileLoaded={onFileLoaded}
                />
                <DepositReceipt item={selectedItem} type={type} />
              </Col>
            </Row>
          </Container>

          <Footer
            item={selectedItem}
            attachedDepositReceipt={attachedDepositReceipt}
            attachDepositReceiptLoading={attachDepositReceiptLoading}
            attachDepositReceiptError={attachDepositReceiptError}
            onFileLoaded={onFileLoaded}
          />
        </div>
      </div>
    </PageWrapper>
  );
};

export default Details;
