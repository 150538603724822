import links from './links';
import transactions from './transactions';
import general from './general';
import tasks from './tasks';
import userInfo from './userInfo';
import sidebar from './sidebar';

export default {
  ...links,
  ...transactions,
  ...general,
  ...tasks,
  ...userInfo,
  ...sidebar,
};
