/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {PageWrapper} from 'components/UI';
import avatar from '@julaya/common/assets/images/app/default-avatar.png';
import {Icon} from '@julaya/common/system';

const Profile = ({user, logout, updateAppNavigation, getUserInfo, userCompanies, getUserCompanies, currentCompanyId}) => {
  const userAvatar = user.avatar !== null ? user.avatar : avatar;
  const [info, setInfo] = useState({
    userName: ''
  });
  const [currentCompany, setCurrentCompany] = useState(null);

  const hasVerifiedCardHolder = user?.CardHolder?.isCardHolder && ['waiting', 'done'].includes(user?.CardHolder?.kycStatus);

  const rolesSelect = {
    ADMIN: 'Administrateur',
    USER: 'Signataire',
    READ: 'Lecture seule'
  };

  useEffect(() => {
    if (Array.isArray(userCompanies) && userCompanies.length > 0) {
      setCurrentCompany(userCompanies.find(company => company.id === currentCompanyId));
    }
  }, [userCompanies, currentCompanyId]);

  useEffect(() => {
    updateAppNavigation({
      title: currentCompany?.name,
      icon: 'reload',
      action: () => {
        getUserInfo(currentCompanyId);
      }
    });
    return () => {
      updateAppNavigation();
    };
  }, [currentCompany]);

  useEffect(() => {
    const {firstname, lastname} = user;
    let infoUpdate = {};
    if (user) {
      infoUpdate.userName = `${firstname} ${lastname.toUpperCase()}`;
      setInfo(infoUpdate);
    }
  }, [JSON.stringify(user)]);

  useEffect(() => {
    if (userCompanies.length === 0) getUserCompanies();
  }, []);

  return (
    <PageWrapper>
      <div className="d-flex flex-column bg-white p-4 rounded">
        <div className="d-flex flex-column align-items-center mb-2">
          <img src={userAvatar} alt="avatar" className="avatar-lg img-thumbnail rounded" />
        </div>
        <div className="d-flex flex-column align-items-center mb-3">
          <div className="font-weight-bold">{info.userName}</div>
          {currentCompany && (
            <>
              <div className="text-m text-muted">{currentCompany.name}</div>
              <div className="text-m text-muted">{'Compte ' + rolesSelect[currentCompany.role]}</div>
            </>
          )}
        </div>

        {hasVerifiedCardHolder ? (
          <div className="row">
            <div className="col-24">
              <div className="alert alert-secondary d-flex align-items-center text-black font-weight-bold py-4">
                <Icon className="mr-3 text-secondary" name="information-circle" size="h5" />
                {user.CardHolder.kycStatus === 'waiting' ? (
                  <span>Utilisateur en cours de vérification veuillez contacter le support pour le modifier</span>
                ) : (
                  <span>Utilisateur vérifié veuillez contacter le support pour le modifier</span>
                )}
              </div>
            </div>
          </div>
        ) : (
          <Link to="/profile/edit" className="btn-primary rounded p-2 pl-4 pr-4  d-flex flex-column align-items-center">
            <div className="d-flex align-items-center">
              <div className="text-m">Modifier le profil</div>
            </div>
          </Link>
        )}
      </div>

      <div className="mt-4">
        <ul className="list-group rounded bg-white border-0">
          {userCompanies.length > 1 && (
            <li className="list-group-item border-1">
              <Link to="/" className="text-black d-flex align-items-center" href="/" rel="noopener noreferrer">
                <Icon className="mr-3" name="briefcase" size="h5" />
                <div className="d-flex justify-content-between align-items-center flex-grow-1">
                  <div>
                    <div className="text-m">Mes entreprises</div>
                  </div>
                </div>
              </Link>
            </li>
          )}
          <li className="list-group-item border-1">
            <a className="text-black d-flex align-items-center" href="/" target="_blank" rel="noopener noreferrer">
              <Icon className="mr-3" name="question-mark-circle" size="h5" />
              <div className="d-flex justify-content-between align-items-center flex-grow-1">
                <div>
                  <div className="text-m">Besoin d'aide ?</div>
                </div>
              </div>
            </a>
          </li>

          <li className="list-group-item border-0">
            <a
              className="text-danger d-flex align-items-center"
              href="#julaya"
              onClick={e => {
                e.preventDefault();
                logout();
              }}>
              <Icon className="mr-3" name="arrow-left-on-rectangle" size="h5" />
              <div className="d-flex justify-content-between align-items-center flex-grow-1">
                <div>
                  <div className="text-m">Déconnexion</div>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </PageWrapper>
  );
};

export default Profile;
