import React from 'react';

import cx from 'classnames';
import {iconList} from '@julaya/common/utils/ui';

import './Icon.scss';
import Typography from '../Typography/Typography';

const Icon = props => {
  let {name, size, color, className, tag: Tag = 'i', ...attrs} = props;

  const typoProps = {};

  if (!iconList.includes(name)) {
    name = 'arrows-right-left';
  }

  if (size) {
    typoProps.variant = size;
  }

  if (color) {
    typoProps.color = color;
  }

  if (Object.keys(typoProps).length > 0) {
    return (
      <Typography className={cx(className)} {...typoProps} {...attrs}>
        <Tag className={cx(`jla-icon-${name} secondary`)} />
      </Typography>
    );
  }

  return <Tag {...attrs} className={cx(`jla-icon-${name}`, className)} />;
};

export default Icon;
