import {storage, network, functions} from '@julaya/common/utils';
import {getUserWallets} from './wallets';

import {
  HYDRATE_AUTH_STATE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  USER_INFO_REQUEST,
  USER_INFO_SUCCESS,
  USER_INFO_FAILURE,
  LOGOUT,
  TEMP_CODE_REQUEST,
  TEMP_CODE_SUCCESS,
  TEMP_CODE_FAILURE,
  PASSWORD_UPDATE_REQUEST,
  PASSWORD_UPDATE_SUCCESS,
  PASSWORD_UPDATE_FAILURE,
  UPDATE_USER_INFO_REQUEST,
  UPDATE_USER_INFO_SUCCESS,
  UPDATE_USER_INFO_FAILURE,
  UPLOAD_AVATAR_REQUEST,
  UPLOAD_AVATAR_SUCCESS,
  UPLOAD_AVATAR_FAILURE,
  GENERATE_2FA_KEY_REQUEST,
  GENERATE_2FA_KEY_SUCCESS,
  GENERATE_2FA_KEY_FAILURE,
  ACTIVATE_2FA_KEY_REQUEST,
  ACTIVATE_2FA_KEY_SUCCESS,
  ACTIVATE_2FA_KEY_FAILURE,
  DELETE_2FA_KEY_REQUEST,
  DELETE_2FA_KEY_SUCCESS,
  DELETE_2FA_KEY_FAILURE,
  CREATE_SUBACCOUNT_REQUEST,
  CREATE_SUBACCOUNT_SUCCESS,
  CREATE_SUBACCOUNT_FAILURE,
  EDIT_SUBACCOUNT_REQUEST,
  EDIT_SUBACCOUNT_SUCCESS,
  EDIT_SUBACCOUNT_FAILURE,
  GET_SUBACCOUNT_REQUEST,
  GET_SUBACCOUNT_SUCCESS,
  GET_SUBACCOUNT_FAILURE,
  GET_SUBACCOUNT_DETAILS_REQUEST,
  GET_SUBACCOUNT_DETAILS_SUCCESS,
  GET_SUBACCOUNT_DETAILS_FAILURE,
  ASSIGN_SUBACCOUNT_ROLE_REQUEST,
  ASSIGN_SUBACCOUNT_ROLE_SUCCESS,
  ASSIGN_SUBACCOUNT_ROLE_FAILURE,
  CGU_VALIDATION_REQUEST,
  CGU_VALIDATION_SUCCESS,
  CGU_VALIDATION_FAILURE,
  GET_USER_COMPANIES_REQUEST,
  GET_USER_COMPANIES_SUCCESS,
  GET_USER_COMPANIES_FAILURE,
  SET_USER_PREFERRED_COMPANY_REQUEST,
  SET_USER_PREFERRED_COMPANY_SUCCESS,
  SET_USER_PREFERRED_COMPANY_FAILURE,
  GET_USER_EXPENSE_POLICY_SUCCESS
} from '../actionTypes';
import {sendDocument} from './documents';

export const hydrateState = () => async dispatch => {
  try {
    const user = await storage.get('user');
    const currentCompany = await storage.get('currentCompany');
    const preferredCompany = await storage.get('preferredCompany');
    const bankDisposalOnboardingStatus = await storage.get('bankDisposalOnboardingStatus');

    if (user && currentCompany) {
      if (user.ownPassword === false) {
        dispatch(logout());
      } else {
        dispatch({
          type: HYDRATE_AUTH_STATE,
          payload: {
            user: user,
            isConnected: true,
            ownPassword: user.ownPassword,
            bankDisposalOnboardingStatus: !!bankDisposalOnboardingStatus,
            preferredCompany: preferredCompany || null
          }
        });
        dispatch(getUserInfo(currentCompany));
        dispatch(getUserCompanies());
      }
    } else {
      try {
        // const data = await network.request('/theme/uri', 'GET', null, true);
        dispatch({
          type: HYDRATE_AUTH_STATE,
          payload: {
            // theme: data.theme,
            user: null,
            avatar: null,
            ownPassword: null,
            isConnected: false
          }
        });
      } catch (error) {
        dispatch({
          type: HYDRATE_AUTH_STATE,
          payload: {
            theme: null,
            user: null,
            avatar: null,
            ownPassword: null,
            isConnected: false
          }
        });
      }
    }
  } catch (error) {
    await localStorage.clear();
    dispatch({
      type: HYDRATE_AUTH_STATE,
      payload: {
        theme: null,
        user: null,
        avatar: null,
        ownPassword: null,
        isConnected: false
      }
    });
  }
};
export const login =
  (params, temp = false) =>
  async dispatch => {
    dispatch({type: LOGIN_REQUEST});
    dispatch(logout());

    try {
      const accessToken = await network.request('/auth/login', 'POST', params, true);

      if (accessToken?.tfaActive) {
        dispatch({type: LOGIN_SUCCESS});
        return accessToken;
      }

      const {ttl, id, userId, createdAt, user} = accessToken;
      if (accessToken && userId) {
        const {firstname, lastname, tfaActive, ownPassword, phone} = user;
        await storage.set('accessToken', {ttl, id, userId, createdAt});
        const preferredCompany = await storage.get('preferredCompany');
        if (!temp) {
          await dispatch(getUserCompanies());
        }
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            user: {
              firstname,
              lastname,
              tfaActive,
              phone
            },
            ownPassword,
            isConnected: true,
            preferredCompany,
            currentCompanyId: null
          }
        });
      } else {
        dispatch({
          type: LOGIN_FAILURE,
          error: 'Des erreurs sont survenues, veuillez réessayer'
        });
      }
    } catch (error) {
      dispatch({type: LOGIN_FAILURE, error: error.message});
    }
  };

export const getUserCompanies = () => async dispatch => {
  dispatch({type: GET_USER_COMPANIES_REQUEST});
  try {
    const response = await network.request(`/me/companies`, 'GET');
    dispatch({type: GET_USER_COMPANIES_SUCCESS, payload: {companiesList: response}});
  } catch (error) {
    dispatch({type: GET_USER_COMPANIES_FAILURE, error: error.message});
  }
};

export const fetchAuthUserExpensePolicies = () => async dispatch => {
  try {
    const expensePolicies = await network.request('/me/expence-policies', 'GET');
    dispatch({type: GET_USER_EXPENSE_POLICY_SUCCESS, payload: {expensePolicies}});
  } catch (error) {
    console.log({error});
  }
};

export const resetUserInfo = () => async dispatch => {
  dispatch({type: LOGIN_REQUEST});
  try {
    await storage.remove('currentCompany');
    let user = await storage.get('user');
    const {firstname, lastname, tfaActive, ownPassword, phone} = user;

    dispatch({
      type: LOGIN_SUCCESS,
      payload: {
        user: {
          firstname,
          lastname,
          tfaActive,
          phone
        },
        avatar: user.avatar,
        tfaActive: user.tfaActive,
        ownPassword,
        isConnected: true,
        currentCompanyId: null,
        preferredCompany: null
      }
    });
  } catch (error) {
    dispatch({type: LOGIN_FAILURE, error: error.message});
  }
};

export const getUserInfo = companyId => async dispatch => {
  dispatch({type: USER_INFO_REQUEST});
  try {
    await storage.set('currentCompany', companyId);
    const accessToken = await storage.get('accessToken');
    if (accessToken && accessToken.userId) {
      const user = await network.request(`/me`, 'GET');

      let sendWallets = [];
      let topUpWallets = [];

      await storage.set('user', user);

      await dispatch(
        getUserWallets(
          {
            type: 'ALL',
            access: 'READ'
          },
          'landing',
          true
        )
      );

      dispatch({
        type: USER_INFO_SUCCESS,
        payload: {
          user: user,
          avatar: user.avatar,
          tfaActive: user.tfaActive,
          currentCompanyId: companyId,
          preferredCompany: null
        }
      });

      if (!['READ', 'ADMIN-ONLY'].includes(user.role)) {
        try {
          sendWallets = await network.request(`/me/wallets`, 'POST', {
            type: 'WALLET',
            access: 'SEND'
          });
          topUpWallets = await network.request(`/me/wallets`, 'POST', {
            type: 'WALLET',
            access: 'TOPUP'
          });
        } catch (error) {}
      }

      dispatch({
        type: USER_INFO_SUCCESS,
        payload: {
          canSend: Array.isArray(sendWallets) && sendWallets.length > 0,
          canTopUp: Array.isArray(topUpWallets) && topUpWallets.length > 0
        }
      });

      dispatch(
        getUserWallets(
          {
            access: 'READ'
          },
          'cgu'
        )
      );
    } else {
      dispatch({
        type: USER_INFO_FAILURE,
        error: 'Des erreurs sont survenues, veuillez réessayer'
      });
    }
  } catch (error) {
    dispatch({type: USER_INFO_FAILURE, error: error.message});
  }
};

export const setPreferredCompany =
  (id = null) =>
  async dispatch => {
    dispatch({type: SET_USER_PREFERRED_COMPANY_REQUEST});
    try {
      if (id === null) {
        await storage.remove('preferredCompany');
      } else {
        await storage.set('preferredCompany', id);
      }

      dispatch({type: SET_USER_PREFERRED_COMPANY_SUCCESS});
    } catch (error) {
      dispatch({type: SET_USER_PREFERRED_COMPANY_FAILURE, error: error.message});
    }
  };

export const logout =
  (showError = false) =>
  async dispatch => {
    dispatch({
      type: LOGOUT,
      payload: {
        logoutInit: true
      }
    });

    setTimeout(async () => {
      await storage.clear();
      dispatch({
        type: LOGOUT,
        payload: {
          user: null,
          tfaActive: null,
          avatar: null,
          ownPassword: null,
          isSubaccount: null,
          accessLevels: null,
          isConnected: false,
          timer: 0,
          tempUser: null,
          tfaActivateSuccess: false,
          subaccountCount: 0,
          subaccountList: [],
          logoutInit: false
        }
      });
      if (showError) {
        dispatch({
          type: LOGIN_FAILURE,
          error: 'Veuillez vous identifier pour continuer'
        });
      }
    }, 100);
  };

export const requestTempPassword = params => async dispatch => {
  dispatch({
    type: TEMP_CODE_REQUEST,
    payload: {timer: 0}
  });
  try {
    await network.request(`/auth/reset/password`, 'POST', params, true);
    dispatch({
      type: TEMP_CODE_SUCCESS,
      payload: {tempUser: params, timer: 60}
    });
  } catch (error) {
    dispatch({type: TEMP_CODE_FAILURE, error: error.message});
  }
};

export const updatePassword = (params, loginParams) => async dispatch => {
  dispatch({type: PASSWORD_UPDATE_REQUEST, tempUser: undefined});
  try {
    await network.request('/auth/reset/password/update', 'POST', params);
    const res = await dispatch(login(loginParams));

    dispatch({type: PASSWORD_UPDATE_SUCCESS});
    return res;
  } catch (error) {
    dispatch({type: PASSWORD_UPDATE_FAILURE, error: error.message});
  }
};

export const updateUserInfo = params => async (dispatch, getState) => {
  dispatch({type: UPDATE_USER_INFO_REQUEST});

  try {
    const {
      auth: {user}
    } = getState();

    let result = await network.request(`/me/edit`, 'PUT', params);
    const updatedUser = {...user, ...result};
    await storage.set('user', updatedUser);

    dispatch({
      type: UPDATE_USER_INFO_SUCCESS,
      payload: {
        user: updatedUser,
        avatar: updatedUser.avatar
      }
    });
  } catch (error) {
    dispatch({type: UPDATE_USER_INFO_FAILURE, error: error.message});
  }
};

export const uploadAvatar = params => async (dispatch, getState) => {
  dispatch({type: UPLOAD_AVATAR_REQUEST});

  try {
    const {
      auth: {user}
    } = getState();

    const response = await network.fileUploader(`/me/edit/avatar`, params);

    user.avatar = response.avatar;

    await storage.set('user', user);

    dispatch({
      type: UPLOAD_AVATAR_SUCCESS,
      payload: {
        user,
        avatar: user.avatar
      }
    });
  } catch (error) {
    dispatch({type: UPLOAD_AVATAR_FAILURE, error: error.message});
  }
};

export const generate2faKey = code => async (dispatch, getState) => {
  dispatch({
    type: GENERATE_2FA_KEY_REQUEST
  });

  try {
    const password = await functions.encryptCode(code);

    const response = await network.request(`/me/tfa/generate`, 'POST', {
      password
    });

    dispatch({
      type: GENERATE_2FA_KEY_SUCCESS,
      payload: {
        tfaKey: response.key,
        tfaQrcode: response.qrCode,
        code
      }
    });
  } catch (error) {
    dispatch({type: GENERATE_2FA_KEY_FAILURE, error: error.message});
  }
};

export const activate2faKey = (code, otp) => async (dispatch, getState) => {
  dispatch({
    type: ACTIVATE_2FA_KEY_REQUEST
  });

  try {
    const password = await functions.encryptCode(code);

    await network.request(`/me/tfa/activate`, 'POST', {
      password,
      otp
    });

    dispatch({
      type: ACTIVATE_2FA_KEY_SUCCESS,
      payload: {
        tfaKey: null,
        tfaQrcode: null,
        code: null,
        tfaActive: true,
        tfaActivateSuccess: true
      }
    });
  } catch (error) {
    dispatch({type: ACTIVATE_2FA_KEY_FAILURE, error: error.message});
  }
};

export const delete2faKey = (code, otp) => async (dispatch, getState) => {
  dispatch({
    type: DELETE_2FA_KEY_REQUEST
  });

  try {
    const password = await functions.encryptCode(code);

    await network.request(`/me/tfa/delete`, 'DELETE', {
      password,
      otp
    });

    dispatch({
      type: DELETE_2FA_KEY_SUCCESS,
      payload: {
        tfaActive: false
      }
    });
  } catch (error) {
    dispatch({type: DELETE_2FA_KEY_FAILURE, error: error.message});
  }
};

export const dismiss2faSuccess = () => async dispatch => {
  dispatch({
    type: ACTIVATE_2FA_KEY_SUCCESS,
    payload: {
      tfaActivateSuccess: false
    }
  });
};

export const createSubaccount = params => async (dispatch, getState) => {
  dispatch({
    type: CREATE_SUBACCOUNT_REQUEST
  });

  try {
    const {
      auth: {user}
    } = getState();

    const {passportBehind, passportFront, slug, ...payload} = params;

    const response = await network.request(`/admin/users`, 'POST', {
      ...payload,
      password: await functions.encryptCode(params.password)
    });

    if (params.companyPrefix) {
      user.company.prefix = params.companyPrefix;
    }
    if (params.avatar) {
      const data = {
        avatar: params.avatar
      };
      dispatch(uploadAvatar(data, response.id));
    }

    if (passportBehind || passportFront) {
      const document = {recto: passportFront, verso: passportBehind, slug, cardHolderId: response.id};
      await dispatch(sendDocument(document, response.id));
    }

    dispatch({
      type: CREATE_SUBACCOUNT_SUCCESS,
      payload: {
        subaccountDetails: {
          ...response,
          active: response.isActive,
          prefix: response.gender === 0 ? 'Monsieur' : 'Madame',
          accessType: response.role,
          role: response.role,
          wallets: response.Wallets,
          userWallets: response?.UserWallets || [],
          cardHolder: response.CardHolder
        }
      }
    });
  } catch (error) {
    dispatch({type: CREATE_SUBACCOUNT_FAILURE, error: error.message});
  }
};

export const editSubaccount = (accountId, data) => async dispatch => {
  dispatch({
    type: EDIT_SUBACCOUNT_REQUEST
  });

  try {
    const params = {
      ...data,
      password: await functions.encryptCode(data.password)
    };
    params.code = undefined;

    const {passportBehind, passportFront, slug, ...payload} = params;

    await network.request(`/admin/users/${accountId}/edit`, 'PUT', payload);
    const response = await network.request(`/admin/users/${accountId}`, 'GET');

    if (params.avatar) {
      const data = {
        avatar: params.avatar
      };
      dispatch(uploadAvatar(data, accountId));
    }

    if (passportFront || passportBehind) {
      const document = {
        ...(passportFront && {recto: passportFront}),
        ...(passportBehind && {verso: passportBehind}),
        slug,
        cardHolderId: response.id
      };
      await dispatch(sendDocument(document, response.id));
    }

    dispatch({
      type: EDIT_SUBACCOUNT_SUCCESS,
      payload: {
        subaccountDetails: {
          ...response,
          active: response.isActive,
          prefix: response.gender === 0 ? 'Monsieur' : 'Madame',
          accessType: response.role,
          role: response.role,
          wallets: response.Wallets,
          userWallets: response?.UserWallets || [],
          cardHolder: response.CardHolder
        }
      }
    });
  } catch (error) {
    dispatch({type: EDIT_SUBACCOUNT_FAILURE, error: error.message});
  }
};

export const archiveSubaccount = (accountId, data) => async dispatch => {
  dispatch({
    type: EDIT_SUBACCOUNT_REQUEST
  });

  try {
    const params = {
      ...data,
      password: await functions.encryptCode(data.password)
    };
    params.code = undefined;

    await network.request(`/admin/users/${accountId}/archive`, 'POST', params);

    dispatch({
      type: EDIT_SUBACCOUNT_SUCCESS
    });
  } catch (error) {
    dispatch({type: EDIT_SUBACCOUNT_FAILURE, error: error.message});
  }
};

export const changeSubaccountActiveStatus = (accountId, data) => async dispatch => {
  dispatch({
    type: EDIT_SUBACCOUNT_REQUEST
  });

  try {
    const params = {
      ...data,
      password: await functions.encryptCode(data.password)
    };
    params.code = undefined;

    await network.request(`/admin/users/${accountId}/activate`, 'POST', params);

    dispatch({
      type: EDIT_SUBACCOUNT_SUCCESS
    });
  } catch (error) {
    dispatch({type: EDIT_SUBACCOUNT_FAILURE, error: error.message});
  }
};

export const assignSubaccountRole = (userId, data, code, otp) => async (dispatch, getState) => {
  dispatch({
    type: ASSIGN_SUBACCOUNT_ROLE_REQUEST
  });

  try {
    const {
      auth: {subaccountDetails}
    } = getState();

    const params = {
      wallets: data,
      password: await functions.encryptCode(code),
      otp
    };

    if (subaccountDetails.id === userId) {
      if (subaccountDetails.collectorInfo) {
        params.collectorInfo = {
          walletId: subaccountDetails.collectorInfo.wallet.id,
          phone: subaccountDetails.collectorInfo.phone,
          countryCode: subaccountDetails.collectorInfo.countryCode
        };
      }

      if (subaccountDetails.accessType !== 'COLLECTOR') {
        params.accessType = subaccountDetails.accessType;
      }
    }

    await network.request(`/admin/users/${userId}/edit`, 'PUT', params);

    const response = await network.request(`/admin/users/${userId}`, 'GET');

    dispatch({
      type: ASSIGN_SUBACCOUNT_ROLE_SUCCESS,
      payload: {
        subaccountDetails: {
          ...response,
          active: response.isActive,
          prefix: response.gender === 0 ? 'Monsieur' : 'Madame',
          accessType: response.role,
          role: response.role,
          wallets: response.Wallets,
          userWallets: response?.UserWallets || [],
          cardHolder: response.CardHolder
        }
      }
    });
  } catch (error) {
    dispatch({type: ASSIGN_SUBACCOUNT_ROLE_FAILURE, error: error.message});
  }
};

export const getSubaccounts =
  (params = {}) =>
  async dispatch => {
    dispatch({
      type: GET_SUBACCOUNT_REQUEST
    });

    try {
      const response = await network.request(`/admin/users/list`, 'POST', params);

      dispatch({
        type: GET_SUBACCOUNT_SUCCESS,
        payload: {
          subaccountList: response.users.map(user => ({
            active: user.isActive,
            ...user
          })),
          subaccountCount: response.count
        }
      });
    } catch (error) {
      dispatch({type: GET_SUBACCOUNT_FAILURE, error: error.message});
    }
  };

// createReducerFunction in actions/auth.js
export const ghostLogin = (token, userId) => async dispatch => {
  await storage.set('accessToken', {ttl: 86400, id: token, userId, createdAt: Date.now()});
  await dispatch(getUserCompanies());

  dispatch({
    type: LOGIN_SUCCESS,
    payload: {
      user: {
        firstname: '',
        lastname: '',
        tfaActive: '',
        phone: ''
      },
      ownPassword: true,
      isConnected: true
    }
  });
};

export const getSubaccountDetails =
  (userId = null) =>
  async dispatch => {
    dispatch({
      type: GET_SUBACCOUNT_DETAILS_REQUEST,
      payload: {
        subaccountDetails: null
      }
    });
    if (userId) {
      try {
        const response = await network.request(`/admin/users/${userId}`, 'GET');

        dispatch({
          type: GET_SUBACCOUNT_DETAILS_SUCCESS,
          payload: {
            subaccountDetails: {
              ...response,
              active: response.isActive,
              prefix: response.gender === 0 ? 'Monsieur' : 'Madame',
              accessType: response.role,
              role: response.role,
              wallets: response.Wallets,
              userWallets: response?.UserWallets || [],
              cardHolder: response.CardHolder
            }
          }
        });
      } catch (error) {
        dispatch({type: GET_SUBACCOUNT_DETAILS_FAILURE, error: error.message});
      }
    } else {
      dispatch({
        type: GET_SUBACCOUNT_DETAILS_SUCCESS,
        payload: {
          subaccountDetails: null
        }
      });
    }
  };

export const cguValidation =
  ({cardId, reference}) =>
  async (dispatch, getState) => {
    dispatch({type: CGU_VALIDATION_REQUEST});

    try {
      const {
        auth: {currentCompanyId}
      } = getState();

      await network.request(`/wallets/${cardId}/card/cgu`, 'POST', {reference});
      await dispatch(getUserInfo(currentCompanyId));

      await dispatch(
        getUserWallets(
          {
            type: 'CARD',
            access: ''
          },
          'cgu'
        )
      );

      dispatch({
        type: CGU_VALIDATION_SUCCESS
      });
    } catch (error) {
      dispatch({type: CGU_VALIDATION_FAILURE, error: error.message});
    }
  };
