import {
  GET_HISTORY_REQUEST,
  GET_HISTORY_SUCCESS,
  GET_BATCH_DETAILS_SUCCESS,
  GET_BATCHES_HISTORY_SUCCESS,
  GET_BATCHES_TASKS_SUCCESS,
  GET_BATCH_DETAILS_REQUEST,
  ATTACH_RECEIPT_REQUEST,
  ATTACH_RECEIPT_SUCCESS,
  ATTACH_DEPOSIT_RECEIPT_REQUEST,
  ATTACH_DEPOSIT_RECEIPT_SUCCESS,
  SET_SELECTED_ITEM,
  DELETE_ATTACHED_FILE_SUCCESS,
  EDIT_TRANSACTION_PARAMS_SUCCESS,
  GET_SUBACCOUNT_LIST_REQUEST,
  GET_SUBACCOUNT_LIST_SUCCESS,
  GET_BATCH_ACTIVITY_REQUEST,
  GET_BATCH_ACTIVITY_SUCCESS,
  EDIT_TRANSACTION_COMMENT_SUCCESS
} from '../actionTypes';

const actions = [
  GET_HISTORY_REQUEST,
  GET_HISTORY_SUCCESS,
  GET_BATCH_DETAILS_SUCCESS,
  GET_BATCHES_HISTORY_SUCCESS,
  GET_BATCHES_TASKS_SUCCESS,
  GET_BATCH_DETAILS_REQUEST,
  ATTACH_RECEIPT_REQUEST,
  ATTACH_RECEIPT_SUCCESS,
  ATTACH_DEPOSIT_RECEIPT_REQUEST,
  ATTACH_DEPOSIT_RECEIPT_SUCCESS,
  SET_SELECTED_ITEM,
  DELETE_ATTACHED_FILE_SUCCESS,
  EDIT_TRANSACTION_PARAMS_SUCCESS,
  GET_SUBACCOUNT_LIST_REQUEST,
  GET_SUBACCOUNT_LIST_SUCCESS,
  GET_BATCH_ACTIVITY_REQUEST,
  GET_BATCH_ACTIVITY_SUCCESS,
  EDIT_TRANSACTION_COMMENT_SUCCESS
];

const initialState = {
  history: [],
  historyCount: 0,
  batchDetails: null,
  batchesHistory: [],
  batchesCount: 0,
  batchesHistoryPage: 1,
  attachedReceipt: null,
  attachedDepositReceipt: null,
  selectedItem: null,
  subaccountList: [],
  batchesTasks: [],
  batchActivity: {}
};

export default [initialState, actions];
