import {network} from '@julaya/common/utils';

import {
  GET_ACCOUNT_DOCUMENTS_REQUEST,
  GET_ACCOUNT_DOCUMENTS_SUCCESS,
  GET_ACCOUNT_DOCUMENTS_FAILURE,
  GET_USER_DOUCMENTS_REQUEST,
  GET_USER_DOUCMENTS_SUCCESS,
  GET_USER_DOUCMENTS_FAILURE,
  DOCUMENT_SEND_REQUEST,
  DOCUMENT_SEND_SUCCESS,
  DOCUMENT_SEND_FAILURE
} from '../actionTypes';

export const sendDocument =
  (params, userId = null) =>
  async dispatch => {
    dispatch({
      type: DOCUMENT_SEND_REQUEST,
      payload: {uploadSlug: params.slug}
    });
    try {
      await network.fileUploader('/admin/documents', params);
      if (userId) {
        await dispatch(getUserDocuments({userId}));
      } else {
        await dispatch(getAccountDocuments());
      }

      dispatch({
        type: DOCUMENT_SEND_SUCCESS,
        payload: {uploadSlug: null}
      });
    } catch (error) {
      dispatch({
        type: DOCUMENT_SEND_FAILURE,
        error: error.message,
        payload: {uploadSlug: null}
      });
    }
  };

export const getAccountDocuments = () => async dispatch => {
  dispatch({type: GET_ACCOUNT_DOCUMENTS_REQUEST});
  try {
    const response = await network.request(`/admin/documents`, 'GET');
    dispatch({
      type: GET_ACCOUNT_DOCUMENTS_SUCCESS,
      payload: {accountDocuments: response}
    });
  } catch (error) {
    dispatch({type: GET_ACCOUNT_DOCUMENTS_FAILURE, error: error.message});
  }
};

export const getUserDocuments = params => async dispatch => {
  dispatch({
    type: GET_USER_DOUCMENTS_REQUEST,
    payload: {userDocuments: null, userId: null}
  });
  try {
    if (params.userId) {
      const response = await network.request(`/users/${params.userId}/documents`, 'GET');
      dispatch({
        type: GET_USER_DOUCMENTS_SUCCESS,
        payload: {userDocuments: response, userId: params.userId}
      });
    } else if (params?.countryOfCitizenship) {
      const response = await network.request(`/documents`, 'POST', {countryOfCitizenship: params?.countryOfCitizenship.toUpperCase()});
      dispatch({
        type: GET_USER_DOUCMENTS_SUCCESS,
        payload: {userDocuments: response, userId: null}
      });
    } else {
      dispatch({
        type: GET_USER_DOUCMENTS_SUCCESS,
        payload: {userDocuments: null, userId: null}
      });
    }
  } catch (error) {
    dispatch({type: GET_USER_DOUCMENTS_FAILURE, error: error.message});
  }
};
