import {parsePhoneNumber, isValidPhoneNumber} from 'libphonenumber-js/mobile';

function isValidPhone(phone, countryCode) {
  try {
    let phoneNumber = null;
    if (countryCode) {
      if (isValidPhoneNumber('+' + phone)) {
        phoneNumber = parsePhoneNumber('+' + phone, {extract: false});
      } else if (isValidPhoneNumber(phone, countryCode)) {
        phoneNumber = parsePhoneNumber(phone, countryCode, {extract: false});
      } else {
        return false;
      }
    } else {
      if (!isValidPhoneNumber('+' + phone)) {
        return false;
      }
      phoneNumber = parsePhoneNumber('+' + phone, {extract: false});
    }

    return {
      dialingCode: phoneNumber.countryCallingCode,
      countryCode: phoneNumber.country,
      nationalPhone: phoneNumber.nationalNumber,
      internationalPhone: phoneNumber.countryCallingCode + phoneNumber.nationalNumber
    };
  } catch (e) {
    throw e;
  }
}

export default {isValidPhone};
