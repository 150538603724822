import { useDidUpdate } from '@julaya/common/hooks';
import { SearchForm } from '@julaya/common/ui/atoms';
import { BankSelectItem, CustomSelect, Drawer } from '@julaya/common/ui/molecules';
import { useEffect, useState } from 'react';

const DrawerSelector = ({type, placeholder, selectedItem, options, onSelect, drawerTitle, drawerIcon, drawerColor}) => {
  const [query, setQuery] = useState('');
  const [localOptions, setLocalOptions] = useState(options);
  const [drawerOpen, setDrawerOpen] = useState(false);

  useDidUpdate(() => {
    if (options.length) {
      setLocalOptions(options);
    }
  }, [options]);

  useEffect(() => {
    if (query) {
      const filtered = options.filter(b => {
        return b.value.serviceTitle?.toLowerCase().includes(query.toLowerCase());
      });
      setLocalOptions(filtered);
    } else {
      setLocalOptions(options);
    }
  }, [query, options]);

  return (
    <>
      <CustomSelect onClick={() => setDrawerOpen(true)} selectedItem={selectedItem} placeholder={placeholder} />

      <Drawer setOpen={setDrawerOpen} icon={drawerIcon} open={drawerOpen} title={drawerTitle} color={drawerColor}>
        <Drawer.Content>
          <SearchForm value={query} onChange={setQuery} name="select-search" variant="grey" />
          <div className="border-top my-4"></div>
          {localOptions.map((item, i) => {
            return (
              <BankSelectItem
                key={`b-${i}`}
                onClick={() => {
                  setDrawerOpen(false);
                  onSelect(item.value);
                }}
                tag={item.tag}
                image={item.image}
                label={type === 'bank' ? item.value.serviceTitle : item.label}
                subtitle={item.subtitle}
                disabled={item.disabled}
                averageTiming={type === 'bank' ? getBankAverageTimingBySlug(item.value.serviceSlug) : null}
              />
            );
          })}
        </Drawer.Content>
      </Drawer>
    </>
  );
};

const standardTiming = {
  text: 'Standard',
  color: 'neutral'
};

const rapidTiming = {
  text: 'Plus rapide',
  color: 'success'
};

const slowTiming = {
  text: 'Plus lent',
  color: 'warning'
};

function getBankAverageTimingBySlug(slug = '') {
  if (slug.includes('ecobank')) {
    return rapidTiming;
  } else if (slug.includes('sgbci') || slug.includes('sgbs')) {
    return slowTiming;
  } else {
    return standardTiming;
  }
}

export default DrawerSelector;
