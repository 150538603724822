import {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import cx from 'classnames';
import {UncontrolledTooltip} from 'reactstrap';

import {PageWrapper} from 'components/UI';
import {Radio, Button, Text, Checkbox, Alert} from '@julaya/common/ui/atoms';

import {CardWallet, Modal, ErrorDisplay} from '@julaya/common/ui/molecules';

import {useDialog, useDidUpdate} from '@julaya/common/hooks';

import './EntrepriseSwitch.scss';

const EntrepriseSwitch = props => {
  const {
    setPreferredCompany,
    preferredCompany,
    getUserCompanies,
    userCompanies,
    userCompaniesLoading,
    userCompaniesError,
    getUserInfo,
    userInfoLoading,
    userInfoError,
    landingWallets,
    landingWalletsLoading,
    landingWalletsError,
    isMobile = false,
    toggleMenuOpen,
    isMenuOpen
  } = props;

  const history = useHistory();

  useEffect(() => {
    isMenuOpen && toggleMenuOpen(false);
  }, []);

  const [selectedEntreprise, setSelectedEntreprise] = useState();
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [isAdminOnly, setIsAdminOnly] = useState(false);
  const [modal, toggleModal] = useDialog();

  useEffect(() => {
    if (Array.isArray(userCompanies) && userCompanies.length > 0) {
      if (userCompanies.length === 1 && userCompanies[0].hasAccess) {
        if (userCompanies[0].role === 'ADMIN-ONLY') {
          setIsAdminOnly(true);
        }
        getUserInfo(userCompanies[0].id);
      } else if (preferredCompany) {
        const preferredCompanyExists = userCompanies.find(c => c.id === preferredCompany);
        if (preferredCompanyExists) {
          if (preferredCompanyExists.role === 'ADMIN-ONLY') {
            setIsAdminOnly(true);
          }
          getUserInfo(preferredCompany);
        }
      }
    }
  }, [userCompanies]);

  useDidUpdate(() => {
    if (userInfoLoading === false && userInfoError === '' && landingWalletsLoading === false && landingWalletsError === '') {
      let initialPage = '/dashboard';
      if (isAdminOnly && landingWallets.length === 0) {
        initialPage = '/admin';
      }
      history.push(initialPage);
    }
  }, [userInfoLoading, landingWalletsLoading]);

  const handleStartOperation = () => {
    setPreferredCompany(checkboxValue ? selectedEntreprise : null);
    getUserInfo(selectedEntreprise);
  };

  return (
    <>
      <PageWrapper
        id="entreprise-switch-container"
        className={cx('entreprise-switch-container', {'--mobile': isMobile})}
        title={userCompaniesLoading || userInfoLoading || landingWalletsLoading ? '' : 'Mes entreprises'}>
        {isMobile && <div className="page-title">Mes entreprises</div>}
        {userCompaniesLoading || userInfoLoading || landingWalletsLoading ? (
          //! TODO : create a spinner component into atoms
          <div className="d-flex flex-column justify-content-center align-items-center my-5">
            <span
              className="spinner-border spinner-border-lg text-primary"
              style={{width: '3rem', height: '3rem'}}
              role="status"
              aria-hidden="true"></span>
          </div>
        ) : userCompaniesError ? (
          <div>
            <ErrorDisplay errorText={userCompaniesError || 'Aucune entreprise trouvée'} onClick={getUserCompanies} />
          </div>
        ) : (
          <>
            {userCompanies.map((elt, index) => {
              return (
                <Radio
                  key={`radio-elt-id-${index}`}
                  id={`radio-elt-id-${index}`}
                  className="p-0"
                  status="white"
                  selected={selectedEntreprise === elt.id}
                  onChange={() => {
                    if (elt.hasAccess) {
                      if (elt.role === 'ADMIN-ONLY') {
                        setIsAdminOnly(true);
                      }
                      setSelectedEntreprise(elt.id);
                      if (elt.id === preferredCompany) {
                        setCheckboxValue(true);
                      }
                    }
                  }}>
                  <CardWallet
                    label={elt.name}
                    balance={`${elt.nbrUsers} utilisateur(s)`}
                    type="entreprise"
                    disabled={!elt.hasAccess}
                    isMobile={isMobile}
                  />

                  {!elt.hasAccess && !isMobile && (
                    <UncontrolledTooltip
                      container="entreprise-switch-container"
                      className="restriction-tooltip"
                      placement="left-start"
                      target={`radio-elt-id-${index}`}>
                      <strong>Pas d’accès à l’entreprise</strong>
                      <br></br> Votre administrateur ne vous a pas encore attribué de compte. <br></br>Rapprochez vous de lui afin d’obtenir l’accès
                      aux différentes fonctionnalités de la plateforme.
                    </UncontrolledTooltip>
                  )}
                </Radio>
              );
            })}
            <CardWallet isEmpty emptyIcon="jla-icon-office" emptyText="Ajouter une entreprise" emptyVariant="" emptyAction={toggleModal} />
            <div className="d-flex flex-column h-100 justify-content-between">
              <div>
                <Checkbox
                  label="Définir comme entreprise préférée"
                  name="preferred-entreprise"
                  status={!isMobile && 'white'}
                  value={checkboxValue}
                  onChange={e => selectedEntreprise && setCheckboxValue(e.target.checked)}
                  inline={true}
                  border
                />
                {checkboxValue && (
                  <Alert
                    className="action__alert"
                    title="Vous pourrez changer d’entreprise sur votre profil ou modifier votre choix préféré par la suite."
                    icon="information-circle"
                  />
                )}
              </div>
              <div className="action-container__button">
                <Button
                  text="Commencer vos opérations"
                  disabled={typeof selectedEntreprise === 'undefined' || userInfoLoading}
                  loading={userInfoLoading}
                  full={true}
                  action={handleStartOperation}
                />
              </div>
            </div>
          </>
        )}
      </PageWrapper>
      {/* Entreprise Creation Modal */}
      <Modal toogleModal={toggleModal} open={modal} title="Ajouter une entreprise" icon="icon-briefcase">
        <Modal.Content>
          <div className="entreprise-modal-content">
            <Button variant="badge" size="medium" icon="icon-phonebook" />

            <div className="entreprise-modal-content__message">
              <Text bold>Contactez le support JULAYA en utilisant le chat ou par téléphone pour ajouter une nouvelle entreprise :</Text>

              <ul>
                <li key="contact-info-1">
                  Côte d’Ivoire : &nbsp; <Text color="primary">25 22 01 86 16</Text>
                </li>
                <li key="contact-info-2">
                  Sénégal :&nbsp; <Text color="primary">77 801 60 00</Text>
                </li>
                <li key="contact-info-2">
                  Bénin :&nbsp; <Text color="primary">63 20 22 22</Text>
                </li>
              </ul>
            </div>
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default EntrepriseSwitch;
