import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "@julaya/common/store/actions";
import selector from "@julaya/common/store/selectors";

import Component from "./components";

const mapStateToProps = ({ auth, loading, error, wallets }) => {
  return {
    ...selector(
      {
        landingWallets: "GET_LANDING_WALLETS",
      },
      { loading, error }
    ),

    user: auth.user,
    userCompanies: auth.companiesList,
    canSend: auth.canSend,
    canTopUp: auth.canTopUp,

    currentCompanyId: auth.currentCompanyId,

    landingWallets: wallets.landingWallets?.wallets || [],
    lastUpdatedBalance: wallets.landingWallets?.lastUpdatedBalance || "",
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
