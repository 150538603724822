/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useRef} from 'react';
import cx from 'classnames';
import {useDidUpdate} from '@julaya/common/hooks';
import {getValidationState} from '../helper/helper';

const PasswordResetTemp = ({
  goToIndex,
  timer,
  tempUser,
  loginLoading,
  loginError,
  login,
  requestTempPassword,
  tempCodeReqLoading,
  tempCodeReqError,
  toggleModal,
  values,
  handleChange,
  setErrors
}) => {
  const [timeLeft, setTimeLeft] = useState(0);
  let interval;

  const [codePinFocused, setCodePinFocused] = useState(false);
  const [codePinValues, setCodePinValues] = useState([]);
  const [codePinIsDone, setCodePinIsDone] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const CODE_PIN_LENGTH = new Array(4).fill(0);
  const codePinReference = useRef();

  /*
  |
  | Code pin
  | ----------
  */
  const handleClick = () => {
    codePinReference.current.focus();
  };
  const handleFocus = () => {
    setCodePinFocused(true);
  };
  const handleBlur = () => {
    setCodePinFocused(false);
  };

  /*
  |
  | Code Pin On Change
  | ---------------------
  */
  useEffect(() => {
    if (values.password.length <= CODE_PIN_LENGTH.length - 1) {
      setSelectedIndex(values.password.length);
    }
    setCodePinIsDone(values.password.length === CODE_PIN_LENGTH.length);
    setCodePinValues((codePinValues + values.password).slice(0, CODE_PIN_LENGTH.length));
  }, [values]);

  useEffect(() => {
    if (codePinIsDone) {
      onSubmit();
    }
  }, [codePinIsDone]);

  /*
  |
  | Submit
  | ----------
  */
  const onSubmit = e => {
    if (e) {
      e.preventDefault();
    }
    let mErrors = {};
    if (getValidationState(values.password)) {
      mErrors.password = true;
    }
    if (Object.keys(mErrors).length !== 0) {
      setErrors(mErrors);
    } else {
      login({...tempUser, ...values}, true);
    }
  };

  useDidUpdate(() => {
    loginLoading === false && loginError === '' && goToIndex(2);
  }, [loginLoading]);

  /*
  |
  | Timer
  | --------
  */
  useEffect(() => {
    if (timer > 0) {
      setTimeLeft(timer);
      interval = setInterval(() => {
        setTimeLeft(t => {
          if (t < 1) {
            clearInterval(interval);
          }
          return t - 1;
        });
      }, 1000);
    } else if (interval) {
      clearInterval(interval);
    }
    return () => interval && clearInterval(interval);
  }, [timer]);

  return (
    <div className="custom-box">
      <a
        href="#Julaya"
        className="reset__back mb-4"
        onClick={() => {
          toggleModal();
        }}>
        <i className="jla-icon-chevron-left"></i>
        Retour
      </a>

      {/* Wording */}
      <h3 className="login__title">Vous avez reçu un code temporaire</h3>
      <div className="text-muted">
        Consultez vos messages sur le numéro de téléphone <span className="font-weight-bold text-black">{tempUser ? tempUser.phone : '0000'}</span>{' '}
        pour trouver le code.
      </div>
      <form className="needs-validation" onSubmit={onSubmit}>
        {/* Digits */}
        <div
          className={cx('reset__digits my-4', {
            'code-done': codePinIsDone
          })}>
          {CODE_PIN_LENGTH.map((v, index) => {
            return (
              <div
                className={cx('reset__digits-item', {
                  active: index === selectedIndex && codePinFocused
                })}
                key={index}>
                {values.password[index]}
              </div>
            );
          })}
          <div className="wrap" onClick={handleClick}>
            <input
              value={values.password}
              name="password"
              pattern="\d*"
              type="number"
              ref={codePinReference}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={handleChange}
              className="reset__digits-input bg-grey"
              maxLength="4"
              autoFocus
            />
          </div>
        </div>

        {/* Error */}
        {tempCodeReqError && <p className="text-danger">{tempCodeReqError}</p>}
        {loginError && <p className="text-danger">{loginError}</p>}

        {/* Button */}
        <button type="submit" className="btn btn-block btn-primary" disabled={loginLoading || !codePinIsDone}>
          {loginLoading && (
            <>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{' '}
            </>
          )}
          Valider
        </button>

        {/* Send code */}
        <div className="mt-4 text-muted text-center">
          {tempCodeReqLoading ? (
            <span>Requête en cours...</span>
          ) : timeLeft > 0 ? (
            <div className="d-flex flex-column justify-content-between">
              <span className="mr-2">Code non reçu ? </span>
              <span className="text-secondary">Renvoyer un code dans {timeLeft} secondes</span>
            </div>
          ) : (
            <a
              href="#julaya"
              className="reset__link text-secondary"
              onClick={e => {
                e.preventDefault();
                requestTempPassword({identifier: tempUser.identifier});
              }}>
              Renvoyer un code
            </a>
          )}
        </div>
      </form>
    </div>
  );
};

export default PasswordResetTemp;
