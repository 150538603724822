import CryptoJS from 'crypto-js';
import {network, storage, constants, functions} from '@julaya/common/utils';

import {
  MAKE_TRANSACTION_REQUEST,
  MAKE_TRANSACTION_SUCCESS,
  MAKE_TRANSACTION_FAILURE,
  DELETE_BATCH_REQUEST,
  DELETE_BATCH_SUCCESS,
  DELETE_BATCH_FAILURE,
  KEEP_AS_DRAFT_REQUEST,
  KEEP_AS_DRAFT_SUCCESS,
  KEEP_AS_DRAFT_FAILURE,
  MAKE_TRANSACTION_BULK_REQUEST,
  MAKE_TRANSACTION_BULK_SUCCESS,
  MAKE_TRANSACTION_BULK_FAILURE,
  ATTACH_DEPOSIT_RECEIPT_REQUEST,
  ATTACH_DEPOSIT_RECEIPT_SUCCESS,
  ATTACH_DEPOSIT_RECEIPT_FAILURE,
  EDIT_TRANSACTION_PARAMS_REQUEST,
  EDIT_TRANSACTION_PARAMS_SUCCESS,
  EDIT_TRANSACTION_PARAMS_FAILURE,
  GET_SERVICES_BY_EXTERNAL_REFERENCE_REQUEST,
  GET_SERVICES_BY_EXTERNAL_REFERENCE_SUCCESS,
  GET_SERVICES_BY_EXTERNAL_REFERENCE_FAILURE,
  TOGGLE_IS_MENU_OPEN,
  RETRY_TRANSACTIONS_FAIL_REQUEST,
  RETRY_TRANSACTIONS_FAIL_SUCCESS,
  RETRY_TRANSACTIONS_FAIL_FAILURE,
  GET_SERVICES_LIST_BY_TYPE_REQUEST,
  GET_SERVICES_LIST_BY_TYPE_SUCCESS,
  GET_SERVICES_LIST_BY_TYPE_FAILURE,
  SET_BANK_DISPOSAL_AMOUNT,
  GET_LATEST_RECEIVERS_REQUEST,
  GET_LATEST_RECEIVERS_SUCCESS,
  GET_LATEST_RECEIVERS_FAILURE,
  EXPORT_HISTORY_FAILURE,
  REFUND_TRANSACTIONS_FAILURE,
  REFUND_TRANSACTIONS_REQUEST,
  REFUND_TRANSACTIONS_SUCCESS,
  CANCEL_TRANSACTIONS_REQUEST,
  CANCEL_TRANSACTIONS_SUCCESS,
  CANCEL_TRANSACTIONS_FAILURE
} from '../actionTypes';

import {getBatchesTasks} from './history';

export const makeTransaction =
  (params, attachementParams = null) =>
  async (dispatch, getState) => {
    dispatch({
      type: MAKE_TRANSACTION_REQUEST,
      payload: {transactionNotify: null}
    });

    const accessToken = await storage.get('accessToken');

    if (accessToken && accessToken.id) {
      params.password = CryptoJS.AES.encrypt(`${params.code}`, `${accessToken.id}`).toString();
      params.code = undefined;

      try {
        const {transaction} = getState();
        let bankDisposalOnboardingStatus = transaction.bankDisposalOnboardingStatus;
        if (constants.platform === 'app' && params.typeSlug === 'bank-disposal') {
          if (bankDisposalOnboardingStatus === false) {
            bankDisposalOnboardingStatus = true;
            await storage.set('bankDisposalOnboardingStatus', true);
          }
        }

        const response = await network.request('/tasks/create/send', 'POST', params);
        // Check if the transaction has made & add the attachement after that
        if (response && attachementParams) {
          const {file, walletId} = attachementParams;
          await dispatch(
            attachDepositReceipt(
              {
                file,
                transactionId: response.TaskItems[0].uuid,
                walletId
              },
              'attachment'
            )
          );
        }
        dispatch({
          type: MAKE_TRANSACTION_SUCCESS,
          payload: {
            transactionNotify: response,
            bankDisposalOnboardingStatus: true
          }
        });
      } catch (error) {
        dispatch({
          type: MAKE_TRANSACTION_FAILURE,
          error: error.message
        });
      }
    } else {
      dispatch({
        type: MAKE_TRANSACTION_FAILURE,
        error: 'Des erreurs sont survenues, veuillez réessayer'
      });
    }
  };

export const retryFailedTransactions = (walletId, params) => async (dispatch, getState) => {
  dispatch({
    type: RETRY_TRANSACTIONS_FAIL_REQUEST
  });

  const accessToken = await storage.get('accessToken');
  params.password = CryptoJS.AES.encrypt(`${params.code}`, `${accessToken.id}`).toString();

  try {
    const {
      auth: {tfaActive}
    } = getState();

    const args = {
      uuids: params.uuids,
      password: await functions.encryptCode(params.code)
    };

    if (tfaActive) {
      args.otp = params.otp;
    }

    await network.request(`/wallets/${walletId}/transactions/retry`, 'POST', args);

    dispatch({
      type: RETRY_TRANSACTIONS_FAIL_SUCCESS
    });
  } catch (error) {
    dispatch({type: RETRY_TRANSACTIONS_FAIL_FAILURE, error: error.message});
  }
};

export const cancelTransactions = (walletId, params) => async (dispatch, getState) => {
  dispatch({
    type: CANCEL_TRANSACTIONS_REQUEST,
    payload: {
      cancelTransactionsResult: []
    }
  });

  try {
    const url = `/wallets/${walletId}/transactions/cancel`;
    const response = await network.request(url, 'POST', params);

    dispatch({
      type: CANCEL_TRANSACTIONS_SUCCESS,
      payload: {
        cancelTransactionsResult: response.transactions
      }
    });
  } catch (error) {
    dispatch({type: CANCEL_TRANSACTIONS_FAILURE, error: error.message});
  }
};

export const refundTransactions = (walletId, params) => async (dispatch, getState) => {
  dispatch({
    type: REFUND_TRANSACTIONS_REQUEST,
    payload: {
      refundTransactionsResult: []
    }
  });

  const accessToken = await storage.get('accessToken');
  params.password = CryptoJS.AES.encrypt(`${params.password}`, `${accessToken.id}`).toString();

  try {
    const url = `/wallets/${walletId}/transactions/refund`;
    const response = await network.request(url, 'POST', params);

    dispatch({
      type: REFUND_TRANSACTIONS_SUCCESS,
      payload: {
        refundTransactionsResult: response.transactions
      }
    });
  } catch (error) {
    dispatch({type: REFUND_TRANSACTIONS_FAILURE, error: error.message});
  }
};

export const deleteBatch =
  (batchId, loadHistory = null) =>
  async dispatch => {
    dispatch({type: DELETE_BATCH_REQUEST});

    try {
      await network.request(`/tasks/${batchId}`, 'DELETE');

      dispatch({
        type: DELETE_BATCH_SUCCESS
      });

      if (loadHistory === 'tasks') {
        dispatch(
          // TODO change pageSize
          getBatchesTasks({
            status: ['pending', 'draft', 'sending', 'to-validate', 'to-retry'],
            query: '',
            page: -1
          })
        );
      }
    } catch (error) {
      dispatch({type: DELETE_BATCH_FAILURE, error: error.message});
    }
  };

export const keepAsDraft = (batchId, transfers) => async dispatch => {
  dispatch({type: KEEP_AS_DRAFT_REQUEST});

  try {
    await network.request(`/batches/${batchId}/draft`, 'POST', {transfers});

    dispatch({
      type: KEEP_AS_DRAFT_SUCCESS
    });
  } catch (error) {
    dispatch({type: KEEP_AS_DRAFT_FAILURE, error: error.message});
  }
};

export const makeTransactionBulk = (uniqueKeyArray, code, otp) => async (dispatch, getState) => {
  dispatch({
    type: MAKE_TRANSACTION_BULK_REQUEST
  });

  try {
    const {
      auth: {tfaActive, user}
    } = getState();

    const args = {
      code: await functions.encryptCode(code),
      uniqueKeyArray
    };

    if (tfaActive) {
      args.otp = otp;
    }

    await network.request('/batches/sign', 'POST', args, false, 'raw');

    dispatch({
      type: MAKE_TRANSACTION_BULK_SUCCESS
    });
  } catch (error) {
    dispatch({type: MAKE_TRANSACTION_BULK_FAILURE, error: error.message});
  }
};

export const attachDepositReceipt = (params, type) => async dispatch => {
  // type =  "receipt" | "attachment"
  dispatch({
    type: ATTACH_DEPOSIT_RECEIPT_REQUEST,
    attachedDepositReceipt: null
  });

  try {
    const {walletId, transactionId, file} = params;
    const response = await network.fileUploader(`/wallets/${walletId}/transactions/${transactionId}/file/attach`, {file, type});

    dispatch({
      type: ATTACH_DEPOSIT_RECEIPT_SUCCESS,
      payload: {
        attachedDepositReceipt: {...response, transactionId}
      }
    });
  } catch (error) {
    dispatch({type: ATTACH_DEPOSIT_RECEIPT_FAILURE, error: error.message});
  }
};

export const editTransactionParams = (data, uniqId, code, otp) => async (dispatch, getState) => {
  dispatch({
    type: EDIT_TRANSACTION_PARAMS_REQUEST
  });

  try {
    const params = {
      data,
      code: await functions.encryptCode(code),
      otp
    };

    const res = await network.request(`/transactions/${uniqId}/edit`, 'POST', params);

    if (res) {
      const {
        history: {history, selectedItem}
      } = getState();

      let item = selectedItem;

      const index = history.findIndex(element => element.uniqId === uniqId);

      if (index !== -1) {
        history[index] = {...history[index], ...data};
        item = history[index];
      }

      dispatch({
        type: EDIT_TRANSACTION_PARAMS_SUCCESS,
        payload: {selectedItem: item, history}
      });
    } else {
      dispatch({
        type: EDIT_TRANSACTION_PARAMS_FAILURE,
        error: 'Des erreurs sont survenues lors de la mise à jour de votre transaction'
      });
    }
  } catch (error) {
    dispatch({type: EDIT_TRANSACTION_PARAMS_FAILURE, error: error.message});
  }
};

export const getServicesByType = (typeSlug, walletId) => async dispatch => {
  dispatch({
    type: GET_SERVICES_LIST_BY_TYPE_REQUEST,
    payload: {servicesByType: []}
  });

  try {
    let services = await network.request(`/me/companies/services`, 'POST', {
      typeSlug: 'bank-disposal',
      way: 'in'
    });
    services = services?.[0]?.services;
    console.log({services});

    if (typeSlug === 'bank-disposal') {
      const bankDisposalPreferredBank = await storage.get('bankDisposalPreferredBank');

      if (bankDisposalPreferredBank) {
        services = data.map(service => {
          if (service.slug === bankDisposalPreferredBank) {
            service.preferredBank = true;
          }

          return service;
        });
      }
    }

    console.log({services});
    dispatch({
      type: GET_SERVICES_LIST_BY_TYPE_SUCCESS,
      payload: {servicesByType: services}
    });
  } catch (error) {
    dispatch({type: GET_SERVICES_LIST_BY_TYPE_FAILURE, error: error.message});
  }
};

export const getServicesByQuery = (walletId, params) => async (dispatch, getState) => {
  const hasQuery = params?.query;
  dispatch({
    type: hasQuery ? GET_SERVICES_BY_EXTERNAL_REFERENCE_REQUEST : GET_LATEST_RECEIVERS_REQUEST,
    payload: hasQuery ? {services: []} : {latestReceivers: []}
  });

  try {
    let result = await network.request(`/me/companies/services`, 'POST', {
      way: params.way,
      walletId,
      ...(hasQuery ? {externalReference: params.query} : {})
    });
    let payload = {};
    const currentState = getState();
    if (hasQuery) {
      const services = currentState.transaction.services || [];
      payload = {services: [...services, ...result], servicesTags: result.tags};
    } else {
      const latestReceivers = currentState.transaction.latestReceivers || [];
      payload = {latestReceivers: [...latestReceivers, ...result], servicesTags: result.tags};
    }
    dispatch({
      type: hasQuery ? GET_SERVICES_BY_EXTERNAL_REFERENCE_SUCCESS : GET_LATEST_RECEIVERS_SUCCESS,
      payload
    });
  } catch (error) {
    dispatch({
      type: hasQuery ? GET_SERVICES_BY_EXTERNAL_REFERENCE_FAILURE : GET_LATEST_RECEIVERS_FAILURE,
      error: error.message,
      payload: hasQuery ? {services: []} : {latestReceivers: []}
    });
  }
};

export const clearServices = () => async dispatch => {
  dispatch({
    type: GET_SERVICES_BY_EXTERNAL_REFERENCE_SUCCESS,
    payload: {services: [], rapidTransferData: null}
  });
};

export const setBankDisposalAmount =
  (amount = '') =>
  async dispatch => {
    dispatch({
      type: SET_BANK_DISPOSAL_AMOUNT,
      payload: {
        bankDisposalAmount: amount
      }
    });
  };

export const toggleMenuOpen =
  (isMenuOpen = false) =>
  async (dispatch, getState) => {
    const {
      transaction: {isMenuOpen: prevIsMenuOpen}
    } = getState();

    if (isMenuOpen === prevIsMenuOpen) {
      isMenuOpen = false;
    }

    dispatch({
      type: TOGGLE_IS_MENU_OPEN,
      payload: {isMenuOpen}
    });

    if (prevIsMenuOpen === 'history-export') {
      dispatch({type: EXPORT_HISTORY_FAILURE, error: ''});
    }
  };
