import React, {useState, useCallback, useEffect, useRef} from 'react';
import cx from 'classnames';
import Icon from '../../DataDisplay/Icon/Icon';
import Button from '../Button/Button';
import Select from '../Select/Select';
import './Dropdown.scss';
import Typography from '../../DataDisplay/Typography/Typography';
import DatePicker from '../DatePicker/DatePicker';

const Dropdown = props => {
  const {
    closeOutside,
    label,
    hint,
    placeholder,
    type,
    message,
    icon,
    background,
    disabled,
    open,
    configDropdownMenu,
    onSubmit = () => {},
    hasFooter,
    headerTitle
  } = props;

  let initValues = {};
  configDropdownMenu.map((item, idx) => {
    switch (item.type) {
      case 'button':
        initValues[item.name] = false;
        break;
      case 'select':
        initValues[item.name] = !item.props.multiple ? null : [];
        break;
      default:
        if (Array.isArray(item.name)) {
          for (const name of item.name) {
            initValues[name] = null;
          }
        } else {
          initValues[item.name] = null;
        }
    }
  });

  const [iconMapping] = useState({
    error: 'x-circle-filled',
    success: 'check-circle-filled',
    warning: 'exclamation-triangle-filled'
  });

  const [isOpen, setIsOpen] = useState(!!open);
  const [state, setState] = useState(initValues);
  const dropdownRef = useRef(null);
  const dropdownMenuRef = useRef(null);

  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, [isOpen]);

  const toggle = useCallback(() => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  }, [disabled, isOpen]);

  const changeState = useCallback(
    newState => {
      setState({...state, ...newState});
    },
    [state]
  );

  const submit = () => {
    closeMenu();
    onSubmit(state);
  };

  const reset = () => {
    setState(initValues);
    closeMenu();
    onSubmit(initValues);
  };

  useEffect(() => {
    if (closeOutside) {
      const handleClickOutside = event => {
        if (
          dropdownMenuRef.current &&
          !dropdownMenuRef.current.contains(event.target) &&
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setIsOpen(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [isOpen, closeOutside]);

  return (
    <div
      ref={dropdownRef}
      className={cx('jla-dropdown', `jla-dropdown--${background}`, `jla-dropdown--${type}`, {
        'jla-dropdown--disabled': disabled,
        'jla-dropdown--open': isOpen
      })}>
      {(label || hint) && (
        <div className="jla-dropdown__header">
          <div className="jla-dropdown__header-label">{label}</div>
          <div className="jla-dropdown__header-hint">{hint}</div>
        </div>
      )}
      <div className="jla-dropdown__body" onClick={toggle}>
        {icon && <Icon size="h6" name={icon} />}
        <div className="jla-dropdown__input-box">{placeholder}</div>
        <Icon size="h6" name="chevron-down-filled" className="jla-dropdown__icon-down" />
      </div>
      <div ref={dropdownMenuRef} className="jla-dropdown__menu-item">
        {headerTitle && (
          <div className="jla-dropdown__menu-item-header">
            <Typography variant="xs" lead>
              {headerTitle}
            </Typography>
            <Typography variant="xs" lead color="neutral" onClick={reset}>
              Réinitialiser
            </Typography>
          </div>
        )}

        {configDropdownMenu && (
          <div className="jla-dropdown__menu-item-body">
            {configDropdownMenu.map((item, idx) => {
              if (item.type === 'button') {
                return (
                  <Button
                    {...item.props}
                    key={idx}
                    btnType={state[item.name] ? 'primary' : 'secondary'}
                    color={state[item.name] ? 'primary' : 'neutral'}
                    onClick={e => {
                      changeState({[item.name]: !state[item.name]});
                    }}
                  />
                );
              } else if (item.type === 'select') {
                return (
                  <Select
                    {...item.props}
                    key={idx}
                    value={state[item.name]}
                    onChange={e => {
                      changeState({[item.name]: [...e.target.value]});
                    }}
                  />
                );
              } else {
                return (
                  <DatePicker
                    key={idx}
                    {...item.props}
                    defaultStartDate={Array.isArray(item.name) ? state[item.name[0]] : state[item.name]}
                    defaultEndDate={Array.isArray(item.name) ? state[item.name[1]] : null}
                    onChangeStartDate={v => {
                      if (Array.isArray(item.name)) {
                        // Update minDate and reset maxDate
                        changeState({[item.name[0]]: v, [item.name[1]]: null});
                      } else {
                        changeState({[item.name]: v});
                      }
                    }}
                    onChangeEndDate={v => {
                      if (Array.isArray(item.name)) {
                        changeState({[item.name[1]]: v});
                      }
                    }}
                  />
                );
              }
            })}
          </div>
        )}

        {hasFooter && (
          <div className="jla-dropdown__menu-item-footer">
            <div className="jla-dropdown__footer-box">
              <Button
                color={'neutral'}
                btnType={'secondary'}
                onClick={() => {
                  closeMenu();
                }}>
                Annuler
              </Button>
            </div>
            <div className="jla-dropdown__footer-box">
              <Button btnType={'primary'} onClick={submit}>
                Valider
              </Button>
            </div>
          </div>
        )}
      </div>
      <div className="jla-dropdown__footer">
        {type !== 'default' && <Icon size="h6" name={iconMapping[type]} className="jla-dropdown__footer-icon" />}
        {message}
      </div>
    </div>
  );
};

export default Dropdown;
