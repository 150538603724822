import React from 'react';

import './Loading.scss';

const Body = props => {
  const {headers} = props;
  return (
    <tbody className="jla-table-loading">
      {[1, 2, 3, 4, 5].map(idxRow => {
        return (
          <tr key={`${idxRow}`}>
            {headers.map((cellConfig, idxHead) => {
              return (
                <td
                  key={`${idxHead}-${idxRow}`}
                  style={{
                    width: `${!!cellConfig.width ? `${cellConfig.width}` : 'unset'}`,
                    textAlign: `${!!cellConfig.align ? `${cellConfig.align}` : 'left'}`
                  }}>
                  <div className="jla-table-loading__placeholder-line"></div>
                </td>
              );
            })}
          </tr>
        );
      })}
    </tbody>
  );
};

export default Body;
