import React from 'react';
import cx from 'classnames';
import {Link} from 'react-router-dom';

import './Button.scss';

/*
/ UI | Atoms | Button
*/

/**
 * This component define all the behavior of Button
 */
const Button = ({
  className = '',
  variant = 'button',
  size = 'medium',
  icon,
  image,
  imageStyle,
  action,
  status = 'primary',
  text,
  border,
  link,
  disabled,
  submit,
  loading,
  full = false,
  cursor,
  rounded
}) => {
  const callback = e => {
    e.preventDefault();
    action();
  };

  const Btn = () => (
    <button
      className={cx(`button_container --${variant} --${status} ${className}`, {
        '--full': full,
        '--border': border,
        '--disabled': disabled,
        '--loading': loading,
        '--cursor': (!disabled && action) || cursor,
        '--small': size === 'small',
        '--medium': size === 'medium',
        '--large': size === 'large',
        '--extra-large': size === 'extra-large',
        '--rounded': rounded,
        '--image': image
      })}
      disabled={disabled}
      onClick={e => {
        action && callback(e);
      }}
      type={submit ? 'submit' : 'button'}>
      {loading ? (
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      ) : (
        <>
          {icon && !image && <i className={`${variant}__icon ${icon}`}></i>}
          {image && !icon && (
            <img style={imageStyle} className={`${variant}__image`} src={require(`@julaya/common/assets/images/${image}`).default}></img>
          )}
          {icon && text && <span style={{margin: '0 0.5rem'}}></span>}
          {text && <span className={`${variant}__text`}>{text}</span>}
        </>
      )}
    </button>
  );

  return link && !disabled ? (
    <Link className={`w-100`} to={link}>
      <Btn />
    </Link>
  ) : (
    <Btn />
  );
};

export default Button;
