import {network, functions} from '@julaya/common/utils';

import moment from 'moment';
import {
  GET_ADMIN_WALLETS_REQUEST,
  GET_ADMIN_WALLETS_SUCCESS,
  GET_ADMIN_WALLETS_FAILURE,
  GET_USER_WALLETS_REQUEST,
  GET_USER_WALLETS_SUCCESS,
  GET_USER_WALLETS_FAILURE,
  GET_WALLET_DETAILS_REQUEST,
  GET_WALLET_DETAILS_SUCCESS,
  GET_WALLET_DETAILS_FAILURE,
  GET_ADMIN_WALLET_DETAILS_REQUEST,
  GET_ADMIN_WALLET_DETAILS_SUCCESS,
  GET_ADMIN_WALLET_DETAILS_FAILURE,
  TOGGLE_WALLET_SELECT_OPEN,
  GET_WALLET_USERS_REQUEST,
  GET_WALLET_USERS_SUCCESS,
  GET_WALLET_USERS_FAILURE,
  CREATE_WALLET_REQUEST,
  CREATE_WALLET_SUCCESS,
  CREATE_WALLET_FAILURE,
  UPDATE_WALLET_REQUEST,
  UPDATE_WALLET_SUCCESS,
  UPDATE_WALLET_FAILURE,
  ARCHIVE_WALLET_REQUEST,
  ARCHIVE_WALLET_SUCCESS,
  ARCHIVE_WALLET_FAILURE,
  GET_BENEFICIARY_DETAILS_FAILURE,
  TOGGLE_IS_MENU_OPEN
} from '../actionTypes';

export const getAdminWallets = data => async dispatch => {
  dispatch({type: GET_ADMIN_WALLETS_REQUEST});
  try {
    const {type = 'ALL', archived = false, page, pageSize = null, query = ''} = data;
    const response = await network.request(`/admin/wallets/list`, 'POST', {type, archived, page, pageSize, query});

    dispatch({
      type: GET_ADMIN_WALLETS_SUCCESS,
      payload: {
        adminWallets: response
      }
    });
  } catch (error) {
    dispatch({type: GET_ADMIN_WALLETS_FAILURE, error: error.message});
  }
};

export const getUserWallets =
  (params = null, dispatchTo = 'landing', force = false) =>
  async (dispatch, getState) => {
    // Reload only landing wallets only every 3mins
    const {
      wallets: {landingWallets}
    } = getState();

    let reload = true;
    if (landingWallets?.lastUpdatedBalanceTime) {
      const d = new Date();
      const seconds = Math.round(d.getTime() / 1000);
      const res = seconds - landingWallets.lastUpdatedBalanceTime;

      if (res < 60) {
        reload = false;
      }
      if (params?.type !== landingWallets?.type) {
        reload = true;
      }
    }

    if (dispatchTo === 'landing' && !reload && !force) {
      dispatch({
        type: GET_USER_WALLETS_SUCCESS,
        payload: {
          landingWallets
        }
      });
    } else {
      let lastUpdatedBalance = `${moment().format('DD/MM/YYYY')} - ${moment().format('HH:mm:ss')}`;
      dispatch({
        type: GET_USER_WALLETS_REQUEST,
        payload: {
          [`${dispatchTo}Wallets`]: {
            wallets: [],
            lastUpdatedBalance
          }
        }
      });
      dispatch({
        type: `GET_${dispatchTo.toLocaleUpperCase()}_WALLETS_REQUEST`
      });
      try {
        const response = await network.request(`/me/wallets`, 'POST', params || {});
        let lastUpdatedBalance = `${moment().format('DD/MM/YYYY')} - ${moment().format('HH:mm:ss')}`;
        const lastUpdatedBalanceDate = new Date();
        const lastUpdatedBalanceTime = Math.round(lastUpdatedBalanceDate.getTime() / 1000);
        const payload = {
          [`${dispatchTo}Wallets`]: {
            wallets: response,
            lastUpdatedBalanceTime: lastUpdatedBalanceTime,
            lastUpdatedBalance
          }
        };
        if (params?.type) {
          payload[`${dispatchTo}Wallets`].type = params.type;
        }
        dispatch({
          type: GET_USER_WALLETS_SUCCESS,
          payload
        });
        dispatch({
          type: `GET_${dispatchTo.toLocaleUpperCase()}_WALLETS_SUCCESS`
        });
      } catch (error) {
        dispatch({type: GET_USER_WALLETS_FAILURE});
        dispatch({
          type: `GET_${dispatchTo.toLocaleUpperCase()}_WALLETS_FAILURE`,
          error: error.message
        });
      }
    }
  };

export const getWalletUsers = () => async dispatch => {
  dispatch({type: GET_WALLET_USERS_REQUEST});
  try {
    const response = await network.request(`/admin/users/list`, 'POST', {page: -1, role: ['USER', 'ADMIN']});

    dispatch({
      type: GET_WALLET_USERS_SUCCESS,
      payload: {
        users: response.users
      }
    });
  } catch (error) {
    dispatch({type: GET_WALLET_USERS_FAILURE, error: error.message});
  }
};

export const getWalletDetails =
  (walletId, inclRelations = false) =>
  async dispatch => {
    dispatch({
      type: GET_WALLET_DETAILS_REQUEST,
      payload: {
        walletDetails: null
      }
    });
    try {
      const walletDetails = await network.request(`/wallets/${walletId}`, 'GET');

      if (inclRelations) {
        const topUp = await network.request(`/me/wallets`, 'POST', {type: 'WALLET', access: 'TOPUP'});

        walletDetails.canTopUp = !!topUp.find(w => w.id === parseInt(walletId));

        const send = await network.request(`/me/wallets`, 'POST', {type: 'WALLET', access: 'SEND'});
        walletDetails.canSend = !!send.find(w => w.id === parseInt(walletId));
      }

      dispatch({
        type: GET_WALLET_DETAILS_SUCCESS,
        payload: {
          walletDetails
        }
      });
    } catch (error) {
      dispatch({type: GET_WALLET_DETAILS_FAILURE, error: error.message});
    }
  };

export const getAdminWalletDetails = walletId => async dispatch => {
  dispatch({
    type: GET_ADMIN_WALLET_DETAILS_REQUEST,
    payload: {
      adminWalletDetails: null
    }
  });
  try {
    const response = await network.request(`/admin/wallets/${walletId}`, 'GET');

    dispatch({
      type: GET_ADMIN_WALLET_DETAILS_SUCCESS,
      payload: {
        adminWalletDetails: response
      }
    });
  } catch (error) {
    dispatch({type: GET_ADMIN_WALLET_DETAILS_FAILURE, error: error.message});
  }
};

export const toggleWalletSelectOpen =
  (isWalletSelectOpen = false) =>
  async (dispatch, getState) => {
    const {
      wallets: {isWalletSelectOpen: prevIsWalletSelectOpen}
    } = getState();

    if (isWalletSelectOpen === prevIsWalletSelectOpen) {
      isWalletSelectOpen = false;
    }

    dispatch({
      type: TOGGLE_WALLET_SELECT_OPEN,
      payload: {isWalletSelectOpen}
    });
  };

export const createWallet = (params, code, otp) => async (dispatch, getState) => {
  dispatch({
    type: CREATE_WALLET_REQUEST
  });

  try {
    const {
      auth: {tfaActive}
    } = getState();

    const args = {
      ...params,
      password: await functions.encryptCode(code)
    };

    if (tfaActive) {
      args.otp = otp;
    }
    await network.request(`/admin/wallets`, 'POST', args);

    dispatch({
      type: CREATE_WALLET_SUCCESS
    });
  } catch (error) {
    dispatch({type: CREATE_WALLET_FAILURE, error: error.message});
  }
};

export const updateWallet =
  (params, walletId, code, otp, isSync = false) =>
  async (dispatch, getState) => {
    dispatch({
      type: UPDATE_WALLET_REQUEST
    });

    try {
      const {
        auth: {tfaActive}
      } = getState();

      const args = {
        ...params,
        password: await functions.encryptCode(code)
      };

      if (tfaActive) {
        args.otp = otp;
      }

      await network.request(`/admin/wallets/${walletId}`, 'PUT', args);

      dispatch({
        type: UPDATE_WALLET_SUCCESS
      });
    } catch (error) {
      dispatch({type: UPDATE_WALLET_FAILURE, error: error.message});
    }
  };

export const rapidTransferToWallet = wallet => async dispatch => {
  try {
    const {externalReference, ..._} = wallet;
    dispatch({
      type: TOGGLE_IS_MENU_OPEN,
      payload: {
        isMenuOpen: 'rapid-transfer',
        rapidTransferData: {
          externalReference: wallet.reference,
          externalFullname: wallet.label
        },
        services: [
          {
            transactionType: 'transfer',
            slug: 'transfer-julaya-internal',
            title: 'Transfert Julaya',
            fees: 0,
            fixFees: 0,
            beneficiary: {
              firstname: wallet.label,
              lastname: '',
              email: '',
              label: '',
              isJulayaBeneficiary: true
            }
          }
        ]
      }
    });
  } catch (error) {
    dispatch({type: GET_BENEFICIARY_DETAILS_FAILURE, error: error.message});
  }
};

export const archiveWallets = (walletId, data) => async dispatch => {
  dispatch({
    type: ARCHIVE_WALLET_REQUEST
  });

  try {
    const params = {
      ...data,
      password: await functions.encryptCode(data.password)
    };

    await network.request(`/admin/wallets/${walletId}/archive`, 'POST', params);

    dispatch({
      type: ARCHIVE_WALLET_SUCCESS
    });
  } catch (error) {
    dispatch({type: ARCHIVE_WALLET_FAILURE, error: error.message});
  }
};
