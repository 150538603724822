import React, {useState, useEffect} from 'react';
import {CustomSelect} from '@julaya/common/ui/molecules';
import {BeneficiaryDrawer} from '@julaya/common/ui/organismes';

const DrawerSelector = ({latestReceivers, services, onSelect, query, setQuery, loading, setLoading, servicesTags, currentStep}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  // const [autoOpen, setAutoOpen] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);
  const placeholder = 'Sélectionnez un compte bénéficiaire';

  // useEffect(() => {
  //   if (currentStep === 1 && autoOpen) {
  //     setDrawerOpen(true);
  //     setAutoOpen(false);
  //   }
  // }, [currentStep]);

  return (
    <>
      <CustomSelect icon="user-circle" onClick={() => setDrawerOpen(true)} selectedItem={selectedItem} placeholder={placeholder} />

      <BeneficiaryDrawer
        {...{
          latestReceivers,
          services,
          onSelect: (item, serviceImage) => {
            setDrawerOpen(false);
            onSelect(item);
            setSelectedItem({
              label: item.title || item.externalReference || item?.serviceSelected?.externalReference,
              image: serviceImage
            });
          },
          query,
          setQuery,
          servicesTags,
          loading,
          setLoading,
          drawerOpen,
          setDrawerOpen
        }}
      />
    </>
  );
};

export default DrawerSelector;
