import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "@julaya/common/store/actions";
import selector from "@julaya/common/store/selectors";

import Component from "./components";

const mapStateToProps = ({ auth, loading, error }) => {
  return {
    ...selector(
      {
        userCompanies: "GET_USER_COMPANIES",
        switchCompany: "SWITCH_USER_COMPANY",
      },
      { loading, error }
    ),

    user: auth.user,
    userCompanies: auth.companiesList,
    currentCompanyId: auth.currentCompanyId,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
