import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "@julaya/common/store/actions";
import { loadingSelector, errorSelector } from "@julaya/common/store/selectors";

import Component from "./components";

const mapStateToProps = ({ auth, loading, error }) => {
  return {
    updateUserInfoLoading: loadingSelector(["UPDATE_USER_INFO"])({ loading }),
    updateUserInfoError: errorSelector(["UPDATE_USER_INFO"])({ error }),
    uploadAvatarLoading: loadingSelector(["UPLOAD_AVATAR"])({ loading }),
    uploadAvatarError: errorSelector(["UPLOAD_AVATAR"])({ error }),
    user: auth.user,
    isSubaccount: auth.isSubaccount,
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
