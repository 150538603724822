import React from 'react';
import cx from 'classnames';

import './Surface.scss';

const SurfaceBase = ({
  type: _type,
  className,
  children,
  items,
  justify,
  direction = 'row',
  gap = '',
  grow = '',
  shrink = '',
  wrap = '',
  grey = false,
  transparent = false
}) => {
  const type = _type ? `__${_type}` : '';

  return (
    <div
      className={cx(`jla-surface${type}`, className, {
        [`jla-surface${type}--bg-grey`]: grey,
        [`jla-surface${type}--bg-transparent`]: transparent,
        [`jla-surface${type}--items-${items}`]: items,
        [`jla-surface${type}--justify-${justify}`]: justify,
        [`jla-surface${type}--${direction}`]: direction,
        [`jla-surface${type}--gap-${gap}`]: gap,
        [`jla-surface${type}--grow`]: grow === true,
        [`jla-surface${type}--grow-0`]: grow === 0,
        [`jla-surface${type}--shrink`]: shrink,
        [`jla-surface${type}--shrink-0`]: shrink === true,
        [`jla-surface${type}--wrap`]: wrap === true,
        [`jla-surface${type}--wrap-reverse`]: wrap === 'reverse',
        [`jla-surface${type}--wrap-nowrap`]: wrap === 'nowrap'
      })}>
      {children}
    </div>
  );
};

const Header = props => {
  return <SurfaceBase {...props} type="header" />;
};

const Body = props => {
  return <SurfaceBase {...props} type="body" />;
};

const Footer = props => {
  return <SurfaceBase {...props} type="footer" />;
};

const Surface = props => {
  return <SurfaceBase direction="column" {...props} type="" />;
};

const Section = props => {
  const {className, children, grey, disabled, overflowVisible} = props;

  return (
    <div
      className={cx('jla-surfaces__section', className, {
        'jla-surfaces__section--grey': grey,
        'jla-surfaces__section--disabled': disabled,
        'jla-surfaces__section--overflow-visible': overflowVisible
      })}>
      {children}
    </div>
  );
};

const SectionTitle = props => {
  const {className, children, primary, warning, error, success, info, onHintClick} = props;

  let hintType = '';
  let hintText = null;

  if (warning) {
    hintText = warning;
    hintType = 'warning';
  }
  if (primary) {
    hintText = primary;
    hintType = 'primary';
  }
  if (error) {
    hintText = error;
    hintType = 'error';
  }
  if (success) {
    hintText = success;
    hintType = 'success';
  }
  if (info) {
    hintText = info;
    hintType = 'info';
  }
  return (
    <div className={cx('jla-surfaces__section-title', className)}>
      <div>{children}</div>
      {hintText && hintType && (
        <div
          className={cx(`jla-surfaces__section-title--${hintType}`, {
            'jla-surfaces__section-title--clickable': !!onHintClick
          })}
          onClick={onHintClick}>
          {hintText}
        </div>
      )}
    </div>
  );
};

const SectionDetails = props => {
  const {className, children} = props;
  return <div className={cx('jla-surfaces__section-details', className)}>{children}</div>;
};

Header.displayName = 'Surface.Header';
Body.displayName = 'Surface.Body';
Footer.displayName = 'Surface.Footer';
Section.displayName = 'Surface.Section';
SectionTitle.displayName = 'Surface.SectionTitle';
SectionDetails.displayName = 'Surface.SectionDetails';

Surface.Header = Header;
Surface.Body = Body;
Surface.Footer = Footer;
Surface.Section = Section;
Surface.SectionTitle = SectionTitle;
Surface.SectionDetails = SectionDetails;

export default Surface;
