/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from 'react';
import classnames from 'classnames';
import {useForm, useDidUpdate} from '@julaya/common/hooks';
import {getValidationState} from '../helper/helper';

const PasswordResetPhone = ({goToIndex, setIdentifier, tempCodeReqLoading, tempCodeReqError, requestTempPassword, toggleModal}) => {
  const [warning, setWarning] = useState(false);
  const [values, handleChange, errors, setErrors] = useForm({
    identifier: '',
    countryCode: '00225'
  });

  /*
  |
  | Submit
  | ---------
  */
  const onSubmit = e => {
    e.preventDefault();
    let mErrors = {};

    if (/^\d+$/.test(values.identifier) === false) {
      setWarning(true);
      return false;
    } else {
      if (getValidationState(values.identifier)) {
        mErrors.identifier = true;
      }
      if (Object.keys(mErrors).length !== 0) {
        setErrors(mErrors);
      } else {
        requestTempPassword({identifier: values.identifier});
        setIdentifier(values.identifier);
      }
    }
  };

  useDidUpdate(() => {
    tempCodeReqLoading === false && tempCodeReqError === '' && goToIndex(1);
  }, [tempCodeReqLoading]);

  return (
    <div className="custom-box">
      <a
        href="#Julaya"
        className="reset__back mb-4"
        onClick={() => {
          toggleModal();
        }}>
        <i className="jla-icon-chevron-left"></i>
        Retour
      </a>

      {/* Wording */}
      <h3 className="login__title">Réinitialiser votre mot de passe</h3>
      <div className="text-muted">Indiquez votre identifiant de connexion afin de recevoir un code temporaire sur SMS.</div>

      {/* Warning */}
      {warning && (
        <div className="reset__warning mt-4">
          Vous ne pouvez pas réinitialiser votre mot de passe si vous n'utilisez pas un numéro de téléphone pour accéder à votre compte, merci de
          contacter le support client pour plus d'informations.
        </div>
      )}

      {/* Form */}
      <form className="needs-validation" onSubmit={onSubmit}>
        <div className="form-group my-4">
          <input
            id="reset-phone"
            className={classnames('form-control bg-grey', {
              'is-invalid': errors.identifier
            })}
            type="text"
            name="identifier"
            placeholder="N° de téléphone ou identifiant"
            aria-describedby="reset-phone-input"
            value={values.identifier}
            onChange={handleChange}
            required
            autoComplete="off"
          />
          <div className="invalid-feedback">{getValidationState(values.identifier)}</div>
        </div>
        {tempCodeReqError && <p className="text-danger">{tempCodeReqError}</p>}
        <button type="submit" className="btn btn-block btn-primary" disabled={tempCodeReqLoading}>
          {tempCodeReqLoading && (
            <>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{' '}
            </>
          )}
          Valider
        </button>
      </form>
    </div>
  );
};

export default PasswordResetPhone;
