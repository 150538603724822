import React from 'react';

// TODO import { useIntl } from "react-intl";

import Icon from '../Icon/Icon';

import './TextHelper.scss';

const TextHelper = props => {
  // type [no type], warning, error, success
  // size [no size], sm
  const {text, size = '', type = '', className = ''} = props;

  /* textHelper icons */
  let textHelperIcon = null;
  if (type === 'warning') {
    textHelperIcon = 'exclamation-triangle-filled';
  }
  if (type === 'error') {
    textHelperIcon = 'x-circle-filled';
  }
  if (type === 'success') {
    textHelperIcon = 'check-circle-filled';
  }

  return text ? (
    <div className={`jla-text-helper ${type ? `jla-text-helper--${type}` : ''} ${size ? `jla-text-helper--${size}` : ''} ${className}`}>
      {textHelperIcon ? <Icon name={textHelperIcon} className="jla-text-helper__icon" /> : null}
      <span>{text}</span>
    </div>
  ) : null;
};

export default TextHelper;
