import {applyMiddleware, createStore, compose} from 'redux';

import thunk from 'redux-thunk';
import {createLogger} from 'redux-logger';
import rootReducer from './reducers';

// const middlewares = [];

// if (process.env.NODE_ENV === `development`) {
//   const { logger } = require(`redux-logger`);

//   middlewares.push(logger);
// }

// const store = compose(applyMiddleware(...middlewares))(createStore)(reducer);

let logger = createLogger({
  // timestamps: true,
  // duration: true
});

const store = createStore(rootReducer, compose(applyMiddleware(thunk, logger)));

export default store;
