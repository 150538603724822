import {
  HYDRATE_AUTH_STATE,
  GET_SUMMARY_REQUEST,
  GET_SUMMARY_SUCCESS,
  MAKE_TRANSACTION_REQUEST,
  MAKE_TRANSACTION_SUCCESS,
  MAKE_TRANSACTION_FAILURE,
  GET_SUMMARY_BULK_SUCCESS,
  MAKE_TRANSACTION_BULK_REQUEST,
  MAKE_TRANSACTION_BULK_SUCCESS,
  SET_STATE_TRANSACTION,
  FILTER_HEADER,
  GET_SERVICES_BY_EXTERNAL_REFERENCE_REQUEST,
  GET_SERVICES_BY_EXTERNAL_REFERENCE_SUCCESS,
  GET_SERVICES_BY_EXTERNAL_REFERENCE_FAILURE,
  TOGGLE_IS_MENU_OPEN,
  SET_BANK_DISPOSAL_AMOUNT,
  GET_SERVICES_LIST_BY_TYPE_REQUEST,
  GET_SERVICES_LIST_BY_TYPE_SUCCESS,
  UPDATE_BREADCRUMB,
  UPDATE_APP_NAVIGATION,
  GET_LATEST_RECEIVERS_REQUEST,
  GET_LATEST_RECEIVERS_SUCCESS,
  CANCEL_TRANSACTIONS_REQUEST,
  CANCEL_TRANSACTIONS_SUCCESS
} from '../actionTypes';

const actions = [
  HYDRATE_AUTH_STATE,
  GET_SUMMARY_REQUEST,
  GET_SUMMARY_SUCCESS,
  MAKE_TRANSACTION_REQUEST,
  MAKE_TRANSACTION_SUCCESS,
  MAKE_TRANSACTION_FAILURE,
  GET_SUMMARY_BULK_SUCCESS,
  MAKE_TRANSACTION_BULK_REQUEST,
  MAKE_TRANSACTION_BULK_SUCCESS,
  SET_STATE_TRANSACTION,
  TOGGLE_IS_MENU_OPEN,
  FILTER_HEADER,
  GET_SERVICES_BY_EXTERNAL_REFERENCE_REQUEST,
  GET_SERVICES_BY_EXTERNAL_REFERENCE_SUCCESS,
  GET_SERVICES_BY_EXTERNAL_REFERENCE_FAILURE,
  SET_BANK_DISPOSAL_AMOUNT,
  GET_SERVICES_LIST_BY_TYPE_REQUEST,
  GET_SERVICES_LIST_BY_TYPE_SUCCESS,
  UPDATE_BREADCRUMB,
  UPDATE_APP_NAVIGATION,
  GET_LATEST_RECEIVERS_REQUEST,
  GET_LATEST_RECEIVERS_SUCCESS,
  CANCEL_TRANSACTIONS_REQUEST,
  CANCEL_TRANSACTIONS_SUCCESS
];

const initialState = {
  bankDisposalOnboardingStatus: false,
  transactionNotify: null,
  summary: null,
  breadcrumb: null,
  summaryBulk: null,
  tutoPlayed: false,
  isMenuOpen: false,
  rapidTransferData: null,
  services: [],
  servicesTags: [],
  servicesByType: [],
  headerFilters: null,
  bankDisposalAmount: '',
  contentReload: null,
  appNavigation: null,
  latestReceivers: [],
  cancelTransactionsResult: [],
  refundTransactionsResult: []
};

export default [initialState, actions];
