/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {functions} from '@julaya/common/utils';

const BatchDetails = ({metadata, additionalData, includeReceiverFees}) => {
  return (
    <div className="feedback min-width feedback-details d-flex align-items-center justify-content-between">
      {/* Border */}
      <svg xmlns="http://www.w3.org/2000/svg">
        <rect x="0" y="0" height="100%" width="100%" rx="10" ry="10" />
      </svg>
      <span>
        Total: <span className="text-muted">{functions.formatMoney(additionalData?.totalWithFees)}</span>
      </span>
      <i className="jla-icon-question-mark-circle  text-primary"></i>

      <div className="info">
        <div className="p-3 d-flex align-items-center justify-content-between ">
          <span className="font-weight-bold">Détail du transfert</span>
          <i className="jla-icon-document text-black"></i>
        </div>
        <div className="border-top p-3">
          <div className="d-flex align-items-center justify-content-between ">
            <span className="font-weight-normal">Montant :</span>
            <span>{functions.formatMoney(parseInt(metadata?.sum))}</span>
          </div>
          <div className="d-flex align-items-center justify-content-between ">
            <span className="font-weight-normal">Frais Julaya :</span>
            <span>{functions.formatMoney(parseInt(metadata?.fees))}</span>
          </div>
        </div>
        {includeReceiverFees && (
          <div className="border-top p-3">
            <div className="font-weight-normal">Compensation des frais de retraits mobile money :</div>
            <div className="d-flex align-items-center justify-content-end ">
              <span>{functions.formatMoney(parseInt(metadata?.opFees))}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BatchDetails;
