/* eslint-disable react-hooks/exhaustive-deps */
import { Checkbox, Text } from '@julaya/common/ui/atoms';
import { BankInfoDisplay, ErrorDisplay } from '@julaya/common/ui/molecules';
import { DrawerSelector } from '@julaya/common/ui/organismes';
import { constants } from "@julaya/common/utils";
import { useEffect } from 'react';

const bankDepositImage = require('@julaya/common/assets/images/transfer-bank-disposal-illustrations/bank-deposit.png').default;

const BankSelect = props => {
  const {batchesTasksError, servicesByType, getServicesByType, errors, typeSlug, handleChange, values, getValidationState, setNextButtonDisabled} =
    props;

  useEffect(() => {
    if (values.service) {
      setNextButtonDisabled(false);
    } else {
      setNextButtonDisabled(true);
    }
  }, [values.service]);

  return (
    <>
      {batchesTasksError ? (
        <ErrorDisplay errorText={batchesTasksError || 'Aucun service trouvé'} onClick={() => getServicesByType(typeSlug, values.walletId)} />
      ) : (
        <>
          <DrawerSelector
            type="bank"
            placeholder="Sélectionnez une banque"
            selectedItem={
              values.service
                ? {
                    label: values.service.serviceTitle,
                    image: values.service.options.logo
                  }
                : null
            }
            options={servicesByType?.map(bank => {
              return {
                value: bank,
                image: constants.serviceSlugIcons[bank?.serviceSlug],
                label: bank.title
              };
            })}
            onSelect={v =>
              handleChange({
                target: {
                  name: 'service',
                  value: v
                }
              })
            }
            drawerTitle="Choix d'une banque"
            drawerIcon="jla-icon-bank"
            drawerColor="primary"
          />
          {errors.service && <Text color="error">{getValidationState(values.service, 'service')}</Text>}

          <div className="border-top my-4"></div>

          {values.service ? (
            <>
              <BankInfoDisplay bank={values.service} />
              <Checkbox
                label="Définir comme banque préférée"
                name="preferredBank"
                value={values.preferredBank}
                onChange={handleChange}
                inline={true}
              />
            </>
          ) : (
            <div className="d-flex flex-column align-items-center" style={{textAlign: 'center'}}>
              <img className="mb-2" src={bankDepositImage} alt="bank-deposit" style={{width: '100px', height: '100px'}} />
              <Text big bold>
                Dans quelle banque allez-vous effectuer vôtre dépôt ?
              </Text>
              <div className="">
                Choisissez parmi nos banques partenaires celle qui va vous permettre de faire facilement votre demande d'approvisionnement.
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default BankSelect;
