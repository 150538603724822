const USER_ROLES = {
  ADMIN: 'Administrateur',
  'ADMIN-ONLY': 'Modérateur',
  USER: 'Signataire',
  READ: 'Lecture seule'
};

const operationsMenu = [
  {
    type: 'link',
    path: '/dashboard',
    paths: ['/accounts/:type', '/history/:accountType/:walletId'],
    name: 'Mes comptes',
    icon: 'home',
    acls: ['READ', 'USER', 'ADMIN', 'ADMIN-ONLY']
  },
  {
    type: 'modal',
    target: 'task-modal',
    paths: ['/create-task', '/tasks/:batchId/edit', '/tasks/retry/:batchId', '/tasks/retry/:batchId/:transactionId', '/tasks/validate/:batchId'],
    name: 'Créer une tâche',
    icon: 'bulk',
    classes: 'transaction-bulk',
    chevron: false,
    acls: ['USER', 'ADMIN']
  },

  {
    type: 'link',
    path: '/transfer/rapid-transfer',
    name: 'Transfert rapide',
    icon: 'bolt',
    acls: ['USER', 'ADMIN'],
    modifier: 'canSend'
  },

  {
    type: 'link',
    path: '/transfer/bank-disposal',
    name: 'Rechargement',
    icon: 'wallet',
    acls: ['USER', 'ADMIN'],
    modifier: 'canTopUp'
  },

  {
    type: 'modal',
    target: 'history-export',
    name: 'Exporter',
    icon: 'arrow-down-on-square',
    acls: ['READ', 'USER', 'ADMIN']
  },
  {
    type: 'link',
    path: '/tasks',
    paths: ['/tasks/details/:walletId/:batchId', '/tasks-list'],
    name: 'Mes tâches',
    icon: 'task',
    acls: ['USER', 'ADMIN']
  }
];

const adminMenu = [
  {
    type: 'link',
    path: '/admin',
    name: 'Tableau de bord',
    icon: 'trending-up'
  },
  {
    type: 'link',
    path: '/admin/wallets',
    name: 'Mes comptes',
    icon: 'wallet',
    paths: [
      '/admin/wallets',
      '/admin/wallets/:walletId',
      '/admin/wallets/:walletId/edit',
      '/admin/wallets/:walletId/documents',
      '/admin/wallets/create'
    ]
  },
  {
    type: 'link',
    path: '/admin/cards',
    name: 'Mes cartes',
    icon: 'credit-card',
    paths: ['/admin/cards', '/admin/cards/create']
  },
  {
    type: 'link',
    path: '/admin/users',
    name: 'Mon équipe',
    icon: 'briefcase'
  },
  {
    type: 'link',
    path: '/admin/beneficiairies',
    name: 'Mes bénéficiaires',
    icon: 'user-group'
  },
  {
    type: 'link',
    path: '/admin/documents',
    name: 'Documents',
    icon: 'document'
  }
];

export default {USER_ROLES, operationsMenu, adminMenu};
