import React from 'react';
import cx from 'classnames';
import './Checkbox.scss';

const Checkbox = ({value, onChange, name, label, inline = true, className = '', status, border}) => {
  return (
    <a
      href="#julaya"
      onClick={e => {
        e.preventDefault();
        onChange({
          target: {
            name,
            type: 'checkbox',
            checked: !value
          }
        });
      }}
      className={cx(`jla-checkbox ${className}`, {
        'jla-checkbox--inline': inline
      })}>
      <div
        className={cx(`jla-checkbox__checkbox`, {
          '--white': status === 'white',
          '--border': border,
          '--checked': value
        })}>
        <i className="jla-icon-check-bold"></i>
      </div>
      <div className="jla-checkbox__label">{label}</div>
    </a>
  );
};

export default Checkbox;

//USAGE
{
  /* <Checkbox
    label='Définir comme banque préférée'
    name='prefered-bank'
    value={checkbox}
    onChange={setCheckbox}
    inline={true}
  /> */
}
