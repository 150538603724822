import React, {useState, useEffect} from 'react';
import moment from 'moment';
import cx from 'classnames';
import {Button} from '@julaya/common/ui/atoms';
import {Collapse} from '@julaya/common/ui/molecules';
import './BankDisposalStatus.scss';

const data = {
  cash: {
    avg: '24:00:00',
    avgHours: 24,
    desc: (
      <div className="bank-disposal-status__desc">
        Votre opération devrait normalement arriver dans les <b>1 jour ouvrable</b>. Il peut arriver, en raison de facteurs indépendants de notre
        volonté, que ce délai se prolonge jusqu'à <b>2 jours ouvrables</b>.
      </div>
    )
  },
  bank: {
    avg: '24:00:00',
    avgHours: 24,
    desc: (
      <div className="bank-disposal-status__desc">
        Votre opération devrait normalement arriver dans les <b>1 jour ouvrable</b>. Il peut arriver, en raison de facteurs indépendants de notre
        volonté, que ce délai se prolonge jusqu'à <b>2 jours ouvrables</b>.
      </div>
    )
  },
  check: {
    avg: '48:00:00',
    avgHours: 48,
    desc: (
      <div className="bank-disposal-status__desc">
        Votre opération devrait normalement arriver dans les <b>1 jour ouvrable</b>. Il peut arriver, en raison de facteurs indépendants de notre
        volonté, que ce délai se prolonge jusqu'à <b>2 jours ouvrables</b>.
      </div>
    )
  }
};

const failedReasons = {
  TOPUP_AMOUNT_DONT_MATCH: {
    title: 'Rejet : Document invalide',
    description: 'Montant du rechargement différent du montant reçu en banque'
  },
  TOPUP_RECEIPT_MISSING: {
    title: 'Rejet : Document invalide',
    description: 'Absence de pièces justificatives'
  },
  TOPUP_BANK_DONT_MATCH: {
    title: 'Rejet : Document invalide',
    description: 'Banque de demande différente de celle de la pièce justificative'
  },
  TOPUP_RECEIPT_NOT_SIGNED: {
    title: 'Rejet : Document invalide',
    description: 'Preuve de virement ou bordereau de versement non signé'
  },
  TOPUP_CHEQUE_NOT_RECEIVED: {
    title: 'Rejet : Document invalide',
    description: 'Chèque non réceptionné'
  }
};

const BankDisposalStatus = ({selectedItem, bg}) => {
  let infos = null;

  const [timeElapsed, setTimeElapsed] = useState(10);
  const [timeElapsedLength, setTimeElapsedLength] = useState('0%');
  const [contactSupport, setContactSupport] = useState(false);

  useEffect(() => {
    let intervalId;
    if (selectedItem?.status === 'waiting' && data[selectedItem.tags]) {
      intervalId = setInterval(() => {
        const now = moment();
        const createdAt = moment(selectedItem.createdAt);
        const t = now.diff(createdAt, 'seconds');
        const avgTime = (100 * t) / ((data[selectedItem?.tags]?.avgHours || 1) * 3600);
        setContactSupport(avgTime > 100);
        setTimeElapsedLength(avgTime > 100 ? '100%' : `${avgTime}%`);
        setTimeElapsed(t);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [timeElapsed]);

  if (data[selectedItem.tags]) {
    infos = data[selectedItem.tags];
  } else {
    return null;
  }

  if (selectedItem.status === 'failed') {
    const title = failedReasons[selectedItem.partnerComment] ? failedReasons[selectedItem.partnerComment].title : 'Rejet : Document invalide';
    const description = failedReasons[selectedItem.partnerComment]
      ? failedReasons[selectedItem.partnerComment].description
      : selectedItem.partnerComment;
    return (
      <Collapse title={title} icon="warning" iconColor="error" chevron={false} flat={false} bg={bg}>
        <div className="bank-disposal-status">
          <div className="bank-disposal-status__desc --no-marging">
            <div>
              <b>{description}</b>
            </div>
            <br />
            <div>Nous vous invitons à initier une nouvelle demande de rechargement de votre compte.</div>
            <br />
            <Button link="/transfer/bank-disposal" status="error" text="Refaire une opération" full={true} />
          </div>
        </div>
      </Collapse>
    );
  }

  if (selectedItem.status === 'waiting' && !selectedItem.needAttachment) {
    return (
      <Collapse
        title="Généralement en moins de 1 jour ouvré."
        icon="info-circle"
        iconColor="pending"
        chevron={true}
        flat={true}
        bg={bg}
        isOpen={true}
      >
        <div className="bank-disposal-status">
          <div className="bank-disposal-status__timer">
            <div>
              <div className="title">Temps écoulé</div>
              <div className={cx('content', {'--error': contactSupport})}>{formatTime(timeElapsed)}</div>
            </div>
            <div>
              <div className="title">Temps moyen de traitement</div>
              <div className="content">{infos.avg}</div>
            </div>
          </div>
          {infos.desc}
          <br />
          {contactSupport && (
            <Button
              variant="badge"
              status="primary"
              text="Contacter le support"
              full={true}
              icon={'icon-external-link'}
              action={() => {
                if (window.HubSpotConversations) {
                  window.HubSpotConversations.widget.open();
                }
              }}
            />
          )}

          <div className="bank-disposal-status__bar">
            <div className="bank-disposal-status__bar-inner" style={{width: timeElapsedLength}}></div>
          </div>
        </div>
      </Collapse>
    );
  }
  return null;
};

const padDigits = (number, digits) => {
  return Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number;
};

const formatTime = function (induration) {
  const thisduration = moment.duration(induration, 'seconds');
  var remainder = thisduration.asMilliseconds() % 1000;
  if (remainder >= 999) {
    thisduration.add(moment.duration(1));
  }
  return String(Math.floor(thisduration.asHours())) + ':' + padDigits(thisduration.minutes(), 2) + ':' + padDigits(thisduration.seconds(), 2);
};

export default BankDisposalStatus;

// USAGE EXAMPLE
