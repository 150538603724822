/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {constants} from '@julaya/common/utils';
import {DropdownSelect, Searchbar} from 'components/UI';

const Filter = props => {
  const {setQuery, query, status, setStatus} = props;
  const taskStatus = {};

  Object.values(constants.statusType).map(task => {
    return (taskStatus[task.slug] = task.title);
  });

  return (
    <div className="pt-4">
      <Searchbar className="mb-3" value={query} onChange={setQuery} name="tasks-overlay-search-query" bg="grey" />
      <div className="mb-3">
        <DropdownSelect value={status} label={'Par statut'} values={taskStatus} onChange={setStatus} />
      </div>

      <div className="my-4"></div>

      {(query !== '' || status.length !== 0) && (
        <div className="text-center mb-4">
          <a
            href="#julaya"
            className="text-secondary font-weight-bold"
            onClick={e => {
              e.preventDefault();
              setQuery('');
              setStatus([]);
            }}>
            Réinitialiser les filtres
          </a>
        </div>
      )}
    </div>
  );
};

export default Filter;
