export function isObject(value) {
  const type = typeof value;
  return value != null && (type === 'object' || type === 'function');
}

export function generateId(name = 'input') {
  return `${name}-${Math.random().toString(36).slice(2, 6)}`;
}

export const colors = ['primary', 'error', 'neutral', 'success', 'warning'];

export const buttonTypes = ['primary', 'secondary', 'tertiary', 'outline', 'ghost'];

export const iconList = [
  'thumb-up',
  'thumb-up-filled',
  'thumb-down',
  'thumb-down-filled',
  'cursor-arrow-rays',
  'cursor-arrow-rays-filled',
  'pencil',
  'adjustments-horizontal-filled',
  'adjustments-vertical-filled',
  'archive-filled',
  'arrow-bar-bottom-filled',
  'arrow-bar-top-filled',
  'arrow-down-circle-filled',
  'arrow-down-filled',
  'arrow-down-on-square-filled',
  'arrow-left-circle-filled',
  'arrow-left-down-filled',
  'arrow-left-filled',
  'arrow-left-on-rectangle-filled',
  'arrow-left-top-filled',
  'arrow-path-filled',
  'arrow-right-circle-filled',
  'arrow-right-down-filled',
  'arrow-right-filled',
  'arrow-right-on-rectangle-filled',
  'arrow-right-top-filled',
  'arrow-top-filled',
  'arrow-top-right-on-square-filled',
  'arrow-up-circle-filled',
  'arrow-up-on-square-filled',
  'arrows-right-left-filled',
  'bank-filled',
  'bars-3-filled',
  'bell-filled',
  'bills-filled',
  'bolt-filled',
  'briefcase-filled',
  'bulk-filled',
  'calendar-filled',
  'camera-filled',
  'cart-filled',
  'chart-pie-filled',
  'chat-bubble-filled',
  'check-bold-filled',
  'check-circle-filled',
  'check-filled',
  'chevron-down-filled',
  'chevron-left-filled',
  'chevron-right-filled',
  'chevron-up-down-filled',
  'chevron-up-filled',
  'clock-filled',
  'cloud-filled',
  'credit-card-filled',
  'device-computer-filled',
  'device-phone-mobile-filled',
  'document-filled',
  'document-plus-filled',
  'double-check-circle-filled',
  'ellipsis-horizontal-circle-filled',
  'ellipsis-horizontal-filled',
  'ellipsis-vertical-filled',
  'envelope-filled',
  'exclamation-circle-filled',
  'exclamation-triangle-filled',
  'eye-filled',
  'eye-off-filled',
  'fingerprint-filled',
  'flag-filled',
  'folder-filled',
  'forbidden-filled',
  'gear-filled',
  'gift-filled',
  'globe-europe-africa-filled',
  'globe-filled',
  'handshake-filled',
  'heart-filled',
  'home-filled',
  'identification-filled',
  'inbox-filled',
  'information-circle-filled',
  'julaya-circle-filled',
  'key-filled',
  'kiosk-filled',
  'link-filled',
  'list-bullet-filled',
  'lock-filled',
  'logs-filled',
  'magnifying-glass-filled',
  'map-filled',
  'map-pin-filled',
  'minus-circle-filled',
  'minus-filled',
  'office-filled',
  'passport-filled',
  'pencil-filled',
  'pencil-square-filled',
  'phone-filled',
  'phone-plus-circle-filled',
  'photo-filled',
  'plus-circle-filled',
  'plus-filled',
  'power-filled',
  'printer-filled',
  'qr-code-filled',
  'question-mark-circle-filled',
  'receipt-accepted-filled',
  'receipt-clock-filled',
  'receipt-percent-filled',
  'receipt-refund-filled',
  'receipt-refused-filled',
  'refresh-filled',
  'retry-filled',
  'scale-filled',
  'send-filled',
  'share-filled',
  'shield-check-filled',
  'sparkles-filled',
  'square-2-stack-filled',
  'square-check-filled',
  'squares-filled',
  'star-filled',
  'table-cell-filled',
  'task-filled',
  'thrash-filled',
  'trending-down-filled',
  'trending-up-filled',
  'truck-filled',
  'unlock-filled',
  'user-check-filled',
  'user-circle-filled',
  'user-clock-filled',
  'user-filled',
  'user-group-filled',
  'user-plus-filled',
  'user-x-filled',
  'wallet-filled',
  'wifi-filled',
  'wrench-screwdriver-filled',
  'x-bold-filled',
  'x-circle-filled',
  'x-mark-filled',
  'adjustments-horizontal',
  'adjustments-vertical',
  'archive',
  'arrow-bar-bottom',
  'arrow-bar-top',
  'arrow-down-circle',
  'arrow-down-on-square',
  'arrow-down',
  'arrow-left-circle',
  'arrow-left-down',
  'arrow-left-on-rectangle',
  'arrow-left-top',
  'arrow-left',
  'arrow-path',
  'arrow-right-circle',
  'arrow-right-down',
  'arrow-right-on-rectangle',
  'arrow-right-top',
  'arrow-right',
  'arrow-top-right-on-square',
  'arrow-up-circle',
  'arrow-up-on-square',
  'arrow-up',
  'arrows-right-left',
  'bank',
  'bars-3',
  'bell',
  'bills',
  'bolt',
  'briefcase',
  'bulb',
  'bulk',
  'calendar',
  'camera',
  'cart',
  'chart-pie',
  'chat-bubble',
  'check-bold',
  'check-circle',
  'check',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up-down',
  'chevron-up',
  'clock',
  'cloud',
  'credit-card',
  'device-computer',
  'device-phone-mobile',
  'document-plus',
  'document',
  'double-check-circle',
  'ellipsis-horizontal-circle',
  'ellipsis-horizontal',
  'ellipsis-vertical',
  'envelope',
  'exclamation-circle',
  'exclamation-triangle',
  'eye-off',
  'eye',
  'fingerprint',
  'flag',
  'folder',
  'forbidden',
  'gear',
  'gift',
  'globe-europe-africa',
  'globe',
  'handshake',
  'heart',
  'home',
  'Icons',
  'identification',
  'inbox',
  'information-circle',
  'julaya-circle',
  'key',
  'kiosk',
  'link',
  'list-bullet',
  'lock',
  'logs',
  'magnifying-glass',
  'map-pin',
  'map',
  'minus-circle',
  'minus',
  'office',
  'passport',
  'pencil-square',
  'pencil',
  'phone-plus-circle',
  'phone',
  'photo',
  'plus-circle',
  'plus',
  'power',
  'printer',
  'qr-code',
  'question-mark-circle',
  'receipt-accepted',
  'receipt-clock',
  'receipt-percent',
  'receipt-refund',
  'receipt-refused',
  'refresh',
  'retry',
  'scale',
  'send',
  'share',
  'shield-check',
  'sparkles',
  'square-2-stack',
  'square-check',
  'squares',
  'star',
  'table-cell',
  'task',
  'thrash',
  'trending-down',
  'trending-up',
  'truck',
  'unlock',
  'user-check',
  'user-circle',
  'user-clock',
  'user-group',
  'user-plus',
  'user-x',
  'wallet',
  'wifi',
  'wrench-screwdriver',
  'x-bold',
  'x-circle',
  'x-mark',
  'placeholder',
  'placeholder-filled',
  'gear',
  'chevron-down'
];

export const textVariants = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'body', 'small'];
