import React, {useEffect, useRef, useState} from 'react';
import cx from 'classnames';
import Icon from '../../DataDisplay/Icon/Icon';
import Avatar from '../../DataDisplay/Avatar/Avatar';
import Toggle from '../../Inputs/Toggle/Toggle';

import './Accordion.scss';

const ActionBtn = props => {
  const {open = false, disabled = false, type} = props;

  if (type === 'accordion') {
    return (
      <div
        className={cx('jla-accordion__action-btn', {
          'jla-accordion__action-btn--open': open,
          'jla-accordion__action-btn--disabled': disabled
        })}>
        {disabled ? <i className="icon-lock" /> : <i className="icon-chevron-down" />}
      </div>
    );
  } else {
    return (
      <Toggle
        checked={open}
        onChange={e => {
          e.preventDefault();
        }}
      />
    );
  }
};

const Accordion = props => {
  const {
    className,
    children,
    type,
    label,
    icon,
    open: isOpen = false,
    disabled = false,
    closable = true,
    bgColor = 'white',
    onToggle,
    expandPosition,
    noPadding,
    variant = 'sm'
  } = props;

  const body = useRef(null);
  const timeout = useRef(null);
  const [heightBody, setHeightBody] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    if (open) {
      setHeightBody(`${Math.floor(body.current.clientHeight)}px`);

      timeout.current = setTimeout(() => {
        setHeightBody('');
      }, 300);
    } else {
      setHeightBody(0);
    }
  }, [open]);
  const onAction = () => {
    if (!disabled) {
      if (open && !closable) {
        return;
      }
      open && setHeightBody(`${Math.floor(body.current.clientHeight + 16)}px`);
      setOpen(!open);
      if (onToggle) {
        onToggle(!open);
      }
    }
  };

  const iconTag =
    variant === 'md' ? (
      <div className="jla-accordion__suffix-block">
        <Avatar icon={icon} color="light" />
      </div>
    ) : (
      <div className="jla-accordion__suffix-block">
        <Icon tag="i" name={icon} />
      </div>
    );

  return (
    <div
      className={cx('jla-accordion', `jla-accordion--${variant}`, `jla-accordion--${bgColor}`, className, {
        'jla-accordion--open': open,
        'jla-accordion--disabled': disabled
      })}>
      <div
        className={cx('jla-accordion__header', {
          'jla-accordion__header--disabled': disabled
        })}
        onClick={e => {
          e.preventDefault();
          onAction();
        }}>
        {expandPosition === 'right' && !!icon && iconTag}
        {expandPosition === 'left' && <ActionBtn {...props} open={open} />}
        <div className={cx('jla-accordion__label', {'jla-accordion__label--left': expandPosition === 'left'})}>{label}</div>

        {expandPosition === 'right' && <ActionBtn {...props} open={open} />}
      </div>
      <div
        className={cx('jla-accordion__body', {
          'jla-accordion__body--open': open
        })}
        style={{
          height: heightBody
        }}>
        <div className="jla-accordion__body-inner">
          <div
            ref={body}
            className={cx('jla-accordion__body-children', {
              'jla-accordion__body-children--disabled': disabled,
              'jla-accordion__body-children--default-bg': bgColor !== 'white',
              'jla-accordion__body-children--gray': bgColor === 'gray',
              'jla-accordion__body-children--no-padding': noPadding
            })}>
            {children ? children : <div className="jla-accordion__no-children">This is placeholder. Swap me with with your content.</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

ActionBtn.displayName = 'Accordion.ActionBtn';
Accordion.ActionBtn = ActionBtn;

export default Accordion;
