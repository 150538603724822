import React from 'react';
import {useDebounce} from '@julaya/common/hooks';
import cx from 'classnames';
import Header from './Header/Header';
import Body from './Body/Body';
import Loading from './Loading/Loading';
import Cell from './Cell/Cell';

import {Pagination, Button, Text, Typography, Dropdown} from '@julaya/common/system';

import SvgFailIllustration from '@julaya/common/assets/images/fail.svg';
import SvgWelcomeIllustration from '@julaya/common/assets/images/welcome.svg';

import './Table.scss';

const Table = props => {
  const {
    children,
    className,
    headers,
    hideHeader,
    minWidth,
    page,
    onPageChanged,
    pageSize,
    total,
    loading,
    onAddClicked,
    addWording,
    setQuery,
    query,
    filtersConfig,
    initialFilters,
    filters,
    setFilters
  } = props;

  const debouncedSearchTerm = useDebounce(query, 700);

  const isDirtyFilters = debouncedSearchTerm || (filters && initialFilters && filters !== initialFilters);

  return (
    <>
      <div className={cx('jla-table-filter')}>
        <div>
          {/* {setArchived && (
            <Button className={"mr-1"} btnType={archived ? "primary" : "secondary"} color="primary" prefixIcon="archive" tag="button" type="button" onClick={() => setArchived(!archived)} />
          )} */}
          {setQuery && (
            <Text
              className={'mr-1'}
              white={true}
              prefixIcon={'magnifying-glass'}
              placeholder="Rechercher"
              onChange={e => setQuery(e.target.value)}
              value={query}
            />
          )}

          {filtersConfig && (
            <Dropdown
              type={'default'}
              background={'white'}
              placeholder={'Filtres'}
              disabled={false}
              closeOutside={true}
              icon={'adjustments-horizontal'}
              hasFooter={true}
              headerTitle={'Filtres'}
              onSubmit={values => setFilters(values)}
              configDropdownMenu={filtersConfig}
            />
          )}
        </div>
        <div>
          {onAddClicked && addWording && (
            <Button btnType="ghost" color="primary" prefixIcon="plus-circle" tag="button" type="button" onClick={onAddClicked}>
              {addWording}
            </Button>
          )}
        </div>
      </div>
      <div className={cx('jla-table-wrapper')}>
        <table
          className={cx('jla-table', className)}
          style={{
            minWidth: `${minWidth}px`
          }}>
          <>
            {headers ? (
              <>
                {!hideHeader && <Header {...props} />}
                {!loading ? <Body {...props} /> : <Loading {...props} />}
              </>
            ) : (
              children
            )}
          </>
        </table>
        {!loading && isDirtyFilters && total === 0 && (
          <div className={cx('jla-table-no-result')}>
            <img src={SvgFailIllustration} alt="Aucun résultat trouvé" width={200} height={150} />
            <Typography tag="h6" variant="h6" lead={true}>
              Aucun résultat trouvé.
            </Typography>
            <Typography tag="p" color="neutral">
              Nous n’avons pas trouvé ce que vous recherchez, veuillez réessayer autrement.
            </Typography>
          </div>
        )}
        {!loading && !isDirtyFilters && total === 0 && (
          <div className={cx('jla-table-no-result')}>
            <img src={SvgWelcomeIllustration} alt="Bienvenue chez Julaya" width={200} height={150} />
            <Typography tag="h6" variant="h6" lead={true}>
              Bienvenue chez Julaya !
            </Typography>
            <Typography tag="p" color="neutral">
              Commencez à découvrir les fonctionnalités de notre plateforme de services dédiés à votre entreprise.
            </Typography>
            {onAddClicked && addWording && (
              <Button btnType="ghost" color="primary" prefixIcon="plus-circle" tag="button" type="button" onClick={onAddClicked}>
                {addWording}
              </Button>
            )}
          </div>
        )}
        {!loading && page && total !== 0 && (
          <Pagination type={'table-bar'} page={page} onChanged={onPageChanged} pageSize={pageSize} total={total} position={'right'} />
        )}
      </div>
    </>
  );
};

Header.displayName = 'Table.Header';
Body.displayName = 'Table.Body';
Cell.displayName = 'Table.Cell';

Table.Header = Header;
Table.Body = Body;
Table.Cell = Cell;

export default Table;
