import {
	GET_USER_LIST_REQUEST,
	GET_USER_LIST_SUCCESS,
	CREATE_USER_REQUEST,
	CREATE_USER_SUCCESS,
	GET_USER_REQUEST,
	GET_USER_SUCCESS,
	GET_IS_VALID_PHONE_REQUEST,
	GET_IS_VALID_PHONE_SUCCESS,
	GET_IS_VALID_PHONE_FAILURE
} from '../actionTypes';

const actions = [
	GET_USER_LIST_REQUEST,
	GET_USER_LIST_SUCCESS,
	CREATE_USER_REQUEST,
	CREATE_USER_SUCCESS,
	GET_USER_REQUEST,
	GET_USER_SUCCESS,
	GET_IS_VALID_PHONE_REQUEST,
	GET_IS_VALID_PHONE_SUCCESS,
	GET_IS_VALID_PHONE_FAILURE
];

const initialState = {
	users: [],
	userDetails: null,
	isValidPhone: false
};

export default [initialState, actions];
