import {functions, network} from '@julaya/common/utils';

import {
  GET_HISTORY_REQUEST,
  GET_HISTORY_SUCCESS,
  GET_HISTORY_FAILURE,
  EXPORT_HISTORY_REQUEST,
  EXPORT_HISTORY_SUCCESS,
  EXPORT_HISTORY_FAILURE,
  GET_BATCHES_HISTORY_REQUEST,
  GET_BATCHES_HISTORY_SUCCESS,
  GET_BATCHES_HISTORY_FAILURE,
  GET_BATCHES_TASKS_REQUEST,
  GET_BATCHES_TASKS_SUCCESS,
  GET_BATCHES_TASKS_FAILURE,
  GET_BATCH_DETAILS_REQUEST,
  GET_BATCH_DETAILS_SUCCESS,
  GET_BATCH_DETAILS_FAILURE,
  GET_BATCH_ACTIVITY_REQUEST,
  GET_BATCH_ACTIVITY_SUCCESS,
  GET_BATCH_ACTIVITY_FAILURE,
  ADD_BATCH_COMMENTS_REQUEST,
  ADD_BATCH_COMMENTS_SUCCESS,
  ADD_BATCH_COMMENTS_FAILURE,
  ATTACH_RECEIPT_REQUEST,
  ATTACH_RECEIPT_SUCCESS,
  ATTACH_RECEIPT_FAILURE,
  SET_SELECTED_ITEM,
  DELETE_ATTACHED_FILE_REQUEST,
  DELETE_ATTACHED_FILE_SUCCESS,
  DELETE_ATTACHED_FILE_FAILURE,
  EDIT_TRANSACTION_COMMENT_REQUEST,
  EDIT_TRANSACTION_COMMENT_SUCCESS,
  EDIT_TRANSACTION_COMMENT_FAILURE,
  MARK_BATCH_AS_DONE_REQUEST,
  MARK_BATCH_AS_DONE_SUCCESS,
  MARK_BATCH_AS_DONE_FAILURE,
  ARCHIVE_TRANSACTIONS_FAIL_REQUEST,
  ARCHIVE_TRANSACTIONS_FAIL_SUCCESS,
  ARCHIVE_TRANSACTIONS_FAIL_FAILURE
} from '../actionTypes';

export const getHistory = (walletId, data) => async (dispatch, getState) => {
  dispatch({
    type: GET_HISTORY_REQUEST,
    payload: {
      history: [],
      historyCount: 0,
      attachedReceipt: null,
      attachedDepositReceipt: null
    }
  });
  try {
    const response = await network.request(`/wallets/${walletId}/transactions`, 'POST', data);
    if (!Array.isArray(response.data)) {
      throw new Error('Des erreurs sont survenues, veuillez réessayer');
    }
    const historyCount = typeof response.count !== 'undefined' ? response.count : 0;
    dispatch({
      type: GET_HISTORY_SUCCESS,
      payload: {history: response.data, historyCount}
    });
  } catch (error) {
    dispatch({type: GET_HISTORY_FAILURE, error: error.message});
  }
};

export const exportHistory = params => async dispatch => {
  dispatch({type: EXPORT_HISTORY_REQUEST});

  try {
    params.password = await functions.encryptCode(params.password);

    await network.request(`/wallets/export`, 'POST', params);

    dispatch({type: EXPORT_HISTORY_SUCCESS});
  } catch (error) {
    dispatch({type: EXPORT_HISTORY_FAILURE, error: error.message});
  }
};

export const getBatchesHistory = data => async dispatch => {
  dispatch({type: GET_BATCHES_HISTORY_REQUEST});
  try {
    const response = await network.request(`/tasks/list`, 'POST', data);
    if (!Array.isArray(response.data)) {
      throw new Error('Malformed');
    }

    const batchesCount = typeof response.count !== 'undefined' ? response.count : 0;
    dispatch({
      type: GET_BATCHES_HISTORY_SUCCESS,
      payload: {
        batchesHistoryPage: data.page,
        batchesHistory: response.data,
        batchesCount
      }
    });
  } catch (error) {
    dispatch({type: GET_BATCHES_HISTORY_FAILURE, error: error.message});
  }
};

export const getBatchesTasks = data => async dispatch => {
  dispatch({type: GET_BATCHES_TASKS_REQUEST});
  try {
    const response = await network.request(`/tasks/list`, 'POST', {
      ...data,
      page: -1,
      status: ['pending', 'draft', 'sending', 'to-validate', 'to-retry']
    });
    if (!Array.isArray(response.data)) {
      throw new Error('Malformed');
    }

    const batchesCount = typeof response.count !== 'undefined' ? response.count : 0;
    dispatch({
      type: GET_BATCHES_TASKS_SUCCESS,
      payload: {
        batchesTasks: response.data,
        batchesCount
      }
    });

    // dispatch(refreshBatchDetails([...response.data]));
  } catch (error) {
    dispatch({type: GET_BATCHES_TASKS_FAILURE, error: error.message});
  }
};

export const getBatchDetails = taskId => async dispatch => {
  dispatch({
    type: GET_BATCH_DETAILS_REQUEST,
    payload: {batchDetails: null}
  });

  try {
    const response = await network.request(`/tasks/${taskId}`, 'GET');

    dispatch({
      type: GET_BATCH_DETAILS_SUCCESS,
      payload: {batchDetails: response}
    });
  } catch (error) {
    dispatch({type: GET_BATCH_DETAILS_FAILURE, error: error.message});
  }
};

export const getBatchActivity = batchId => async (dispatch, getState) => {
  dispatch({
    type: GET_BATCH_ACTIVITY_REQUEST
  });

  try {
    const response = await network.request(`/tasks/${batchId}/activities/list`, 'POST');

    const {
      history: {batchActivity}
    } = getState();

    dispatch({
      type: GET_BATCH_ACTIVITY_SUCCESS,
      payload: {
        batchActivity: {...batchActivity, [batchId]: response}
      }
    });
  } catch (error) {
    dispatch({
      type: GET_BATCH_ACTIVITY_FAILURE,
      error: error.message
    });
  }
};

export const addBatchComment = (taskId, content) => async dispatch => {
  dispatch({
    type: ADD_BATCH_COMMENTS_REQUEST
  });

  try {
    await network.request(`/tasks/${taskId}/activities`, 'POST', {content});

    dispatch({
      type: ADD_BATCH_COMMENTS_SUCCESS
    });

    dispatch(getBatchActivity(taskId));
  } catch (error) {
    dispatch({
      type: ADD_BATCH_COMMENTS_FAILURE,
      error: error.message
    });
  }
};

export const attachReceipt = (params, type) => async (dispatch, getState) => {
  dispatch({
    type: ATTACH_RECEIPT_REQUEST,
    payload: {attachedReceipt: null}
  });

  try {
    const {walletId, transactionId, file} = params;
    const response = await network.fileUploader(`/wallets/${walletId}/transactions/${transactionId}/file/attach`, {
      file,
      type
    });

    const {
      history: {history}
    } = getState();

    const index = history.findIndex(element => element.uuid === transactionId);

    if (index !== -1) {
      history[index].receipts.push({
        data: response.file,
        transactionUuid: transactionId
      });
    }

    dispatch({
      type: ATTACH_RECEIPT_SUCCESS,
      payload: {
        history,
        attachedReceipt: {...response, uuid: transactionId}
      }
    });
  } catch (error) {
    dispatch({type: ATTACH_RECEIPT_FAILURE, error: error.message});
  }
};

export const deleteAttachedFile = params => async (dispatch, getState) => {
  dispatch({
    type: DELETE_ATTACHED_FILE_REQUEST,
    payload: {attachedReceipt: null}
  });

  try {
    await network.request('/transactions/delete-attachment', 'POST', params);

    const {
      history: {history}
    } = getState();

    const index = history.findIndex(element => element.uniqId === params.uniqId);

    if (index !== -1) {
      history[index].receipts = history[index].receipts.filter(element => element.id !== params.id);
      history[index];
    }

    dispatch({
      type: DELETE_ATTACHED_FILE_SUCCESS,
      payload: {
        history,
        selectedItem: history[index]
      }
    });
  } catch (error) {
    dispatch({type: DELETE_ATTACHED_FILE_FAILURE, error: error.message});
  }
};

export const setSelectedItem =
  (selectedItem = null) =>
  async dispatch => {
    dispatch({type: SET_SELECTED_ITEM, payload: {selectedItem}});
  };

export const markBatchAsDone = (taskId, params) => async dispatch => {
  dispatch({
    type: MARK_BATCH_AS_DONE_REQUEST
  });

  try {
    await network.request(`/tasks/${taskId}/mark-as-complete`, 'PATCH');
    // await network.request(`/tasks/${taskId}/mark-as-complete`, 'POST', params);

    dispatch({
      type: MARK_BATCH_AS_DONE_SUCCESS
    });

    await dispatch(getBatchDetails(batchId));
  } catch (error) {
    dispatch({type: MARK_BATCH_AS_DONE_FAILURE, error: error.message});
  }
};

export const archiveTransactions = (walletId, params) => async (dispatch, getState) => {
  dispatch({
    type: ARCHIVE_TRANSACTIONS_FAIL_REQUEST
  });

  try {
    const {
      auth: {tfaActive}
    } = getState();

    const args = {
      uuids: params.uuids,
      password: await functions.encryptCode(params.code)
    };

    if (tfaActive) {
      args.otp = params.otp;
    }

    await network.request(`/wallets/${walletId}/transactions/archive`, 'POST', args);

    dispatch({
      type: ARCHIVE_TRANSACTIONS_FAIL_SUCCESS
    });
  } catch (error) {
    dispatch({
      type: ARCHIVE_TRANSACTIONS_FAIL_FAILURE,
      error: error.message
    });
  }
};

export const editTransactionComment = args => async (dispatch, getState) => {
  dispatch({
    type: EDIT_TRANSACTION_COMMENT_REQUEST
  });

  try {
    const {
      history: {selectedItem, history},
      wallets: {walletDetails}
    } = getState();

    if (!selectedItem) {
      throw new Error('Aucune transaction sélectionnée');
    }
    if (!walletDetails) {
      throw new Error('Aucun compte sélectionné');
    }

    await network.request(`/wallets/${walletDetails.id}/transactions/${selectedItem.uuid}/comment`, 'POST', args);

    const historyData = [...history];
    const index = historyData.findIndex(element => element.uuid === selectedItem.uuid);

    if (index !== -1) {
      historyData[index].comment = args.comment;
    }

    dispatch({
      type: EDIT_TRANSACTION_COMMENT_SUCCESS,
      payload: {
        history: historyData
      }
    });

    dispatch(setSelectedItem({...selectedItem, comment: args.comment}));
  } catch (error) {
    dispatch({
      type: EDIT_TRANSACTION_COMMENT_FAILURE,
      error: error.message
    });
  }
};
