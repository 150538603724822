import React from 'react';

import {constants} from '@julaya/common/utils';

const TransactionIcon = ({item: {status, TransactionType}, iconDisplay}) => {
  /*
  | Default Icon | Left alignement
  | -------------------------------
  */

  if (iconDisplay && iconDisplay === 'default') {
    status = false;

    return (
      <span className={`list-icon icon-container bg-light-secondary ${constants.transactionTypeMap[TransactionType.slug]?.icon}`}>
        <i
          className={
            (constants.transactionTypeMap[TransactionType.slug]?.icon
              ? `${constants.transactionTypeMap[TransactionType.slug].icon}`
              : 'jla-icon-arrows-right-left') + ' text-secondary'
          }></i>
      </span>
    );
  }

  /*
  | Failed Icon | Right alignement
  | -------------------------------
  */
  if (status === 'failed') {
    return (
      <span className="mr-1 icon-container danger">
        <i className="jla-icon-x-circle"></i>
      </span>
    );
  }

  /*
  | Waiting Icon | Right alignement
  | -------------------------------
  */
  if (status === 'waiting') {
    return (
      <span className="mr-1 icon-container warning">
        <i className="jla-icon-clock"></i>
      </span>
    );
  }

  return null;
};

export default TransactionIcon;
