/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useEffect} from 'react';
import cx from 'classnames';
import moment from 'moment';
import {useForm} from '@julaya/common/hooks';
import {Icon} from '@julaya/common/system';
const defaultAvatar = require(`@julaya/common/assets/images/default-avatar.png`).default;

const DetailComment = props => {
  const {
    batchActivity,
    selectedBatch,
    addBatchComment,
    batchActivityLoading,
    batchActivityError,
    addBatchActivityLoading,
    addBatchActivityError,
    user,
    getBatchActivity
  } = props;

  const [values, handleChange, _, __, resetValues] = useForm({
    content: ''
  });

  useEffect(() => {
    getBatchActivity(selectedBatch.id);
  }, []);

  const onSubmit = e => {
    e.preventDefault();
    if (values.content) {
      addBatchComment(selectedBatch.id, values.content);
      resetValues();
    }
  };

  return (
    <div className="comments">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <span></span>
        <a
          href="#julaya"
          onClick={e => {
            e.preventDefault();
            if (selectedBatch && selectedBatch.id) {
              getBatchActivity(selectedBatch.id);
            }
          }}>
          <Icon name="retry" size="h5" />
        </a>
      </div>

      <div className="d-flex align-items-center">
        <div className="avatar-small mr-2" style={{backgroundImage: `url(${user.avatar || defaultAvatar})`}} />
        <form className="needs-validation d-flex flex-column flex-grow-1 m-0" onSubmit={onSubmit}>
          <div className="form-group rounded py-2 px-3 mb-0">
            <div className="input-group">
              <input
                id="new-message"
                className={cx('form-control border-0')}
                type="text"
                name="content"
                placeholder="Ecrivez un commentaire"
                value={values.content}
                onChange={handleChange}
                autoComplete="off"
                required
              />

              <button className="btn btn-primary" type="submit" disabled={batchActivityLoading || addBatchActivityLoading || values.content === ''}>
                {addBatchActivityLoading && (
                  <>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{' '}
                  </>
                )}
                Continuer
              </button>
            </div>
          </div>
        </form>
      </div>

      {batchActivityError && <p className="text-danger py-2">{batchActivityError}</p>}

      {addBatchActivityError && <p className="text-danger py-2">{addBatchActivityError}</p>}

      {selectedBatch && batchActivity
        ? batchActivity[selectedBatch.id]?.data?.map(activity => {
            return (
              <div className="d-flex mt-4" key={activity.id}>
                <div
                  className="avatar-small mr-2"
                  style={{
                    backgroundImage: `url(${activity?.User?.avatar || defaultAvatar})`
                  }}></div>
                <div>
                  <div className="d-flex align-items-center small">
                    <div className="font-weight-bold mr-3">{activity?.User?.firstname + ' ' + activity?.User?.lastname}</div>
                    <div className="text-muted">{moment(activity.createdAt).format('DD/MM/YYYY - HH:mm')}</div>
                  </div>
                  <div
                    className={cx('mt-2 text-muted', {
                      'font-weight-bold': activity.type === 'status'
                    })}>
                    {activity.content}
                  </div>
                </div>
              </div>
            );
          })
        : selectedBatch && (
            <div className="d-flex flex-grow-1 justify-content-center align-items-center py-4">
              <span className="text-primary spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </div>
          )}
    </div>
  );
};

export default DetailComment;
