const julayaUrl = 'https://julaya.co/';
const julayaProUrl = 'https://pro.julaya.co/';
const julayaAppUrl = 'https://app.julaya.co/';
const appointementUrl = 'https://meetings.hubspot.com/ldjaya';
const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.julayaagent';
const findMoreUrl = playStoreUrl; //IN CASE DIFFERENT FROM PLAYSTORE
const discoverUrl = 'https://julaya.co/decouvrir-julaya';
const agreementUrl = 'https://julaya.co/politique-de-confidentialite'; //NOTE: Links
const googleAuthAppStore = 'https://apps.apple.com/us/app/google-authenticator/id388497605';
const googleAuthPlayStore = 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';
const whatsappUrl = 'http://google.com'; //NOTE: Links
const mediumUrl = 'http://google.com'; //NOTE: Links
const facebookUrl = 'https://www.facebook.com/julayamobilemoney/';
const linkedinUrl = 'https://www.linkedin.com/company/julaya/';
const instagramUrl = 'https://www.instagram.com/julaya_money/';
const helpUrl = 'https://aide.julaya.co';
const helpCreateUrl = 'https://aide.julaya.co/hc/fr-fr/requests/new';

export default {
  julayaUrl,
  julayaProUrl,
  julayaAppUrl,
  appointementUrl,
  playStoreUrl,
  findMoreUrl,
  discoverUrl,
  agreementUrl,
  googleAuthAppStore,
  googleAuthPlayStore,
  whatsappUrl,
  mediumUrl,
  facebookUrl,
  linkedinUrl,
  instagramUrl,
  helpUrl,
  helpCreateUrl
};
