import React, {useState, useRef} from 'react';
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import {useDidUpdate, useClickOutsideMenu} from '@julaya/common/hooks';
import cx from 'classnames';
import {Icon} from '@julaya/common/system';

const DateRangepicker = ({title, defaultMinDate, onChangeMinDate, defaultMaxDate, onChangeMaxDate, icon}) => {
  const [dropdownOpen, setOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState('startDate');
  const [label, setLabel] = useState(title);
  const [modalDatetimeOpened, setModalOpened] = useState(false);
  const datetimeModal = useRef();

  // Toggle modal
  const toggler = () => {
    if (!modalDatetimeOpened) {
      setModalOpened('dropdown-datetime');
    } else {
      setModalOpened(false);
    }
  };

  //Click outside modal
  useClickOutsideMenu('dropdown-datetime', datetimeModal, '.dropdown-datetime', modalDatetimeOpened, toggler);

  const onChangeFunction = (value, setToggle = true) => {
    if (value === null) {
      onChangeMinDate('');
      onChangeMaxDate('');
      setCurrentValue('startDate');
    }
    const date = value instanceof moment ? value.format('DD/MM/YYYY') : '';

    if (currentValue === 'startDate') {
      onChangeMinDate(date);
      setLabel(`${date}`);
      setCurrentValue('endDate');
    } else {
      onChangeMaxDate(date);
      setLabel(`${defaultMinDate} - ${date}`);
    }
    setToggle && toggle();
  };

  useDidUpdate(() => {
    setLabel(defaultMinDate ? `${defaultMinDate}` : title);
  }, [defaultMinDate]);

  const toggle = () => setOpen(!dropdownOpen);

  return (
    <div
      className={cx(`dropdown-datetime`, {
        active: modalDatetimeOpened
      })}>
      <div className="dropdown-datetime-btn w-100 bg-grey" onClick={() => toggler('dropdown-datetime')}>
        <div className="d-flex align-items-center">
          {icon && <i className={`${icon} h4 mb-0 align-self-center text-muted mr-2`}></i>}
          <span className="text-muted">{label}</span>
        </div>

        <div className="d-flex align-items-center">
          <Icon className="text-muted" name="chevron-down" size="h6" />
          {defaultMinDate !== '' && (
            <div onClick={() => onChangeFunction(null, false)} className="border-0 pl-2">
              <i className="icon-close-circle"></i>
            </div>
          )}
        </div>
      </div>

      <div className="dropdown-datetime-content py-4" ref={datetimeModal}>
        {defaultMinDate === '' ? (
          <ReactDatetime
            timeFormat={false}
            defaultValue={currentValue === 'startDate' ? defaultMinDate : defaultMaxDate}
            onChange={e => onChangeFunction(e, false)}
            open={!!modalDatetimeOpened}
            renderInput={() => null}
          />
        ) : (
          <ReactDatetime
            timeFormat={false}
            defaultValue={currentValue === 'startDate' ? defaultMinDate : defaultMaxDate}
            onChange={onChangeFunction}
            open={!!modalDatetimeOpened}
            renderInput={() => null}
          />
        )}
      </div>
    </div>
  );
};

export default DateRangepicker;
