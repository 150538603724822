import {functions, constants, colors} from '@julaya/common/utils';
import {moment} from '@julaya/common/utils';
import cx from 'classnames';
import Comment from './Comment';

export const details = {
  wallet: {
    title: 'Opération sur',
    getValue: ({item: {wallet}}) => {
      if (!wallet) return null;
      return <span className="font-weight-bold text-black">{`${wallet.label} - ${wallet.reference}`}</span>;
    }
  },
  status: {
    title: "Statut de l'opération",
    getValue: ({item: {status}}) => {
      const data = colors.status[status] || null;

      if (data === null) {
        return null;
      }

      return (
        <div className={`status-pill ${data.bg}`}>
          <i className={data.icon}></i> <span>{data.text}</span>
        </div>
      );
    }
  },

  amount: {
    title: 'Montant',
    getValue: ({item: {amount}}) => {
      return <span className="font-weight-bold">{functions.formatMoney(amount.toString().replace(/\D+/g, ''))}</span>;
    }
  },

  operatorFees: {
    title: 'Frais de retrait (inclus)',
    getValue: ({item: {includeFees, serviceFees}}) => {
      if (!includeFees) {
        return null;
      }
      return (
        <span
          className={cx('font-weight-bold', {
            'text-danger': serviceFees && serviceFees > 0
          })}>
          {serviceFees && +serviceFees > 0 ? `${serviceFees} ${constants.currency}` : '-'}
        </span>
      );
    }
  },

  fees: {
    title: "Frais de l'opération",
    getValue: ({item: {fees, status}}) => {
      return (
        <span
          className={cx('font-weight-bold', {
            'text-danger': !!fees && status !== 'failed' && status !== 'archived',
            'text-black': (fees === 0 && status !== 'failed') || status === 'failed' || status === 'archived'
          })}>
          {status === 'failed' || status === 'archived' ? '-' : fees ? `${fees} ${constants.currency}` : 'Gratuit'}
        </span>
      );
    }
  },

  receiver: {
    title: 'Bénéficiaire',
    getValue: ({item: {externalReference, externalFullname, TransactionType}}) => {
      let details = externalReference;
      try {
        switch (TransactionType.slug) {
          case 'transfer':
            details = `${functions.formatPhone(externalReference)}${externalFullname ? ` - ${externalFullname}` : ''}`;
            break;
          case 'wave-transfer':
            details = `${functions.formatPhone(externalReference)}${externalFullname ? ` - ${externalFullname}` : ''}`;
            break;
          case 'disposal':
            details = `${functions.formatPhone(externalReference)}${externalFullname ? ` - ${externalFullname}` : ''}`;
            break;
          case 'airtime':
            details = `${functions.formatPhone(externalReference)}${externalFullname ? ` - ${externalFullname}` : ''}`;
            break;
          case 'wizall-transfer':
            details = `${functions.formatPhone(externalReference)}${externalFullname ? ` - ${externalFullname}` : ''}`;
            break;
          case 'international-transfer':
            details = `${functions.formatPhone(externalReference)}${externalFullname ? ` - ${externalFullname}` : ''}`;
            break;
          case 'cb-transfer':
            details = `${functions.formatPhone(externalReference)}${externalFullname ? ` - ${externalFullname}` : ''}`;
            break;
          case 'bank-transfer':
            details = externalFullname;
            break;
          default:
            details = externalFullname;
            break;
        }
      } catch (error) {
        return null;
      }
      if (details) {
        return <span className={`font-weight-bold text-black`}>{details}</span>;
      } else {
        return null;
      }
    }
  },
  service: {
    title: 'Service',
    getValue: ({item: {TransactionType, Service}}) => {
      if (['bank-disposal', 'transfer', 'wave-transfer', 'disposal', 'airtime', 'wizall-transfer', 'bank-transfer'].includes(TransactionType.slug)) {
        return <span className={`font-weight-bold text-black`}>{Service.title}</span>;
      } else {
        return null;
      }
    }
  },

  date: {
    title: "Date de l'opération",
    getValue: ({item}) => {
      return <span className="font-weight-bold text-black">{moment(item.createdAt).format('DD/MM/YYYY - HH:mm')}</span>;
    }
  },

  ref: {
    title: 'Numéro de réference',
    getValue: ({item: {uuid}}) => {
      if (uuid) {
        return <span className={'text-secondary font-weight-bold'}>{uuid}</span>;
      } else {
        return null;
      }
    }
  },
  comment: {
    title: 'Commentaire',
    getValue: props => {
      return <Comment {...props} />;
    }
  }
};
