import cx from 'classnames';

import {Button} from '../../atoms';

import './Card.scss';

const Card = ({className, icon, image, title, description, rounded = false, action, next}) => {
  return (
    <div className={cx(`card__container ${className ? className : ''}`, {'--next': next})} onClick={action}>
      <Button
        variant="badge"
        imageStyle={{width: '32px', height: '32px'}}
        icon={icon}
        size="large"
        rounded={rounded}
        image={image}
        status={image && 'ghost'}
      />
      <div className="card__info">
        <div className="card__info__title">{title}</div>
        <div className="card__info__description">{description}</div>
      </div>
    </div>
  );
};

//
// USAGE
//
// Optional :
// - Specify rounded if you want rounded icon or not
// - action : if you want to fire up an action when you click the Card elt
// - next : specified in case there is an after element => make a margin-bottom
// - image : to use the image , specify the path under '@julaya/common/assets/images/'
//
/* 
	<Card
	icon={"icon-help"}
	title="Test Title"
	description="Test Description"
	action={() => function()}
	rounded
	next
	/> 
	*/
//

export default Card;
