import {Link} from 'react-router-dom';
import cx from 'classnames';

import {Button} from '@julaya/common/ui/atoms';
import './CardWallet.scss';

const types = {
  wallet: {
    icon: 'jla-icon-wallet',
    title: 'Compte'
  },
  card: {
    icon: 'jla-icon-credit-card',
    title: 'Carte bancaire'
  },
  entreprise: {
    icon: 'jla-icon-briefcase',
    title: 'Entreprise'
  }
};

const Wallet = props => {
  const {
    loading,
    label,
    reference,
    balance,
    disabled = false,
    isMobile,
    type = 'wallet',
    variant = 'light',
    selected = false,
    onClick = null,
    isEmpty = false,
    emptyLink,
    emptyIcon,
    emptyText,
    emptyAction = null,
    emptyVariant = 'white'
  } = props;

  if (isEmpty) {
    return (
      <>
        {emptyLink ? (
          <Link
            to={emptyLink}
            className={cx(`card_wallet empty border --${emptyVariant}`, {
              '--cursor': emptyLink
            })}>
            <div className="mb-2">
              <i className={`${emptyIcon} m-0 h1 text-secondary`}></i>
            </div>
            <div className="mb-2 font-weight-bold">{emptyText}</div>
          </Link>
        ) : (
          <div
            className={cx(`card_wallet empty border --${emptyVariant}`, {
              '--cursor': emptyAction
            })}
            onClick={emptyAction}>
            <div className="mb-2">
              <i className={`${emptyIcon} m-0 h1 text-secondary`}></i>
            </div>
            <div className="mb-2 font-weight-bold">{emptyText}</div>
          </div>
        )}
      </>
    );
  }

  if (loading) {
    return (
      <div className={cx(`card_wallet card_wallet--${variant}`, {loading})}>
        <div className="wallet__info">
          <div className="d-flex mb-3">
            <div className="wallet__label">
              <div className="placeholder-line" style={{width: '4rem'}}></div>
            </div>
            <div className="wallet__reference">
              <div className="placeholder-line" style={{width: '6rem'}}></div>
            </div>
          </div>
          <div className="wallet__meta">
            <div className={`wallet__type wallet__type--${type}`}>
              <div className="placeholder-square"></div>
              <div className="ml-2 placeholder-line" style={{width: '3rem'}}></div>
            </div>

            <div className={`wallet__country wallet__country--${country}`}>
              <div className="placeholder-square"></div>
              <div className="ml-2 placeholder-line" style={{width: '3rem'}}></div>
            </div>
          </div>
        </div>
        <div className="wallet__balance">
          <div className="placeholder-status" style={{width: '5rem'}}></div>
        </div>
      </div>
    );
  }

  const wallet = (
    <>
      <div
        className={cx(`card_wallet card_wallet--${variant}`, {
          disabled: disabled
        })}>
        <div className="wallet__info">
          <div className="d-flex mb-3">
            <div className="wallet__label">{label}</div>
            {reference && <div className="wallet__reference">{reference}</div>}
          </div>
          <div className="wallet__meta">
            <div className={`wallet__type wallet__type--${type}`}>
              <i className={`${types[type].icon}`}></i>
              <span className="wallet__type-label">{types[type].title}</span>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="wallet__balance">{balance}</div>
          {type === 'entreprise' &&
            isMobile &&
            (disabled ? (
              <Button variant="badge" className="icon ml-3" status={'neutral'} icon={'icon-lock'} size="medium" />
            ) : (
              <Button variant="badge" className="icon ml-3" status={'neutral'} icon={'icon-chevron-right'} size="medium" />
            ))}
        </div>
      </div>
    </>
  );

  return onClick ? (
    <a
      href="#julaya"
      onClick={e => {
        e.preventDefault();
        onClick();
      }}
      disabled={disabled}
      className={cx('wallet-wrapper', {
        selected: selected
      })}>
      {wallet}
    </a>
  ) : (
    wallet
  );
};

export default Wallet;
